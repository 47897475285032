import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CableproCalculationServiceProxy } from '@shared/service-proxies/service-proxy-calc';
import { UpdateXmlVersionData } from '../models/arcflash.model';
import { PdfReportServiceProxy } from '@shared/service-proxies/service-proxy-rpt';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HeaderComponent } from '@app/shared/layout/header.component';
import { CalculationService } from 'services/project.service';

@Component({
  selector: 'app-calc-version-update',
  templateUrl: './calc-version-update.component.html',
  styleUrls: ['./calc-version-update.component.css']
})
export class CalcVersionUpdateComponent   extends AppComponentBase implements OnInit {
  public isReportAvailable: boolean = true;
  public calculationid: number;
  public projectId: number;
  public calculationType: string;
  public projectName: string;
  public calculationName: string;
  public reportAttachmentName: string;
  public xmlloader: boolean = false;
  public redirecttoCalculation: boolean = true;
  private UpdateXmlVersionData: UpdateXmlVersionData;
  private CalculationReportInput: any;
  constructor(injector: Injector, config: NgbModalConfig, public activeModal: NgbActiveModal,
        private _pdfReportSerivceProxy: PdfReportServiceProxy,
        private _CableproCalc: CableproCalculationServiceProxy , private _router: Router,private _calculationService: CalculationService
  ) {
    super(injector);
    config.backdrop = 'static';
    config.keyboard = false;
    config.animation = true;
    config.centered = true;
  }

  ngOnInit() {
  }

  updatexml() {
    this.xmlloader = true;
    this.UpdateXmlVersionData = new UpdateXmlVersionData();
    this.UpdateXmlVersionData.CalculationId = this.calculationid
    this.UpdateXmlVersionData.CalculationType = this.calculationType
    this._CableproCalc
      .UpdateXmlVersion(this.UpdateXmlVersionData)
      .subscribe((response) => {
if(response.status==1)
{
        this.xmlloader = false;
        this.activeModal.close('download');
        this._calculationService.refreshSidebarCalculations();
        if(this.redirecttoCalculation)
        this.SetCalculationLink();
}
else{
  this.xmlloader = false;
  this.activeModal.close('download');
  this._modalPopupService.confirm("Developer error", "Selected calculation <b>"+this.calculationName+"</b> cannot be opened in current product version.<br><br>Please contact support for assistance.", (value) => {
    if (value) {
      $(".clsscreenshot").hide();
      $("#contactusDlg").modal("show");    
    }
}, undefined, "Contact Support", "btn-primary", "Close")



}
      });
  }

 SetCalculationLink(): void {

    let calculationType: string = 'calcs';
 
     if (this.calculationType === 'CableSizeBS')
        calculationType = 'bscalcs';
    else if (this.calculationType === 'AutoMaxDemand')
        calculationType = 'automaxd';
    else if (this.calculationType === 'ProtectiveCoordination')
        calculationType = 'pdccalcs';
    else if (this.calculationType === 'CablePulling')
        calculationType = 'calcp';
    else if (this.calculationType === 'NetworkCalculation')
        calculationType = 'networkcalc';
    else if (this.calculationType === 'ArcFlash')
        calculationType = 'arcflash';

 
    this._router.navigate(['/app/main/projects/', this.projectId, calculationType, this.calculationid], { queryParams: { name: this.projectName, cname: this.calculationName } });

}
  DownloadReport(): void {
    this.xmlloader = true;
    this.CalculationReportInput = {};
    this.CalculationReportInput.reportAttachmentName = this.reportAttachmentName;
    this.CalculationReportInput.calculationId = this.calculationid;
    this.CalculationReportInput.calculation_type = this.calculationType;
    if(this.calculationType =='NetworkCalculation' || this.calculationType=='ArcFlash')
    {
        this._pdfReportSerivceProxy.DownloadZipOfReportFromS3(this.CalculationReportInput).subscribe((result) => {           
            let blob = new Blob([result.body], { type: 'application/octet-stream' });
            saveAs(blob, this.CalculationReportInput.reportAttachmentName + ".zip");
            this.xmlloader = false;
            this.activeModal.dismiss(false);
        }, ()=>    {
          this.xmlloader = false;
          this.activeModal.dismiss(false);
          this._modalPopupService.confirm("Developer error", "Error occured while downloading the report.<br><br>Please contact support for assistance.", (value) => {
              if (value) {
                  $(".clsscreenshot").hide();
                  $("#contactusDlg").modal("show");
              }
          }, undefined, "Contact Support", "btn-primary", "Close")
      }
      );
    } 
    else
    {
        this._pdfReportSerivceProxy.GenerateReportFromS3(this.CalculationReportInput).subscribe((result) => {

     
            let blob = new Blob([result.body], { type: 'application/pdf' });
            saveAs(blob, this.CalculationReportInput.reportAttachmentName);
            this.xmlloader = false;
            this.activeModal.dismiss(false);

     

        }, ()=>    {
          this.xmlloader = false;
          this.activeModal.dismiss(false);
          this._modalPopupService.confirm("Developer error", "Error occured while downloading the report.<br><br>Please contact support for assistance.", (value) => {
              if (value) {
                  $(".clsscreenshot").hide();
                  $("#contactusDlg").modal("show");
              }
          }, undefined, "Contact Support", "btn-primary", "Close")
      }
      );
    }
   
}


}
