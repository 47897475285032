import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injector, Input, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    ArmourDto, BSDRFServiceServiceProxy, CableproServiceProxy, CableproXmlInput, CalculationInput,
    CalculationReportInput, ConductorDto, CoreDto, CustomereportFieldsInput, DRFWizrd, GetCurrentLoginInformationsOutput,
    InstallTableDto, InsulationDto, PhaseDto, SettingOutput, SpecifyConductorDto, TableItem, UiControlsLookupDto, UserLoginInfoDto, WindSpeedDto,
    WizardServiceProxy, WizardFactorInput, WizardFactorOutput, SubscriptionServiceProxy
} from '@shared/service-proxies/service-proxies';
import { CableproCalculationServiceProxy, CalculationResult } from '@shared/service-proxies/service-proxy-calc';
import { PdfReportServiceProxy } from '@shared/service-proxies/service-proxy-rpt';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BSDRFService, BSService, CalculationService, ProductService, SettingService } from '../../../services/project.service';
import { LoaderService } from './loader.service';
import { ErrorWarningModel } from './models/network-calc.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { NgSelectModule } from '@node_modules/@ng-select/ng-select';
import { FormsModule } from '@node_modules/@angular/forms';
import { NgbModule } from '@node_modules/@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '@shared/utils/utils.module';
import { AbpModule } from '@node_modules/abp-ng2-module';
import { CommonModule } from '@node_modules/@angular/common';
import { AddeditcalcComponent } from '../projects/addeditcalc.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedService } from '@shared/service-proxies/shared.service';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL2 } from '../projects/settings.component';
import { ImageUtils } from '@shared/helpers/ImageUtils';
import { CustomName } from './cablepro.component';

class Table22Model {
    Arrangement: number;
    NoOfCircuits: number;
    drf: number;
}
class Table271Model {
    AmbientTemp: number;
    UseStandard: boolean;
    Insulation: number;
    drf: number;
}
class Table25Model {
    CableType: number;
    NoOfCircuits: number;
    TouchingType: number;
    InstallType: number;
    Distance: number;
    drf: number;
    Arrangement: number;
}
/* Depth of burial*/
class Table28Model {
    UseStandard: boolean;
    Depth: number;
    Buried: number;
    drf: number;
}
class Table29Model {
    UseStandard: boolean;
    Resitivity: number;
    Buried: number;
    drf: number;
}
class Table23Model {
    CableType: number;
    Installation: number;
    Row: number;
    Cables: number;
    drf: number;
}
class Table23InstallationModel {
    name: string;
    value: number;
    img: string;
    desc2: string;
    desc: string;
}
@Component({
    selector: 'app-bscalc',
    standalone: true,
    templateUrl: './bscalc.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./loader.component.css'],
    imports: [NgSelectModule, FormsModule, NgbModule, UtilsModule, AbpModule, CommonModule, AddeditcalcComponent, NgxPaginationModule, CustomName]
})
export class BSCalcComponent extends AppComponentBase implements OnInit, AfterViewInit {
    public calculationInput: CalculationInput = new CalculationInput();
    public wizardFactorInput: WizardFactorInput = new WizardFactorInput();
    public wizardFactorInputList: WizardFactorInput[] = [];
    public wizardFactorOutput: WizardFactorOutput[] = [];
    public AddCalcMode: string = 'add';
    public mode: string = 'load';
    public insulationObj: any;
    public conductorObj: any;
    public coreObj: any;
    public phaseObj: any;
    public insulationData: InsulationDto[] = [];
    public conductorData: ConductorDto[] = [];
    public coreData: CoreDto[] = [];
    public phaseData: PhaseDto[] = [];
    public armourData: ArmourDto[] = [];
    public installTableData: InstallTableDto[] = [];
    public specifyConductorData: SpecifyConductorDto[] = [];
    public uiControlsLookUpData: UiControlsLookupDto[] = [];
    public windSpeedData: WindSpeedDto[] = [];
    public PrevInsulationId = 0;
    public PrevConductorId = 0;
    public PrevCoreId = 0;
    public PrevPhaseId = 0;
    public newwizardInstallationtype = 0;
    public PrevImageIndex = 0;
    public PrevEarthConductorId = 0;
    public PrevArmourId = 0;
    public CableproInput = new Model();
    public hasWind: boolean;
    public TableId: number;
    public ShowActive = false;
    public showActiveLab = true;
    public ShowConduitSize: boolean = false;
    public ShowConduitSizeLab: boolean = true;
    public PrevConduitSize: any;
    public ShowNeutral = false;
    public ShowNeutralLab = true;
    public ShowEarth = false;
    public ShowEarthLab = true;
    public CableSize = {};
    public PrevCableSize = null;
    public forceCalc: boolean = false;
    public Xml: any;
    public PrevActiveValue: any;
    public PrevNeutralValue: any;
    public PrevEarthValue: any;
    public PrevMCBVal: any = '';
    public PrevMCCBVal: any = '';
    public PrevACBVal: any = '';
    public PrevHRCVal: any = '';
    public PrevCurveVal: any = '';
    public PrevCustomeRatingVal: any = '';
    public showLable = false;
    public active_res: any = '-';
    public neutral_res: any = '-';
    public earth_res: any = '-';
    public voltage_res: any = '-';
    public voltage_res1: any = '-';
    public faultLoop_res: any = '-';
    public conduit_res: any = '-';
    public Circuitrating_drated: any;
    public Dictatingfactorres: any;
    public Currentrating_cable_tabulated: any;
    public Currentrating_cable_tabulated_Ref_Desc_TableNo: any;
    public Currentrating_cable_tabulated_Ref_Desc_ColumnNo: any;
    public CCC_Currentrating_cable_tabulated_Operating_temperature: any;
    public VoltageDrop_RefAndDesc_Resistance_TableNo: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp: any;
    public VoltageDrop_RefAndDesc_Reactance_TableNo: any;
    public VoltageDrop_RefAndDesc_TableName: any;
    public VoltageDrop_RefAndDesc_ColumnNo: any;
    public VoltageDrop_RefAndDesc_Reactance_ColumnNo: any;
    public VoltageDrop_Data_PhaseConductor_Resistance: any;
    public VoltageDrop_Data_PhaseConductor_Reactance: any;
    public VoltageDrop_Result_MaxLengthOfRun: any;
    public ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size: any = '-';
    public ShortCircuitPerformance_PhaseConductor_kfactor: any = '-';
    public ShortCircuitPerformance_ok: any = '1';
    public ShortCircuitPerformance_EarthConductor_ok: any = '1';
    public displayShortCircuitPerformance!: boolean;
    public ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct: any = '-';
    public ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size: any = '-';
    public ShortCircuitPerformance_NuetralConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size: any = '-';
    public ShortCircuitPerformance_EarthConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct: any = '-';
    public CircuitCurrentrating_Adequate: any;
    public VoltageDrop_Result_VoltageDrop_Acceptable: any;
    public EarthLoop_ok: any;
    public MaxAllowedDevice: any;
    public ConditSizeFound: any;

    public NoofConduit: string = '1';
    public ConduitDiameter: any;
    public MinimumConduitDiameter: any;
    public ConditCableDiameter: any;
    public ConditCableDiameterActive: any;
    public ConditCableDiameterNeutral: any;
    public ConditNoofCablesPerCondit: any;
    public ConditNoofCablesPerConditActive: any;
    public ConditNoofCablesPerConditNeutral: any;
    public ConditSpaceFactorActual: any;
    public ConditSpaceFactorStandard: any;

    @Input() bsCalculationId: number;
    @Input() calculationName: string;
    @Input() projectId: number;
    @Input() projectName: string;
    @Input() isFromWebfacing: boolean = false;


    public cableproXmlInput: CableproXmlInput = new CableproXmlInput();
    public dc_Voltage: string;
    public onePhase_Voltage: string;
    public threePhase_Voltage: string;
    public voltage_txt: string;
    public flag = false;
    public Spare_current_capacity_of_circuit: any = '-';
    public active_NoOf_circuit: any = '1';
    public neutral_NoOf_circuit: any = '1';
    public earth_NoOf_circuit: any = '1';

    public faultloop1: any;
    public faultloop2: any;
    public faultloop3: any;
    public faultloop4: any;
    public faultloop5: any;
    public faultloop6: any;
    public faultloop7: any;

    public CalculationReportInput: CalculationReportInput = new CalculationReportInput();
    public OriDrfWizard: DRFWizrd;
    //report fields
    public vdrop: any;
    public vdroppecent: any;
    public vdropPermissibale: any;
    public referencetempdegCReport: any;
    public standardCountryReport: any;
    public activeCondz1Report: any;
    public Earthcondz2Report: any;
    public settingOutput: SettingOutput = new SettingOutput();
    public companyLogo_ShowLogo: boolean;
    public customeReportFields_ShowCustomField: boolean;
    public companyLogo_FileName: string;
    public showreportloader: boolean = false;

    public customFields: CustomereportFieldsInput[] = [];
    public calculation_type: string = "3";
    public VoltageDrop_Data_NeutralConductor_Resistance: any;
    public VoltageDrop_Data_NeutralConductor_Reactance: any;
    public validProjectName = false;
    public validJobName = false;
    public Three_phaseShort_circuitCurrent: any;
    public Active_to_EarthShort_circuitCurrent: any;
    public tempDeracting: string;
    public currentUnit: string = "1";
    public showEfficency = false;
    public tempRating: string;
    public showEarthButton = true;
    public user!: UserLoginInfoDto | undefined;
    public premiumUser!: boolean;
    public voltageSupplyLabel: string = '';
    public prevVoltageSupplyLabel: string = '';
    private isPlanAddedManually: boolean = false;
    public toRemoveData: any;

    public displayCurrentError!: boolean;

    /* Derating Wizard Fields Starts */
    public UserWizard: boolean;
    public finalDrf: number;
    public wizard: any;
    public drInstallType: number | 1;
    public drBunchingType: number | 1;
    public drBunchingCore: number | 1;

    public table22model: Table22Model;
    public drTable22output: any;
    public drTable22ArrangementValues: any;
    public drTable22CircuitValues: any;

    public table23model: Table23Model;
    public table23output: any;
    public table23RowValues: any;
    public table23CableValues: any;

    public table25model: Table25Model;
    public table25NoOfCircuitValues: any;
    public table25CableTypeValues: any;
    public table25ArrangementValues: any;
    public drTable25output: any;

    public table271model: Table271Model;
    public insulationValues: any;
    public drTable271output: any;
    public lblcurrent: string = 'Current';

    public table28model: Table28Model;
    public table28output: any;
    public table28ConductorSizeValues: any;

    public table29model: Table29Model;
    public table29output: any;
    public table29InstallationValues: any;

    private selectInstallTableMethodIndex: any;
    private selectInstallTableTableId: any;
    private selectInstallTableName: string;
    private selectInstallTableDescription: string;
    private selectInstallTableVoltagedropTable: string;
    private selectInstallTableCurrentTable: string;
    /* Derating Wizard Fields Ends */
    public powerunit: string = '1';
    public efficiency: number = 100;

    displayErrorBtn: boolean = false;
    warningErrorStrData: string = '';
    warningErrorBtnText: string = "Warnings";
    warningErrorHeader: string = "Errors";
    warningErrorList: ErrorWarningModel[] = [];
    paginationConfig = {
        id: 'error-pagination-cablepro-bs',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 4
    };
    public earthConductorData: any = [
        { "id": 0, "text": "Same as phase conductor" },
        { "id": 1, "text": "Copper" },
        { "id": 2, "text": "Aluminium" }
    ]
    public devices: any = [
        { "id": '0', "text": "Unknown" },
        { "id": '1', "text": "MCB" },
        { "id": '2', "text": "MCCB" },
        { "id": '3', "text": "ACB" },
        { "id": '4', "text": "HRC Fuse" },
        { "id": '5', "text": "Custom" }
    ]

    public device: string = "0";
    public NeutralDisabled: boolean = false;

    public earthList: any = [
        { "id": '1', "text": "1 mm²" },
        { "id": '1.5', "text": "1.5 mm²" },
        { "id": '2.5', "text": "2.5 mm²" },
        { "id": '4', "text": "4 mm²" },
        { "id": '6', "text": "6 mm²" },
        { "id": '10', "text": "10 mm²" },
        { "id": '16', "text": "16 mm²" },
        { "id": '25', "text": "25 mm²" },
        { "id": '35', "text": "35 mm²" },
        { "id": '50', "text": "50 mm²" },
        { "id": '70', "text": "70 mm²" },
        { "id": '95', "text": "95 mm²" },
        { "id": '120', "text": "120 mm²" },
        { "id": '150', "text": "150 mm²" },
        { "id": '185', "text": "185 mm²" },
        { "id": '240', "text": "240 mm²" },
        { "id": '300', "text": "300 mm²" },
        { "id": '400', "text": "400 mm²" },
        { "id": '500', "text": "500 mm²" },
        { "id": '630', "text": "630 mm²" },

    ]

    public powerUnits: any = [
        { "id": '1', "text": "Amps" },
        { "id": '2', "text": "Watts" },
        { "id": '3', "text": "kW" },
        { "id": '4', "text": "h.p." }
    ]
    public conduitSizesPristine: any = [
        { "id": '16', "text": "1 x 16 mm" },
        { "id": '20', "text": "1 x 20 mm" },
        { "id": '25', "text": "1 x 25 mm" },
        { "id": '32', "text": "1 x 32 mm" },
        { "id": '40', "text": "1 x 40 mm" },
        { "id": '50', "text": "1 x 50 mm" },
        { "id": '63', "text": "1 x 63 mm" },
        { "id": '65', "text": "1 x 65 mm" },
        { "id": '80', "text": "1 x 80 mm" },
        { "id": '100', "text": "1 x 100 mm" },
        { "id": '125', "text": "1 x 125 mm" },
        { "id": '150', "text": "1 x 150 mm" },
        { "id": '175', "text": "1 x 175 mm" },
        { "id": '225', "text": "1 x 225 mm" },
        { "id": '300', "text": "1 x 300 mm" },
        { "id": '375', "text": "1 x 375 mm" },
        { "id": '475', "text": "1 x 475 mm" },
        { "id": '600', "text": "1 x 600 mm" }
    ]

    public conduitSizes: any = [
        { "id": '16', "text": "1 x 16 mm" },
        { "id": '20', "text": "1 x 20 mm" },
        { "id": '25', "text": "1 x 25 mm" },
        { "id": '32', "text": "1 x 32 mm" },
        { "id": '40', "text": "1 x 40 mm" },
        { "id": '50', "text": "1 x 50 mm" },
        { "id": '63', "text": "1 x 63 mm" },
        { "id": '65', "text": "1 x 65 mm" },
        { "id": '80', "text": "1 x 80 mm" },
        { "id": '100', "text": "1 x 100 mm" },
        { "id": '125', "text": "1 x 125 mm" },
        { "id": '150', "text": "1 x 150 mm" },
        { "id": '175', "text": "1 x 175 mm" },
        { "id": '225', "text": "1 x 225 mm" },
        { "id": '300', "text": "1 x 300 mm" },
        { "id": '375', "text": "1 x 375 mm" },
        { "id": '475', "text": "1 x 475 mm" },
        { "id": '600', "text": "1 x 600 mm" }
    ]


    public earthfaulttime: number;
    public protection_curve: number;
    public rcd_chk: boolean = false;

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private _CableproService: BSService,
        private _CableproCalc: CableproCalculationServiceProxy,
        private _settingService: SettingService,
        private _appSessionService: AppSessionService,
        private loaderService: LoaderService,
        private ref: ChangeDetectorRef,
        private drfService: BSDRFServiceServiceProxy,
        private _pdfReportSerivceProxy: PdfReportServiceProxy,
        private drfGetService: BSDRFService,
        private _calculationService: CalculationService,
        private _CableproDRFService: CableproServiceProxy,
        private _wizardService: WizardServiceProxy,
        private _router: Router,
        private _projectService: ProductService,
        private _subscriptionService: SubscriptionServiceProxy,
        private sharedService: SharedService,
        private http: HttpClient,
        @Optional() @Inject(API_BASE_URL2) baseUrl?: string
    ) {
        super(injector);
        (window as any).bscablePro = this;
        this.baseUrl = baseUrl ? baseUrl : "";
        this.toRemoveData = this.route.params.subscribe((params) => {
            this.bsCalculationId = parseInt(params.cid);
            this.projectId = parseInt(params.pid);
            this.calculationName = this.route.snapshot.queryParamMap.get('cname');
            this.projectName = this.route.snapshot.queryParamMap.get('name');
            this.CalculationReportInput.projectname = this.projectName;
            this.CalculationReportInput.jobNo = this.calculationName;
            this._calculationService.getVoltageDrop().subscribe(result => {
                this.voltageSupplyLabel = result;
                this.prevVoltageSupplyLabel = result;
                if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null) {
                    this.voltageSupplyLabel = 'drop';
                    this.prevVoltageSupplyLabel = 'drop';
                }
                if (this.voltageSupplyLabel == 'rise') {
                    if (this.currentUnit == "1") {
                        this.lblcurrent = 'Output current';
                    }
                    else {
                        this.lblcurrent = 'Output power';
                    }
                }
            });
        });

    }

    private actionSubscription!: Subscription;
    ngOnInit(): void {
        this.actionSubscription = this.sharedService.action$.subscribe((action: string) => {
            if (action === 'report') {
                this.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
                this.companyLogo_FileName = undefined;
                this.CalculationReportInput.eamilList = undefined;
                this.deleteVisible = false;
                $("#reportDlg").modal('show');
            }
        });
        this.inputSubject
            .pipe(debounceTime(1000)) // Adjust the debounce time (in milliseconds) as needed
            .subscribe((value: any) => {
                this.GetTable271Dr(value.e, value.checkdecimalplaces);
            });
        this.CableSize = {
            insulation: '0',
            conductor: '0',
            earthconductor: '0',
            core: '0',
            phase: '0',
            faultlevel: '3',
            supplyvoltage: '230',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            protection: {
                shortcircuittime: '0.1'
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '0',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: '2',
            isderatingwiz: '0',
            deratingtemp: '30'
            ,
            conduitsize: {
                mode: '0',
                size: '0'
            }
        };
        // $('#chkPrintName').prop('checked', true);
        this.CalculationReportInput.printName = true;

        // this.ShowNameChanged();
        this._settingService.getSettings().subscribe((result) => {
            if (result.standards_CableSizing !== undefined) {
                this.CableSize['calculationstandard'] = '2';
                this.CableSize['voltagefactor'] = result.short_Circuit_VoltageFactor_C.toString();
                this.CableSize['dcvoltage'] = result.deafaultVoltages_DcVoltage.toString();
                this.CableSize['onephasevoltage'] = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.CableSize['threephasevoltage'] = result.deafaultVoltages_ThreePhaseAcvoltage.toString();
                this.dc_Voltage = result.deafaultVoltages_DcVoltage.toString();
                this.onePhase_Voltage = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.threePhase_Voltage = result.deafaultVoltages_ThreePhaseAcvoltage.toString();
                this.standardCountryReport = '2';
                this.voltage_txt = this.onePhase_Voltage;
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;

                //report fields
                this.companyLogo_ShowLogo = result.companyLogo_ShowLogo;
                this.customeReportFields_ShowCustomField = result.customeReportFields_ShowCustomField;
                this.companyLogo_FileName = result.companyLogo_LogoUrl;
                this.customFields = result.customFields;
            } else {
                this.CableSize['calculationstandard'] = '2';
                this.CableSize['voltagefactor'] = '1.1';
                this.CableSize['dcvoltage'] = '1500';
                this.CableSize['onephasevoltage'] = '230';
                this.CableSize['threephasevoltage'] = '400';
                this.dc_Voltage = '1500';
                this.onePhase_Voltage = '230';
                this.threePhase_Voltage = '400';
                this.standardCountryReport = '2';
                this.companyLogo_ShowLogo = false;
                this.customeReportFields_ShowCustomField = false;
                this.companyLogo_FileName = '';
                this.customFields = null;
            }
            this.GetData('Load', '', 0, 0, 0, 0, 0, 0, 0, '', '', this.bsCalculationId, 0, 0, 0);
        });
        setTimeout(() => {
            this.specifyConductorChange();
            this.onDropDownChange();
            this.onCheckBoxChange();
            this.onNoEarthChange();
            document.getElementById('defaultOpen').click();

            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });
        });
        this.onretrySave();
        this.premiumUser = false;
        this.isPlanAddedManually = false;
        this.displayCurrentError = false;
        this.getCurrentLoginInformations();
        this.displayShortCircuitPerformance = false;

        this.drInstallType = 1;
        //        this.drBunchingType = 1;
        if (this.CableSize['core'] == "0")
            this.drBunchingCore = 1;
        else
            this.drBunchingCore = 2;
        //this.drBunchingCore = 1;
        this.wizard = undefined;
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        this.table22model = new Table22Model();
        this.table22model.Arrangement = 1;
        this.table22model.NoOfCircuits = 1
        this.table22model.drf = 1;
        this.drTable22output = null;
        this.drTable22ArrangementValues = [
            { "name": "Bunched in air, on a surface, embedded or enclosed - Reference Methods A to F", "value": 1 },
            { "name": "Single layer on wall or floor - Reference Method C", "value": 2 },
            { "name": "Single layer multicore on a perforated horizontal or vertical cable tray system - Reference Method E", "value": 3 },
            { "name": "Single layer multicore on cable ladder system or cleats - Reference Method E", "value": 4 },
        ];
        this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
        { "name": 2, "value": 2 },
        { "name": 3, "value": 3 },
        { "name": 4, "value": 4 },
        { "name": 5, "value": 5 },
        { "name": 6, "value": 6 },
        { "name": 7, "value": 7 },
        { "name": 8, "value": 8 },
        { "name": 9, "value": 9 },
        { "name": 12, "value": 12 },
        { "name": 16, "value": 16 },
        { "name": 20, "value": 20 }];

        this.table25model = new Table25Model();
        this.table25model.NoOfCircuits = 1;
        this.table25model.CableType = 2;
        this.table25model.TouchingType = 1;
        this.table25model.InstallType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;

        this.table25NoOfCircuitValues = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
        ];
        this.table25ArrangementValues = [
            { "name": "Nil (cables touching)", "value": 1 },
            { "name": "One cable diameter", "value": 2 },
            { "name": "Spaced 0.125 m", "value": 3 },
            { "name": "Spaced 0.25 m", "value": 4 },
            { "name": "Spaced 0.5 m", "value": 5 },
        ];

        this.table271model = new Table271Model();
        this.table271model.UseStandard = true;
        this.table271model.AmbientTemp = 30;
        this.table271model.Insulation = 2;
        this.table271model.drf = 1;
        if (this.drInstallType == 1) {
            this.insulationValues = [
                // { "name": "60 &#176;C thermosetting", "value": 1 },
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
                { "name": "Mineral - Thermoplastic covered or bare and exposed to touch 70 &#176;C", "value": 4 },
                { "name": "Mineral - Bare and not exposed to touch 105 &#176;C", "value": 5 },
            ];
        } else {
            this.insulationValues = [
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
            ];
        }
        this.table28model = new Table28Model();
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.7;
        this.table28model.Buried = 1;
        this.table28model.drf = 1;

        $('#txtdepthofburial').attr('disabled', 'true');
        $('#txtresitivity').attr('disabled', 'true');

        this.table29model = new Table29Model();
        this.table29model.UseStandard = true;
        $('#inputUseStandard').attr('disabled', 'true');
        this.table29model.Resitivity = 2.5;
        this.table29model.Buried = 1;
        this.table29model.drf = 1;

        this.table23model = new Table23Model();
        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.98 : 1;
        if (this.drBunchingCore == 1) {
            this.GetTable23Values();
            this.table23CableValues = [
                { "name": "1 cct", "value": 1 },
                { "name": "2 ccts", "value": 2 },
                { "name": "3 ccts", "value": 3 },
            ];
        }
        else {
            this.GetTable24Values();
            this.table23CableValues = [
                { "name": "1", "value": 1 },
                { "name": "2", "value": 2 },
                { "name": "3", "value": 3 },
                { "name": "4", "value": 4 },
                { "name": "5", "value": 5 },
                { "name": "6", "value": 6 },
                { "name": "9", "value": 9 },
            ];
        }
        this.table23RowValues = [
            { "name": "1 tray", "value": 1 },
            { "name": "2 trays", "value": 2 },
            { "name": "3 trays", "value": 3 },
            { "name": "6 trays", "value": 6 },
        ];


        if (localStorage.getItem("ReportSettingEmails")) {
            this.CalculationReportInput.eamilList = localStorage.getItem("ReportSettingEmails");
        }

        // if (this.table29model.UseStandard)
        //     $('#txtresitivity').attr('disabled', 'true');
        //    else
        //     $('#txtresitivity').removeAttr('disabled');

    }

    ngDoCheck() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover'
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if ($('.c-select').length) {
                let $singleSelect = $('.c-select'),
                    $singleSelectHasSearch = $('.c-select.has-search'),
                    $multipleSelect = $('.c-select.c-select--multiple'),
                    $disabledSelect = $('.c-select.is-disabled');

                $singleSelect.select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });

                // multiple select
                $multipleSelect.select2({
                    width: '100%',
                    multiple: true
                });

                // disabled select
                $disabledSelect.select2({
                    width: '100%',
                    disabled: true
                });
            }

            $('#active-toggle').change((e) => {
                if ($(e.target).is(':checked') === true) {

                    this.ShowActive = true;
                    $('#Active_c').removeAttr('disabled');
                    this.showActiveLab = false;
                    this.CableSize['specifyconductor']['phase']['mode'] = '1';
                    this.CableSize['specifyconductor']['phase']['size'] = this.PrevActiveValue !== undefined ? parseFloat(this.PrevActiveValue.replace(' mm²', '')) : 1;
                    this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                    this.getAllControlsValue();
                } else {

                    this.ShowActive = false;
                    $('#Active_c').attr('disabled', 'true');
                    $('#Active_c').val(1);
                    this.showActiveLab = true;
                    this.CableSize['specifyconductor']['phase']['mode'] = '0';
                    this.CableSize['specifyconductor']['phase']['size'] = '25';
                    this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                    this.getAllControlsValue();
                }
            });
            $("#reportDlg").on('shown.bs.modal', () => {
                this.CalculationReportInput.jobNo = this.route.snapshot.queryParamMap.get('cname');
                this.calculationName = this.route.snapshot.queryParamMap.get('cname');
            });
            $('#neutral-toggle').change((e) => {
                if ($(e.target).is(':checked') === true) {
                    this.ShowNeutral = true;
                    $('#Neutral_c').removeAttr('disabled');
                    this.ShowNeutralLab = false;
                    this.CableSize['specifyconductor']['neutral']['mode'] = '1';

                    this.CableSize['specifyconductor']['neutral']['size'] = this.PrevNeutralValue !== undefined ? parseFloat(this.PrevNeutralValue.replace(' mm²', '')) : 1;

                    this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                    this.getAllControlsValue();
                } else {
                    this.ShowNeutral = false;
                    $('#Neutral_c').attr('disabled', 'true');
                    $('#Neutral_c').val(1);
                    this.ShowNeutralLab = true;
                    this.CableSize['specifyconductor']['neutral']['mode'] = '0';
                    this.CableSize['specifyconductor']['neutral']['size'] = '25';
                    this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                    this.getAllControlsValue();
                }
            });
            $('#earth-toggle').change((e) => {
                if ($(e.target).is(':checked') === true) {
                    if (!this.CableproInput.EarthId)
                        this.CableproInput.EarthId = "1";
                    this.ShowEarth = true;
                    $('#Earth_c').removeAttr('disabled');
                    this.ShowEarthLab = false;
                    this.CableSize['specifyconductor']['earth']['mode'] = '2';
                    let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                    if (obj) {
                        this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                    }
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                    this.getAllControlsValue();
                } else {
                    this.ShowEarth = false;
                    $('#Earth_c').attr('disabled', 'true');
                    $('#Earth_c').val(1);
                    this.ShowEarthLab = true;
                    this.CableSize['specifyconductor']['earth']['mode'] = '1';
                    this.CableSize['specifyconductor']['earth']['size'] = '25';
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                    this.getAllControlsValue();
                }
            });

            $('input[name=current]').change(this.showHideProtectionWarning);
            $('#gotopricing1, #gotopricing2, #spSubscribeNow').on('click', (e) => {
                this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
            });

        });


    }

    ngAfterContentInit() {
        $('#correctionWizard').on('hidden.bs.modal', () => {
            if (this.CableSize['isderatingwiz'] == "0")
                this.UserWizard = false;
        });
    }

    GetData(mode: string, value: string, insulationId: number, conductorId: number, coreId: number, phaseId: number, methodIndex: number, installTableId: number, windSpeedId: number, load: string, protection: string, calculationId: number, ConductorIndex: number, CoreIndex: number, armourId: number) {
        this.showLoader();
        this._CableproService.getCableproes(mode, value, insulationId, conductorId, armourId, coreId, phaseId, methodIndex, installTableId, windSpeedId, load, protection, calculationId, ConductorIndex, CoreIndex).subscribe((result) => {
            var opt = {
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            };
            if (mode === 'Load') {
                if (result.cableproXmlInputOpen === null || result.cableproXmlInputOpen === undefined) {
                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.armourData = result.armourDto;
                    this.installTableData = result.installTableDto;
                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.hasWind = false;
                    this.CableproInput.EarthConductorId = 0;

                    if ($('#neutral-toggle').val() !== 'on') {
                        $('#neutral-toggle').click();
                    }
                    this.NeutralDisabled = true;
                    $('#Neutral_c').attr('disabled', 'true');
                    $('#neutral-toggle').attr('disabled', 'true');

                    $('#Active_c').attr('disabled', 'true');
                    $('#Earth_c').attr('disabled', 'true');

                    this.CableproInput.InsulationId = this.insulationData[0].id;
                    this.CableproInput.ConductorId = this.conductorData[0].id;
                    this.CableproInput.CoreId = this.coreData[0].id;
                    this.CableproInput.PhaseId = this.phaseData[0].id;
                    this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                    this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                    this.CableproInput.EarthId = this.earthList[0].id;
                    this.CableproInput.InstallTableId = this.installTableData[0].id;
                    this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                    this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;

                    this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                    this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                    this.CableproInput.ArmourId = this.armourData[0].id;
                    this.CableSize['armour'] = this.armourData[0].xmlValue.toString();
                    if (this.mode == 'load') {
                        this.mode = 'loaded';
                    }
                    this.voltageSupplyLabel = 'drop';
                    this.prevVoltageSupplyLabel = 'drop';
                    this.lblcurrent = 'Current';
                }
                else {
                    this._projectService.refreshSidebarProjects();


                    this.flag = true;
                    this.calculation_type = result.cableproXmlInputOpen.calculationType;
                    if (result.cableproXmlInputOpen.phaseXmlVal == '0') {
                        if ($('#neutral-toggle').val() !== 'on') {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal == '1') {
                        this.NeutralDisabled = false;
                        $('#Neutral_c').removeAttr('disabled');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal == '2') {
                        if ($('#neutral-toggle').prop('checked')) {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal == '3' || result.cableproXmlInputOpen.phaseXmlVal == '4') {
                        this.NeutralDisabled = false;
                        $('#Neutral_c').removeAttr('disabled');
                    }
                    $('#Active_c').attr('disabled', 'true');
                    $('#Earth_c').attr('disabled', 'true');
                    this.device = result.cableproXmlInputOpen.protection_device;
                    $('input[name="current"]').val(result.cableproXmlInputOpen.load_current);
                    $('input[name="Derating factor"]').val(result.cableproXmlInputOpen.load_deratingFactor);
                    this.tempDeracting = result.cableproXmlInputOpen.load_deratingFactor;
                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.efficiency = result.cableproXmlInputOpen.efficiency;
                    if (result.cableproXmlInputOpen.powerunit != null) {

                        this.powerunit = result.cableproXmlInputOpen.powerunit;
                        this.onddlPowerUnitChange(this.powerunit, "true");
                        // $("#ddlunit").val(this.powerunit).trigger("change", "true");

                    }
                    else {
                        this.powerunit = "1";
                        this.onddlPowerUnitChange(this.powerunit, "true");
                        // $("#ddlunit").val(this.powerunit).trigger("change", "true");
                    }
                    $('input[name="power-factor"]').val(result.cableproXmlInputOpen.load_powerFactor);
                    $('input[name="Voltage Drop"]').val(result.cableproXmlInputOpen.supply_voltageDrop);
                    this.voltage_txt = result.cableproXmlInputOpen.supply_voltage;
                    // $('input[name="Voltage-Supply"]').val(result.cableproXmlInputOpen.supply_voltage);
                    $('input[name="fault-level"]').val(result.cableproXmlInputOpen.supply_faultLevel);
                    $('input[name="length-of-run"]').val(result.cableproXmlInputOpen.supply_lengthOfrun);
                    if (result.cableproXmlInputOpen.supply_useVd == '1') {
                        $('#use-vd').prop('checked', true);
                    } else {
                        $('#use-vd').prop('checked', false);
                    }

                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.armourData = result.armourDto;
                    this.installTableData = result.installTableDto;

                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.hasWind = false;
                    this.CableproInput.InstallTableId = result.cableproXmlInputOpen.installTableId;
                    this.PrevConductorId = parseInt(result.cableproXmlInputOpen.conductorXmlVal);

                    this.PrevCoreId = parseInt(result.cableproXmlInputOpen.coreXmlVal);
                    this.PrevArmourId = result.cableproXmlInputOpen.armourXmlVal;
                    this.PrevImageIndex = this.CableproInput.MethodIndex;

                    this.CableproInput.InsulationId = result.cableproXmlInputOpen.insulationId;
                    this.CableproInput.ConductorId = result.cableproXmlInputOpen.conductorId;
                    if (result.cableproXmlInputOpen.earthconductorXmlVal != null && result.cableproXmlInputOpen.earthconductorXmlVal != undefined)
                        this.PrevEarthConductorId = parseInt(result.cableproXmlInputOpen.earthconductorXmlVal);
                    else
                        this.PrevEarthConductorId = 0;
                    this.CableproInput.EarthConductorId = this.PrevEarthConductorId;

                    if (result.cableproXmlInputOpen.isVoltageDrop == false) {
                        this.voltageSupplyLabel = 'rise';
                        this.prevVoltageSupplyLabel = 'rise';
                    }
                    else {
                        this.voltageSupplyLabel = 'drop';
                        this.prevVoltageSupplyLabel = 'drop';
                    }
                    if (this.voltageSupplyLabel == 'rise') {
                        if (this.currentUnit == "1") {
                            this.lblcurrent = 'Output current';
                        }
                        else {
                            this.lblcurrent = 'Output power';
                        }
                    }
                    this.CableproInput.CoreId = result.cableproXmlInputOpen.coreId;

                    this.CableproInput.PhaseId = result.cableproXmlInputOpen.phaseId;
                    this.CableproInput.MethodIndex = result.cableproXmlInputOpen.methodIndex;

                    this.CableproInput.ArmourId = result.cableproXmlInputOpen.armourId;
                    this.CableSize['armour'] = result.cableproXmlInputOpen.armourXmlVal;
                    if (parseInt(result.cableproXmlInputOpen.coreXmlVal) == 0)
                        this.drBunchingCore = 1;
                    else
                        this.drBunchingCore = 2;

                    this.OnChangeCore(null);

                    if (result.cableproXmlInputOpen.protection_device == '0') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        setTimeout(() => {
                            $('#protection input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                        }, 1000);
                    }
                    else if (result.cableproXmlInputOpen.protection_device == '1') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['MCBrating'] = result.cableproXmlInputOpen.protection_MCBrating;
                        this.CableSize['protection']['curve'] = result.cableproXmlInputOpen.protection_curve;
                        this.protection_curve = parseInt(result.cableproXmlInputOpen.protection_curve);
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        setTimeout(() => {
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            // $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_MCB').val(result.cableproXmlInputOpen.protection_MCBrating).trigger('change');
                            // $('#select_Curve').val(result.cableproXmlInputOpen.protection_curve).trigger('change', "false");                            
                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '2') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['MCCBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);

                            let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);
                            // $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_MCCB').val(result.cableproXmlInputOpen.protection_MCCBrating).trigger('change');

                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '3') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['ACBrating'] = result.cableproXmlInputOpen.protection_ACBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);
                            $('#select_ACB').val(result.cableproXmlInputOpen.protection_ACBrating).trigger('change');
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '4') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['HRCFuserating'] = result.cableproXmlInputOpen.protection_HRCrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            // $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_HRCFuse').val(result.cableproXmlInputOpen.protection_HRCrating).trigger('change');
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '5') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['customrating'] = result.cableproXmlInputOpen.protection_Customrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.CableSize['protection']['description'] = result.cableproXmlInputOpen.protection_description;
                        setTimeout(() => {
                            $('input[caption="Custom Rating"]').val(result.cableproXmlInputOpen.protection_Customrating);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('input[caption="Description"]').val(result.cableproXmlInputOpen.protection_description);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            this.select2Dropdown();
                        }, 1000);
                    }
                    this.CableSize['insulation'] = result.cableproXmlInputOpen.insulationXmlVal;
                    this.CableSize['conductor'] = result.cableproXmlInputOpen.conductorXmlVal;
                    this.CableSize['earthconductor'] = result.cableproXmlInputOpen.earthconductorXmlVal;
                    this.CableSize['core'] = result.cableproXmlInputOpen.coreXmlVal;
                    if (this.CableSize['core'] == "0")
                        this.drBunchingCore = 1;
                    else
                        this.drBunchingCore = 2;

                    this.CableSize['phase'] = result.cableproXmlInputOpen.phaseXmlVal;
                    this.CableSize['faultlevel'] = result.cableproXmlInputOpen.supply_faultLevel;
                    this.CableSize['voltagedrop'] = result.cableproXmlInputOpen.supply_voltageDrop;
                    this.CableSize['powerunit'] = result.cableproXmlInputOpen.powerunit;

                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.CableSize['efficiency'] = result.cableproXmlInputOpen.efficiency;
                    else
                        this.CableSize['efficiency'] = this.efficiency;

                    this.CableSize['supplyvoltage'] = result.cableproXmlInputOpen.supply_voltage;
                    this.CableSize['lengthofrun'] = result.cableproXmlInputOpen.supply_lengthOfrun;
                    this.CableSize['usevd'] = result.cableproXmlInputOpen.supply_useVd;
                    this.CableSize['current'] = result.cableproXmlInputOpen.load_current;
                    this.CableSize['currentamp'] = result.cableproXmlInputOpen.load_currentamp;
                    this.CableSize['powerfactor'] = result.cableproXmlInputOpen.load_powerFactor;
                    this.CableSize['deratingfactor'] = parseFloat(result.cableproXmlInputOpen.load_deratingFactor).toFixed(2);
                    this.CableSize['device'] = result.cableproXmlInputOpen.protection_device;
                    this.CableSize['isderatingwiz'] = result.cableproXmlInputOpen.isderatingwiz;
                    this.CableSize['deratingtemp'] = result.cableproXmlInputOpen.deratingtemp;
                    if (result.cableproXmlInputOpen.specify_Phaseconductor_mode == '1') {
                        $('#active-toggle').prop('checked', true);
                        this.showActiveLab = false;
                        this.ShowActive = true;
                        $('#Active_c').removeAttr('disabled');
                        $('#Active_c').val(result.cableproXmlInputOpen.specify_Phaseconductor_circuit);
                        this.CableSize['specifyconductor']['phase']['mode'] = '1';
                        this.CableSize['specifyconductor']['phase']['circuits'] = result.cableproXmlInputOpen.specify_Phaseconductor_circuit;
                        this.CableSize['specifyconductor']['phase']['size'] = result.cableproXmlInputOpen.specify_Phaseconductor_size;
                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Phaseconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.ActiveId = selectedItem.id;
                            this.PrevActiveValue = selectedItem.value.replace(' mm²', '');
                        }
                    }
                    else {
                        this.ShowActive = false;
                        this.showActiveLab = true;
                    }
                    if (result.cableproXmlInputOpen.conduit_Mode == '1') {
                        $('#conduit-size-toggle').prop('checked', true);
                        this.ShowConduitSizeLab = false;
                        this.ShowConduitSize = true;
                        this.CableproInput.ConduitSizeId = parseInt(result.cableproXmlInputOpen.conduit_Size);
                        this.CableSize['conduitsize']['mode'] = '1';
                        this.CableSize['conduitsize']['size'] = parseFloat(result.cableproXmlInputOpen.conduit_Size);

                        this.PrevConduitSize = result.cableproXmlInputOpen.conduit_Size;
                    }
                    else {
                        this.ShowConduitSize = false;
                        this.CableSize['conduitsize']['mode'] = '0';
                        $('#conduit-size-toggle').prop('checked', false);
                        this.ShowConduitSizeLab = true;
                    }
                    if (this.PrevConduitSize !== undefined) {
                        let val = this.conduitSizes.filter(x => x.id === this.PrevConduitSize.trim());
                        if (val.length > 0) {
                            this.CableproInput.ConduitSizeId = val[0].id;
                            this.PrevConduitSize = val[0].id;
                        } else {
                            this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                        }
                    } else {
                        this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                        this.PrevConduitSize = this.conduitSizes[0].id;
                    }

                    if (result.cableproXmlInputOpen.specify_Neutralconductor_mode == '1') {
                        $('#neutral-toggle').prop('checked', true);
                        $('#Neutral_c').val(result.cableproXmlInputOpen.specify_Neutralconductor_circuit);
                        this.CableSize['specifyconductor']['neutral']['circuits'] = result.cableproXmlInputOpen.specify_Neutralconductor_circuit;
                        this.CableSize['specifyconductor']['neutral']['size'] = result.cableproXmlInputOpen.specify_Neutralconductor_size;
                        this.CableSize['specifyconductor']['neutral']['mode'] = 1
                        this.ShowNeutral = true;
                        this.ShowNeutralLab = false

                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size || x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.NeutralId = selectedItem.id;
                            //  this.PrevNeutralValue = selectedItem.value.replace(' mm2', '');
                            this.PrevNeutralValue = result.cableproXmlInputOpen.specify_Neutralconductor_size;
                        }
                    }
                    else {
                        this.ShowNeutral = false;
                        this.ShowNeutralLab = true;
                        $('#neutral-toggle').prop('checked', false);
                        $('#Neutral_c').attr('disabled', 'true');
                    }
                    if (result.cableproXmlInputOpen.specify_Earthconductor_mode == '0') {
                        $('#earth-chk').prop('checked', true);
                        this.ShowEarthLab = true;
                        this.showEarthButton = false;
                        this.ShowEarth = false;
                        this.CableSize['specifyconductor']['earth']['circuits'] = "0";
                        this.CableSize['specifyconductor']['earth']['size'] = '0'
                        this.CableSize['specifyconductor']['earth']['mode'] = '0';
                    } else if (result.cableproXmlInputOpen.specify_Earthconductor_mode == '2') {
                        $('#earth-toggle').prop('checked', true);
                        $('#Earth_c').removeAttr('disabled');
                        $('#Earth_c').val(result.cableproXmlInputOpen.specify_Earthconductor_circuit);
                        this.CableSize['specifyconductor']['earth']['circuits'] = result.cableproXmlInputOpen.specify_Earthconductor_circuit;
                        this.CableSize['specifyconductor']['earth']['mode'] = '2';
                        this.CableSize['specifyconductor']['earth']['size'] = parseFloat(result.cableproXmlInputOpen.specify_Earthconductor_size);
                        var obj = this.earthList.find(x => x.id == result.cableproXmlInputOpen.specify_Earthconductor_size);
                        if (obj) {
                            this.CableproInput.EarthId = obj.id;
                            this.PrevEarthValue = result.cableproXmlInputOpen.specify_Earthconductor_size;
                        }
                        else {
                            this.CableproInput.EarthId = this.earthList[0].id;
                        }
                        this.ShowEarth = true;
                        this.ShowEarthLab = false;

                    }
                    this.CableSize['tableno'] = this.CableproInput.InstallTableId;
                    this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex
                    this.CableSize['description'] = result.cableproXmlInputOpen.installTable_description;

                    if (this.installTableData[0].images != null && this.installTableData[0].images != undefined) {
                        if ((this.installTableData[0].images.length - 1) >= this.CableproInput.MethodIndex) {
                            this.CableSize['currentTable'] = this.installTableData[0].images ? this.installTableData[0].images[this.CableproInput.MethodIndex].currentTable : '';
                            this.CableSize['voltagedropTable'] = this.installTableData[0].images ? this.installTableData[0].images[this.CableproInput.MethodIndex].voltagedropTable : '';
                        }
                        else {
                            this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                            this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                            this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                        }
                    }
                    if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                        if (this.CableSize['tableno'] == '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 1000);
                        } else if (this.CableSize['tableno'] == '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    }
                    if (this.CableSize['tableno'] == '16') {
                        setTimeout(() => {
                            $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                            $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                            $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                            $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                        }, 1000);
                    }
                    this.onCancel();
                    if (result.cableproXmlInputOpen.deratingFactorData) {
                        this.finalDrf = parseFloat(result.cableproXmlInputOpen.deratingFactorData.finalDrf.toFixed(2));
                        this.CalculationReportInput.wizardValues = result.cableproXmlInputOpen.deratingFactorData;
                        $('.chkUW').prop('checked', 'true');
                        $('#deratingFactor').val(this.finalDrf);
                        this.UserWizard = true;
                        this.CableSize['isderatingwiz'] = "1";
                        this.OriDrfWizard = result.cableproXmlInputOpen.deratingFactorData;
                    }
                    this.setWizardFactorValues();
                    //if use wizard
                }
                this.flag = false;
            } else {
                if (result.insulationDto !== null && result.insulationDto !== undefined) {
                    this.insulationData = result.insulationDto;
                    this.CableproInput.InsulationId = result.cableproXmlInputOpen.insulationId;
                    this.CableSize['insulation'] = result.cableproXmlInputOpen.insulationXmlVal;
                    // setTimeout((e) => {
                    //     $('#insulation').val(this.CableproInput.InsulationId).select2(opt);
                    // }, 100);
                }
                if (result.conductorDto !== null && result.conductorDto !== undefined) {
                    this.conductorData = result.conductorDto;
                    this.CableproInput.ConductorId = conductorId;
                    var item = this.conductorData.find(x => x.id == conductorId);
                    if (item) {
                        this.CableSize['conductor'] = item.xmlValue;
                    }
                    else {
                        if (ConductorIndex <= this.conductorData.length - 1) {
                            this.CableproInput.ConductorId = this.conductorData[ConductorIndex].id;
                            this.CableSize['conductor'] = this.conductorData[ConductorIndex].xmlValue;
                        }
                        else {
                            this.CableproInput.ConductorId = this.conductorData[0].id;
                            this.CableSize['conductor'] = this.conductorData[0].xmlValue;
                        }

                    }
                }
                if (this.earthConductorData && this.earthConductorData.length > 0) {
                    if (this.PrevEarthConductorId == -1) {
                        this.PrevEarthConductorId = 1;
                    }
                    this.CableSize['earthconductor'] = this.PrevEarthConductorId;
                    this.CableproInput.EarthConductorId = this.PrevEarthConductorId;
                }
                if (result.coreDto !== null && result.coreDto !== undefined) {
                    this.coreData = result.coreDto;
                    this.CableproInput.CoreId = coreId == 0 ? result.cableproXmlInputOpen.coreId : coreId;
                    var item = this.coreData.find(x => x.id == this.CableproInput.CoreId);
                    if (item) {
                        this.CableSize['core'] = item.xmlValue;
                    }
                    else {
                        if (CoreIndex <= (this.coreData.length - 1)) {
                            this.CableproInput.CoreId = this.coreData[CoreIndex].id;
                            this.CableSize['core'] = this.coreData[CoreIndex].xmlValue;
                        }
                        else {
                            this.CableproInput.CoreId = this.coreData[0].id;
                            this.CableSize['core'] = this.coreData[0].xmlValue;
                        }
                    }
                }
                if (result.armourDto !== null && result.armourDto !== undefined) {
                    this.armourData = result.armourDto;
                    this.CableproInput.ArmourId = armourId;
                    var item = this.armourData.find(x => x.id == this.CableproInput.ArmourId);
                    if (item) {
                        this.CableSize['armour'] = item.xmlValue;
                    }
                    else {
                        this.CableproInput.ArmourId = this.armourData[0].id;
                        this.CableSize['armour'] = this.armourData[0].xmlValue;
                    }
                }
                if (result.phaseDto !== null && result.phaseDto !== undefined) {
                    this.phaseData = result.phaseDto;
                    this.CableproInput.PhaseId = phaseId;
                    var item = result.phaseDto.find(x => x.id == phaseId);
                    if (item) {
                        this.CableSize['phase'] = result.phaseDto.find(x => x.id == phaseId).xmlValue;
                    }
                    else {
                        this.CableproInput.PhaseId = this.phaseData[0].id;
                        this.CableSize['phase'] = this.phaseData[0].xmlValue;
                    }

                    if (this.CableproInput.PhaseId.toString() == '100') {
                        this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '101') {
                        this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '102') {
                        this.CableSize['supplyvoltage'] = this.dc_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '103') {
                        this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '104') {
                        this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                    }
                }


                if (result.installTableDto !== null
                    && result.installTableDto !== undefined
                    && this.installTableData[0].images != null
                    && this.installTableData[0].images.length > 0) {
                    this.installTableData = result.installTableDto;
                    this.CableproInput.InstallTableId = this.installTableData[0].id;
                    this.CableSize['tableno'] = this.installTableData[0].id;

                    if (this.installTableData[0].images != null && this.installTableData[0].images != undefined) {
                        var installTableImageName = '', installTableImageDesc = '';
                        if (this.installTableData[0].images.length - 1 < this.PrevImageIndex) {
                            this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                            this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                            this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[0].name + ' ' + this.installTableData[0].images[0].description;
                            installTableImageName = this.installTableData[0].images[0].name;
                            installTableImageDesc = this.installTableData[0].images[0].description;
                        } else {
                            if (this.installTableData[0].images.length - 1 >= this.PrevImageIndex) {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[this.PrevImageIndex].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[this.PrevImageIndex].voltagedropTable;
                            }
                            else {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                            }
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[this.PrevImageIndex || 0].name + ' ' + this.installTableData[0].images[this.PrevImageIndex || 0].description;
                            installTableImageName = this.installTableData[0].images[0].name;
                            installTableImageDesc = this.installTableData[0].images[0].description;
                        }
                        this.UpdateWizardOnInstallationChange(this.CableproInput.MethodIndex, this.CableproInput.InstallTableId, installTableImageName, installTableImageDesc, this.CableSize['voltagedropTable'], this.CableSize['currentTable'], 'getdata');
                    }
                    if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                        if (this.CableSize['tableno'] == '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 1000);
                        } else if (this.CableSize['tableno'] == '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 1000);
                        }
                    }
                    if (this.CableSize['tableno'] == '16') {
                        setTimeout(() => {
                            $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                            $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                            $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                            $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                        }, 1000);
                    }
                }
                if (result.specifyConductorDto !== null && result.specifyConductorDto !== undefined) {
                    this.specifyConductorData = result.specifyConductorDto;

                    if (this.PrevActiveValue !== undefined) {
                        let val = this.specifyConductorData.filter(x => x.value === this.PrevActiveValue.trim() || x.value === this.PrevActiveValue.trim() + ' mm²');
                        if (val.length > 0) {
                            this.CableproInput.ActiveId = val[0].id;
                        } else {
                            if (this.specifyConductorData && this.specifyConductorData.length > 0) {
                                this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                            }
                        }
                    } else {
                        if (this.specifyConductorData && this.specifyConductorData.length > 0) {
                            this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                        }
                    }
                    if (this.PrevNeutralValue !== undefined) {
                        let val = this.specifyConductorData.filter(x => x.value === this.PrevNeutralValue.trim() || x.value === this.PrevNeutralValue.trim() + ' mm²');
                        if (val.length > 0) {
                            this.CableproInput.NeutralId = val[0].id;
                        } else {
                            if (this.specifyConductorData && this.specifyConductorData.length > 0) {
                                this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                            }
                        }
                    } else {
                        if (this.specifyConductorData && this.specifyConductorData.length > 0) {
                            this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                        }
                    }
                }
                this.hasWind = false;
                if (result.uiControlsLookupDto !== null && result.uiControlsLookupDto !== undefined) {
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.CableSize['protection'] = {};
                    for (let i = 0; i < result.uiControlsLookupDto.length; i++) {
                        if (result.uiControlsLookupDto[i].type === 'Protection' && protection !== '') {
                            if (result.uiControlsLookupDto[i].uiControlsValues === protection) {
                                for (let j = 0; j < result.uiControlsLookupDto[i].controles.length; j++) {
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Short-circuit time') {
                                        this.CableSize['protection']['shortcircuittime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Earth fault time') {
                                        this.CableSize['protection']['earthfaulttime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        this.earthfaulttime = parseInt(result.uiControlsLookupDto[i].controles[j].fDefaultValue);
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Rating') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                if (protection === 'HRC Fuse') {
                                                    this.CableSize['protection']['HRCFuserating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                } else {
                                                    this.CableSize['protection']['' + protection + 'rating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                }
                                            }
                                        }
                                        setTimeout(() => {
                                            if (protection === 'MCB') {
                                                if (this.PrevMCBVal === '') {
                                                    this.PrevMCBVal = $('#select_MCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevMCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCBVal]);
                                                }
                                            } else if (protection === 'MCCB') {
                                                if (this.PrevMCCBVal === '') {
                                                    this.PrevMCCBVal = $('#select_MCCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCCBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevMCCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCCBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCCBVal]);
                                                    let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'ACB') {
                                                if (this.PrevACBVal === '') {
                                                    this.PrevACBVal = $('#select_ACB').prop("selectedIndex");
                                                }
                                                if (this.PrevACBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevACBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevACBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevACBVal]);
                                                    let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];

                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'HRC Fuse') {
                                                if (this.PrevHRCVal === '') {
                                                    this.PrevHRCVal = $('#select_HRCFuse').prop("selectedIndex");
                                                }
                                                if (this.PrevHRCVal !== '') {
                                                    $('#select_HRCFuse')[0][this.PrevHRCVal].selected = true;
                                                    this.CableSize['protection']['HRCFuserating'] = $('#select_HRCFuse')[0][this.PrevHRCVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_HRCFuse')[0][this.PrevHRCVal]);
                                                }
                                            }
                                            this.forceCalc = true;
                                            this.getAllControlsValue();
                                        }, 100);
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Curve') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                this.CableSize['protection']['curve'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                this.protection_curve = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                            }
                                        }
                                        // setTimeout(() => {
                                        //     if (this.PrevCurveVal !== '') {
                                        //         $('#select_Curve')[0][this.PrevCurveVal].selected = true;
                                        //         this.CableSize['protection']['curve'] = $('#select_Curve')[0][this.PrevCurveVal].value;
                                        //     }
                                        // }, 100);
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'RCD') {
                                        this.CableSize['protection']['rcd'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        this.rcd_chk = result.uiControlsLookupDto[i].controles[j].fDefaultValue == "1";
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip current') {
                                        this.CableSize['protection']['tripcurrent'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip Multiplier') {
                                        this.CableSize['protection']['tripmultiplier'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Thermal setting') {
                                        this.CableSize['protection']['thermalsetting'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Custom Rating') {
                                        this.CableSize['protection']['customrating'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        setTimeout(() => {
                                            if (this.PrevCustomeRatingVal === '') {
                                                this.PrevCustomeRatingVal = $('input').filter("[caption='Custom Rating']").val();
                                            }
                                            if (this.PrevCustomeRatingVal !== '') {
                                                $('input').filter("[caption='Custom Rating']").val(this.PrevCustomeRatingVal);
                                                this.CableSize['protection']['customrating'] = $('input').filter("[caption='Custom Rating']").val();
                                                this.ProtectionCalcForCustomRating();
                                            }
                                        }, 100);
                                    } if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Description') {
                                        this.CableSize['protection']['description'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.getAllControlsValue();
            $(".tooltip").hide();
            //   alert();
            this.hideLoader();

        },
            error => {
                this.hideLoader();
            });
    }

    showLoader(): void {
        this.loaderService.showHeader();
    }
    hideLoader(): void {
        this.loaderService.hideHeader();
    }
    getCurrentLoginInformations(): void {
        this.user = this._appSessionService.user;
        this.premiumUser = this.user.premiumUser;
        this.isPlanAddedManually = this.user.isPlanAddedManually;
    }
    ProtectionCalcDropdown(e: any, elem: any) {
        this._calculationService.getPrevValue().subscribe((result) => {
            let prevRatingValue = result;
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            let RatingValue: any = '';

            if (e !== '') {
                RatingValue = e.target.options[e.target.selectedIndex].innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }

            if (elem !== '') {
                RatingValue = elem.innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }

            if (prevRatingValue == null || prevRatingValue == undefined || prevRatingValue == '') {
                prevRatingValue = RatingValue;
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => { }, error => { });
            }

            if (prevRatingValue != RatingValue) {
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => {
                    if ($('input').filter("[caption='Thermal setting']").length) {
                        $('input').filter("[caption='Thermal setting']").val(RatingValue);
                        this.CableSize['protection']['thermalsetting'] = RatingValue;
                    }
                    let currentValue: any = '';
                    let Val1: any = '';
                    let Val2: any = '';
                    if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
                        Val1 = parseFloat(RatingValue);
                    } else {
                        Val1 = parseInt(RatingValue);
                    }
                    if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                        Val2 = parseFloat(MultiplierValue);
                    } else {
                        Val2 = parseInt(MultiplierValue);
                    }
                    currentValue = Val1 * Val2;
                    currentValue = currentValue.toFixed(2);
                    if ($('input').filter("[caption='Trip current']").length) {
                        $('input').filter("[caption='Trip current']").val(currentValue);
                        this.CableSize['protection']['tripcurrent'] = currentValue;
                    }
                    this.getAllControlsValue();
                    // this.highlighttext(this.Dictatingfactorres);
                    //$(".tooltip").hide();
                });
            }
        });
    }

    ProtectionCalcForCustomRating() {
        const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
        const RatingValue = $('input').filter("[caption='Custom Rating']").val().toString();
        $('input').filter("[caption='Thermal setting']").val(RatingValue);
        $('input[caption="Thermal setting"]').attr('max', RatingValue);
        this.CableSize['protection']['thermalsetting'] = RatingValue;
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (RatingValue < (100).toString()) {
            this.showLable = true;
        } else {
            this.showLable = false;
        }
        if (!Number.isNaN(Number(RatingValue)) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
            Val2 = parseFloat(MultiplierValue);
        } else {
            Val2 = parseInt(MultiplierValue);
        }
        currentValue = Val1 * Val2;
        currentValue = currentValue.toFixed(2);
        $('input').filter("[caption='Trip current']").val(currentValue);
        this.CableSize['protection']['tripcurrent'] = currentValue;
        this.getAllControlsValue();
    }
    OnInputChange(e: any, numberofplaces: number) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, numberofplaces);

        const caption: string = e.target.getAttribute('caption');
        const device: string = e.target.getAttribute('device');
        if (caption === 'Short-circuit time') {
            this.CableSize['protection']['shortcircuittime'] = e.target.value;
        } else if (caption === 'Trip current') {
            this.CableSize['protection']['tripcurrent'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Trip Multiplier') {
            this.CableSize['protection']['tripmultiplier'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Thermal setting') {
            this.CableSize['protection']['thermalsetting'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Custom Rating') {
            this.CableSize['protection']['customrating'] = e.target.value;
            this.PrevCustomeRatingVal = e.target.value;
            this.ProtectionCalcForCustomRating();
        }
        this.getAllControlsValue();
    }
    ProtectionCalcForCurrentandMultiplier(e: any, device: any, caption: any) {
        let RatingValue: any = ''
        if (device === 'MCB') {
            RatingValue = $('#select_MCB')[0][this.PrevMCBVal].innerHTML.split(" ")[1];
        } else if (device === 'MCCB') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
            //RatingValue = $('#select_MCCB')[0][this.PrevMCCBVal].innerHTML.split(" ")[1];
        } else if (device === 'ACB') {
            // RatingValue = $('#select_ACB')[0][this.PrevACBVal].innerHTML.split(" ")[1];
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
        } else if (device === 'HRC Fuse') {
            RatingValue = $('#select_HRCFuse')[0][this.PrevHRCVal].innerHTML.split(" ")[1];
        } else if (device === 'Custom') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
        }
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
            Val2 = parseFloat(e.target.value);
        } else {
            Val2 = parseInt(e.target.value);
        }

        if (caption === 'Trip current') {
            currentValue = Math.round(Val2 / Val1 * 100) / 100;
            if ($('input').filter("[caption='Trip Multiplier']").length) {
                $('input').filter("[caption='Trip Multiplier']").val(currentValue);
                this.CableSize['protection']['tripmultiplier'] = currentValue;
            }
        } else if (caption === 'Trip Multiplier') {
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            if ($('input').filter("[caption='Trip current']").length) {
                $('input').filter("[caption='Trip current']").val(currentValue);
                this.CableSize['protection']['tripcurrent'] = currentValue;
            }
        } else if (caption === 'Thermal setting') {
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            if (e.target.value < 100) {
                this.showLable = true;
            } else {
                this.showLable = false;
            }
            if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                Val1 = parseFloat(MultiplierValue);
            } else {
                Val1 = parseInt(MultiplierValue);
            }
            if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
                Val2 = parseFloat(e.target.value);
            } else {
                Val2 = parseInt(e.target.value);
            }
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            $('input').filter("[caption='Trip current']").val(currentValue);
            this.CableSize['protection']['tripcurrent'] = currentValue;
        }
        this.getAllControlsValue();
    }
    onDescriptionChnage(e: any) {
        this.CommonValidationFn(e);

        const caption: string = e.target.getAttribute('caption');
        if (caption === 'Description') {
            this.CableSize['protection']['description'] = e.target.value;
        }
        this.getAllControlsValue();
    }
    tabChange(evt, tabId) {
        //if (tabId === 'tab2' && !this.premiumUser) return false;
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
    }
    /*onSelectInstallTable(methodIndex: any, tableId: any, name: string, description: string, voltagedropTable: string, currentTable: string) {*/
    onSelectInstallTable() {
        let methodIndex = this.selectInstallTableMethodIndex;
        let tableId = this.selectInstallTableTableId;
        let name = this.selectInstallTableName;
        let description = this.selectInstallTableDescription;
        let voltagedropTable = this.selectInstallTableVoltagedropTable;
        let currentTable = this.selectInstallTableCurrentTable;
        this.CableSize['conduitsize']['mode'] = '0';

        if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        }
        if (this.CableSize['tableno'] == '16') {
            setTimeout(() => {
                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
            }, 100);
        }
        this.CableproInput.MethodIndex = methodIndex;
        this.PrevImageIndex = methodIndex;
        $('#divInstallTbl').find('img').each(function (e) {
            if ($(this).attr('data-index') === methodIndex.toString()) {
                $(this).attr('style', 'border:2px #2ea1f8 solid').attr('style', 'width:100%');
            } else {
                $(this).attr('style', 'border:2px #fff solid').attr('style', 'width:100%');
            }
        });
        this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex;
        this.CableSize['description'] = name + ' ' + description;
        this.CableSize['currentTable'] = currentTable;
        this.CableSize['voltagedropTable'] = voltagedropTable;
        this.CableSize['tableno'] = tableId;
        this.CableSize['columnno'] = methodIndex;
        this.GetData('installation', '', 0, this.CableproInput.ConductorId, 0, 0, this.CableproInput.MethodIndex, tableId, 0, '', '', this.bsCalculationId, 0, 0, this.CableproInput.ArmourId);
    }
    highlighttext(txt: any) {
        $(".clscurrent").removeClass('highlightcontrol');
        $("#currentdiv").removeAttr("data-toggle");
        $("#currentdiv").removeAttr("data-original-title");

        $(".clsfaultlevel").removeClass('highlightcontrol');
        $("#faultleveldiv").removeAttr("data-toggle");
        $("#faultleveldiv").removeAttr("data-original-title");
        $("#faultleveldiv").removeAttr("title");

        $(".clsvoltagedrop").removeClass('highlightcontrol');
        $("#voltagedropdiv").removeAttr("data-toggle");
        $("#voltagedropdiv").removeAttr("data-original-title");
        $("#voltagedropdiv").removeAttr("title");

        $(".clsthermal[caption='Thermal setting']").removeClass('highlightcontrol');
        $("#clsthermaldiv").removeAttr("data-original-title");
        $("#clsthermaldiv").removeAttr("data-toggle");

        $(".ddlmcb").removeAttr("data-original-title");
        $(".ddlmcb").removeAttr("data-toggle");

        $(".ddlhrcfuse").removeAttr("data-original-title");
        $(".ddlhrcfuse").removeAttr("data-toggle");

        var droporrise = this.voltageSupplyLabel;

        $(".ddlmcb").removeClass('borderdropdown');
        $(".ddlhrcfuse").removeClass('borderdropdown');

        //$("#clsthermaldiv[caption='Thermal setting']").removeAttr("data-original-title");


        if (txt == 'Current') {
            //this.clscurrent = true;
            $("#currentdiv").attr("data-toggle", "tooltip");
            $("#currentdiv").attr("data-original-title", "Current or device rating is dictating the active size");
            $(".clscurrent").addClass('highlightcontrol');

            $(".clsthermal[caption='Thermal setting']").addClass('highlightcontrol');
            $("#clsthermaldiv").attr("data-toggle", "tooltip");
            $("#clsthermaldiv").attr("data-original-title", "Current or device rating is dictating the active size");

            //$("#clsthermaldiv[caption='Thermal setting']").attr("data-toggle","tooltip");
            $(".ddlmcb").addClass('borderdropdown');
            $(".ddlmcb").attr("data-toggle", "tooltip");
            $(".ddlmcb").attr("data-original-title", "Current or device rating is dictating the active size");

            $(".ddlhrcfuse").addClass('borderdropdown');
            $(".ddlhrcfuse").attr("data-toggle", "tooltip");
            $(".ddlhrcfuse").attr("data-original-title", "Current or device rating is dictating the active size");

            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });


        }
        else if (txt == 'Voltage drop') {

            $(".clsvoltagedrop").addClass('highlightcontrol');
            $("#voltagedropdiv").attr("data-toggle", "tooltip");
            $("#voltagedropdiv").attr("data-original-title", "Voltage " + droporrise + " is dictating the active size");
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Short-circuit') {
            $("#faultleveldiv").attr("data-toggle", "tooltip");
            $("#faultleveldiv").attr("data-original-title", "Short-circuit is dictating the active size");
            $(".clsfaultlevel").addClass('highlightcontrol');
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Voltage drop and Short-circuit') {

            $("#faultleveldiv").attr("data-toggle", "tooltip");
            $("#faultleveldiv").attr("data-original-title", "Voltage " + droporrise + " and/or short-circuit are dictating the active size");
            $("#voltagedropdiv").attr("data-toggle", "tooltip");
            $("#voltagedropdiv").attr("data-original-title", "Voltage " + droporrise + " and/or short-circuit are dictating the active size");

            $(".clsfaultlevel").addClass('highlightcontrol');
            $(".clsvoltagedrop").addClass('highlightcontrol');
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Manual') {

        }
    }
    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
    getAllControlsValue() {
        this.flag = false;
        this.showLoader();
        /*if (this.isCalculating) {
            return;
        }*/
        if (this.CableSizeValuesUpdate()) {
            this.CableSize['browsername'] = this.detectBrowserName();
            this.CableSize['UserName'] = this.user.userName;
            this._CableproCalc.BSCalculate(this.CableSize).subscribe((result) => {

                if (result.errorstring) {
                    this.warningErrorStrData = JSON.stringify(result.errorstring);
                    this.generateErrorsModel(result.errorstring);
                }

                this.forceCalc = false;
                let res = new CalculationResult();
                res.Id = this.bsCalculationId;
                res.resultValue = JSON.stringify(result);
                if (this.bsCalculationId > 0) {
                    this._CableproCalc.setCalculationOutput(res).subscribe((result) => { });
                }
                this.Xml = result;
                this.Dictatingfactorres = this.Xml.Dictatingfactorres;
                //  $(".tooltip").hide();
                this.highlighttext(this.Dictatingfactorres);
                // alert();
                if (this.Xml.active && !isNaN(this.Xml.active.Val) && this.Xml.active.Val.toString().indexOf('.') != -1) {
                    this.active_res = parseFloat(this.Xml.active.Val);
                    let val = this.specifyConductorData.filter(x => x.value === this.active_res + ' mm²');
                    if (val.length > 0) {
                        this.CableproInput.ActiveId = val[0].id;
                    }
                } else {
                    this.active_res = this.Xml.active ? parseInt(this.Xml.active.Val) : 0;
                }

                if (this.Xml.neutral && !isNaN(this.Xml.neutral.Val) && this.Xml.neutral.Val.toString().indexOf('.') != -1) {
                    this.neutral_res = parseFloat(this.Xml.neutral.Val);
                } else {
                    this.neutral_res = this.Xml.neutral ? parseInt(this.Xml.neutral.Val) : 0;
                }
                this.active_NoOf_circuit = this.Xml.active1 ? this.Xml.active1.Val : 1;
                this.neutral_NoOf_circuit = this.Xml.neutral1 ? this.Xml.neutral1.Val : 1;

                $('#Active_c').val(this.active_NoOf_circuit);
                $('#Neutral_c').val(this.neutral_NoOf_circuit);
                if ($('#earth-chk').is(':checked') === true) {
                    this.earth_NoOf_circuit = '-';
                    $('#earth_lab').children().hide();
                    this.earth_res = '-';
                } else {
                    this.earth_NoOf_circuit = this.Xml.earth1 ? this.Xml.earth1.Val : 0;
                    if (this.Xml.earth && !isNaN(this.Xml.earth.Val) && this.Xml.earth.Val.toString().indexOf('.') != -1) {
                        if ($('#earth_lab').children().length) {
                            $('#earth_lab').children().show();
                        }
                        this.earth_res = (this.Xml.earth ? parseFloat(this.Xml.earth.Val) : 0);// + ' ' + 'mm';
                    } else {
                        if ($('#earth_lab').children().length) {
                            $('#earth_lab').children().show();
                        }
                        this.earth_res = (this.Xml.earth ? parseInt(this.Xml.earth.Val) : 0);// + ' ' + 'mm';
                    }
                }
                $('#Earth_c').val(this.earth_NoOf_circuit);
                this.voltage_res = this.Xml.voltage ? this.RemoveTrailingZeros((Math.round(this.Xml.voltage.Val * 100) / 100).toFixed(2)) : 0;
                this.voltage_res1 = this.Xml.voltageDrop ? this.RemoveTrailingZeros((Math.round(this.Xml.voltageDrop.Val * 100) / 100).toFixed(2)) : 0;
                this.faultLoop_res = this.Xml.earth3 ? Math.round(this.Xml.earth3.Val * 10000) / 10000 : 0;
                this.conduit_res = this.Xml.conduit ? this.Xml.conduit.Val : 0;
                this.Circuitrating_drated = this.Xml.Circuitrating_drated ? Math.round(this.Xml.Circuitrating_drated.Val) : 0;
                this.Currentrating_cable_tabulated = this.Xml.Currentrating_cable_tabulated ? Math.round(this.Xml.Currentrating_cable_tabulated.Val) : '';
                this.Currentrating_cable_tabulated_Ref_Desc_TableNo = this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo ? this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val : '';
                this.Currentrating_cable_tabulated_Ref_Desc_ColumnNo = this.Xml.Currentrating_cable_tabulated_Ref_Desc_ColumnNo ? this.Xml.Currentrating_cable_tabulated_Ref_Desc_ColumnNo.Val : '';
                this.CCC_Currentrating_cable_tabulated_Operating_temperature = this.Xml.CCC_Currentrating_cable_tabulated_Operating_temperature ? Math.round(this.Xml.CCC_Currentrating_cable_tabulated_Operating_temperature.Val * 100) / 100 : '';
                this.VoltageDrop_RefAndDesc_Resistance_TableNo = this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo ? this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val : '';

                this.VoltageDrop_RefAndDesc_TableName = this.Xml.VoltageDrop_RefAndDesc_TableName ? this.Xml.VoltageDrop_RefAndDesc_TableName.Val : '';
                this.VoltageDrop_RefAndDesc_ColumnNo = this.Xml.VoltageDrop_RefAndDesc_ColumnNo ? this.Xml.VoltageDrop_RefAndDesc_ColumnNo.Val : '';

                this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp = this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp ? this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp.Val : '';
                this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp = this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp ? this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp.Val : '';
                this.VoltageDrop_RefAndDesc_Reactance_TableNo = this.Xml.VoltageDrop_RefAndDesc_Reactance_TableNo ? this.Xml.VoltageDrop_RefAndDesc_Reactance_TableNo.Val : '';
                this.VoltageDrop_RefAndDesc_Reactance_ColumnNo = this.Xml.VoltageDrop_RefAndDesc_Reactance_ColumnNo ? this.Xml.VoltageDrop_RefAndDesc_Reactance_ColumnNo.Val : '';
                this.VoltageDrop_Data_PhaseConductor_Resistance = this.Xml.VoltageDrop_Data_PhaseConductor_Resistance ? Number(this.Xml.VoltageDrop_Data_PhaseConductor_Resistance.Val) : '';
                this.VoltageDrop_Data_PhaseConductor_Reactance = this.Xml.VoltageDrop_Data_PhaseConductor_Reactance ? Number(this.Xml.VoltageDrop_Data_PhaseConductor_Reactance.Val) : '';
                this.VoltageDrop_Result_MaxLengthOfRun = this.Xml.VoltageDrop_Result_MaxLengthOfRun ? Math.round(this.Xml.VoltageDrop_Result_MaxLengthOfRun.Val * 100) / 100 : '';

                this.CableSize['currentamp'] = this.Xml.currentamp ? this.Xml.currentamp : '';
                this.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size = this.Xml.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size ? Math.round(this.Xml.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_PhaseConductor_kfactor = this.Xml.ShortCircuitPerformance_PhaseConductor_kfactor ? Math.round(this.Xml.ShortCircuitPerformance_PhaseConductor_kfactor.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_ok = this.Xml.ShortCircuitPerformance_ok.Val;
                this.ShortCircuitPerformance_EarthConductor_ok = this.Xml.ShortCircuitPerformance_EarthConductor_ok.Val;
                if (this.ShortCircuitPerformance_ok == '0')
                    this.displayShortCircuitPerformance = true;
                else
                    this.displayShortCircuitPerformance = false;
                this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct ? Math.round(this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size = this.Xml.ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size ? Math.round(this.Xml.ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_NuetralConductor_Kfactor = this.Xml.ShortCircuitPerformance_NuetralConductor_Kfactor ? Math.round(this.Xml.ShortCircuitPerformance_NuetralConductor_Kfactor.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size = this.Xml.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size ? Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_EarthConductor_Kfactor = this.Xml.ShortCircuitPerformance_EarthConductor_Kfactor ? Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_Kfactor.Val * 100) / 100 : '';
                this.ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct = this.Xml.ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct ? Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct.Val * 100) / 100 : '';

                this.CircuitCurrentrating_Adequate = this.Xml.CircuitCurrentrating_Adequate ? this.Xml.CircuitCurrentrating_Adequate.Val : '';
                this.VoltageDrop_Result_VoltageDrop_Acceptable = this.Xml.VoltageDrop_Result_VoltageDrop_Acceptable ? this.Xml.VoltageDrop_Result_VoltageDrop_Acceptable.Val : '';
                this.EarthLoop_ok = this.Xml.EarthLoop_ok ? this.Xml.EarthLoop_ok.Val : '';
                this.MaxAllowedDevice = this.Xml.MaxAllowedDevice ? this.Xml.MaxAllowedDevice.Val : '';

                this.ConditSizeFound = this.Xml.ConditSizeFound ? this.Xml.ConditSizeFound.Val : '';

                this.ConduitDiameter = this.Xml.ConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConduitDiameter.Val * 100) / 100).toFixed(2)) : "16";
                this.MinimumConduitDiameter = this.Xml.MinimumConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.MinimumConduitDiameter.Val * 100) / 100).toFixed(2)) : "16";
                if (this.Xml.ConditSizeFound == "-") {
                    this.ConditSizeFound = this.Xml.ConditSizeFound;
                    this.ShowConduitSize = false;
                    this.CableSize['conduitsize']['mode'] = '0';
                    $('#conduit-size-toggle').prop('checked', false);
                    this.ShowConduitSizeLab = true;
                    // this.getAllControlsValue();
                }
                else {
                    this.ConditSizeFound = this.Xml.ConditSizeFound.Val;
                    this.ConduitDiameter = this.Xml.ConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConduitDiameter.Val * 100) / 100).toFixed(2)) : 0;
                    if (this.CableSize['conduitsize']['mode'] == '0') {
                        this.CableSize['conduitsize']['size'] = this.ConduitDiameter;
                        this.CableproInput.ConduitSizeId = this.ConduitDiameter;
                    }
                }

                this.NoofConduit = this.Xml.NoofConduit ? this.Xml.NoofConduit.Val : '1';
                this.ConditCableDiameter = this.Xml.ConditCableDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameter.Val * 100) / 100).toFixed(2)) : 0;
                this.ConditCableDiameterActive = this.Xml.ConditCableDiameterActive ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameterActive.Val * 100) / 100).toFixed(2)) : 0;
                this.ConditCableDiameterNeutral = this.Xml.ConditCableDiameterNeutral ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameterNeutral.Val * 100) / 100).toFixed(2)) : 0;
                this.conduitSizes = this.conduitSizesPristine.filter(m => parseFloat(m.id) >= parseFloat(this.MinimumConduitDiameter));
                if (this.ShowConduitSize) {
                    let selectedOption = this.conduitSizes.find(x => x.id == this.CableproInput.ConduitSizeId);
                    if (!selectedOption) {
                        this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                        this.CableSize['conduitsize']['size'] = this.CableproInput.ConduitSizeId;
                        this.getAllControlsValue();
                    }
                }
                this.ConditSpaceFactorActual = this.Xml.ConditSpaceFactorActual ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditSpaceFactorActual.Val * 100) / 100).toFixed(2)) : 0;
                this.ConditSpaceFactorStandard = this.Xml.ConditSpaceFactorStandard ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditSpaceFactorStandard.Val * 100) / 100).toFixed(2)) : 0;
                this.ConditNoofCablesPerCondit = this.Xml.ConditNoofCablesPerCondit ? this.Xml.ConditNoofCablesPerCondit.Val : 0;
                this.ConditNoofCablesPerConditActive = this.Xml.ConditNoofCablesPerConditActive ? this.Xml.ConditNoofCablesPerConditActive.Val : 0;
                this.ConditNoofCablesPerConditNeutral = this.Xml.ConditNoofCablesPerConditNeutral ? this.Xml.ConditNoofCablesPerConditNeutral.Val : 0;
                this.NoofConduit = this.Xml.NoofConduit ? this.RemoveTrailingZeros((Math.round(this.Xml.NoofConduit.Val * 100) / 100).toFixed(2)) : "0";

                this.faultloop1 = this.Xml.faultloop1 ? Number(this.Xml.faultloop1.Val).toFixed(4) : '';
                this.faultloop2 = this.Xml.faultloop2 ? Number(this.Xml.faultloop2.Val).toFixed(4) : '';
                this.faultloop3 = this.Xml.faultloop3 ? Number(this.Xml.faultloop3.Val).toFixed(4) : '';
                this.faultloop4 = this.Xml.faultloop4 ? Number(this.Xml.faultloop4.Val).toFixed(4) : '';
                this.faultloop5 = this.Xml.faultloop5 ? Number(this.Xml.faultloop5.Val).toFixed(4) : '';
                this.faultloop6 = this.Xml.faultloop6 ? Number(this.Xml.faultloop6.Val).toFixed(4) : "-";
                this.faultloop7 = this.Xml.faultloop7 ? Number(this.Xml.faultloop7.Val).toFixed(2) : "-";
                if (this.CircuitCurrentrating_Adequate == '0') {
                    $("#active_lab").css("cssText", "color: red !important;");
                } else {
                    $("#active_lab").css("cssText", "color: rgba(0, 0, 0) !important;");
                }
                // alert(this.VoltageDrop_Result_VoltageDrop_Acceptable);
                if (this.VoltageDrop_Result_VoltageDrop_Acceptable == '0') {
                    $('.vda').css("cssText", "color: red !important;");
                } else {
                    $('.vda').css("cssText", "color: rgba(0, 0,0) !important;");
                }
                if (this.EarthLoop_ok == '0') {
                    $('.fli').css("cssText", "color: red !important;");
                } else {
                    $('.fli').css("cssText", "color: rgba(0,0, 0) !important;");
                }
                if (this.ConditSizeFound == "1") {
                    this.ConditSizeFound = this.Xml.conduit.Val;
                }
                else {
                    this.ConditSizeFound = '-';
                }
                this.CalculationReportInput.imgSrc = '';

                if ($("#selectedimage").length) {

                    this.CalculationReportInput.imgSrc = $("#selectedimage").attr("src");


                }
                this.Spare_current_capacity_of_circuit = this.Xml.Spare_current_capacity_of_circuit ? Math.round(this.Xml.Spare_current_capacity_of_circuit.Val) : '';
                if (this.Spare_current_capacity_of_circuit < 0) {
                    $('.spr').css("cssText", "color: red !important;");
                } else {
                    $('.spr').css("cssText", "color: rgba(0,0, 0) !important;");
                }
                if (this.bsCalculationId) {
                    if (this.Xml != undefined) {
                        this.cableproXmlInput.xmlValue = this.Xml.InputXml;
                        this.cableproXmlInput.id = this.bsCalculationId;

                        this.vdrop = this.Xml.voltageDrop ? this.Xml.voltageDrop.Val : '';
                        if (this.Xml.voltage != null && this.Xml.voltage != undefined && this.Xml.voltage.Val)
                            this.vdroppecent = this.Xml.voltage.Val;
                        this.vdropPermissibale = this.Xml.PermissableVoltageDrop.Val;
                        this.referencetempdegCReport = this.Xml.ReferencetempdegCReport.Val;

                        this.activeCondz1Report = Math.round(this.Xml.ActiveCondz1Report.Val * 1000000) / 1000000;
                        this.Earthcondz2Report = Math.round(this.Xml.Earthcondz2Report.Val * 1000000) / 1000000;
                        this.VoltageDrop_Data_NeutralConductor_Resistance = this.Xml.VoltageDrop_Data_NeutralConductor_Resistance ? Math.round(this.Xml.VoltageDrop_Data_NeutralConductor_Resistance.Val * 10000) / 10000 : '';
                        this.VoltageDrop_Data_NeutralConductor_Reactance = this.Xml.VoltageDrop_Data_NeutralConductor_Reactance ? Math.round(this.Xml.VoltageDrop_Data_NeutralConductor_Reactance.Val * 10000) / 10000 : '';

                        this.Three_phaseShort_circuitCurrent = this.Xml.Three_phaseShort_circuitCurrent ? Math.round(this.Xml.Three_phaseShort_circuitCurrent.Val * 10) / 10 : '';
                        this.Active_to_EarthShort_circuitCurrent = this.Xml.Active_to_EarthShort_circuitCurrent ? Math.round(this.Xml.Active_to_EarthShort_circuitCurrent.Val * 10) / 10 : '';
                        this.tempDeracting = this.CableSize['deratingfactor'];

                        this.cableproXmlInput.xmlValue = this.Xml.InputXml;
                    }
                    this.cableproXmlInput.id = this.bsCalculationId;
                    if (navigator.onLine === true) {
                        this.UpdateXmlValues(this.cableproXmlInput);
                        if (this.mode == 'load') {
                            this.mode = 'loaded';
                        }
                    } else {
                        this.hideLoader();
                        $('#popup-mask').show();
                    }

                    this.showHideProtectionWarning();
                }
            }, error => {
                this.hideLoader();
            });
        }
    }
    UpdateXmlValues(data: CableproXmlInput) {
        if (this.mode == 'load')
            return;
        //this.showLoader();
        this._CableproService.storeXml(data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            this.hideLoader();
        });
    }
    onCurrentChange(e: any) {
        // if (!this.premiumUser && e.target.value > 200) {
        //     $('#current-restriction-model').modal('show');
        //     e.target.value = this.CableSize['current'];
        //     return false;
        // }
        // else {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 4);
        this.CableSize['current'] = e.target.value;
        this.getAllControlsValue();
        //}
    }
    CommonValidationFn(event: any) {

        if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            event.target.value = event.target.max;
        }
        if (parseFloat(event.target.value) < parseFloat(event.target.min) || event.target.value === '') {
            event.target.value = event.target.min;
        }
        //this.getAllControlsValue();
    }
    //CommonValidationProtectionFn(event: any) {
    //    this.CommonValidationFn(event);
    //    this.getAllControlsValue();
    //}
    onDeratingFactorChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);

        this.UserWizard = false;
        //  $('.chkUW').removeAttr('checked');
        $('.chkUW').prop('checked', false);
        //  this.onCancel();

        this.CableSize['deratingfactor'] = e.target.value;
        this.getAllControlsValue();
        this.CalculationReportInput.wizardValues = new DRFWizrd();
        this.CalculationReportInput.wizardValues.finalDrf = Number(parseFloat(e.target.value).toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];
        this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);

    }
    onVoltageDrop(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.CableSize['voltagedrop'] = e.target.value;
        this.getAllControlsValue();
    }
    onVoltageSupplyChange(e: any) {
        if (this.CableproInput.PhaseId.toString() == '102') /* DC */
            $('input[name="Voltage-Supply"]').attr('max', 1500);
        else
            $('input[name="Voltage-Supply"]').attr('max', 1000);
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.CableSize['supplyvoltage'] = e.target.value;
        this.getAllControlsValue();
    }
    onFaultLevelChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 4);
        this.CableSize['faultlevel'] = e.target.value;
        this.getAllControlsValue();
    }
    onLengthOfRunChange(e: any) {
        // if (!this.premiumUser && e.target.value > 200) {
        //     $('#length-of-run-restriction-model').modal('show');
        //     e.target.value = this.CableSize['lengthofrun'];
        //     return false;
        // }
        // else {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.CableSize['lengthofrun'] = e.target.value;
        this.getAllControlsValue();
        //   }
    }
    onActiveCircuitChange(e: any) {
        this.CommonValidationFn(e);
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['phase']['circuits'] = val.toFixed(0);
        this.getAllControlsValue();
    }
    onNeutralCircuitChange(e: any) {
        this.CommonValidationFn(e);
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['neutral']['circuits'] = val.toFixed(0);
        this.getAllControlsValue();
    }
    onEarthCircuitChange(e: any) {
        this.CommonValidationFn(e);
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['earth']['circuits'] = val.toFixed(0);
        this.getAllControlsValue();
    }
    onPowerFactorChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);
        this.CableSize['powerfactor'] = e.target.value;
        this.getAllControlsValue();
    }
    specifyConductorChange() {
        $('#active-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowActive = true;
                $('#Active_c').removeAttr('disabled');
                this.showActiveLab = false;
                this.CableSize['specifyconductor']['phase']['mode'] = '1';
                this.CableSize['specifyconductor']['phase']['size'] = this.PrevActiveValue !== undefined ? parseFloat(this.PrevActiveValue.replace(' mm²', '')) : 1;
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                this.getAllControlsValue();
            } else {
                this.ShowActive = false;
                $('#Active_c').attr('disabled', 'true');
                $('#Active_c').val(1);
                this.showActiveLab = true;
                this.CableSize['specifyconductor']['phase']['mode'] = '0';
                this.CableSize['specifyconductor']['phase']['size'] = '25';
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                this.getAllControlsValue();
            }
        });
        $('#conduit-size-toggle').change((e) => {

            if ($(e.target).is(':checked') === true) {

                this.ShowConduitSize = true;
                this.ShowConduitSizeLab = false;
                this.CableSize['conduitsize']['mode'] = '1';

                this.getAllControlsValue();
            } else {
                //console.log('not checked');
                this.ShowConduitSize = false;

                this.ShowConduitSizeLab = true;
                this.CableSize['conduitsize']['mode'] = '0';
                this.getAllControlsValue();
            }
        });
        $('#neutral-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowNeutral = true;
                $('#Neutral_c').removeAttr('disabled');
                this.ShowNeutralLab = false;
                this.CableSize['specifyconductor']['neutral']['mode'] = '1';

                this.CableSize['specifyconductor']['neutral']['size'] = this.PrevNeutralValue !== undefined ? parseFloat(this.PrevNeutralValue.replace(' mm²', '')) : 1.5;

                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                this.getAllControlsValue();
            } else {
                this.ShowNeutral = false;
                $('#Neutral_c').attr('disabled', 'true');
                $('#Neutral_c').val(1);
                this.ShowNeutralLab = true;
                this.CableSize['specifyconductor']['neutral']['mode'] = '0';
                this.CableSize['specifyconductor']['neutral']['size'] = '25';
                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                this.getAllControlsValue();
            }
        });
        $('#earth-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowEarth = true;
                $('#Earth_c').removeAttr('disabled');
                this.ShowEarthLab = false;
                this.CableSize['specifyconductor']['earth']['mode'] = '2';
                let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                if (obj) {
                    this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                }
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                this.getAllControlsValue();
            } else {
                this.ShowEarth = false;
                $('#Earth_c').attr('disabled', 'true');
                $('#Earth_c').val(1);
                this.ShowEarthLab = true;
                this.CableSize['specifyconductor']['earth']['mode'] = '1';
                this.CableSize['specifyconductor']['earth']['size'] = '25';
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                this.getAllControlsValue();
            }
        });
    }
    onDropDownChange() {
        $(document).off('change', '#select_HRCFuse');
        $(document).on('change', '#select_HRCFuse', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['HRCFuserating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevHRCVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
        });
        $(document).off('change', '#select_MCB');

        $(document).on('change', '#select_MCB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['MCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevMCBVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
        });
        $(document).off('change', '#select_MCCB');

        $(document).on('change', '#select_MCCB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['MCCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);
                this.PrevMCCBVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
        });
        $(document).off('change', '#select_ACB');

        $(document).on('change', '#select_ACB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);

                this.CableSize['protection']['ACBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevACBVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
        });
    }
    onCheckBoxChange() {
        $(document).off('change', '.chkGroup input:checkbox');
        $(document).on('change', '.chkGroup input:checkbox', (e: any) => {

            if ($(e.target).prop('checked') == true) {
                this.CableSize['protection']['rcd'] = '1';
            } else {
                this.CableSize['protection']['rcd'] = '0';
            }
            this.getAllControlsValue();
        });
    }

    onNoEarthChange() {
        $('#earth-chk').on('change', (e: any) => {

            if ($(e.target).is(':checked') === true) {
                this.CableSize['specifyconductor']['earth']['mode'] = '0';
                this.CableSize['specifyconductor']['earth']['size'] = '0'
                this.CableSize['specifyconductor']['earth']['circuits'] = '0';
                $('#earth-toggle').attr('disabled', 'true');
                this.ShowEarth = false;
                $('#Earth_c').attr('disabled', 'true');
                $('#Earth_c').val(1);
                this.ShowEarthLab = true;
                this.showEarthButton = false;
            } else {
                $('#earth-toggle').removeAttr('disabled');
                this.showEarthButton = true;
                if ($('#earth-toggle').is(':checked') === true) {
                    this.ShowEarth = true;
                    $('#Earth_c').removeAttr('disabled');
                    this.ShowEarthLab = false;
                    this.CableSize['specifyconductor']['earth']['mode'] = '2';
                    let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                    if (obj) {
                        this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                    }
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                } else {
                    this.ShowEarth = false;
                    $('#Earth_c').attr('disabled', 'true');
                    $('#Earth_c').val(1);
                    this.ShowEarthLab = true;
                    this.CableSize['specifyconductor']['earth']['mode'] = '1';
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                }
            }
            this.getAllControlsValue();
        });
    }
    onretrySave() {
        $('#retry-save').on('click', () => {
            if (navigator.onLine === true) {
                this.UpdateXmlValues(this.cableproXmlInput);
                $('#popup-mask').hide();
            }
        });
        $('#dismissAlert').on('click', () => {
            $('#popup-mask').hide();
        });
    }
    SetUpgradeLink() {
        if (this.isPlanAddedManually === true)
            this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
        else
            this.ManageSubscription(this.user, this.loaderService, this._subscriptionService);
        $('#reportDlg').modal('hide')
    }
    ClearReportTrail(): void {
        $('#reportDlg').modal('hide');
    }
    // ShowNameChanged() {
    //     setTimeout(() => {
    //         if (this.CalculationReportInput.printName) {
    //             $('#chkPrintName').attr('checked', 'true');
    //         } else {
    //             $('#chkPrintName').removeAttr('checked');
    //         }
    //     }, 100);
    // }
    ClearReport(): void {
        //this.CalculationReportInput.projectname = '';
        //  this.CalculationReportInput.jobNo = '';
        this.CalculationReportInput.sendEmail = false;
        //  this.CalculationReportInput.eamilList = '';
        this.CalculationReportInput.printName = true;
        // $('#chkPrintName').prop('checked', true);

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        $('#reportDlg').modal('hide');
    }
    downloadTextFile(tabDelimitedContent, filename) {
        const blob = new Blob([tabDelimitedContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    DownloadErrorsAndWarning() {
        const rows = this.warningErrorList
            .map(person => {
                const modifiedIsError = person.IsError == '1' ? "Error" : "Warning";  // Modify the age by adding 5 years
                return `${modifiedIsError}\t${person.ErrorCode}\t${person.Message}`;  // Combine name, modified age, and city
            })
            .join('\n');
        const tabDelimitedContent = ["Type\tCode\Message\n", ...rows].join('');
        this.downloadTextFile(tabDelimitedContent, 'Errors and Warnings.txt')
    }
    GenerateReport(): void {
        if (this.IsReportEmailValid()) {
            if (this.IsReportFormValid()) {
                this.showreportloader = true;
                localStorage.setItem('ReportSettingEmails', this.CalculationReportInput.eamilList);
                this.CalculationReportInput.reportAttachmentName = 'Cable_Pro_Report_';
                this.CalculationReportInput.useWizard = this.UserWizard;
                this.CalculationReportInput.voltagefactor = this.CableSize['voltagefactor'];
                this.CalculationReportInput.conductor = this.CableSize['conductor'];
                this.CalculationReportInput.core = this.CableSize['core'];
                this.CalculationReportInput.current = this.CableSize['current'];
                this.CalculationReportInput.powerunit = this.CableSize['powerunit'];
                this.CalculationReportInput.efficiency = this.CableSize['efficiency'];
                this.CalculationReportInput.dcvoltage = this.CableSize['dcvoltage'];
                this.CalculationReportInput.deratingfactor = this.CableSize['deratingfactor'];
                this.CalculationReportInput.description = this.CableSize['description'];
                this.CalculationReportInput.device = this.CableSize['device'];
                this.CalculationReportInput.endingrowindex = this.CableSize['endingrowindex'];
                this.CalculationReportInput.faultlevel = this.CableSize['faultlevel'];
                this.CalculationReportInput.insulation = this.CableSize['insulation'];
                this.CalculationReportInput.lengthofrun = this.CableSize['lengthofrun'];
                this.CalculationReportInput.onephasevoltage = this.CableSize['onephasevoltage'];
                this.CalculationReportInput.phase = this.CableSize['phase'];
                this.CalculationReportInput.powerfactor = this.CableSize['powerfactor'];
                this.CalculationReportInput.startingrowindex = this.CableSize['startingrowindex'];
                this.CalculationReportInput.supplyvoltage = this.CableSize['supplyvoltage'];
                this.CalculationReportInput.tablectrlvalue = this.CableSize['tablectrlvalue'];
                this.CalculationReportInput.tableno = this.CableSize['tableno'];
                this.CalculationReportInput.threephasevoltage = this.CableSize['threephasevoltage'];
                this.CalculationReportInput.unit = this.CableSize['unit'];
                this.CalculationReportInput.armourId = this.CableSize['armour'];
                this.CalculationReportInput.earthConductor = this.CableSize['earthconductor'];

                this.CalculationReportInput.circuitrating_drated = this.Circuitrating_drated;
                this.CalculationReportInput.Dictatingfactor = this.Dictatingfactorres;
                this.CalculationReportInput.currentrating_cable_tabulated = this.Currentrating_cable_tabulated;
                this.CalculationReportInput.ccC_Currentrating_cable_tabulated_Operating_temperature = this.CCC_Currentrating_cable_tabulated_Operating_temperature;

                this.CalculationReportInput.currentrating_cable_tabulated_Ref_Desc_TableNo = this.Currentrating_cable_tabulated_Ref_Desc_TableNo;
                this.CalculationReportInput.currentrating_cable_tabulated_Ref_Desc_ColumnNo = this.Currentrating_cable_tabulated_Ref_Desc_ColumnNo;
                this.CalculationReportInput.voltageDrop_Result_MaxLengthOfRun = this.VoltageDrop_Result_MaxLengthOfRun;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp = this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp = this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_TableNo = this.VoltageDrop_RefAndDesc_Resistance_TableNo;
                this.CalculationReportInput.spare_current_capacity_of_circuit = this.Spare_current_capacity_of_circuit;
                this.CalculationReportInput.voltageDrop_Data_PhaseConductor_Resistance = this.VoltageDrop_Data_PhaseConductor_Resistance;
                this.CalculationReportInput.calculation_type = this.calculation_type.toString();
                this.CalculationReportInput.conduitmode = this.CableSize['conduitsize']['mode'];
                this.CalculationReportInput.referencetempdegC = this.referencetempdegCReport;
                this.CalculationReportInput.standardCountry = this.standardCountryReport;
                if ($('#select_ACB').length) {
                    this.CalculationReportInput.acBrating = $('#select_ACB option:selected')[0].innerText.trim();
                }
                if ($('#select_MCB').length) {
                    this.CalculationReportInput.mcBrating = $('#select_MCB option:selected')[0].innerText.trim();
                }
                if ($('#select_MCCB').length) {
                    this.CalculationReportInput.mccBrating = $('#select_MCCB option:selected')[0].innerText.trim();
                }
                // if ($('#select_Curve').length) {
                //     this.CalculationReportInput.curve = $('#select_Curve option:selected')[0].innerText.trim();
                // }
                if (this.protection_curve)
                    this.CalculationReportInput.curve = this.protection_curve.toString();// $('#select_Curve option:selected')[0].innerText.trim();

                if ($('#select_HRCFuse').length) {
                    this.CalculationReportInput.hrcFuserating = $('#select_HRCFuse option:selected')[0].innerText.trim();
                }
                this.CalculationReportInput.earthfaulttime = this.CableSize['protection']['earthfaulttime'];
                this.CalculationReportInput.rcd = this.CableSize['protection']['rcd'];
                this.CalculationReportInput.shortcircuittime = this.CableSize['protection']['shortcircuittime'];
                this.CalculationReportInput.thermalsetting = this.CableSize['protection']['thermalsetting'];
                this.CalculationReportInput.tripcurrent = this.CableSize['protection']['tripcurrent'];
                this.CalculationReportInput.tripmultiplier = this.CableSize['protection']['tripmultiplier'];
                this.CalculationReportInput.customrating = this.CableSize['protection']['customrating'];
                this.CalculationReportInput.ConditSizeFound = this.ConditSizeFound;
                this.CalculationReportInput.protectiondescription = this.CableSize['protection']['description'];
                this.CalculationReportInput.faultLoop_res = this.faultLoop_res;
                this.CalculationReportInput.ShortCircuitPerformance_ok = this.ShortCircuitPerformance_ok;
                this.CalculationReportInput.ShortCircuitPerformance_EarthConductor_ok = this.ShortCircuitPerformance_EarthConductor_ok;
                this.CalculationReportInput.shortCircuitPerformance_PhaseConductor_kfactor = this.ShortCircuitPerformance_PhaseConductor_kfactor;
                this.CalculationReportInput.shortCircuitPerformance_PhaseConductor_MinActive_Cond_Size = this.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size ? this.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size : '';
                this.CalculationReportInput.shortCircuitPerformance_EarthConductor_Kfactor = this.ShortCircuitPerformance_EarthConductor_Kfactor;
                this.CalculationReportInput.shortCircuitPerformance_EarthConductor_MinEarth_Cond_Size = this.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size;
                this.CalculationReportInput.active_NoOf_circuit = this.active_NoOf_circuit;
                this.CalculationReportInput.neutral_NoOf_circuit = this.neutral_NoOf_circuit;
                this.CalculationReportInput.earth_NoOf_circuit = this.earth_NoOf_circuit;
                this.CalculationReportInput.active_res = this.active_res;
                this.CalculationReportInput.earth_res = this.earth_res;
                this.CalculationReportInput.neutral_res = this.neutral_res;
                this.CalculationReportInput.activeCondz1Report = this.activeCondz1Report;
                this.CalculationReportInput.earthcondz2Report = this.Earthcondz2Report;
                this.CalculationReportInput.companyLogo_ShowLogo = this.companyLogo_ShowLogo;
                this.CalculationReportInput.customeReportFields_ShowCustomField = this.customeReportFields_ShowCustomField;
                this.CalculationReportInput.companyLogo_FileName = this.companyLogo_FileName;
                this.CalculationReportInput.customFields = this.customFields;
                this.CalculationReportInput.calculationName = this.calculationName;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Reactance_TableNo = this.VoltageDrop_RefAndDesc_TableName;/* In BS Calculation, sending Voltage Drop table name*/
                this.CalculationReportInput.voltageDrop_RefAndDesc_Reactance_ColumnNo = this.VoltageDrop_RefAndDesc_ColumnNo;/* In BS Calculation, sending Voltage Drop column name*/
                this.CalculationReportInput.voltageDrop_Data_NeutralConductor_Resistance = this.VoltageDrop_Data_NeutralConductor_Resistance;
                this.CalculationReportInput.voltageDrop_Data_PhaseConductor_Reactance = this.VoltageDrop_Data_PhaseConductor_Reactance;
                this.CalculationReportInput.voltageDrop_Data_NeutralConductor_Reactance = this.VoltageDrop_Data_NeutralConductor_Reactance;
                this.CalculationReportInput.supplyvoltage = this.CableSize['supplyvoltage'];
                this.CalculationReportInput.voltagedropTable8 = (Math.round(this.vdrop * 100) / 100).toString();
                this.CalculationReportInput.voltageDropTable8Percentage = (Math.round(this.vdroppecent * 100) / 100).toString();
                this.CalculationReportInput.voltageDropPermissisableTable8 = (Math.round(this.vdropPermissibale * 100) / 100).toString();
                this.CalculationReportInput.permissibaleVolatagedrop = this.CableSize['voltagedrop'];
                this.CalculationReportInput.windspeed = this.CableSize['installtablewind']['wind'];
                this.CalculationReportInput.protective_Device_ImpedanceZmax = this.EarthLoop_ok;
                this.CalculationReportInput.faultloop1 = this.faultloop1;
                this.CalculationReportInput.faultloop2 = this.faultloop2;
                this.CalculationReportInput.faultloop3 = this.faultloop3;
                this.CalculationReportInput.faultloop4 = this.faultloop4;
                this.CalculationReportInput.faultloop5 = this.faultloop5;
                this.CalculationReportInput.faultloop6 = this.faultloop6;
                this.CalculationReportInput.faultloop7 = this.faultloop7;
                this.CalculationReportInput.errordata = this.warningErrorStrData;

                this.CalculationReportInput.ConditSizeFound = this.ConditSizeFound;
                this.CalculationReportInput.ConditCableDiameter = this.ConditCableDiameter;
                this.CalculationReportInput.ConditNoofCablesPerCondit = this.ConditNoofCablesPerCondit;
                this.CalculationReportInput.ConditSpaceFactorActual = this.ConditSpaceFactorActual;
                this.CalculationReportInput.ConditSpaceFactorStandard = this.ConditSpaceFactorStandard;

                this.CalculationReportInput.ConditNoofCablesPerConditActive = this.ConditNoofCablesPerConditActive;
                this.CalculationReportInput.ConditNoofCablesPerConditNeutral = this.ConditNoofCablesPerConditNeutral;
                this.CalculationReportInput.ConditCableDiameterActive = this.ConditCableDiameterActive;
                this.CalculationReportInput.ConditCableDiameterNeutral = this.ConditCableDiameterNeutral;
                this.CalculationReportInput.ConduitDiameter = this.ConduitDiameter;

                this.CalculationReportInput.NoofConduit = this.NoofConduit;
                this.CalculationReportInput.MinimumConduitDiameter = this.MinimumConduitDiameter;

                if (this.MaxAllowedDevice === 'Inf') {
                    this.CalculationReportInput.maxAllowedDevice = 'Device Unknown';
                } else {
                    this.CalculationReportInput.maxAllowedDevice = (Math.round(this.MaxAllowedDevice * 1000) / 1000).toString();
                }
                this.CalculationReportInput.short_circuitCurrentText = this.getShortCircuitCurrent();
                this.CalculationReportInput.three_phaseShort_circuitCurrent = this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct;
                this.CalculationReportInput.active_to_EarthShort_circuitCurrent = this.Active_to_EarthShort_circuitCurrent;
                this.CalculationReportInput.earthMode = this.CableSize['specifyconductor']['earth']['mode'];
                this.CalculationReportInput.neutral_mode = this.CableSize['specifyconductor']['neutral']['mode'];
                this.CalculationReportInput.active_mode = this.CableSize['specifyconductor']['phase']['mode'];

                // this.CalculationReportInput.projectname = (this.CalculationReportInput.projectname || '').trim().replace(' ', '-');
                // this.CalculationReportInput.jobNo = (this.CalculationReportInput.jobNo || '').trim().replace(' ', '-');

                if (this.CalculationReportInput.jobNo != null && this.CalculationReportInput.jobNo.trim() == '')
                    this.CalculationReportInput.jobNo = (this.CalculationReportInput.jobNo || '').trim().replace(' ', '-');

                if (this.CalculationReportInput.projectname != null && this.CalculationReportInput.projectname.trim() == '')
                    this.CalculationReportInput.projectname = (this.CalculationReportInput.projectname || '').trim().replace(' ', '-');


                this.CalculationReportInput.reportAttachmentName += this.projectName + '_';
                this.CalculationReportInput.reportAttachmentName += this.calculationName + '.pdf';
                this.CalculationReportInput.calculationId = this.bsCalculationId;

                this.CalculationReportInput.reportHeaderDate = moment().format('DD-MMM-YYYY hh:mm A').toString();
                this.CalculationReportInput.voltageSupplyLabel = this.voltageSupplyLabel;
                this._pdfReportSerivceProxy.CustomBSCalculationGenerateReport(this.CalculationReportInput).subscribe((result) => {
                    if (!this.CalculationReportInput.sendEmail) {
                        let blob = new Blob([result], { type: 'application/pdf' });
                        saveAs(blob, this.CalculationReportInput.reportAttachmentName);
                    }
                    else {
                        $('#EmailSent').modal('show');
                    }
                    this.ClearReport();
                    this.showreportloader = false;
                });
            }
        }
    }
    EmailReportChanged() {
        setTimeout(() => {
            if (this.CalculationReportInput.sendEmail) {
                $('#chkEmailReport').attr('checked', 'true');
            } else {
                $('#chkEmailReport').removeAttr('checked');
            }
        }, 100);
    }
    rebind() {
        setTimeout(() => {
            this.calculationName = this.route.snapshot.queryParamMap.get('cname');
            this.projectName = this.route.snapshot.queryParamMap.get('name');
        }, 150);
    }
    showHideProtectionWarning() {

        this.displayCurrentError = false;
        if (this.CableSize['protection'] !== undefined) {
            switch (this.device) {
                case '0':
                    this.displayCurrentError = false;
                    break;
                case '1':
                    if (this.CableSize['protection']['MCBrating'] !== undefined) {
                        var tempvalue = parseInt(this.CableSize['protection']['MCBrating']);
                        if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device=MCB][value=' + tempvalue + ']').text()))
                            this.displayCurrentError = true;
                    }
                    break;
                case '2':
                case '3':
                case '5':
                    if (this.CableSize['protection']['thermalsetting'] !== undefined) {
                        if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting']))
                            this.displayCurrentError = true;
                    }
                    break;
                case '4':
                    if (this.CableSize['protection']['HRCFuserating'] !== undefined) {
                        var tempvalue = parseInt(this.CableSize['protection']['HRCFuserating']);
                        if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device="HRC Fuse"][value=' + tempvalue + ']').text()))
                            this.displayCurrentError = true;
                    }
                    break;
            }
        }
    }
    CableSizeValuesUpdate(): boolean {

        if (this.PrevCableSize === null) {
            this.PrevCableSize = {
                insulation: '0',
                conductor: '0',
                earthconductor: '0',
                core: '0',
                phase: '0',
                faultlevel: '3',
                supplyvoltage: '230',
                voltagedrop: '2.5',
                powerunit: '1',
                efficiency: '100',
                lengthofrun: '50',
                current: '100',
                powerfactor: '0.9',
                unit: '0',
                deratingfactor: '1',
                device: '0',
                usevd: '1',
                protection: {
                    shortcircuittime: '0.1'
                },
                installtablewind: { wind: '' },
                specifyconductor: {
                    phase: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    neutral: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    earth: {
                        mode: '1',
                        size: '0',
                        circuits: '1'
                    }
                },
                tableno: '0',
                columnno: '0',
                startingrowindex: '0',
                endingrowindex: '0',
                description: 'Unenclosed Spaced',
                tablectrlvalue: '0',
                voltagefactor: '1.1',
                calculationstandard: '2'
                ,
                isderatingwiz: '0',
                deratingtemp: '30',
                conduitsize: {
                    mode: '0',
                    size: '0'
                }
            };

            this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
            this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
            this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
            this.PrevCableSize['insulation'] = this.CableSize['insulation'];
            this.PrevCableSize['conductor'] = this.CableSize['conductor'];
            this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
            this.PrevCableSize['core'] = this.CableSize['core'];
            this.PrevCableSize['phase'] = this.CableSize['phase'];
            this.PrevCableSize['armour'] = this.CableSize['armour'];
            this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
            this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
            this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
            this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
            this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];
            this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
            this.PrevCableSize['current'] = this.CableSize['current'];
            this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
            this.PrevCableSize['unit'] = this.CableSize['unit'];
            this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
            this.PrevCableSize['device'] = this.CableSize['device'];
            this.PrevCableSize['usevd'] = this.CableSize['usevd'];
            this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
            this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];
            this.PrevCableSize['conduitsize']['mode'] = this.CableSize['conduitsize']['mode'];
            this.PrevCableSize['conduitsize']['size'] = this.CableSize['conduitsize']['size'];
            this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
            this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
            this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
            this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
            this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
            this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
            this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
            this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
            this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
            this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
            this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
            this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
            this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];

            this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];

            this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
            this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
            this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];

            this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits'];
            this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
            this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];

            this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
            this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
            this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];

            this.PrevCableSize['tableno'] = this.CableSize['tableno'];
            this.PrevCableSize['columnno'] = this.CableSize['columnno'];
            this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
            this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex'];
            this.PrevCableSize['description'] = this.CableSize['description'];
            this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue'];
            this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor'];
            this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
            this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
            this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];

            return true;
        }
        else {
            if (this.forceCalc == true)
                return true;
            if (this.PrevCableSize['dcvoltage'] != this.CableSize['dcvoltage']) {
                this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
                return true;
            }

            if (this.PrevCableSize['onephasevoltage'] != this.CableSize['onephasevoltage']) {
                this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['threephasevoltage'] != this.CableSize['threephasevoltage']) {
                this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['insulation'] != this.CableSize['insulation']) {
                this.PrevCableSize['insulation'] = this.CableSize['insulation'];
                return true;
            }

            if (this.PrevCableSize['conductor'] != this.CableSize['conductor']) {
                this.PrevCableSize['conductor'] = this.CableSize['conductor'];
                return true;
            }
            if (this.PrevCableSize['earthconductor'] != this.CableSize['earthconductor']) {
                this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
                return true;
            }
            if (this.PrevCableSize['core'] != this.CableSize['core']) {
                this.PrevCableSize['core'] = this.CableSize['core'];
                return true;
            }

            if (this.PrevCableSize['phase'] != this.CableSize['phase']) {
                this.PrevCableSize['phase'] = this.CableSize['phase'];
                return true;
            }

            if (this.PrevCableSize['armour'] != this.CableSize['armour']) {
                this.PrevCableSize['armour'] = this.CableSize['armour'];
                return true;
            }

            if (this.PrevCableSize['faultlevel'] != this.CableSize['faultlevel']) {
                this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
                return true;
            }

            if (this.PrevCableSize['supplyvoltage'] != this.CableSize['supplyvoltage']) {
                this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
                return true;
            }

            if (this.PrevCableSize['voltagedrop'] != this.CableSize['voltagedrop']) {
                this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
                return true;
            }
            if (this.PrevCableSize['powerunit'] != this.CableSize['powerunit']) {
                this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
                return true;
            }
            if (this.PrevCableSize['efficiency'] != this.CableSize['efficiency']) {
                this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];
                return true;
            }

            if (this.PrevCableSize['lengthofrun'] != this.CableSize['lengthofrun']) {
                this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
                return true;
            }

            if (this.PrevCableSize['current'] != this.CableSize['current']) {
                this.PrevCableSize['current'] = this.CableSize['current'];
                return true;
            }

            if (this.PrevCableSize['powerfactor'] != this.CableSize['powerfactor']) {
                this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
                return true;
            }

            if (this.PrevCableSize['unit'] != this.CableSize['unit']) {
                this.PrevCableSize['unit'] = this.CableSize['unit'];
                return true;
            }

            if (this.PrevCableSize['deratingfactor'] != this.CableSize['deratingfactor']) {
                this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
                return true;
            }

            if (this.PrevCableSize['device'] != this.CableSize['device']) {
                this.PrevCableSize['device'] = this.CableSize['device'];
                return true;
            }

            if (this.PrevCableSize['usevd'] != this.CableSize['usevd']) {
                this.PrevCableSize['usevd'] = this.CableSize['usevd'];
                return true;
            }

            let protectionValuesUpdateCount: number = 0;

            if (this.PrevCableSize['protection']['shortcircuittime'] != this.CableSize['protection']['shortcircuittime']) {
                this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCBrating'] != this.CableSize['protection']['MCBrating']) {
                this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['ACBrating'] != this.CableSize['protection']['ACBrating']) {
                this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['HRCFuserating'] != this.CableSize['protection']['HRCFuserating']) {
                this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCCBrating'] != this.CableSize['protection']['MCCBrating']) {
                this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['curve'] != this.CableSize['protection']['curve']) {
                this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['earthfaulttime'] != this.CableSize['protection']['earthfaulttime']) {
                this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['rcd'] != this.CableSize['protection']['rcd']) {
                this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripcurrent'] != this.CableSize['protection']['tripcurrent']) {
                this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripmultiplier'] != this.CableSize['protection']['tripmultiplier']) {
                this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['thermalsetting'] != this.CableSize['protection']['thermalsetting']) {
                this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['customrating'] != this.CableSize['protection']['customrating']) {
                this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['description'] != this.CableSize['protection']['description']) {
                this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];
                protectionValuesUpdateCount += 1;
            }

            let specifyConductorValuesUpdateCount: number = 0;

            if (this.PrevCableSize['specifyconductor']['phase']['mode'] != this.CableSize['specifyconductor']['phase']['mode']) {
                this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['circuits'] != this.CableSize['specifyconductor']['phase']['circuits']) {
                this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['size'] != this.CableSize['specifyconductor']['phase']['size']) {
                this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['circuits'] != this.CableSize['specifyconductor']['neutral']['circuits']) {
                this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits']
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['size'] != this.CableSize['specifyconductor']['neutral']['size']) {
                this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['mode'] != this.CableSize['specifyconductor']['neutral']['mode']) {
                this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['mode'] != this.CableSize['specifyconductor']['earth']['mode']) {
                this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['size'] != this.CableSize['specifyconductor']['earth']['size']) {
                this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['circuits'] != this.CableSize['specifyconductor']['earth']['circuits']) {
                this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['installtablewind']['wind'] != this.CableSize['installtablewind']['wind']) {
                this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];
                return true;
            }

            if (this.PrevCableSize['tableno'] != this.CableSize['tableno']) {
                this.PrevCableSize['tableno'] = this.CableSize['tableno'];
                return true;
            }

            if (this.PrevCableSize['columnno'] != this.CableSize['columnno']) {
                this.PrevCableSize['columnno'] = this.CableSize['columnno'];
                return true;
            }

            if (this.PrevCableSize['startingrowindex'] != this.CableSize['startingrowindex']) {
                this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
                return true;
            }

            if (this.PrevCableSize['endingrowindex'] != this.CableSize['endingrowindex']) {
                this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex']
                return true;
            }

            if (this.PrevCableSize['description'] != this.CableSize['description']) {
                this.PrevCableSize['description'] = this.CableSize['description']
                return true;
            }

            if (this.PrevCableSize['tablectrlvalue'] != this.CableSize['tablectrlvalue']) {
                this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue']
                return true;
            }

            if (this.PrevCableSize['voltagefactor'] != this.CableSize['voltagefactor']) {
                this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor']
                return true;
            }

            if (this.PrevCableSize['calculationstandard'] != this.CableSize['calculationstandard']) {
                this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
                return true;
            }

            if (this.PrevCableSize['currentTable'] != this.CableSize['currentTable']) {
                this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
                return true;
            }

            if (this.PrevCableSize['voltagedropTable'] != this.CableSize['voltagedropTable']) {
                this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];
                return true;
            }

            if (this.PrevCableSize['isderatingwiz'] != this.CableSize['isderatingwiz']) {
                this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
                return true;
            }
            if (this.PrevCableSize['deratingtemp'] != this.CableSize['deratingtemp']) {
                this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];
                return true;
            }
            if (this.PrevCableSize['conduitsize']['mode'] != this.CableSize['conduitsize']['mode']) {
                this.PrevCableSize['conduitsize']['mode'] = this.CableSize['conduitsize']['mode'];
                return true;
            }

            if (this.PrevCableSize['conduitsize']['size'] != this.CableSize['conduitsize']['size']) {
                this.PrevCableSize['conduitsize']['size'] = this.CableSize['conduitsize']['size'];
                return true;
            }

            this.hideLoader();
            if (specifyConductorValuesUpdateCount > 0 || protectionValuesUpdateCount > 0)
                return true;
            else
                return false;
        }
    }
    SetVoltageLabelValue(calledBy: string) {

        this.CableSize['usevd'] = '1';
        if (calledBy == 'rise') {

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Output current';
            }
            else {
                this.lblcurrent = 'Output power';
            }
        }
        else {

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Current';
            }
            else {
                this.lblcurrent = 'Power';
            }

        }
        this.voltageSupplyLabel = calledBy;

        if (this.voltageSupplyLabel !== this.prevVoltageSupplyLabel) {
            this.prevVoltageSupplyLabel = this.voltageSupplyLabel;
            this.UpdateVoltageLabelValue();
        }
    }
    onEfficiencyChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimal(e);
        this.CableSize['efficiency'] = e.target.value;
        this.getAllControlsValue();

    }
    Allowdecimal(event: any) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        this.efficiency = event.target.value;
    }
    UpdateVoltageLabelValue() {
        if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null || this.voltageSupplyLabel.trim() == '')
            return false;

        if (this.bsCalculationId === undefined || this.bsCalculationId === null || this.bsCalculationId == 0)
            return false;

        let isVoltageDrop: boolean = (this.voltageSupplyLabel == 'drop');
        this.highlighttext(this.Dictatingfactorres);
        this._calculationService.updateVoltageStatus(this.bsCalculationId, isVoltageDrop).subscribe((result) => {
        }, error => {
        });
    }
    onddlEarthConductorChange(value, isTriggered: any = "false") {
        this.CableproInput.EarthConductorId = value;

        if (isTriggered === "true") {
            this.CableSize['earthconductor'] = this.PrevEarthConductorId.toString();
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
        else {
            this.PrevEarthConductorId = value;
            this.CableSize['earthconductor'] = value;
            this.getAllControlsValue();

        }
    }
    IsReportEmailValid(): boolean {

        let isValidFlag: boolean = true;

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        if ($('#chkEmailReport').is(':checked')) {

            let reportEmailAddress: string = $('#sendEmail').val().toString().trim();

            let reportEmailAddressArray: string[] = reportEmailAddress.split(',');

            let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,13}\b$/i;

            if (reportEmailAddress.length === 0) {
                isValidFlag = false;
                $('#sendEmailError1').css('display', '');
            }
            else if (reportEmailAddressArray.length > 0) {
                for (let i = 0; i < reportEmailAddressArray.length; i++) {
                    if (reportEmailAddressArray[i].trim().length > 0 && !emailPattern.test(reportEmailAddressArray[i].trim())) {
                        isValidFlag = false;
                        $('#sendEmailError2').css('display', '');
                        break;
                    }
                }
            }
            else if (!emailPattern.test(reportEmailAddress)) {
                isValidFlag = false;
                $('#sendEmailError2').css('display', '');
            }

            $('#sendEmail').focus();
        }

        return isValidFlag;
    }

    IsReportFormValid(): boolean {
        let isValidFlag: boolean = true;
        $('#RptProjectName').css('display', 'none');
        $('#RptCalculationName').css('display', 'none');
        if ($('#chkPrintName').is(':checked')) {
            let rptProjectName: string = $('#project-name').val().toString().trim();
            if (rptProjectName.length === 0) {
                isValidFlag = false;
                $('#RptProjectName').css('display', '');
                $('#project-name').focus();
                return isValidFlag;
            }
            let rptCalculationName: string = $('#job-number').val().toString().trim();
            if (rptCalculationName.length === 0) {
                isValidFlag = false;
                $('#RptCalculationName').css('display', '');
                $('#job-number').focus();
            }
        }
        return isValidFlag;
    }

    ngOnDestroy() {
        this.toRemoveData.unsubscribe();
        this.bsCalculationId = null;
        this._calculationService.setPrevValue('').subscribe((result) => { }, error => { });
        if (this.actionSubscription) {
            this.actionSubscription.unsubscribe();
        }
    }
    /* to copy object and avoid global value passing*/
    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    RemoveTrailingZeros(numberWithZero: string): string {
        try {
            if (numberWithZero.indexOf('.') != -1) {
                var splitnumberList = numberWithZero.split('.');

                let afterDecimalValue = parseInt(splitnumberList[1]);

                if (afterDecimalValue < 10)
                    return parseFloat(numberWithZero).toString();
                else {
                    let modulusValue = afterDecimalValue % 10;

                    if (modulusValue == 0)
                        return parseFloat(numberWithZero).toFixed(1);
                    else
                        return parseFloat(numberWithZero).toFixed(2);
                }
            }
            else
                return parseInt(numberWithZero).toString();
        } catch (e) {
            return numberWithZero;

        }
    }
    /* BS Cable Size Derating Wizard */
    StoreDHROutput(data: DRFWizrd) {
        if (data === undefined) {
            return
        }
        if (data.finalDrf == 1 && data.result.length === 0) {
            return;
        }
        // this.showLoader();
        // this.updateDeratingFactorValue(this.UserWizard, data);
        this.UserWizard = $('.chkUW:checked').length > 0;
        if (this.UserWizard) {
            // this.CableSize['isderatingwiz'] = "1";
            this.getAllControlsValue();
        }
        else {
            this.CableSize['isderatingwiz'] = "0";
            this.getAllControlsValue();
        }
        this._CableproDRFService.storeDHROutput(this.bsCalculationId, this.UserWizard = $('.chkUW:checked').length > 0, data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            this.hideLoader();
        });
    }
    UseWizardChange(e) {
        setTimeout(() => {
            this.UserWizard = $('.chkUW:checked').length > 0;

            if (this.UserWizard) {
                // this.CableSize['isderatingwiz'] = "1";
                $('#showWizard').click();
            }
            else {
                this.CableSize['isderatingwiz'] = "0";
            }

            this.StoreDHROutput(this.CalculationReportInput.wizardValues);
        });
    }
    SetFirstWizardScreen() {
        this.GetTable271Dr(null);
        this.setInstallationType();

        if (this.drInstallType == 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
            //  $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');

            $("#m_wizard_form_step_2").addClass('m-wizard__form-step--current');
            //  $(".typetwo").removeClass('m-wizard__step--current');
            if (!$(".typeone").hasClass("ng-star-inserted m-wizard__step--done"))
                $(".typeone .m-wizard__step-number span").css("background-color", '#34bfa3');

            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_5").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_6").removeClass('m-wizard__form-step--current');

            // type 2
            $("#m_wizard_form_step_7").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_8").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_9").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        else if (this.drInstallType == 2) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });

            $('#m_wizard').css('display', 'block');
            //   $('#m_wizard_soil').css('display', 'block');
            //$(".typeone").removeClass('m-wizard__step--current');
            if (!$(".typetwo").hasClass("ng-star-inserted m-wizard__step--done"))
                $(".typetwo .m-wizard__step-number span").css("background-color", '#34bfa3');

            $("#m_wizard_form_step_7").addClass('m-wizard__form-step--current');
            //            $(".typetwo").addClass('m-wizard__step--current');

            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_5").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_8").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_9").removeClass('m-wizard__form-step--current');

            // type 1
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_6").removeClass('m-wizard__form-step--current');


            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        this.wizard.goTo(1);
    }

    bindWizard(startStep: number = 1) {
        // if(startStep==1)
        // {
        //     this.onCancel();
        // }

        // this.UserWizard = true;
        // $('.chkUW').prop('checked', 'true');
        this.finalDrf = 1;
        this.wizard = (<any>$('#m_wizard')).mWizard({
            startStep: startStep,
            manualNav: false,
        });
        if (this.drInstallType == 1) {
            this.insulationValues = [
                // { "name": "60 &#176;C thermosetting", "value": 1 },
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
                { "name": "Mineral - Thermoplastic covered or bare and exposed to touch 70 &#176;C", "value": 4 },
                { "name": "Mineral - Bare and not exposed to touch 105 &#176;C", "value": 5 },
            ];
        }
        else {
            this.insulationValues = [
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
            ];
        }
        // $('#optDrInstallType1').click();
        $('input[name=optDrBunchingType],input[name=optDrInstallType]').on('click', (e) => {
            setTimeout((e) => {
                this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
                this.wizard.totalSteps = this.wizard.steps.length;
            }, 300);
            return true;
        });

        this.SetFirstWizardScreen();

    }


    GetTable22Dr(e: any) {
        setTimeout(() => {
            this.drfService.getTable22(this.table22model.Arrangement, this.table22model.NoOfCircuits).subscribe(result => {
                this.table22model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                this.drTable22output = result;
            });
        }, 500);

    }
    /* Air/Soil ambient temprature TABLE 4B1 - Table271*/
    GetTable271Dr(e: any, checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces)
            this.allowDecimalSetModal(e, 'AmbientTemp');
        setTimeout(() => {

            if (e) {
                if ($(e.target).is(':checked') === true)
                    $('#inputUseStandard').attr('disabled', 'true');
                else
                    $('#inputUseStandard').removeAttr('disabled');
            }
            else {
                // $("#drTable271UseStandard").prop("checked",true);
                if ($("#drTable271UseStandard").is(':checked') === true)
                    $('#inputUseStandard').attr('disabled', 'true');
                else
                    $('#inputUseStandard').removeAttr('disabled');
            }

            let standard: number = 2;
            let isCalledByInsulation: boolean = false;

            if (this.table271model.UseStandard == true) {
                if (this.drInstallType == 1) {
                    this.table271model.AmbientTemp = 30;
                    this.table271model.Insulation = 2;
                } else {
                    this.table271model.AmbientTemp = 20;
                    this.table271model.Insulation = 2;
                }

                // $('#drTable271Insulation').val(this.table271model.Insulation).trigger('change');
                //this.drTable271output = undefined;
                if (!isCalledByInsulation)
                    this.SetMinMaxAmbientTemp();
                this.drfService.getTable271(standard, this.table271model.Insulation, this.table271model.AmbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.drTable271output = result;
                    this.drTable271output["Type of Insulation:"] = "";
                    // this.finalDrf = this.table271model.drf;
                });
                this.table271model.drf = 1;
                return false;
            } else {
                if (!isCalledByInsulation)
                    this.SetMinMaxAmbientTemp();
                this.drfService.getTable271(standard, this.table271model.Insulation, this.table271model.AmbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.drTable271output = result;
                    // this.finalDrf = this.table271model.drf;
                });
            }

            this.CableSize['deratingtemp'] = this.table271model.AmbientTemp.toString();

        }, 100);
    }

    allowDecimalSetModal(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "AmbientTemp") {
            this.table271model.AmbientTemp = event.target.value;
        }
        if (type == "AbientTemp") {
            this.table271model.AmbientTemp = event.target.value;
        }
        if (type == "Depth") {
            this.table28model.Depth = event.target.value;
        }


    }
    allowDecimalSetModalThree(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "Resitivity") {
            this.table29model.Resitivity = event.target.value;
        }

    }
    Allowdecimalwithplaces(event: any, places: number) {

        var num = event.target.value;

        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = 0;
        if (places == 2) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        }
        else if (places == 3) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        }
        else if (places == 4) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : event.target.value;

        }

        event.target.value = with2Decimals
        //this.efficiency=event.target.value;
    }

    SetMinMaxAmbientTemp(): void {
        if (this.drInstallType == 1) {
            if (this.table271model.AmbientTemp > 55 && this.table271model.Insulation == 1) { this.table271model.AmbientTemp = 55; }
            else if (this.table271model.AmbientTemp > 60 && (this.table271model.Insulation == 2 || this.table271model.Insulation == 4)) { this.table271model.AmbientTemp = 60; }
            else if (this.table271model.AmbientTemp > 80 && this.table271model.Insulation == 3) { this.table271model.AmbientTemp = 80; }
            else if (this.table271model.AmbientTemp > 95 && this.table271model.Insulation == 5) { this.table271model.AmbientTemp = 95; }
            else if (this.table271model.AmbientTemp < 25) { this.table271model.AmbientTemp = 25; }
        }
        if (this.drInstallType == 2) {
            if (this.table271model.AmbientTemp > 60 && this.table271model.Insulation == 2) {
                this.table271model.AmbientTemp = 60;
            }
            else if (this.table271model.AmbientTemp > 80 && this.table271model.Insulation == 3) {
                this.table271model.AmbientTemp = 80;
            }
            else if (this.table271model.AmbientTemp < 10) {
                this.table271model.AmbientTemp = 10;
            }
        }
    }
    GetTable25Dr() {
        if (this.table25model.NoOfCircuits == 1) {
            this.table25model.drf = 1;
            //this.drTable25output = undefined;
            //return false;
        }

        if (this.table25model.InstallType == 1) {
            this.table25ArrangementValues = [
                { "name": "Nil (cables touching)", "value": 1 },
                { "name": "One cable diameter", "value": 2 },
                { "name": "Spaced 0.125 m", "value": 3 },
                { "name": "Spaced 0.25 m", "value": 4 },
                { "name": "Spaced 0.5 m", "value": 5 },
            ];
        }
        else if (this.table25model.InstallType == 2) {
            this.table25ArrangementValues = [
                { "name": "Nil (ducts touching)", "value": 1 },
                { "name": "Spaced 0.25 m", "value": 4 },
                { "name": "Spaced 0.5 m", "value": 5 },
                { "name": "Spaced 1.0 m", "value": 6 },
            ];

        }
        setTimeout(() => {
            let tempSelected = this.table25ArrangementValues.find(m => m.value == this.table25model.Arrangement);
            if (!tempSelected)
                this.table25model.Arrangement = this.table25ArrangementValues[0].value;
            else
                this.table25model.Arrangement = this.table25model.Arrangement;
            // $('#drTable25Arrangement').val(this.table25model.Arrangement).select2({
            //     width: '100%',
            //     minimumResultsForSearch: Infinity // disable search
            // }).trigger('change');
            // $('#drTable25Arrangement').trigger('select2:select');
            this.setTable25Values(this.table25model.Arrangement);
            // setTimeout(() => { $('#drTable25Arrangement').trigger('select2:select'); }, 500);
        });
    }
    setTable25Dr() {
        if (this.CableSize['core'] == 0)
            this.table25model.CableType = 1;
        else
            this.table25model.CableType = 2;

        this.drfService.getTable25(this.table25model.CableType, this.table25model.NoOfCircuits, this.table25model.TouchingType, this.table25model.Distance, this.table25model.InstallType).subscribe(result => {
            this.table25model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            this.drTable25output = result;
        });
    }
    setTable25Values(arrangementValue: any) {
        switch (parseInt(arrangementValue)) {
            case 1:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 1;
                break;
            case 2:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 2;
                break;
            case 3:
                this.table25model.Distance = 0.125;
                this.table25model.TouchingType = 3;
                break;
            case 4:
                this.table25model.Distance = 0.25;
                this.table25model.TouchingType = 4;
                break;
            case 5:
                this.table25model.Distance = 0.5;
                this.table25model.TouchingType = 5;
                break;
            case 6:
                this.table25model.Distance = 1.0;
                this.table25model.TouchingType = 6;
                break;
        }
        this.setTable25Dr();
    }
    /*Depth of Burial*/
    GetTable28Dr(e, type: any = '', checkdecimalplaces: boolean = false) {
        if (checkdecimalplaces && type == 'Depth')
            this.allowDecimalSetModal(e, 'Depth');
        setTimeout(() => {

            if (this.table28model.UseStandard)
                $('#txtdepthofburial').attr('disabled', 'true');
            else
                $('#txtdepthofburial').removeAttr('disabled');

            if (this.table28model.UseStandard) {
                //6  this.table28model.Buried = 1;

                this.table28model.Depth = 0.7;
                this.table28model.drf = 1;
                //this.table28output = undefined;
                //return false;
                this.setTable28Dr();

            }
            if (this.table28model.Depth < 0.5) {
                this.table28model.Depth = 0.5;
            }
            if (this.table28model.Depth > 3) {
                this.table28model.Depth = 3;
            }
            this.setTable28Dr();
        }, 800);
    }
    setTable28Dr() {
        this.drfService.getTable28(this.table28model.Depth, 0, this.table28model.Buried).subscribe(result => {
            this.table28model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            this.table28output = result;
        });
    }
    OnInstallationTypeChange(e: any) {
        setTimeout(() => {


            this.table271model.UseStandard = true;
            if (this.drInstallType == 1) {
                this.table271model.AmbientTemp = 30;
                this.table271model.Insulation = 2;
                this.insulationValues = [
                    // { "name": "60 &#176;C thermosetting", "value": 1 },
                    { "name": "70 &#176;C thermoplastic", "value": 2 },
                    { "name": "90 &#176;C thermosetting", "value": 3 },
                    { "name": "Mineral - Thermoplastic covered or bare and exposed to touch 70 &#176;C", "value": 4 },
                    { "name": "Mineral - Bare and not exposed to touch 105 &#176;C", "value": 5 },
                ];
            } else {
                this.table271model.AmbientTemp = 20;
                this.table271model.Insulation = 2;
                this.insulationValues = [
                    { "name": "70 &#176;C thermoplastic", "value": 2 },
                    { "name": "90 &#176;C thermosetting", "value": 3 },
                ];
            }
            // $('#drTable271Insulation').val(this.table271model.Insulation).trigger('change');
            this.GetTable271Dr(null);
        });
    }
    /* Soil Thermal Resistivity - Table 4B3*/
    GetTable29Dr(e: any, checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces)
            this.allowDecimalSetModalThree(e, 'Resitivity');
        setTimeout(() => {

            if (this.table29model.UseStandard)
                $('#txtresitivity').attr('disabled', 'true');
            else
                $('#txtresitivity').removeAttr('disabled');

            if (this.table29model.UseStandard) {
                this.table29model.Resitivity = 2.5;
                // this.table29model.Buried = 1;
                this.table29model.drf = 1;
                //    this.table29output = undefined;
                //  return false;
                this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Buried).subscribe(result => {
                    this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.table29output = result;
                    this.table29output["Type of installation:"] = "";
                });
            }

            if (this.table29model.Resitivity > 3) {
                this.table29model.Resitivity = 3;
            }
            if (this.table29model.Resitivity < 0.5) {
                this.table29model.Resitivity = 0.5;
            }
            this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Buried).subscribe(result => {
                this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                this.table29output = result;
            });
        }, 800);
    }
    calltable29(Buried: number) {
        this.drfService.getTable29(this.table29model.Resitivity, Buried).subscribe(result => {
            this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            this.table29output = result;
        });
    }

    public Table23InstallationValues: Table23InstallationModel[];
    public Table24InstallationValues: Table23InstallationModel[];
    GetTable23Values() {
        this.drfGetService.getTable23Installations().subscribe(result => {
            this.Table23InstallationValues = result;
        });
    }
    GetTable24Values() {
        this.drfGetService.getTable24Installations().subscribe(result => {
            this.Table24InstallationValues = result;
        });
    }
    onSelectInstall(value: number) {
        this.table23model.Installation = value;
        //
        // alert(this.CableSize['core']);

        //alert(this.CableSize['core']);
        /* Single Core - 1*/
        if (this.CableSize['core'] == "0") {
            if (value == 2 || value == 5) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];

                if (value == 2) {
                    if (this.table23model.Cables > 2) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                    ];
                } else {
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                        { "name": "3 ccts", "value": 3 },
                    ];
                }
            }
            else {
                this.table23RowValues = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
            }
        }
        else {/* Multi Core - 1*/
            if (value == 1 || value == 5 || value == 6) {
                if (this.table23model.Row > 6) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                    { "name": "6 trays", "value": 6 },
                ];
                if (this.table23model.Cables > 9) {
                    this.table23model.Cables = 1;
                }
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
            }
            else if (value == 2 || value == 7) {
                if (this.table23model.Row > 3) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
                if (this.table23model.Cables > 6) {
                    this.table23model.Cables = 1;
                }
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    { "name": "6", "value": 6 },
                ];
            }
            else if (value == 3 || value == 4) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];
                if (value == 3) {
                    if (this.table23model.Cables > 9) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1", "value": 1 },
                        { "name": "2", "value": 2 },
                        { "name": "3", "value": 3 },
                        { "name": "4", "value": 4 },
                        { "name": "6", "value": 6 },
                        { "name": "9", "value": 9 },
                    ];
                } else {
                    if (this.table23model.Cables > 6) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1", "value": 1 },
                        { "name": "2", "value": 2 },
                        { "name": "3", "value": 3 },
                        { "name": "4", "value": 4 },
                        { "name": "6", "value": 6 },
                    ];
                }
            }
        }
        this.GetTable23Dr(null);
    }
    GetTable23Dr(e: any) {
        if (this.CableSize['core'] == 0)
            this.table23model.CableType = 1;
        else
            this.table23model.CableType = 2;

        this.drfService.getTable23(this.table23model.CableType, this.table23model.Installation, this.table23model.Row, this.table23model.Cables).subscribe(result => {
            this.table23model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            this.table23output = result;
        });
    }
    OnChangeCore(e: any) {
        setTimeout(() => {

            if (this.drBunchingCore == 1) {
                this.table23model.CableType = 1;
                this.table23model.drf = 0.98;
                this.GetTable23Values();
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
            }
            else {
                this.table23model.CableType = 2;
                this.table23model.drf = 1;
                this.GetTable24Values();
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
            }

            this.onSelectInstall(this.table23model.Installation);
        }, 200);
    }
    OnNext() {
        this.setWizardFactorDefaultValues();
        setTimeout(() => {
            this.finalDrf = 1;

            if (this.drInstallType == 1) {
                if (this.drBunchingType != 1) {
                    if (this.drTable22output && this.drBunchingType == 2)
                        this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable22output["Correction Factor:"]).toFixed(2));
                    else if (this.table23output && this.drBunchingType == 3)
                        this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table23output["Correction Factor:"]).toFixed(2));
                }
                if (this.drTable271output) {
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable271output["Correction Factor:"]).toFixed(2));
                    //$("#table27Air1").show();
                }
            }
            else if (this.drInstallType == 2) {
                if (this.drTable25output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable25output["Correction Factor:"]).toFixed(2));
                if (this.table28output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table28output["Correction Factor:"]).toFixed(2));
                if (this.table29output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table29output["Correction Factor:"]).toFixed(2));
                if (this.drTable271output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable271output["Correction Factor:"]).toFixed(2));
            }

            // if (this.drTable22output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable22output["Correction Factor:"]).toFixed(2));
            // }
            // if (this.table23output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table23output["Correction Factor:"]).toFixed(2));
            // }
            // if (this.drTable25output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable25output["Correction Factor:"]).toFixed(2));
            // }
            // if (this.drTable271output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable271output["Correction Factor:"]).toFixed(2));
            // }
            // if (this.table28output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table28output["Correction Factor:"]).toFixed(2));
            // }
            // if (this.table29output) {
            //     this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table29output["Correction Factor:"]).toFixed(2));
            // }
            if (this.wizard.currentStep > 1 && this.wizard.currentStep <= 4 && this.drInstallType == 2) {
                if (this.wizard.currentStep == 2 && this.table25model.InstallType != 0) {
                    this.table28model.Buried = this.table25model.InstallType;
                    this.setTable28Dr();

                }
                if (this.wizard.currentStep == 3 && this.table28model.Buried != 0) {
                    if (this.table28model.Buried != this.table25model.InstallType) {
                        this.table25model.InstallType = this.table28model.Buried;
                    }
                    this.table29model.Buried = this.table28model.Buried;
                    this.calltable29(this.table29model.Buried);
                } if (this.wizard.currentStep == 4 && this.table29model.Buried != 0) {
                    if (this.table29model.Buried != this.table28model.Buried) {
                        this.table25model.InstallType = this.table29model.Buried;
                        this.table28model.Buried = this.table29model.Buried;
                        this.setTable28Dr();
                    }
                }
            }
        }, 250);
    }
    onCancel(fromSubmit: boolean = false) {
        if (!fromSubmit && this.OriDrfWizard) {
            this.CalculationReportInput.wizardValues = this.OriDrfWizard
        }
        else {
            this.CalculationReportInput.wizardValues = new DRFWizrd();
            if (this.finalDrf)
                this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
            else
                this.CalculationReportInput.wizardValues.finalDrf = 1;
            this.CalculationReportInput.wizardValues.result = [];
            if (this.drTable22output) {
                var item = new TableItem();
                item.name = "Table22";
                item.summary = $('#table22').text();;
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table23output) {
                var item = new TableItem();
                item.name = "Table23";
                item.summary = $('#table23').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable25output) {
                var item = new TableItem();
                item.name = "Table25";
                item.summary = $('#table25').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable271output) {
                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table28output) {
                var item = new TableItem();
                item.name = "Table28";
                item.summary = $('#table28').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table29output) {
                var item = new TableItem();
                item.name = "Table29";
                item.summary = $('#table29').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            this.OriDrfWizard = this.CalculationReportInput.wizardValues;
        }
        this.onResetControlValues();
        if (this.wizard)
            this.wizard.goTo(1);
    }
    OnSubmit() {
        this.UserWizard = true;
        this.CableSize['isderatingwiz'] = "1";
        $('.chkUW').prop('checked', 'true');
        $('#deratingFactor').val(this.finalDrf.toFixed(2)).change();
        $('#correctionWizard').modal('toggle');
        if (this.drInstallType == 1)
            $('#startStepAir').click();
        else
            $('#startStepGround').click();
        this.CableSize['deratingfactor'] = this.finalDrf.toFixed(2);
        this.getAllControlsValue();
        //this.onCancel(true);
        this.updateWizardFactorValues();
        //this.StoreDHROutput(this.CalculationReportInput.wizardValues);
        this.SetFirstWizardScreen();
    }

    /* New Work regarding wizard saving values */
    UpdateWizardOnInstallationChange(methodIndex: any, tableId: any, name: string, description: string, voltagedropTable: string, currentTable: string, callby: string) {

        if (callby == 'mainpage') {
            this.selectInstallTableMethodIndex = methodIndex;
            this.selectInstallTableTableId = tableId;
            this.selectInstallTableName = name;
            this.selectInstallTableDescription = description;
            this.selectInstallTableVoltagedropTable = voltagedropTable;
            this.selectInstallTableCurrentTable = currentTable;
        }
        var mainInstallationType = 0;

        let newImageName = (name + ' ' + description).toLowerCase().trim();

        if (newImageName.search('ground') == -1)
            mainInstallationType = 1;
        else
            mainInstallationType = 2;

        if (this.wizardFactorOutput.length > 0) {
            let wizardInstallationtype = this.wizardFactorOutput[0].installationType;
            if (this.newwizardInstallationtype == 0)
                this.newwizardInstallationtype = this.wizardFactorOutput[0].installationType;

            if (this.newwizardInstallationtype != mainInstallationType) {
                this.newwizardInstallationtype = mainInstallationType;
                if (callby == 'mainpage')
                    this.onSelectInstallTable();
                this.UserWizard = false;
                this.CableSize['isderatingwiz'] = "0";
                //   $('.chkUW').removeAttr('checked');
                $('.chkUW').prop('checked', false);
                // this.onCancel();

                this.onResetControlValues();
                this.resetWizardFactorValue();
            }
            else {
                if (callby == 'mainpage')
                    this.onSelectInstallTable();
                //                     if (mainInstallationType == 2)
                //                     {
                //                       //  this.resetWizardControlValuesOnInstallationChange(newImageName);

                //                     }
                if (callby == 'getdata')
                    this.resetWizardFactorValue();
            }
        }
        else {
            if (callby == 'mainpage')
                this.onSelectInstallTable();
            if (callby == 'getdata')
                this.resetWizardFactorValue();
            this.onResetControlValues();
            this.setWizardFactorValues();
        }
    }
    resetWizardFactorValue() {
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        $('.chkUW').prop('checked', false);
        // this.onCancel();

        if (this.finalDrf) {
            this.CalculationReportInput.wizardValues = new DRFWizrd();
            this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
            this.CalculationReportInput.wizardValues.result = [];
            this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);
        }
    }
    updateDeratingFactorValue(useWizard: boolean, data: DRFWizrd) {
        this.showLoader();
        this._CableproDRFService.storeDHROutput(this.bsCalculationId, useWizard, data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            this.hideLoader();
        });
    }

    onResetControlValues() {
        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;

        this.drInstallType = 1;
        this.drBunchingType = 1;
        this.drBunchingCore = 1;
        if (this.CableSize['core'] == "0")
            this.drBunchingCore = 1;
        else
            this.drBunchingCore = 2;


        var imageName = (this.CableSize['description'] != null && this.CableSize['description'] != undefined) ? this.CableSize['description'].toLowerCase().trim() : "";

        if (imageName.search('ground') == -1) {

            this.table22model.Arrangement = 1;
            // (<any>$('#drTable22Arrangement')).select2('val', '1');
            this.table22model.NoOfCircuits = 1;
            // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
            this.table22model.drf = 1;

            this.table23model.CableType = this.drBunchingCore;
            this.table23model.Row = 1;
            (<any>$('#table23Row')).select2('val', '1');
            this.table23model.Cables = 1;
            this.table23model.Installation = 1;
            this.table23model.drf = 0.98;

            this.table271model.UseStandard = true;
            this.table271model.AmbientTemp = 30;
            this.table271model.Insulation = 2;
            this.table271model.drf = 1;
        }
        else {
            this.drInstallType = 2;
            this.table25model.NoOfCircuits = 1;
            // (<any>$('#drTable25NoOfCicruits')).select2('val', '1');
            this.table25model.CableType = this.drBunchingCore;
            this.table25model.TouchingType = 1;
            this.table25model.InstallType = 1;
            this.table25model.Distance = 0;
            this.table25model.drf = 1;
            this.table25model.Arrangement = 1;
            // (<any>$('#drTable25Arrangement')).select2('val', '1');

            this.table28model.UseStandard = true;
            this.table28model.Depth = 0.7;
            this.table28model.Buried = 1;
            this.table28model.drf = 1;

            this.table29model.UseStandard = true;
            this.table29model.Resitivity = 2.50;
            this.table29model.Buried = 1;
            this.table29model.drf = 1;
        }
    }

    setInstallationType() {
        let newImageName = (this.CableSize['description'] != null && this.CableSize['description'] != undefined) ? this.CableSize['description'].toLowerCase().trim() : "";

        if (newImageName.search('ground') == -1)
            this.drInstallType = 1;
        else
            this.drInstallType = 2;
    }
    updateWizardFactorValues() {
        this.wizardFactorInputList = [];
        var stepId = 1;
        var controlId = 1;
        var standardId = this.CableSize['calculationstandard'];
        this.CalculationReportInput.wizardValues = new DRFWizrd();
        this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];

        if (this.drInstallType == 1) {
            if (this.drBunchingType == 2) {
                /* control 1 */
                let arrangementName = this.drTable22ArrangementValues.find(x => x.value == this.table22model.Arrangement).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "Arrangement of Cables";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let circuitName = this.drTable22CircuitValues.find(x => x.value == this.table22model.NoOfCircuits).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "No of circuits";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.NoOfCircuits.toString();
                this.wizardFactorInput.controlText = circuitName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                stepId += 1;
            }
            else if (this.drBunchingType == 3) {
                /* control 1 */
                let rowName = this.table23RowValues.find(x => x.value == this.table23model.Row).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "Number of trays or ladders";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Row.toString();
                this.wizardFactorInput.controlText = rowName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let cableName = this.table23CableValues.find(x => x.value == this.table23model.Cables).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "No. of three-phase circuits per tray or ladder" : "No. of cables per tray or ladder";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Cables.toString();
                this.wizardFactorInput.controlText = cableName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 3 */
                let imgNameandDescription: any;
                if (this.drBunchingCore == 1)
                    imgNameandDescription = this.Table23InstallationValues.find(x => x.value == this.table23model.Installation);
                else
                    imgNameandDescription = this.Table24InstallationValues.find(x => x.value == this.table23model.Installation);

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 3;


                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "Single core - In trays, racks, cleats or other supports in air" : "Multi-core - In trays, racks, cleats or other supports in air";
                this.wizardFactorInput.controlType = "img";
                this.wizardFactorInput.controlValue = this.table23model.Installation.toString();
                this.wizardFactorInput.controlText = imgNameandDescription.name + ' ' + imgNameandDescription.desc;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.imageName = imgNameandDescription.name;
                this.wizardFactorInput.imageDesc = imgNameandDescription.desc;
                this.wizardFactorInput.imageTitle = imgNameandDescription.desc2;
                this.wizardFactorInput.imageSrc = imgNameandDescription.img;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                stepId += 1;
            }

            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 1;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Air temprature";
            this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = "30";
                this.wizardFactorInput.controlText = "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = "30";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AmbientTemp.toString();
                this.wizardFactorInput.controlText = "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = "30";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                let insulationName = this.insulationValues.find(x => x.value == this.table271model.Insulation).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Air temprature";
                this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Insulation";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table271model.Insulation.toString();
                this.wizardFactorInput.controlText = insulationName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            //// Storing results
            var controlId = 1;
            if (this.drBunchingType != 1) {
                if (this.drTable22output && this.drBunchingType == 2) {
                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Correction Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Correction Factor:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Correction Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Arrangement of cables:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Bunched arrangement:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table22";
                    item.summary = $('#table22').text();;
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
                else if (this.table23output && this.drBunchingType == 3) {
                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Correction Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["Correction Factor:"];
                    this.wizardFactorInput.controlText = this.table23output["Correction Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Installation:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["Installation:"];
                    this.wizardFactorInput.controlText = this.table23output["Installation:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "No. of tiers or rows of supports:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.controlText = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.bsCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Conductor size:";
                    this.wizardFactorInput.controlType = "label";
                    if (this.table23output['No.of circuits per tier or row:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No.of circuits per tier or row:'];
                        this.wizardFactorInput.controlText = this.table23output['No.of circuits per tier or row:'];
                    }
                    if (this.table23output['No. of multi-core cables:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No. of multi-core cables:'];
                        this.wizardFactorInput.controlText = this.table23output['No. of multi-core cables:'];
                    }
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table23";
                    item.summary = $('#table23').text();
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
            }
            if (this.drTable271output) {
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Correction Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Correction Factor:"];
                this.wizardFactorInput.controlText = this.drTable271output["Correction Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Air ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }

            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = "Overall Correction Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

        }
        else if (this.drInstallType == 2) {
            stepId = 1;
            /* control 1 */
            let noOfCircuitsName = this.table25NoOfCircuitValues.find(x => x.value == this.table25model.NoOfCircuits).name;
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table25model.InstallType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Buried groups";
            this.wizardFactorInput.stepTitle = "Groups of buried circuits";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "No. of circuits";
            this.wizardFactorInput.controlType = "dropdownlist";
            this.wizardFactorInput.controlValue = this.table25model.NoOfCircuits.toString();
            this.wizardFactorInput.controlText = noOfCircuitsName;
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = false;
            this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (this.table25model.NoOfCircuits > 1) {
                let arrangementName = this.table25ArrangementValues.find(x => x.value == this.table25model.Arrangement).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.table25model.InstallType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Buried groups";
                this.wizardFactorInput.stepTitle = "Groups of buried circuits";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Arrangement";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table25model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }

            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table28model.Buried;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Depth of burial";
            this.wizardFactorInput.stepTitle = "Depth of Burial (m)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Depth";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table28model.UseStandard) {
                this.wizardFactorInput.controlValue = "0.70";
                this.wizardFactorInput.controlText = "Use standard 0.7 m";
                this.wizardFactorInput.defaultValue = "Use standard 0.7 m";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table28model.Depth.toString();
                this.wizardFactorInput.controlText = this.table28model.Depth.toString();
                this.wizardFactorInput.defaultValue = "Use standard 0.7 m";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = true;
            }
            this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table29model.Buried;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil resistivity";
            this.wizardFactorInput.stepTitle = "Soil thermal resistivity";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Resistivity";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table29model.UseStandard) {
                this.wizardFactorInput.controlValue = "2.5";
                this.wizardFactorInput.controlText = "Use standard 2.5 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "2.5";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table29model.Resitivity.toString();
                this.wizardFactorInput.controlText = "Use standard 2.5 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "2.5";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = null;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil temprature";
            this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = "20";
                this.wizardFactorInput.controlText = "Use standard 20 &#176;C";
                this.wizardFactorInput.defaultValue = "20";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AmbientTemp.toString();
                this.wizardFactorInput.controlText = "Use standard 20 &#176;C";
                this.wizardFactorInput.defaultValue = "20";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                let conductorTempName = this.insulationValues.find(x => x.value == this.table271model.Insulation).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = null;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Soil temprature";
                this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Insulation";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table271model.Insulation.toString();
                this.wizardFactorInput.controlText = conductorTempName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }

            /* Storing Results */
            controlId = 1;
            if (this.drTable25output) {
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Correction Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["Correction Factor:"];
                this.wizardFactorInput.controlText = this.drTable25output["Correction Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Spacing (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.controlText = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "No. of circuits:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.controlText = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table25";
                item.summary = $('#table25').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table28output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Correction Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Correction Factor:"];
                this.wizardFactorInput.controlText = this.table28output["Correction Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Depth of burial (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.controlText = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference depth (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.controlText = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor size:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Conductor size:"];
                this.wizardFactorInput.controlText = this.table28output["Conductor size:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                /////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table28";
                item.summary = $('#table28').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table29output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Correction Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Correction Factor:"];
                this.wizardFactorInput.controlText = this.table29output["Correction Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Thermal resistivity of soil (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference resistivity (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Type of installation:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Type of installation:"];
                this.wizardFactorInput.controlText = this.table29output["Type of installation:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table29";
                item.summary = $('#table29').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable271output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Correction Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Correction Factor:"];
                this.wizardFactorInput.controlText = this.drTable271output["Correction Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Soil ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.bsCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            controlId += 1;
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.bsCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = 5;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = "Overall Correction Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);
        }
        this.OriDrfWizard = this.CalculationReportInput.wizardValues;
        this._wizardService.storeWizardFactor(this.wizardFactorInputList).subscribe((result) => {
            this.updateDeratingFactorValue(true, this.CalculationReportInput.wizardValues);
        }, error => { });
    }
    setWizardFactorDefaultValues() {
        // this._wizardService.getWizardFactors(this.bsCalculationId, this.drInstallType).subscribe(result => {
        //     if (result.items.length > 0) {
        //         this.wizardFactorOutput = result.items;
        //         for (let wizardFactor of this.wizardFactorOutput) {
        let stepId = this.wizard.currentStep;
        let callby = ($('.m-wizard__step--current')).find('.m-wizard__step-label').text().trim().toLowerCase();
        switch (stepId) {
            case 1:
                //  this.drBunchingType = 1;
                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                    //    this.drBunchingType = 2;
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //   this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                    // $('#drTable22Arrangement').trigger('change');
                    //}
                    //if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                    // $('#drTable22NoOfCircuits').trigger('change');
                    //}
                    //if (wizardFactor.isLastControl)
                    this.GetTable22Dr(null);
                }
                else if (callby === 'in trays') { /* In air - Cable Trays */
                    // this.drBunchingType = 3;
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //   this.table23model.Row = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Row').trigger('change');
                    //}
                    //  else if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Cable').trigger('change');
                    //}
                    // else if (wizardFactor.controlId == 3) {/* control 3 */
                    //     this.table23model.Installation = parseInt(wizardFactor.controlValue);
                    // }
                    //if (wizardFactor.isLastControl) {
                    //  this.table23model.CableType = this.drBunchingCore;
                    this.GetTable23Dr(null);
                    //}
                }
                else if (callby === 'buried groups' || callby === 'arrangement  buried groups' || (callby === '' && this.drInstallType == 2)) { /* In ground - Buried Circuits */
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //  this.table25model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                    // $('#drTable25NoOfCicruits').trigger('change');
                    //}
                    //if (this.table25model.NoOfCircuits > 1 && wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table25model.Arrangement = parseInt(wizardFactor.controlValue);
                    // $('#drTable25Arrangement').trigger('change');
                    //}
                    // if (wizardFactor.isLastControl) {
                    //     this.drInstallType = 2;
                    //     this.table25model.CableType = this.drBunchingCore;
                    //     this.table25model.InstallType = wizardFactor.buriedType;
                    this.GetTable25Dr();
                    this.setTable25Values(this.table25model.Arrangement);
                    //}
                }
                else if (this.drInstallType == 1 && (callby === 'air temprature' || callby === 'air temperature')) {/* In air */
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //  this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                    // this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandard').trigger('change');
                    $('#drTable271UseStandard').trigger('change');
                    //}
                    if (!this.table271model.UseStandard) {/* control 2 */
                        // this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                        // $('#drTable271Insulation').trigger('change');
                    }
                    //if (wizardFactor.isLastControl)
                    this.GetTable271Dr(null);
                }
                break;
            case 2:
            case 4:
                if (stepId == 2) {
                    if (this.drInstallType == 1 && (callby === 'air temprature' || callby === 'air temperature')) {/* In air */
                        // if (wizardFactor.controlId == 1) {/* control 1 */
                        //  this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                        // this.table271model.UseStandard = wizardFactor.useDefaultValue;
                        $('#inputUseStandard').trigger('change');
                        $('#drTable271UseStandard').trigger('change');
                        // }
                        if (!this.table271model.UseStandard) {/* control 2 */
                            //   this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                            // $('#drTable271Insulation').trigger('change');
                        }
                        //if (wizardFactor.isLastControl)
                        this.GetTable271Dr(null);
                    }
                    if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                        // $('#drTable22Arrangement').trigger('change');

                        // $('#drTable22NoOfCircuits').trigger('change');

                        this.GetTable22Dr(null);
                    }

                    if (callby === 'in trays') { /* In air - Cable Trays */
                        //this.drBunchingType = 3;
                        //if (wizardFactor.controlId == 1) {/* control 1 */
                        //   this.table23model.Row = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Row').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 2) {/* control 2 */
                        //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Cable').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 3) {/* control 3 */
                        //   this.table23model.Installation = parseInt(wizardFactor.controlValue);
                        // }
                        // if (wizardFactor.isLastControl) {
                        //     this.table23model.CableType = this.drBunchingCore;
                        this.GetTable23Dr(null);
                        // }
                    }
                    else if (this.drInstallType == 2 && callby === 'depth of burial') {/* In ground */

                        //   if (wizardFactor.controlId == 1) {/* control 1 */
                        // this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                        // this.table28model.UseStandard = wizardFactor.useDefaultValue;
                        // this.table28model.Buried = wizardFactor.buriedType;
                        $('#inputDepth').trigger('change');
                        //  }
                        //if (wizardFactor.isLastControl)
                        this.GetTable28Dr(null);
                    }
                }
                else if (stepId == 4 && this.drInstallType == 2 && (callby === 'soil temprature' || callby === 'soil temperature')) {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                    //     this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    // }
                    //  if (!this.table271model.UseStandard) {/* control 2 */
                    //     this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                    // }
                    // if (wizardFactor.isLastControl)
                    this.GetTable271Dr(null);
                }
                break;
            case 3:
                if (this.drInstallType == 2 && callby === 'soil resistivity') {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                    //     this.table29model.UseStandard = wizardFactor.useDefaultValue;
                    // }
                    //if (wizardFactor.isLastControl)
                    this.GetTable29Dr(null);
                }
                break;
        }
        //         }
        //     }
        // });
    }

    setWizardFactorValues() {
        if (this.bsCalculationId != null && this.bsCalculationId != undefined) {
            this._wizardService.getWizardFactors(this.bsCalculationId, this.drInstallType).subscribe(result => {
                if (result.items.length > 0) {
                    this.wizardFactorOutput = result.items;
                    for (let wizardFactor of this.wizardFactorOutput) {
                        let stepId = wizardFactor.stepId;
                        let callby = wizardFactor.stepName.toLowerCase();
                        switch (stepId) {
                            case 1:
                                this.drBunchingType = 1;
                                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                                    this.drBunchingType = 2;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                                        // $('#drTable22Arrangement').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                        // $('#drTable22NoOfCircuits').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl)
                                        this.GetTable22Dr(null);
                                }
                                else if (callby === 'in trays') { /* In air - Cable Trays */
                                    this.drBunchingType = 3;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table23model.Row = parseInt(wizardFactor.controlValue);
                                        // $('#drTable23Row').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table23model.Cables = parseInt(wizardFactor.controlValue);
                                        // $('#drTable23Cable').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (wizardFactor.controlId == 3) {/* control 3 */
                                        this.table23model.Installation = parseInt(wizardFactor.controlValue);
                                        this.onSelectInstall(this.table23model.Installation);
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.table23model.CableType = this.drBunchingCore;
                                        this.GetTable23Dr(null);
                                    }
                                }
                                else if (callby === 'buried groups') { /* In ground - Buried Circuits */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table25model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                        // $('#drTable25NoOfCicruits').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (this.table25model.NoOfCircuits > 1 && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table25model.Arrangement = parseInt(wizardFactor.controlValue);
                                        // $('#drTable25Arrangement').val(wizardFactor.controlValue).select2({
                                        //     width: '100%',
                                        //     minimumResultsForSearch: Infinity // disable search
                                        // }).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.drInstallType = 2;
                                        this.table25model.CableType = this.drBunchingCore;
                                        this.table25model.InstallType = wizardFactor.buriedType;
                                        this.GetTable25Dr();
                                        this.setTable25Values(this.table25model.Arrangement);
                                    }
                                }
                                else if (wizardFactor.installationType == 1 && callby === 'air temprature') {/* In air */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                        $('#inputUseStandard').val(wizardFactor.controlValue).trigger('change');
                                        $('#drTable271UseStandard').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                        // $('#drTable271Insulation').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl)
                                        this.GetTable271Dr(null);
                                }
                                break;
                            case 2:
                            case 4:
                                if (stepId == 2) {
                                    if (wizardFactor.installationType == 1 && callby === 'air temprature') {/* In air */
                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                                            this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                            $('#inputUseStandard').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandard').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                            this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                            // $('#drTable271Insulation').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        if (wizardFactor.isLastControl)
                                            this.GetTable271Dr(null);
                                    }
                                    else if (wizardFactor.installationType == 2 && callby === 'depth of burial') {/* In ground */

                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                                            this.table28model.UseStandard = wizardFactor.useDefaultValue;
                                            this.table28model.Buried = wizardFactor.buriedType;
                                            $('#inputDepth').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        if (wizardFactor.isLastControl)
                                            this.GetTable28Dr(null);
                                    }
                                }
                                else if (stepId === 4 && wizardFactor.installationType == 2 && callby === 'soil temprature') {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                    }
                                    if (wizardFactor.isLastControl)
                                        this.GetTable271Dr(null);
                                }
                                break;
                            case 3:
                                if (wizardFactor.installationType == 2 && callby === 'soil resistivity') {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                                        this.table29model.UseStandard = wizardFactor.useDefaultValue;
                                    }

                                    if (this.table29model.UseStandard)
                                        $('#txtresitivity').attr('disabled', 'true');
                                    else
                                        $('#txtresitivity').removeAttr('disabled');

                                    if (wizardFactor.isLastControl)
                                        this.GetTable29Dr(null);
                                }
                                break;
                        }
                    }
                }
            });
        }
    }

    getShortCircuitCurrent(): string {
        const currentPhaseValue = this.CableproInput.PhaseId;
        let returnStr: string;
        if (this.Xml && currentPhaseValue) {
            switch (currentPhaseValue) {
                case 100:
                    returnStr = 'Ph.to-neutral short-cct';
                    if (this.Xml.Phase_To_Neutral_SC_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Phase_To_Neutral_SC_circuitCurrent.Val * 10) / 10;
                    break;
                case 101:
                    returnStr = 'Three Ph. Short-cct';
                    if (this.Xml.Three_phaseShort_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Three_phaseShort_circuitCurrent.Val * 10) / 10;
                    break;
                case 102:
                    returnStr = 'Active-to-neutral short-cct';
                    if (this.Xml.Phase_To_Neutral_SC_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Phase_To_Neutral_SC_circuitCurrent.Val * 10) / 10;
                    break;
                default:
                    returnStr = 'Phase short-cct';
                    break;
            }
        }
        return returnStr;
    }

    generateErrorsModel(errorStr) {
        this.warningErrorList = errorStr;
        if (this.warningErrorList && errorStr != "[]" && this.warningErrorList.length > 0) {
            this.warningErrorList = this.warningErrorList.filter(m => m.ErrorCode);
            if (this.warningErrorList && this.warningErrorList.length > 0) {
                this.warningErrorList = this.warningErrorList.sort((a, b) => a.ErrorCode < b.ErrorCode ? -1 : 1).sort((a, b) => a.Type < b.Type ? -1 : 1).sort((a, b) => parseInt(a.IsError) > parseInt(b.IsError) ? -1 : 1);
                this.warningErrorStrData = JSON.stringify(this.warningErrorList);
                this.displayErrorBtn = true;
                this.paginationConfig.totalItems = this.warningErrorList.length;
                let errors = this.warningErrorList.filter(m => m.IsError == "1");
                let warnings = this.warningErrorList.filter(m => m.IsError == "0");
                if (errors && errors.length > 0) {
                    this.warningErrorBtnText = "Errors (" + errors.length + ")";
                }
                else {
                    this.warningErrorBtnText = "Warnings (" + warnings.length + ")";
                }

                if ((errors && errors.length > 0) && (warnings && warnings.length > 0)) {
                    this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings (" + warnings.length + ")";
                }
                else if ((errors && errors.length > 0) && (!warnings || warnings.length == 0))
                    this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings";
                else if ((warnings && warnings.length > 0) && (!errors || errors.length == 0))
                    this.warningErrorHeader = "Errors and Warnings (" + warnings.length + ")";
                else {
                    this.warningErrorHeader = "Errors and Warnings";
                }
            }
        }
        else {
            this.displayErrorBtn = false;
        }

    }

    showWarningErrorPopup() {
        $('#warnings-errors-model-cablesize-bs').modal('show');
    }

    pageChanged(event) {
        this.paginationConfig.currentPage = event;
    }

    getErrorMsg(error: string): string {
        let ret: string = '';
        if (error) {
            return error.replace(/\\"/g, '"').replace(/lessthansymbol/g, "<");
        }
        return ret;
    }

    onddlDeviceChange(value) {
        this.device = value;
        $(".tooltip").hide();
        let val: string = '';
        if (this.device == '0') {
            val = 'Unknown';
        } else if (this.device == '1') {
            val = 'MCB';
        } else if (this.device == '2') {
            val = 'MCCB';
        } else if (this.device == '3') {
            val = 'ACB';
        } else if (this.device == '4') {
            val = 'HRC Fuse';
        } else if (this.device == '5') {
            val = 'Custom';
        }
        this.CableSize['device'] = this.device;
        if (this.flag !== true) {
            this._calculationService.setPrevValue('-1').subscribe((result) => {
                this.GetData('protection', '', 0, 0, 0, 0, 0, 0, 0, '', val, this.bsCalculationId, 0, 0, this.CableproInput.ArmourId);
            }, error => { });
        }
        setTimeout(function () {
            $('#protection').find('select').each(function () {
                $(this).select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            });
        }, 1000);
        this.showHideProtectionWarning();
    }

    onddlActiveChange(e) {
        this.CableproInput.ActiveId = e.id;
        this.PrevActiveValue = e.value;
        this.CableSize['specifyconductor']['phase']['mode'] = '1';
        this.CableSize['specifyconductor']['phase']['size'] = parseFloat(this.PrevActiveValue.replace(' mm²', ''));
        this.getAllControlsValue();
    }

    onddlNeutralChange(e) {
        this.CableproInput.NeutralId = e.id;
        this.PrevNeutralValue = e.value;
        this.CableSize['specifyconductor']['neutral']['mode'] = '1';
        this.CableSize['specifyconductor']['neutral']['size'] = parseFloat(this.PrevNeutralValue);
        this.getAllControlsValue();
    }
    onddlConduitSizeChange(e) {
        this.CableproInput.ConduitSizeId = e.id;
        this.PrevConduitSize = e.id;
        this.CableSize['conduitsize']['mode'] = '1';
        this.CableSize['conduitsize']['size'] = e.id;
        this.getAllControlsValue();
    }
    onddlEarthChange(e) {
        this.CableproInput.EarthId = e.id;
        let val = e.text;
        this.CableSize['specifyconductor']['earth']['mode'] = '2';
        this.CableSize['specifyconductor']['earth']['size'] = e.id;
        this.getAllControlsValue();
    }

    onddlPowerUnitChange(value, isTriggered = "false") {
        this.powerunit = value;
        this.currentUnit = value;
        if (this.currentUnit == "1") {
            if (this.voltageSupplyLabel == "rise") {
                this.lblcurrent = 'Output current';
            }
            else
                this.lblcurrent = 'Current';
        }
        else {
            if (this.voltageSupplyLabel == "rise") {
                this.lblcurrent = 'Output power';
            }
            else
                this.lblcurrent = 'Power';
        }
        if (this.currentUnit == "4") {
            this.showEfficency = true;
        }
        else {
            this.showEfficency = false;

        }

        if (this.currentUnit != null && this.currentUnit != "") {
            this.CableSize['powerunit'] = this.currentUnit;
            if (isTriggered != "true")
                this.getAllControlsValue();
        }
    }

    onddlInsulationChange(e) {
        this.CableproInput.InsulationId = parseInt(e.id);
        if ((this.CableproInput.InsulationId == 102 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 2)) {
            this.PrevConductorId = 0;
        }
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        // $('.chkUW').removeAttr('checked');
        $('.chkUW').prop('checked', false);

        this.CableSize['insulation'] = e.xmlValue.toString();

        this.GetData('insulation', e.id, 0, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.bsCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId);
    }

    onddlConductorChange(value) {
        this.CableproInput.ConductorId = value;
        let obj = this.conductorData.find(m => m.id == this.CableproInput.ConductorId);
        if (obj) {
            this.PrevConductorId = obj.xmlValue;
            this.CableSize['conductor'] = obj.xmlValue.toString();// (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
            this.GetData('conductor', value, this.CableproInput.InsulationId, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.bsCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId);
        }
    }

    onddlCoreChange(e) {
        this.CableproInput.CoreId = parseInt(e.id);
        this.PrevCoreId = e.xmlValue;
        let bunchingCore: string = e.xmlValue.toString();
        this.CableSize['core'] = bunchingCore;


        if (this.CableSize['core'] == "0")
            this.drBunchingCore = 1;
        else
            this.drBunchingCore = 2;
        // reset table 23 values as per core selection
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.CableType = this.drBunchingCore;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
        this.OnChangeCore(null);
        this.GetTable23Dr(null);
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        $('.chkUW').prop('checked', false);

        this.GetData('core', e.id.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.bsCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId);
    }

    onddlArmourChange(e) {
        this.CableproInput.ArmourId = parseInt(e.id);
        this.PrevArmourId = e.xmlValue;
        this.CableSize['armour'] = e.xmlValue.toString();
        this.GetData('armour', this.CableproInput.ArmourId.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, this.CableproInput.InstallTableId, 0, '', '', this.bsCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId);
    }

    onddlWindChange(e) {
        this.CableproInput.WindSpeedId = parseInt(e.id);
        this.CableSize['installtablewind']['wind'] = e.xmlValue.toString();
        this.getAllControlsValue();
    }

    onddlPhaseChange(e) {
        this.CableproInput.PhaseId = e.id;
        if (this.CableproInput.PhaseId.toString() == '100') {
            this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
        } else if (this.CableproInput.PhaseId.toString() == '101') {
            this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        } else if (this.CableproInput.PhaseId.toString() == '102') {
            this.CableSize['supplyvoltage'] = this.dc_Voltage;
        }

        if (this.CableproInput.PhaseId.toString() == '100' || this.CableproInput.PhaseId.toString() == '102') {
            if ($('#neutral-toggle').prop('checked')) {
                $('#neutral-toggle').click();
            }
            this.NeutralDisabled = true;
            $('#neutral-toggle').attr('disabled', 'true');
            $('#Neutral_c').attr('disabled', 'true');

        } else {
            this.NeutralDisabled = false;
            $('#neutral-toggle').removeAttr('disabled');
        }
        this.voltage_txt = this.CableSize['supplyvoltage'];
        this.CableSize['phase'] = e.xmlValue.toString();
        this.GetData('phase', this.CableproInput.PhaseId.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.bsCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId);
    }

    onddldrTable23RowChange(e) {
        this.table23model.Row = e.value;
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable23CableChange(e) {
        this.table23model.Cables = parseInt(e.value);
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable271InsulationChange(e) {
        this.table271model.Insulation = parseInt(e.value);
        this.SetMinMaxAmbientTemp();
        this.drfService.getTable271(2, this.table271model.Insulation, this.table271model.AmbientTemp, this.drInstallType).subscribe(result => {
            this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            if (this.table271model.UseStandard != true) {
                this.drTable271output = result;
            }
        });
    }

    onddldrTable22ArrangementChange(e) {
        this.table22model.Arrangement = parseInt(e.value);

        this.drTable22CircuitValues = [
            { "name": 1, "value": 1 },
            { "name": 2, "value": 2 },
            { "name": 3, "value": 3 },
            { "name": 4, "value": 4 },
            { "name": 5, "value": 5 },
            { "name": 6, "value": 6 },
            { "name": 7, "value": 7 },
            { "name": 8, "value": 8 },
            { "name": 9, "value": 9 },
            { "name": 12, "value": 12 },
            { "name": 16, "value": 16 },
            { "name": 20, "value": 20 }];

        this.GetTable22Dr(null);
    }

    onddldrTable22NoOfCircuitsChange(e) {
        this.table22model.NoOfCircuits = parseInt(e.value);
        if (this.table22model.NoOfCircuits == 1) {
            this.table22model.drf = 1;
            this.drTable22output = undefined;
            return false;
        }
        this.GetTable22Dr(null);
    }

    onddldrTable25NoOfCicruitsChange(e) {
        this.table25model.NoOfCircuits = parseInt(e.value);
        this.GetTable25Dr();
        this.setTable25Values(this.table25model.Arrangement);
    }

    onddldrTable25ArrangementChange(e) {
        this.table25model.Arrangement = parseInt(e.value);
        this.setTable25Values(e.value);
    }

    onddlProtectionCurveChange(e) {
        this.protection_curve = parseInt(e.value);

        this.CableSize['protection']['curve'] = e.value.toString();
        this.PrevCurveVal = e.value.toString();

        if (this.CableSize['protection']['curve'] == '0') {
            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('4');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseInt(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        } else if (this.CableSize['protection']['curve'] == '1') {

            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('7.5');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseFloat(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        } else {

            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('15');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseInt(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        }
        this.getAllControlsValue();
    }

    onddlEarthFaultTimeChange(e) {
        this.earthfaulttime = parseInt(e.value);
        this.CableSize['protection']['earthfaulttime'] = e.value.toString();
        this.getAllControlsValue();
    }

    select2Dropdown() {
        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }
    }

    private inputSubject = new Subject<any>();
    GetTable271DrInputChange(e: any, checkdecimalplaces: boolean = false) {
        this.inputSubject.next({ e: e, checkdecimalplaces: checkdecimalplaces });
    }

    public deleteVisible: Boolean = false;
    public defaultLogoImagePath = '/assets/common/images/Nologo.png';
    removeLogo(): void {
        // this.CalculationReportInput.companyLogo_LogoUrl = '';
        this.CalculationReportInput.companyLogo_FileName = '';
        this.companyLogo_FileName = undefined;
        this.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
        this.deleteVisible = false;
    }
    openfileControl(): void {
        $('#fileupload').click();
    }
    private baseUrl: string;
    public btnuploadDisabled: boolean = false;
    public company_Logo_LogoPresignedUrl: string = '/assets/common/images/Nologo.png';
    SetLogoImage(event: any) {
        let url_ = this.baseUrl + '/api/services/app/Setting/CreateFile';
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            if (!this.validateFile(file.name)) {
                return false;
            }
            else {
                let resizedFile: File;
                var utils = new ImageUtils();
                utils.resizeImage(file, 600, 600).then(blob => {
                    //You can upload the resized image: doUpload(blob)
                    resizedFile = utils.blobToFile(blob, file.name);
                    let formData: FormData = new FormData();
                    formData.append('file', resizedFile, file.name);
                    // formData.append('file', file, file.name);
                    let headers = new Headers();
                    headers.append('Content-Type', 'multipart/form-data');
                    headers.append('Accept', 'application/json');
                    this.btnuploadDisabled = true;
                    this.getUploadResponse(url_, formData).subscribe(result => {
                        this.settingOutput = result;
                        if (this.settingOutput !== undefined) {
                            // this.CalculationReportInput.companyLogo_LogoUrl = this.settingOutput['result'].companyLogo_LogoUrl;
                            this.company_Logo_LogoPresignedUrl = this.settingOutput['result'].company_Logo_LogoPresignedUrl;
                            this.companyLogo_FileName = this.settingOutput['result'].companyLogo_LogoUrl;
                            this.deleteVisible = true;
                            this.btnuploadDisabled = false;
                        }
                    }, (error) => {
                        this.btnuploadDisabled = false;
                        this.isInvalidFileType = true;
                    });
                }, err => {
                    console.error("Photo error", err);
                });
            }
        }
    }
    public isInvalidFileType: boolean = false;
    validateFile(name: string): boolean {
        var ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'gif' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png'
            || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'ico' || ext.toLowerCase() == 'bmp') {
            this.isInvalidFileType = false;
            return true;
        }
        else {
            this.isInvalidFileType = true;
            return false;
        }
    }
    getUploadResponse(url_: string, formData: FormData): Observable<SettingOutput> {
        return this.http.post<SettingOutput>(url_, formData);
    }
}

class Model {
    InsulationId: number;
    ConductorId: number;
    EarthConductorId: number;
    CoreId: number;
    PhaseId: number;
    ActiveId: number;
    NeutralId: number;
    EarthId: any;
    MethodIndex: number;
    InstallTableId: number;
    WindSpeedId: number;
    ArmourId: number;
    ConduitSizeId: number;
}
