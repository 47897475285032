import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { GuestLoginResponse } from './models/guestlogin';
import { environment } from 'environments/environment';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private userKey = 'loggedInUser'; // For persistence in localStorage
  // private userSubject: BehaviorSubject<GuestLoginResponse | null>;
  remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;

  constructor(private http: HttpClient, private _appSessionService: AppSessionService) {
    // const storedUser = this.localStorageService.getLocalStorageItem(this.userKey);
    // this.userSubject = new BehaviorSubject<any>(storedUser ? JSON.parse(storedUser) : null);
    // (window as any).cable = this;
  }

  // Perform login call and handle token storage
  webfacingLogin(standard: number): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-token': environment.token // direct token in header, not as Bearer
    });

    const uuid = this.getOrGenerateUUID(standard);

    return this.http.get(`${this.remoteServiceBaseUrl}/api/TokenAuth/WebFacingLogin`, {
      headers,
      params: { uuid, standard },
      withCredentials: true
    });
  }

  getOrGenerateUUID(standard: number) {
    let uuid = localStorage.getItem('webfacing.uniqueipuser_' + standard.toString());
    if (!uuid) {
      uuid = this.generateUUID();
      localStorage.setItem('webfacing.uniqueipuser_' + standard.toString(), uuid);
    }
    return uuid;
  }

  // Helper to generate a UUID
  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // Save login response to cookies and local storage
  storeLoginData(standard: number, data: GuestLoginResponse) {
    localStorage.setItem('webfacing.projectid_' + standard.toString(), data.projectId.toString());
    localStorage.setItem('webfacing.projectname_' + standard.toString(), data.projectName);
    localStorage.setItem('webfacing.calculationid_' + standard.toString(), data.calculationId.toString());
    localStorage.setItem('webfacing.calculationname_' + standard.toString(), data.calculationName);
  }


  // Helper methods for cookies
  setCookie(name: string, value: string, date: Date) {
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  }

  getCookie(name: string) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }

  // // Setter for updating user details
  // setUser(userData: any): void {
  //   this.userSubject.next(userData); // Update the BehaviorSubject
  //   localStorage.setItem(this.userKey, JSON.stringify(userData)); // Persist in localStorage
  // }

  // Check if token is already stored in cookies
  isLoggedIn(): boolean {
    return !!this._appSessionService.user;
  }

  // Validate if the guest login token is expired or not
  validateGuestLoginToken(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getCookie('Abp.AuthToken')
    });

    return this.http.get(`${this.remoteServiceBaseUrl}/api/TokenAuth/ValidateAUCableSizingGuestLoginToken`, {
      headers,
      withCredentials: true
    });
  }

}
