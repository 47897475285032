// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the wrapper takes up the full viewport height */
}

main {
  flex: 1;
  /* Push the footer to the bottom by taking up remaining space */
}

footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/webfacing/webfacing.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,yDAAyD;AAC3D;;AAEA;EACE,OAAO;EACP,+DAA+D;AACjE;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  /* Ensure the wrapper takes up the full viewport height */\n}\n\nmain {\n  flex: 1;\n  /* Push the footer to the bottom by taking up remaining space */\n}\n\nfooter {\n  background: #333;\n  color: white;\n  text-align: center;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
