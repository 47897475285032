// gtm.guard.ts
import { Injectable } from '@angular/core';

import { GoogleTagManagerService } from './google-tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class GTMGuard  {
  constructor(private gtmService: GoogleTagManagerService) {}

  canActivate(): boolean {
    this.gtmService.loadGTMContainer(); // Load GTM container here
    return true; // Allow access to the route
  }
}