// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonclass {
    width: 287px;
    height: 40px;
    font-family: Open Sans;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    /* Neutral (Light) / N700 */
    background: #253858;
    border-radius: 3px;
}

.buttonclass-disabled {
    opacity: 0.7;
    width: 287px;
    height: 40px;
    font-family: Open Sans;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    /* Neutral (Light) / N700 */
    background: #253858;
    border-radius: 3px;
}

.cplp_login-section .container {
    background: white;
    padding: 50px 20px;
    max-width: 700px;
}

img.logo {
    width: auto;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .cplp_login-section {
        padding: 0px !important;
    }

    .cplp_login-body {
        background-color: #fff !important;
    }
}

.elek-link {
    color: #4285f4;
}

.elek-link:hover{
    color: #4285f4;
}`, "",{"version":3,"sources":["webpack://./src/maintenance/maintenance.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,iCAAiC;IACrC;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".buttonclass {\n    width: 287px;\n    height: 40px;\n    font-family: Open Sans;\n    font-weight: 400;\n    font-size: 14px;\n    color: #ffffff;\n    /* Neutral (Light) / N700 */\n    background: #253858;\n    border-radius: 3px;\n}\n\n.buttonclass-disabled {\n    opacity: 0.7;\n    width: 287px;\n    height: 40px;\n    font-family: Open Sans;\n    font-weight: 400;\n    font-size: 14px;\n    color: #ffffff;\n    /* Neutral (Light) / N700 */\n    background: #253858;\n    border-radius: 3px;\n}\n\n.cplp_login-section .container {\n    background: white;\n    padding: 50px 20px;\n    max-width: 700px;\n}\n\nimg.logo {\n    width: auto;\n    margin: 0 auto 30px;\n}\n\n@media only screen and (max-width: 767px) {\n    .cplp_login-section {\n        padding: 0px !important;\n    }\n\n    .cplp_login-body {\n        background-color: #fff !important;\n    }\n}\n\n.elek-link {\n    color: #4285f4;\n}\n\n.elek-link:hover{\n    color: #4285f4;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
