// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-style-primary {
    border-top: 4px solid #007bff;
    color: #007bff;
  }
  
  .modal-style-success {
    border-top: 4px solid #28a745;
    color: #28a745;
  }
  
  .modal-style-danger {
    border-top: 4px solid #dc3545;
    color: #dc3545;
  }
  
  .modal-style-warning {
    border-top: 4px solid #ffc107;
    color: #ffc107;
  }`, "",{"version":3,"sources":["webpack://./src/app/shared/common/confirmation-modal/confirmation-modal.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,6BAA6B;IAC7B,cAAc;EAChB","sourcesContent":[".modal-style-primary {\n    border-top: 4px solid #007bff;\n    color: #007bff;\n  }\n  \n  .modal-style-success {\n    border-top: 4px solid #28a745;\n    color: #28a745;\n  }\n  \n  .modal-style-danger {\n    border-top: 4px solid #dc3545;\n    color: #dc3545;\n  }\n  \n  .modal-style-warning {\n    border-top: 4px solid #ffc107;\n    color: #ffc107;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
