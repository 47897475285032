import { AbpHttpInterceptor } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ProductService, SettingService, CalculationService, DRFService, BSService, PlanService, VersionService, BSDRFService } from '../../services/project.service';
import * as ApiServiceProxies from './service-proxies';
import * as ApiServiceProxiesCalc from './service-proxy-calc';
import * as PdfReportServiceProxy from './service-proxy-rpt';
import * as CompanyAccountServiceProxy from './company-account.service';
@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.CableproServiceProxy,
        ApiServiceProxies.BSServiceProxy,
        ApiServiceProxiesCalc.CableproCalculationServiceProxy,
        PdfReportServiceProxy.PdfReportServiceProxy,
        ApiServiceProxies.CableproProjectServiceProxy,
        ApiServiceProxies.CalculationServiceProxy,
        ApiServiceProxies.SettingServiceProxy,
        ApiServiceProxies.DRFServiceServiceProxy,       
        ApiServiceProxies.CheckOutServiceProxy,       
        ApiServiceProxies.VersionServiceProxy,       
        ApiServiceProxies.BSDRFServiceServiceProxy,
        ApiServiceProxies.WizardServiceProxy,
        CompanyAccountServiceProxy.CompanyAccountService,
        ProductService,
        SettingService,
        CalculationService,
        DRFService,
        BSService,
        PlanService,
        VersionService,
        BSDRFService,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
