import { Injectable } from '@angular/core';
import { ConfirmationModalComponent } from '@app/shared/common/confirmation-modal/confirmation-modal.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.animation = true;
    config.centered = true;
  }

  success(title: string, body: string, buttonText?: string, callback?: Function) {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalBody = body;
    modalRef.componentInstance.iconClass = "fa-check-circle m--font-success";
    modalRef.componentInstance.acceptButtonName = !buttonText ? "Close" : buttonText;
    modalRef.componentInstance.acceptButtonClass = 'btn-default';
    modalRef.componentInstance.isAlertOnly = true;
    modalRef.result.then((result: boolean) => {
      if (callback)
        callback(result);
    }, (reason) => {
      if (callback)
        callback(reason);
    });
  }
  simplepopup(title: string, body: string, buttonText?: string, callback?: Function) {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalBody = body;
    modalRef.componentInstance.acceptButtonName = !buttonText ? "Close" : buttonText;
    modalRef.componentInstance.acceptButtonClass = 'btn-default';
    modalRef.componentInstance.isAlertOnly = true;
    modalRef.result.then((result: boolean) => {
      if (callback)
        callback(result);
    }, (reason) => {
      if (callback)
        callback(reason);
    });
  }

  error(title: string, body: string, buttonText?: string, callback?: Function, showCloseBtn: boolean = true, showIcon: boolean = true, acceptButtonClass: string = 'btn-default') {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalBody = body;
    modalRef.componentInstance.iconClass = showIcon ? "fa-times-circle m--font-danger" : undefined;
    modalRef.componentInstance.acceptButtonName = !buttonText ? "Close" : buttonText;
    modalRef.componentInstance.acceptButtonClass = acceptButtonClass;
    modalRef.componentInstance.isAlertOnly = true;
    modalRef.componentInstance.showCloseBtn = showCloseBtn;
    modalRef.result.then((result: boolean) => {
      if (callback)
        callback(result);
    }, (reason) => {
      if (callback)
        callback(reason);
    });
  }

  warning(title: string, body: string, buttonText?: string, callback?: Function, showCloseBtn: boolean = true, showIcon: boolean = true, acceptButtonClass: string = 'btn-default') {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalBody = body;
    modalRef.componentInstance.iconClass = showIcon ? "fa-warning m--font-warning" : undefined;
    modalRef.componentInstance.acceptButtonName = !buttonText ? "Close" : buttonText;
    modalRef.componentInstance.acceptButtonClass = acceptButtonClass;
    modalRef.componentInstance.isAlertOnly = true;
    modalRef.componentInstance.showCloseBtn = showCloseBtn;
    modalRef.result.then((result: boolean) => {
      if (callback)
        callback(result);
    }, (reason) => {
      if (callback)
        callback(reason);
    });
  }

  confirm(title: string, body: string, callback?: Function, iconClass?: string, acceptButtonName?: string, acceptButtonClass?: string, cancelButtonName?: string, cancelButtonClass?: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.modalBody = body;
    modalRef.componentInstance.iconClass = iconClass;
    modalRef.componentInstance.acceptButtonName = !acceptButtonName ? "Ok" : acceptButtonName;
    modalRef.componentInstance.acceptButtonClass = !acceptButtonClass ? 'btn-danger' : acceptButtonClass;
    modalRef.componentInstance.cancelButtonName = !cancelButtonName ? "Cancel" : cancelButtonName;
    modalRef.componentInstance.cancelButtonClass = !cancelButtonClass ? 'btn-default' : cancelButtonClass;
    modalRef.result.then((result: boolean) => {
      if (callback)
        callback(result);
    }, (reason) => {
      if (callback)
        callback(reason);
    });
  }

  billingManagerPrivilegesRequired() {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = "Billing Manager Privileges Required";
    modalRef.componentInstance.iconClass = "fa-warning text-warning";
    modalRef.componentInstance.acceptButtonName = "Close";
    modalRef.componentInstance.acceptButtonClass = "btn-default";
    modalRef.componentInstance.isAlertOnly = true;
    modalRef.componentInstance.modalBody = "You do not have billing manager privileges. <br><br>Please request additional licences from the account’s billing manager.";
  }

  notEnoughLicences() {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = "Not Enough Licences";
    modalRef.componentInstance.modalBody = "This account does not have enough licences to add another user. <br><br>Please request additional licences from the account’s billing manager.";
    modalRef.componentInstance.iconClass = "fa-info text-primary";
    modalRef.componentInstance.acceptButtonName = "Close";
    modalRef.componentInstance.acceptButtonClass = "btn-default";
    modalRef.componentInstance.isAlertOnly = true;
  }
 
  notEnoughLicencesBillingManager() {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.modalTitle = "Not Enough Licences";
    modalRef.componentInstance.modalBody = "There are not enough licences available in your account. Since your subscription was paid via direct invoice, Please contact <a href='mailto:sales@elek.com'>sales@elek.com</a> to make changes.";
    modalRef.componentInstance.iconClass = "fa-times-circle text-danger";
    modalRef.componentInstance.acceptButtonName = "Close";
    modalRef.componentInstance.acceptButtonClass = "btn-default";
    modalRef.componentInstance.isAlertOnly = true;
  }
}
