import { AbpHttpInterceptor, AbpModule } from 'abp-ng2-module';
import { PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { CommonModule } from '@shared/common/common.module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import * as localForage from 'localforage';
import * as _ from 'lodash';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AppPreBootstrap } from './AppPreBootstrap';
import { AppModule } from './app/app.module';
// import { TreeviewModule } from 'ngx-treeview';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { API_BASE_URL2 } from '../src/app/main/projects/settings.component';
import { AnonymousUserPageComponent, API_STRIPE_KEY_Public } from './anonymouspage/anonymoususerpage.component';
import { AnonymousUserCheckOutSuccessComponent } from './anonymouspage/anonymoususer.checkout.success.component';
import { AnonymousUserCheckOutCancelComponent } from './anonymouspage/anonymoususer.checkout.cancel.component';
import { AuthInterceptor, CustomHttpInterceptor } from './shared/service-proxies/AuthInterceptor';
import { API_STRIPE_KEY_SubscribePlans } from '@app/main/subscriptions/subscriptionplans.component';
import { API_STRIPE_KEY_SubscribePlans_BS } from '@app/main/subscriptions/bscalc.subscriptionplans.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from 'account/register/register.component';
import { LoginService } from 'account/login/login.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaLoaderService, ReCaptchaV3Service } from 'ng-recaptcha';
import { UtilsModule } from '@shared/utils/utils.module';
import { LoginComponent } from 'account/login/login.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { environment } from 'environments/environment';
import { CableProComponent } from '@app/main/cablepro/cablepro.component';
import { LoaderComponent } from '@app/main/cablepro/loader.component';
import { BSCalcComponent } from '@app/main/cablepro/bscalc.component';

export function appInitializerFactory(
    injector: Injector,
    platformLocation: PlatformLocation) {
    return () => {
        abp.ui.setBusy();

        handleLogoutRequest(injector.get(AppAuthService));

        return new Promise<boolean>((resolve, reject) => {
            AppConsts.appBaseHref = getBaseHref(platformLocation);
            let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

            AppPreBootstrap.run(appBaseUrl, () => {
                // Initialize local Forage
                localForage.config({
                    driver: localForage.LOCALSTORAGE,
                    name: 'Cable_Pro_AU_Web',
                    version: 1.0,
                    storeName: 'abpzerotemplate_local_storage',
                    description: 'Cached data for Cable_Pro_AU_Web'
                });

                if (UrlHelper.isMaintenanceUrl(location.href)) {
                    abp.ui.clearBusy();
                    resolve(true);
                    return;
                }

                let appSessionService: AppSessionService = injector.get(AppSessionService);
                let ui: AppUiCustomizationService = injector.get(AppUiCustomizationService);
                appSessionService.init().then(
                    (result) => {

                        //Css classes based on the layout
                        let appUiCustomizationService: AppUiCustomizationService = injector.get(AppUiCustomizationService);
                        if (abp.session.userId) {
                            $('body').attr('class', appUiCustomizationService.getAppModuleBodyClass());
                        } else {
                            $('body').attr('class', appUiCustomizationService.getAccountModuleBodyClass());
                        }

                        //tenant specific custom css
                        if (appSessionService.tenant && appSessionService.tenant.customCssId) {
                            $('head').append('<link id="TenantCustomCss" href="' + AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetCustomCss?id=' + appSessionService.tenant.customCssId + '" rel="stylesheet"/>');
                        }

                        //set og share image meta tag
                        if (!appSessionService.tenant || !appSessionService.tenant.logoId) {
                            $('meta[property=og\\:image]').attr('content', window.location.origin + '/assets/common/images/app-logo-on-' + ui.getAsideSkin() + '.png');
                        } else {
                            $('meta[property=og\\:image]').attr('content', AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetLogo?id=' + appSessionService.tenant.logoId);
                        }

                        abp.ui.clearBusy();

                        // if (shouldLoadLocale()) {
                        //     let angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
                        //     import(`@angular/common/locales/${angularLocale}.js`)
                        //         .then(module => {
                        //             registerLocaleData(module.default);
                        //             resolve(result);
                        //         }, reject);
                        // } else {
                        resolve(result);
                        // }
                    },
                    (err) => {
                        abp.ui.clearBusy();
                        reject(err);
                    }
                );
            }, resolve, reject);
        });
    };
}

function getDocumentOrigin() {
    if (!document.location.origin) {
        return document.location.protocol + "//" + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }

    return document.location.origin;
}

export function shouldLoadLocale(): boolean {
    return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== 'en-US';
}

export function convertAbpLocaleToAngularLocale(locale: string): string {
    if (!AppConsts.localeMappings) {
        return locale;
    }

    let localeMapings = _.filter(AppConsts.localeMappings, { from: locale });
    if (localeMapings && localeMapings.length) {
        return localeMapings[0]['to'];
    }

    return locale;
}

export function getRemoteServiceBaseUrl(): string {
    return AppConsts.remoteServiceBaseUrl;
}



export function getRemoteServiceCalBaseUrlSetting(): string {
    return AppConsts.remoteServiceBaseUrl;
}

export function getStripeApiKeyPublicPage(): string {
    return AppConsts.appStripeApiKey;
}

export function getStripeApiKeySubscribePlans(): string {
    return AppConsts.appStripeApiKey;
}

export function getStripeApiKeySubscribePlansBS(): string {
    return AppConsts.appStripeApiKey;
}

export function getCurrentLanguage(): string {
    return abp.localization.currentLanguage.name;
}

export function getBaseHref(platformLocation: PlatformLocation): string {
    var baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }

    return '/';
}

function handleLogoutRequest(authService: AppAuthService) {
    let currentUrl = UrlHelper.initialUrl;
    let returnUrl = UrlHelper.getReturnUrl();
    if (currentUrl.indexOf(('account/logout')) >= 0 && returnUrl) {
        authService.logout(true, returnUrl);
    }
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppModule,
        CommonModule.forRoot(),
        AbpModule,
        ServiceProxyModule,
        HttpClientModule,
        RootRoutingModule,
        NgxSliderModule,
        NgSelectModule,
        FormsModule,
        NgbModule,
        UtilsModule,
        NgOtpInputModule,
        NgxIntlTelInputModule,
        CableProComponent,
        BSCalcComponent,
        LoaderComponent
    ],
    exports: [],
    declarations: [
        RootComponent,
        AnonymousUserPageComponent,
        AnonymousUserCheckOutSuccessComponent,
        AnonymousUserCheckOutCancelComponent,
        LoginComponent,
        RegisterComponent
    ],
    providers: [
        LoginService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.RecaptchSiteKey,
        },
        RecaptchaLoaderService,
        ReCaptchaV3Service,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
        { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
        { provide: API_BASE_URL2, useFactory: getRemoteServiceCalBaseUrlSetting },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector, PlatformLocation],
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage
        },
        { provide: API_STRIPE_KEY_Public, useFactory: getStripeApiKeyPublicPage },
        { provide: API_STRIPE_KEY_SubscribePlans, useFactory: getStripeApiKeySubscribePlans },
        { provide: API_STRIPE_KEY_SubscribePlans_BS, useFactory: getStripeApiKeySubscribePlansBS }        
    ],
    bootstrap: [RootComponent]
})
export class RootModule {

}
