import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CalculationInput, CalculationOutput, LoadData, ProjectOutput, GetCurrentLoginInformationsOutput } from '@shared/service-proxies/service-proxies';
import { CalculationService, ProductService, SettingService } from '../../../services/project.service';
import { Subject } from 'rxjs';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { NgSelectModule } from '@node_modules/@ng-select/ng-select';
import { CommonModule } from '@node_modules/@angular/common';
import { FormsModule } from '@node_modules/@angular/forms';
import { NgbModule } from '@node_modules/@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '@shared/utils/utils.module';
import { AbpModule } from 'abp-ng2-module';

@Component({
    selector: 'app-addeditcalc',
    templateUrl: './addeditcalc.component.html',
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgbModule, UtilsModule, AbpModule, CommonModule]
})
export class AddeditcalcComponent extends AppComponentBase implements OnInit, AfterViewInit {

    public calculationOutput: CalculationOutput[] = [];
    public ProjectId: number;
    private protectiveCalculationCount: number = 0;
    private networkCalculationCount: number = 0;
    private cablePullingCalculationCount: number = 0;
    public projectOutput: any = [];
    @Input('refreshCalc') refreshCalc?: Subject<any>;
    @Input() calculationInput: CalculationInput = new CalculationInput();
    @Input() mode: string = 'add';
    @Input() isFromCalculationListPage: boolean = false;
    public IsFormvalid: boolean = true;
    public projectName: string = 'Test project';
    public electricInstallationValues: any;
    public electricInstallation: number;
    public LoadDataXml: LoadData = new LoadData();
    public calcStandard: number;
    @Output() getCalcs = new EventEmitter<number>();
    @Output() VoltageRiseReport? = new EventEmitter<any>();
    @Output() valuesUpdated = new EventEmitter<string>();
    public premiumUser!: boolean;
    public isPlanAddedManually: boolean = false;
    public userPlan = "";
    public isPlanLevelOne: boolean = false;
    public isPlanLevelTwo: boolean = false;
    public isPlanLevelThree: boolean = false;
    constructor(injector: Injector, private _projectService: ProductService, private _calculationService: CalculationService,
        private _appSessionService: AppSessionService, private activeRoute: ActivatedRoute, private _router: Router,
        private _settingService: SettingService) {
        super(injector);
        (window as any).addedit = this;
    }
    ngOnInit(): void {
        this._settingService.getSettings().subscribe((result) => {
            this.calcStandard = result.standards_CableSizing || 0;
        });
        this.ProjectId = +this.activeRoute.snapshot.params['pid'];
        this.projectName = this.activeRoute.snapshot.queryParamMap.get('name');
        this.calculationInput.projectId = this.ProjectId;
        this.calculationInput.calculationType = 1;
        this.GetCalcuations(this.ProjectId);
        this.projectOutput = [];
        // this.onSelect();
        this.electricInstallationValues = [
            { "name": "Domestic - Single domestic electrical installation or individual living unit per phase", value: 1 },
            { "name": "Domestic - Blocks of living units", value: 2 },
            { "name": "Industrial - Residential institutions, hotels, boarding houses, hospitals, accomodation houses, motels", value: 3 },
            { "name": "Industrial - Factories, shops, stores, offices, business premises, schools and churches", value: 4 },
        ];
        this.electricInstallation = 1;
        this.calculationInput.installId = 1;
        this.premiumUser = false;
        this.isPlanAddedManually = false;
        this.getCurrentLoginInformations();
        this.getproject();
        this.GetCalculationsByProject(() => {
            this.GetCalculationName(this.calculationInput.calculationType);
        });
        $(document).on('show.bs.modal', '#newcalcuation', () => {
            this.GetCalculationName(this.calculationInput.calculationType);
        })

        if (this.refreshCalc) {
            this.refreshCalc.subscribe(e => {
                this.GetCalculationsByProject(() => {
                    this.GetCalculationName(this.calculationInput.calculationType);
                });
            });
        }
    }
    ngAfterViewInit() {
        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }
        $(document).on('change', '#ddlElectricInstallation', (e: any) => {
            this.calculationInput.installId = parseInt(e.target.options[e.target.selectedIndex].value);
            if (this.mode === 'edit') {
                this.message.confirm(
                    'All loads which have been added will be removed.',
                    'Do you want to continue?',
                    (isConfirmed) => {
                        if (isConfirmed) {
                            this.LoadDataXml = new LoadData();
                            this.LoadDataXml.electricalInstallation = this.calculationInput.installId;
                            this.LoadDataXml.items = [];
                            this._calculationService.storeMaxDemandXml(this.LoadDataXml, this.calculationInput.id).subscribe(result => {
                                this.EditCalcuation();
                            });
                        } else {
                            $('#ddlElectricInstallation').val(this.electricInstallation).trigger('change.select2');
                        }
                    }
                );
            }
        });


    }

    getCurrentLoginInformations(): void {
        this.premiumUser = this._appSessionService.user.premiumUser;
        this.isPlanAddedManually = this._appSessionService.user.isPlanAddedManually;
        if (this._appSessionService.user.userStripePlanLevel !== null && this._appSessionService.user.userStripePlanLevel !== undefined) {
            if (this._appSessionService.user.userStripePlanLevel == 1) {
                this.isPlanLevelOne = true;
                this.userPlan = 'b';
            }
            else if (this._appSessionService.user.userStripePlanLevel == 2) {
                this.isPlanLevelTwo = true;
                this.userPlan = 's';
            }
            else if (this._appSessionService.user.userStripePlanLevel == 3) {
                this.isPlanLevelThree = true;
                this.userPlan = 'p';
            }
        }
        this.isPlanAddedManually = this._appSessionService.user.isPlanAddedManually;
    }

    GetCalcuations(id: number): void {
        if (id > 0)
            this.getCalcs.emit(id);
    }

    getproject() {
        if (!this.projectOutput || this.projectOutput.length == 0) {
            this._projectService.getProjectsForDdl().subscribe(result => {
                this.projectOutput = result.items;
                let $singleSelect = $('.c-select');
                $singleSelect.select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            });
        }
    }

    notifyUpdated(): void {
        this.valuesUpdated.emit('');
    }
    save(): void {
        if (this.mode === 'add' && this.calculationInput.calculationType == 999) {
            if (this.VoltageRiseReport) {
                this.VoltageRiseReport.emit(this.calculationInput.projectId);
            }
            $('#newcalcuation').modal('hide');
        }
        else if (this.CheckFormValid()) {
            if (this.mode === 'add') {
                this.AddCalcuation();
            } else {
                this.EditCalcuation();
            }
        }
    }
    EditCalcuation(): void {
        this._calculationService.editCalculation(this.calculationInput).subscribe(result => {
            this.Resetform();
            this.ProjectId = +this.activeRoute.snapshot.params['pid'];
            this.calculationInput.projectId = this.ProjectId;
            // this.onSelect();
            this.GetCalcuations(this.ProjectId);

            //this.getproject();
            $('#newcalcuation').modal('hide');
            this._calculationService.refreshSidebarCalculations();
            this._projectService.refreshSidebarProjects();

        });
    }

    CalculationNameChange(): void {
        this.IsFormvalid = true;
    }
    AddCalcuation(): void {
        this.projectName = this.projectOutput.find(m => m.id == this.calculationInput.projectId).name;
        this.ProjectId = this.calculationInput.projectId;
        //     if(this.isPlanLevelOne && this.premiumUser)
        //     {
        //     if((this.networkCalculationCount >=1 || this.cablePullingCalculationCount >=1 || this.protectiveCalculationCount >=1)
        //    && !(this.calculationInput.calculationType ==1 ||this.calculationInput.calculationType ==2)
        //     )
        //     {
        //         $("#trial-device").show();
        //         return ;
        //     }
        //     }
        //     if(this.isPlanLevelTwo && this.premiumUser)
        //     {

        //         if((this.networkCalculationCount >=1 || this.cablePullingCalculationCount >=1 || this.protectiveCalculationCount >=1)
        //         && !(this.calculationInput.calculationType ==1 ||this.calculationInput.calculationType ==2 || this.calculationInput.calculationType ==3)
        //          )
        //          {
        //         $("#trial-device").show();
        //         return ;

        //          }
        //     }



        if (this.calcStandard == 2 && (this.calculationInput.calculationType != 2 && this.calculationInput.calculationType != 4 && this.calculationInput.calculationType != 5 && this.calculationInput.calculationType != 6 && this.calculationInput.calculationType != 7 && this.calculationInput.calculationType != 8)) {
            this.calculationInput.calculationType = 3;
        }
        this._calculationService.createCalculation(this.calculationInput).subscribe(result => {
            var returnUrl = '';
            var otherParams = '&name=' + this.projectName + '&cname=' + this.calculationInput.name;
            if (this.calculationInput.calculationType === 1) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/calcs/' + result.id;
            }
            else if (this.calculationInput.calculationType === 3) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/bscalcs/' + result.id;
            }
            else if (this.calculationInput.calculationType === 4) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/automaxd/' + result.id;
            }
            else if (this.calculationInput.calculationType === 5) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/pdccalcs/' + result.id;
            }
            else if (this.calculationInput.calculationType === 6) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/calcp/' + result.id;
            }
            else if (this.calculationInput.calculationType === 7) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/networkcalc/' + result.id;
            }
            else if (this.calculationInput.calculationType === 8) {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/arcflash/' + result.id;
            }
            else {
                returnUrl = '?returnUrl=/app/main/projects/' + this.ProjectId + '/maxd/' + result.id;
            }
            // do not append version as it is being appended from routeback component.
            this._router.navigateByUrl('/app/main/routeback' + returnUrl + otherParams);
            this.Resetform();
            this.GetCalcuations(this.ProjectId);
            $('#newcalcuation').modal('hide');
            this.notifyUpdated();
            this._calculationService.refreshSidebarCalculations();
            this._projectService.refreshSidebarProjects();

        });

    }
    CheckFormValid(): boolean {
        if (this.calculationInput.name == null || this.calculationInput.name === undefined || this.calculationInput.name.length === 0 || this.calculationInput.name.trim() === undefined || this.calculationInput.name.trim() == '') {
            this.IsFormvalid = false;
            return false;
        }
        if (this.calculationInput.projectId == null || this.calculationInput.projectId === undefined || this.calculationInput.projectId === 0) {
            this.IsFormvalid = false;
            return false;
        }
        if (this.calculationInput.calculationType == null || this.calculationInput.calculationType === undefined || this.calculationInput.calculationType === 0) {
            this.IsFormvalid = false;
            return false;
        }
        if (this.calculationInput.calculationType == null || this.calculationInput.calculationType === undefined || this.calculationInput.calculationType === 0) {
            this.IsFormvalid = false;
            return false;
        }

        return true;
    }
    Resetform() {
        this.calculationInput.name = null;
        this.calculationInput.description = null;
        this.calculationInput.calculationType = 1;
        this.calculationInput.projectId = this.ProjectId;
        this.calculationInput.id = 0;
        this.electricInstallation = 1;
    }
    SetCalcType(type: number) {
        this.calculationInput.calculationType = type;
        this.GetCalculationName(this.calculationInput.calculationType);
        if (type == 3) {
            type = 1;
        }
        $('.img-selector').find('img').removeClass('active');
        $('.img-selector').find('img[data-calctype=' + type + ']').addClass('active');
        setTimeout(() => {
            $('#ddlElectricInstallation').select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });
        }, 100);
    }

    GetCalculationsByProject(callback) {
        if (this.ProjectId > 0) {
            this._calculationService.getCalculations(this.ProjectId).subscribe(result => {
                if (result.items.length > 0) {
                    this.calculationOutput = result.items;
                }
                else {
                    this.calculationOutput = [];
                }
                if (callback)
                    callback();
            });
        }
    }

    GetCalculationName(calculationType: any) {
        let name = '';
        switch (calculationType) {
            case 1: {
                //statements; 
                if (this.calcStandard == 2)
                    calculationType = 3;
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("cable sizing"));
                if (items && items.length > 0) {
                    name = "Cable Sizing (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Cable Sizing";
                break;
            }
            case 3: {
                //statements; 
                if (this.calcStandard == 2)
                    calculationType = 3;
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("cable sizing"));
                if (items && items.length > 0) {
                    name = "Cable Sizing (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Cable Sizing";
                break;
            }
            case 4: {
                //statements; 
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("maximum demand"));
                if (items && items.length > 0) {
                    name = "Maximum Demand (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Maximum Demand";
                break;
            }
            case 6: {
                //statements; 
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("cable pulling"));
                if (items && items.length > 0) {
                    name = "Cable Pulling (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Cable Pulling";
                break;
            }
            case 7: {
                //statements; 
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("lv network"));
                if (items && items.length > 0) {
                    name = "LV Network (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "LV Network";
                break;
            }
            case 5: {
                //statements; 
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("protection coordination"));
                if (items && items.length > 0) {
                    name = "Protection Coordination (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Protection Coordination";
                break;
            }
            case 8: {
                //statements; 
                let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase().startsWith("arc flash"));
                if (items && items.length > 0) {
                    name = "Arc Flash (" + (items.length) + ")";
                    name = this.checkRecursiveCalculationName(name, calculationType, items.length);
                }
                else
                    name = "Arc Flash";
                break;
            }
            default: {
                //statements; 
                name = "Calculation";
                break;
            }
        }

        this.calculationInput.name = name;
    }

    checkRecursiveCalculationName(calculationName, calculationType, count) {
        let name = calculationName;
        let items = this.calculationOutput.filter(m => m.calculationType == calculationType && m.name.toLowerCase() == name.toLowerCase());
        if (items && items.length > 0) {
            let calculationCount = count + 1;
            switch (calculationType) {
                case 1: {
                    let tempName = "Cable Sizing (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 3: {
                    let tempName = "Cable Sizing (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 4: {
                    let tempName = "Maximum Demand (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 6: {
                    let tempName = "Cable Pulling (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 7: {
                    let tempName = "LV Network (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 5: {
                    let tempName = "Protection Coordination (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                case 8: {
                    let tempName = "Arc Flash (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
                default: {
                    //statements; 
                    let tempName = "Calculation (" + (calculationCount) + ")";
                    name = this.checkRecursiveCalculationName(tempName, calculationType, calculationCount);
                    break;
                }
            }
        }
        return name;
    }

    ngOnDestroy() {
        if (this.refreshCalc)
            this.refreshCalc.unsubscribe();
    }

}
