// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1024px) {
  .subscription-bar-visible {
    margin-top: 30px !important;
  }
  .subscription-bar-visible #m_aside_left {
    margin-top: 30px !important;
  }
}
@media (max-width: 1024px) {
  .subscription-bar-visible .m-wrapper {
    margin-top: 30px !important;
  }
}
.webfacing-body {
  padding-top: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.less"],"names":[],"mappings":"AAAA;EACI;IACI,2BAAA;EACN;EAFE;IAIQ,2BAAA;EACV;AACF;AAGA;EACI;IAGQ,2BAAA;EAHV;AACF;AAOA;EACI,yBAAA;AALJ","sourcesContent":["@media (min-width: 1024px) {\n    .subscription-bar-visible {\n        margin-top: 30px !important;\n\n        #m_aside_left {\n            margin-top: 30px !important;\n        }\n    }\n}\n\n@media (max-width: 1024px) {\n    .subscription-bar-visible {\n        \n        .m-wrapper {\n            margin-top: 30px !important;\n        }\n    }\n}\n\n.webfacing-body {\n    padding-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
