import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AuthService } from 'services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class WebFacingGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(): boolean | Promise<boolean> {
        if (this.authService.isLoggedIn()) {
            return new Promise<boolean>((resolve) => {
                this.authService.validateGuestLoginToken().subscribe(
                    () => {
                        resolve(true);
                    },
                    (error: HttpErrorResponse) => {
                        if (error.status === HttpStatusCode.Forbidden) {
                            this.router.navigate(['/']);
                            resolve(false);
                        } else {
                            throw error;
                        }
                    }
                );
            });
        } else {
            return true;
        }
    }
}