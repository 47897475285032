import { style } from '@angular/animations';
import { Component, Injector, OnDestroy, Inject, Optional, InjectionToken, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetCurrentLoginInformationsOutput, SessionServiceProxy, PlanOutput, CheckOutServiceProxy, UserEditDto, CouponOutput, CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { catchError, tap, window } from 'rxjs/operators';
export const API_STRIPE_KEY_Public = new InjectionToken<string>('API_STRIPE_KEY_Public');
import { DomSanitizer, Title } from '@angular/platform-browser';
import { getAllISOCodes, Currency } from "iso-country-currency";
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '@app/main/cablepro/navigation.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Component({
    templateUrl: './anonymoususerpage.component.html',
    animations: [accountModuleAnimation()],
    // styleUrls: ['../assets/anonymouspage/css/style.css']
    // TODO: inline styles to be moved?
    styles: ['.tooltipc {position: relative;display: inline-block;}.tooltipc .tooltiptextc { font-weight:400; line-height: 1.5;visibility: hidden;width: 200px;background-color: #000;color: #fff;text-align: center; border-radius: 6px; padding: 5px 10px; position: absolute; z-index: 1;top: 100%;left: 50%;margin-left: -100px;opacity: 0;transition: opacity 0.3s;font-size: .875rem;} .tooltipc .tooltiptextc::after {content: "";position: absolute;bottom: 100%;left: 50%;margin-left: -5px;border-width: 5px;border-style: solid;border-color: transparent transparent #000 transparent;}.tooltipc:hover .tooltiptextc {visibility: visible;opacity: 1;color:#fff;}'],
    encapsulation: ViewEncapsulation.None
})
export class AnonymousUserPageComponent extends AppComponentBase implements OnDestroy {
    public showLoggedOut: boolean;
    public topPosition: number;
    public selectedCountry: string = 'AU';
    public Countries: Currency[] = getAllISOCodes();
    public selectedCountryObj: Currency = this.Countries.find(m => m.iso == this.selectedCountry);
    public currencyData: any;
    public planOutput: PlanOutput[] = [];
    public annualPlanOutput: PlanOutput[] = [];
    public monthlyPlanOutput: PlanOutput[] = [];
    public isMonthlyPlanValid1: boolean = true;
    public isMonthlyPlanValid2: boolean = true;
    public isYearlyPlanValid: boolean = true;
    public subscribePlanResult: string = "";
    public successFlag: boolean = false;
    public errorFlag: boolean = false;
    public planName: string = "";
    public planType: number = 0;
    public planInterval: string = "";
    public planAmount: any;
    public planAmountYearly: any;
    public planGST: any;
    public planGSTYearly: any;
    public planTotal: any = 0;
    public planTotalYearly: any = 0;
    public planHeading: string = "";
    public planCurrency: string = "";
    public planId: number = 0;
    public plan: PlanOutput = new PlanOutput();
    public checkOutUser: UserEditDto = new UserEditDto();
    public isRegisteredUser: boolean = false;
    public isEmailIdRegistered: boolean = true;
    public isNewUserhaveNoEmailIdRegistered: boolean = true;
    public stripe: any;
    private stripeKeyPublic: string;
    public couponOutput: CouponOutput[] = [];
    public appliedCoupon: CouponOutput = new CouponOutput();
    public isCouponApplied: boolean = false;
    public planDiscount: any = 0;
    public planDiscountYearly: any = 0;
    public couponHeading: string = "";
    public planTotalWithDiscount: any;
    public Iseventbinded: any = false;
    public planTotalYearlyWithDiscount: any;

    constructor(
        injector: Injector,
        private _router: Router,
        private _appSessionService: AppSessionService,
        protected html_sanitizer: DomSanitizer,
        private http: HttpClient,
        private navigationService: NavigationService,
        private _planService: CheckOutServiceProxy,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private titleService: Title,
        @Optional() @Inject(API_STRIPE_KEY_Public) stripeKeyPublic?: string
    ) {
        super(injector);
        this.titleService.setTitle("ELEK Cable Pro Software");
        this.stripeKeyPublic = stripeKeyPublic ? stripeKeyPublic : "";
        if (this._appSessionService.user) {
            this._router.navigateByUrl("/app/main/usersetup" + '?v=' + this.appSession.application.version);
            return;
        }
        this.GetPlans(100);

        this.loadScripts();

        this.showLoggedOut = false;
        this.topPosition = 0;
        this.navigationService.clearHistory();
        if (localStorage.getItem("showLogoutMsg")) {
            this.showLoggedOut = true;
            this.topPosition = 40;
            setTimeout(() => {


                this.dismissAlert();
            }, 10000);
        }
        this.getCurrentCountry();
        this.getCurrentCurrencyRate();
        this._appAuthService.deleteSessionCookie('Abp.AuthToken');
        this._appAuthService.deleteSessionCookie(AppConsts.authorization.encrptedAuthTokenName);
        this._appAuthService.deleteSessionCookie(AppConsts.productLife.ssoTokenKey);
    }

    getCurrentCountry() {
        this._commonLookupServiceProxy.getIpInfo().subscribe((response) => {
            if (response && response.isSuccess) {
                if (response.data && response.data.country) {
                    this.selectedCountry = response.data.country;
                    this.selectedCountryObj = this.Countries.find(m => m.iso == this.selectedCountry);
                }
            }
            this.sortCountries();
        });
    }

    sortCountries(): void {
        this.Countries = [...this.Countries.sort((a, b) => this._commonLookupServiceProxy.compareCountries(a, b, this.selectedCountryObj.countryName))];
        if (this.selectedCountryObj.countryName != "Australia") {
            this.replaceTextInSubheading();
        }
    }

    getCurrentCurrencyRate() {
        this._commonLookupServiceProxy.getCurrencyInfo("AUD").subscribe((response) => {
            if (response && response.isSuccess && response.data) {
                this.currencyData = JSON.parse(response.data);
            }
        });
    }

    sameAsHtml(html_content) {
        var a = this.html_sanitizer.bypassSecurityTrustHtml(html_content);
        return a;
    }

    ngAfterViewInit() {
        var monthly = document.getElementById("pricing-row-monthly");
        monthly.style.display = "none";

        stripe = Stripe(this.stripeKeyPublic);

        $(document).on('click', '#emailid-as-username', (e: any) => {
            if ($('#emailid-as-username').prop('checked')) {
                if ($('#unregemailid').val().toString().trim().length > 0)
                    $('#userid').val($('#unregemailid').val());
            }
            else { $('#userid').val(''); }
        });
        // var monthly = document.getElementById("pricing-row-monthly");
        // monthly.style.display = "none";
    }

    loadScripts() {
        const dynamicScripts = [
            'assets/anonymouspage/js/jquery-ui.min.js',
            //'assets/anonymouspage/js/plugins.js',
            //'assets/anonymouspage/js/owl.carousel.js',
            'assets/anonymouspage/js/jquery.accordion.js',
            'assets/anonymouspage/js/custom.js',
            'https://unpkg.com/ionicons@5.0.0/dist/ionicons.js',

        ];
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('body')[0].appendChild(node);
        }

        const dynamicCss = [
            'assets/anonymouspage/css/bootstrap.min.css',
            'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600%7COpen+Sans:400%7CVarela+Round',
            'assets/anonymouspage/css/jquery-ui.min.css',
            'assets/anonymouspage/css/jquery.accordion.css',
            'assets/anonymouspage/css/ionicons.min.css',
            'assets/anonymouspage/css/style.css',
            'assets/custom/css/Pricing.css',
            'assets/custom/css/pricing-2.css',
            'https://unpkg.com/@icon/open-iconic/open-iconic.css',
            'assets/anonymouspage/css/owl.carousel.css',
            'assets/anonymouspage/css/owl.theme.css'
        ];

        for (let i = 0; i < dynamicCss.length; i++) {
            const node = document.createElement('link');
            node.href = dynamicCss[i];
            node.rel = 'stylesheet';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    ngOnDestroy() {
        $('script[src="assets/anonymouspage/js/popper.min.js"]').remove();
        $('script[src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"]').remove();
        $('script[src="assets/anonymouspage/js/jquery.validate.min.js"]').remove();
        $('script[src="assets/anonymouspage/js/plugins.js"]').remove();
        $('script[src="assets/anonymouspage/js/jquery-ui.min.js"]').remove();
        $('script[src="assets/anonymouspage/js/jquery.accordion.js"]').remove();
        $('script[src="assets/anonymouspage/js/validator.js"]').remove();

        $('script[src="assets/anonymouspage/js/custom.js"]').remove();
        // $('script[src="https://registry.yarnpkg.com/zone.js/-/zone.js-0.8.26.tgz#7bdd72f7668c5a7ad6b118148b4ea39c59d08d2d"]').remove();        

        $('link[href="assets/anonymouspage/css/bootstrap.min.css"]').remove();
        $('link[href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600%7COpen+Sans:400%7CVarela+Round"]').remove();

        $('link[href="assets/anonymouspage/css/jquery-ui.min.css"]').remove();
        $('link[href="assets/anonymouspage/css/jquery.accordion.css"]').remove();
        $('link[href="assets/anonymouspage/css/ionicons.min.css"]').remove();

        $('link[href="assets/anonymouspage/css/style.css"]').remove();
        $('link[href="assets/custom/css/components.css"]').remove();
        $('link[href="assets/custom/css/Pricing.css"]').remove();
        $('link[href="assets/custom/css/pricing-2.css"]').remove();
    }

    dismissAlert() {
        localStorage.removeItem("showLogoutMsg");
        this.showLoggedOut = false;
        this.topPosition = 0;
    }
    sliderSwitchClick() {
        // var x = document.getElementById("annual-price-base")
        // var y = document.getElementById("annual-price-full");
        // var z = document.getElementById("annual-price-pro");
        // var a = document.getElementById("monthly-price-base")
        // var b = document.getElementById("monthly-price-full");
        // var c = document.getElementById("monthly-price-pro");
        var annual = document.getElementById("pricing-row-annual");
        var monthly = document.getElementById("pricing-row-monthly");

        if (annual.style.display === "none") {
            annual.style.display = "flex";
            monthly.style.display = "none";
        }
        else {
            annual.style.display = "none";
            monthly.style.display = "flex";
        }
        // if (x.style.display === "none") {
        // 	x.style.display = "block";
        // 	y.style.display = "block";
        // 	z.style.display = "block";
        // 	a.style.display = "none";
        // 	b.style.display = "none";
        // 	c.style.display = "none";
        // } else {
        // 	x.style.display = "none";
        // 	y.style.display = "none";
        // 	z.style.display = "none";
        // 	a.style.display = "block";
        // 	b.style.display = "block";
        // 	c.style.display = "block";
        // }
    }
    GetPlans(standardid: number): void {
        this._planService.getPlans(standardid).subscribe(result => {
            if (result.items.length > 0) {
                this.planOutput = result.items;

                this.annualPlanOutput = this.planOutput.filter(x => x.planType == 1).sort(x => x.id);
                this.monthlyPlanOutput = this.planOutput.filter(x => x.planType == 2).sort(x => x.id);
            }
            else {
                this.planOutput = [];
                this.annualPlanOutput = [];
                this.monthlyPlanOutput = [];
            }

        });

        //this._planService.getCoupons(standardid).subscribe(result => {
        //    if (result.items.length > 0) {
        //        this.couponOutput = result.items;
        //    }
        //    else {
        //        this.couponOutput = [];
        //    }
        //});
    }

    scrollToplans(): void {
        document.getElementById('view-plans').scrollIntoView();
    }

    onddlCountryChange(data) {
        this.selectedCountryObj = this.Countries.find(m => m.iso == data.iso);
        if (data.countryName != "Australia") {
            this.replaceTextInSubheading();
        }
        else {
            this.GetPlans(100);
        }
    }
    replaceTextInSubheading() {
        for (var i = 0; i < this.monthlyPlanOutput.length; i++) {
            this.monthlyPlanOutput[i].subHeading = this.monthlyPlanOutput[i].subHeading.replace(',GST Inc', '')
        }
        for (var i = 0; i < this.annualPlanOutput.length; i++) {
            this.annualPlanOutput[i].subHeading = this.annualPlanOutput[i].subHeading.replace(',GST Inc', '')
        }
    }

    getCurrencyForPrice(amount) {
        let price = amount;

        if (this.currencyData && this.currencyData.data && this.selectedCountryObj) {
            try {
                let rate = this.currencyData.data[this.selectedCountryObj.currency];
                if (rate) {
                    return Math.round(parseInt(amount) * parseFloat(rate.value));
                }
            }
            catch { return price; }
        }

        return price;
    }
}
