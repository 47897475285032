import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  private actionSource = new Subject<string>();
  action$ = this.actionSource.asObservable();

  constructor() { }

  triggerAction(action: string): void {
    this.actionSource.next(action);
  }
}
