// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#NotificationsTable {
  color: #000;
}
#NotificationsTable a {
  color: #000;
}
#NotificationsTable .notification-read {
  color: #999;
}
#NotificationsTable .notification-read a {
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/notifications/notifications.component.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAFA;EAIQ,WAAA;AACR;AALA;EAQQ,WAAA;AAAR;AARA;EAWY,WAAA;AAAZ","sourcesContent":["#NotificationsTable {\n    color: #000;\n\n    a {\n        color: #000;\n    }\n\n    .notification-read {\n        color: #999;\n\n        a {\n            color: #999;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
