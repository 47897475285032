import { Component, Injector, OnInit, AfterViewInit, Pipe, PipeTransform, ChangeDetectorRef, Input, SimpleChanges, Optional, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import {
    CableproServiceProxy, CableproXmlInput, InsulationDto, ConductorDto, CoreDto, PhaseDto, InstallTableDto, SpecifyConductorDto,
    UiControlsLookupDto, WindSpeedDto, CalculationReportInput, SettingOutput, CustomereportFieldsInput, UserLoginInfoDto,
    DRFServiceServiceProxy, DRFWizrd, TableItem, CalculationInput, WizardServiceProxy, WizardFactorInput, WizardFactorOutput, SubscriptionServiceProxy
} from '@shared/service-proxies/service-proxies';
import { CableproCalculationServiceProxy, CalculationResult } from '@shared/service-proxies/service-proxy-calc';
import { LoaderService } from './loader.service';
import { GetCurrentLoginInformationsOutput } from '@shared/service-proxies/service-proxies';
import { saveAs } from 'file-saver';
import { PdfReportServiceProxy } from '@shared/service-proxies/service-proxy-rpt';
import * as moment from 'moment';
import { SettingService, DRFService, CalculationService, ProductService } from '../../../services/project.service';
import { ErrorWarningModel } from './models/network-calc.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { environment } from 'environments/environment';
import { AbpModule } from 'abp-ng2-module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgbModule } from '@node_modules/@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@node_modules/@angular/forms';
import { NgSelectModule } from '@node_modules/@ng-select/ng-select';
import { CommonModule } from '@node_modules/@angular/common';
import { AddeditcalcComponent } from '../projects/addeditcalc.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedService } from '@shared/service-proxies/shared.service';
import { API_BASE_URL2 } from '../projects/settings.component';
import { ImageUtils } from '@shared/helpers/ImageUtils';
import { HttpClient } from '@angular/common/http';

class Model {
    InsulationId: number;
    ConductorId: number;
    CoreId: number;
    PhaseId: number;
    ActiveId: number;
    NeutralId: number;
    EarthId: string;
    EarthConductorId: number;
    MethodIndex: number;
    InstallTableId: number;
    WindSpeedId: number;
    ConduitSizeId: number;
}
class Table22Model {
    Arrangement: number;
    AddType: number;
    NoOfCircuits: number;
    drf: number;
}
class Table271Model {
    AbientTemp: number;
    ConductorTemp: number;
    UseStandard: boolean;
    drf: number;
}
class Table25Model {
    CableType: number;
    NoOfCircuits: number;
    TouchingType: number;
    InstallType: number;
    Distance: number;
    drf: number;
    Arrangement: number;
}
class Table28Model {
    UseStandard: boolean;
    Depth: number;
    ConductorSize: number;
    Core: number;
    Buried: number;
    drf: number;
}
class Table29Model {
    UseStandard: boolean;
    Resitivity: number;
    Installation: number;
    drf: number;
}
class Table23Model {
    CableType: number;
    Installation: number;
    Row: number;
    Cables: number;
    drf: number;
}
class Table23InstallationModel {
    name: string;
    value: number;
    img: string;
    desc2: string;
    desc: string;
}

@Pipe({ name: 'symbol', standalone: true })
export class CustomName implements PipeTransform {
    transform(name: string) {
        let val = name.split(' ')[0];
        let unit = name.split(' ')[1];
        return val + ' ' + unit;
    }
}
@Component({
    selector: 'cable-sizing-as',
    standalone: true,
    templateUrl: './cablepro.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./loader.component.css'],
    imports: [NgSelectModule, FormsModule, NgbModule, UtilsModule, AbpModule, CommonModule, AddeditcalcComponent, NgxPaginationModule, CustomName]
})
export class CableProComponent extends AppComponentBase implements OnInit, AfterViewInit {
    public calculationInput: CalculationInput = new CalculationInput();
    public wizardFactorInput: WizardFactorInput = new WizardFactorInput();
    public wizardFactorInputList: WizardFactorInput[] = [];
    public wizardFactorOutput: WizardFactorOutput[] = [];
    public mode: string = 'add';
    public showreportloader: boolean = false;

    public insulationObj: any;
    public conductorObj: any;
    public coreObj: any;
    public phaseObj: any;
    public insulationData: InsulationDto[] = [];
    public conductorData: ConductorDto[] = [];
    public coreData: CoreDto[] = [];
    public phaseData: PhaseDto[] = [];
    public installTableData: InstallTableDto[] = [];
    public specifyConductorData: SpecifyConductorDto[] = [];
    public uiControlsLookUpData: UiControlsLookupDto[] = [];
    public windSpeedData: WindSpeedDto[] = [];
    public PrevInsulationId = 0;
    public PrevConductorId = 0;
    public PrevCoreId = 0;
    public PrevPhaseId = 0;
    public PrevImageIndex = 0;
    public CableproInput = new Model();
    public hasWind: boolean;
    public TableId: number;
    public ShowActive = false;
    public showActiveLab = true;
    public ShowConduitSize: boolean = false;
    public ShowConduitSizeLab: boolean = true;
    public PrevConduitSize: any;
    public ShowNeutral = false;
    public ShowNeutralLab = true;
    public ShowEarth = false;
    public ShowEarthLab = true;
    public CableSize = {};
    public PrevCableSize = null;
    public forceCalc: boolean = false;
    public Xml: any;
    public Dictatingfactorres: any;
    public resultXml: any;
    public PrevActiveValue: any;
    public PrevNeutralValue: any;
    public PrevMCBVal: any = '';
    public inc: number = 1
    public PrevMCCBVal: any = '';
    public PrevACBVal: any = '';
    public PrevHRCVal: any = '';
    public PrevCurveVal: any = '';
    public PrevCustomeRatingVal: any = '';
    public showLable = false;
    public active_res: any = '-';
    public neutral_res: any = '-';
    public earth_res: any = '-';
    public voltage_res: any = '-';
    public voltage_res1: any = '-';
    public faultLoop_res: any = '-';
    //public conduit_res: any = '-';
    public Circuitrating_drated: any;
    public Dictatingfactor: any;
    public Currentrating_cable_tabulated: any;
    public Currentrating_cable_tabulated_Ref_Desc_TableNo: any;
    public Currentrating_cable_tabulated_Ref_Desc_ColumnNo: any;
    public CCC_Currentrating_cable_tabulated_Operating_temperature: any;
    public VoltageDrop_RefAndDesc_Resistance_TableNo: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp: any;
    public VoltageDrop_RefAndDesc_Reactance_TableNo: any;
    public VoltageDrop_RefAndDesc_Reactance_ColumnNo: any;
    public VoltageDrop_Data_PhaseConductor_Resistance: any;
    public VoltageDrop_Data_PhaseConductor_Reactance: any;
    public VoltageDrop_Result_MaxLengthOfRun: any;
    public ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size: any = '-';
    public ShortCircuitPerformance_PhaseConductor_kfactor: any = '-';
    public ShortCircuitPerformance_ok: any = '1';
    public ShortCircuitPerformance_EarthConductor_ok: any = '1';
    public ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct: any = '-';
    public ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size: any = '-';
    public ShortCircuitPerformance_NuetralConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size: any = '-';
    public ShortCircuitPerformance_EarthConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct: any = '-';
    public CircuitCurrentrating_Adequate: any;
    public VoltageDrop_Result_VoltageDrop_Acceptable: any;
    public EarthLoop_ok: any;
    public MaxAllowedDevice: any;
    public ConditSizeFound: any;
    public ConditSizeBasedOn: any;

    public NoofConduit: string = '1';
    public ConduitDiameter: any;
    public MinimumConduitDiameter: any;

    public ConditCableDiameter: any;
    public ConditCableDiameterActive: any;
    public Activeneutralshownseparately: any;

    public ConditCableDiameterNeutral: any;
    public ConditNoofCablesPerCondit: any;
    public ConditNoofCablesPerConditActive: any;
    public ConditNoofCablesPerConditNeutral: any;
    public ConditSpaceFactorActual: any;
    public ConditSpaceFactorStandard: any;
    public ConditSpaceFactorActualNeutral: any;

    public ConditRefrenceTable: any;
    public ConditNeutralRefrenceTable: any;
    @Input() newCalculationId: number;
    @Input() calculationName: string;
    @Input() projectId: number;
    @Input() projectName: string;
    @Input() isFromWebfacing: boolean = false;
    @Input() nzStandard: boolean = false;
    public cableproXmlInput: CableproXmlInput = new CableproXmlInput();
    public dc_Voltage: string;
    public onePhase_Voltage: string;
    public threePhase_Voltage: string;
    public voltage_txt: string;
    public flag = false;
    public Spare_current_capacity_of_circuit: any = '-';
    public active_NoOf_circuit: any = '1';
    public neutral_NoOf_circuit: any = '1';
    public earth_NoOf_circuit: any = '1';
    public PrevEarthConductorId = 0;

    public faultloop1: any;
    public faultloop2: any;
    public faultloop3: any;
    public faultloop4: any;
    public faultloop5: any;
    public faultloop6: any;
    public faultloop7: any;

    public CalculationReportInput: CalculationReportInput = new CalculationReportInput();
    public OriDrfWizard: DRFWizrd;
    //report fileds
    public vdrop: any;
    public vdroppecent: any;
    public vdropPermissibale: any;
    public referencetempdegCReport: any;
    public standardCountryReport: any;
    public calculation_type: string = "1";
    public activeCondz1Report: any;
    public Earthcondz2Report: any;
    public settingOutput: SettingOutput = new SettingOutput();
    public companyLogo_ShowLogo: boolean;
    public isOldCalculation: boolean = false;
    public customeReportFields_ShowCustomField: boolean;
    public companyLogo_FileName: string;
    public customFields: CustomereportFieldsInput[] = [];
    public VoltageDrop_Data_NeutralConductor_Resistance: any;
    public VoltageDrop_Data_NeutralConductor_Reactance: any;
    public validProjectName = false;
    public validJobName = false;
    public Three_phaseShort_circuitCurrent: any;
    public Active_to_EarthShort_circuitCurrent: any;
    public tempDeracting: string;
    public currentUnit: string = "1";
    public tempRating: string;
    public showEarthButton = true;
    public showEfficency = false;
    public user!: UserLoginInfoDto | undefined;
    public isWebFacingGuestUser: boolean = false;
    public calcCount: number = 0;
    public premiumUser!: boolean;
    private isPlanAddedManually: boolean = false;
    public planLevel: string | number = '';

    public drInstallType: number | 1;
    public drBunchingType: number | 1;
    public drBunchingCore: number | 1;

    public table22model: Table22Model;
    public drTable22output: any;
    public drTable271output: any;
    public drTable25output: any;
    public drTable22ArrangementValues: any;
    public drTable22CircuitValues: any;

    public table271model: Table271Model;
    public conductorTempValues: any;

    public table25model: Table25Model;
    public table25NoOfCircuitValues: any;
    public table25CableTypeValues: any;
    public table25ArrangementValues: any;

    public table28model: Table28Model;
    public table28output: any;
    public table28ConductorSizeValues: any;
    public displayCurrentError!: boolean;
    public displayShortCircuitPerformance!: boolean;

    public wizard: any;

    public table29model: Table29Model;
    public table29output: any;
    public table29InstallationValues: any;

    public table23model: Table23Model;
    public table23output: any;
    public table23RowValues: any;
    public table23CableValues: any;
    public loading: boolean;
    public isCalculating: boolean;
    public finalDrf: number;
    public UserWizard: boolean;
    public voltageSupplyLabel: string = '';
    public lblcurrent: string = 'Current';
    public prevVoltageSupplyLabel: string = '';
    public toRemoveData: any;
    private SelectInstallTableMethodIndex: any;
    private SelectInstallTableTableId: any;
    private SelectInstallTableName: string;
    private SelectInstallTableDescription: string;
    public powerunit: string = '1';
    public efficiency: number = 100;

    displayErrorBtn: boolean = false;
    warningErrorStrData: string = '';
    warningErrorBtnText: string = "Warnings";
    warningErrorHeader: string = "Errors";
    warningErrorList: ErrorWarningModel[] = [];
    paginationConfig = {
        id: 'error-pagination-cablepro',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 4
    };
    public earthConductorData: any = [
        { "id": 0, "text": "Same as phase conductor" },
        { "id": 1, "text": "Copper" },
        { "id": 2, "text": "Aluminium" }
    ]
    public devices: any = [
        { "id": '0', "text": "Unknown" },
        { "id": '1', "text": "MCB" },
        { "id": '2', "text": "MCCB" },
        { "id": '3', "text": "ACB" },
        { "id": '4', "text": "HRC Fuse" },
        { "id": '5', "text": "Custom" }
    ]
    public device: string = "0";
    public NeutralDisabled: boolean = false;

    public earthList: any = [
        { "id": '1', "text": "1 mm²" },
        { "id": '1.5', "text": "1.5 mm²" },
        { "id": '2.5', "text": "2.5 mm²" },
        { "id": '4', "text": "4 mm²" },
        { "id": '6', "text": "6 mm²" },
        { "id": '10', "text": "10 mm²" },
        { "id": '16', "text": "16 mm²" },
        { "id": '25', "text": "25 mm²" },
        { "id": '35', "text": "35 mm²" },
        { "id": '50', "text": "50 mm²" },
        { "id": '70', "text": "70 mm²" },
        { "id": '95', "text": "95 mm²" },
        { "id": '120', "text": "120 mm²" },
        { "id": '150', "text": "150 mm²" },
        { "id": '185', "text": "185 mm²" },
        { "id": '240', "text": "240 mm²" },
        { "id": '300', "text": "300 mm²" },
        { "id": '400', "text": "400 mm²" },
        { "id": '500', "text": "500 mm²" },
        { "id": '630', "text": "630 mm²" },

    ]

    public conduitSizesPristine: any = [
        { "id": '16', "text": "1 x 16 mm" },
        { "id": '20', "text": "1 x 20 mm" },
        { "id": '25', "text": "1 x 25 mm" },
        { "id": '32', "text": "1 x 32 mm" },
        { "id": '40', "text": "1 x 40 mm" },
        { "id": '50', "text": "1 x 50 mm" },
        { "id": '63', "text": "1 x 63 mm" },
        { "id": '65', "text": "1 x 65 mm" },
        { "id": '80', "text": "1 x 80 mm" },
        { "id": '100', "text": "1 x 100 mm" },
        { "id": '125', "text": "1 x 125 mm" },
        { "id": '150', "text": "1 x 150 mm" },
        { "id": '175', "text": "1 x 175 mm" },
        { "id": '225', "text": "1 x 225 mm" },
        { "id": '300', "text": "1 x 300 mm" },
        { "id": '375', "text": "1 x 375 mm" },
        { "id": '475', "text": "1 x 475 mm" },
        { "id": '600', "text": "1 x 600 mm" }
    ]

    public conduitSizes: any = [
        { "id": '16', "text": "1 x 16 mm" },
        { "id": '20', "text": "1 x 20 mm" },
        { "id": '25', "text": "1 x 25 mm" },
        { "id": '32', "text": "1 x 32 mm" },
        { "id": '40', "text": "1 x 40 mm" },
        { "id": '50', "text": "1 x 50 mm" },
        { "id": '63', "text": "1 x 63 mm" },
        { "id": '65', "text": "1 x 65 mm" },
        { "id": '80', "text": "1 x 80 mm" },
        { "id": '100', "text": "1 x 100 mm" },
        { "id": '125', "text": "1 x 125 mm" },
        { "id": '150', "text": "1 x 150 mm" },
        { "id": '175', "text": "1 x 175 mm" },
        { "id": '225', "text": "1 x 225 mm" },
        { "id": '300', "text": "1 x 300 mm" },
        { "id": '375', "text": "1 x 375 mm" },
        { "id": '475', "text": "1 x 475 mm" },
        { "id": '600', "text": "1 x 600 mm" }
    ]

    public powerUnits: any = [
        { "id": '1', "text": "Amps" },
        { "id": '2', "text": "Watts" },
        { "id": '3', "text": "kW" },
        { "id": '4', "text": "h.p." }
    ]

    public earthfaulttime: number;
    public protection_curve: number;
    public rcd_chk: boolean = false;

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private _CableproService: CableproServiceProxy,
        private _CableproCalc: CableproCalculationServiceProxy,
        private _settingService: SettingService,
        private loaderService: LoaderService,
        private _appSessionService: AppSessionService,
        private ref: ChangeDetectorRef,
        private drfService: DRFServiceServiceProxy,
        private _pdfReportSerivceProxy: PdfReportServiceProxy,
        private drfGetService: DRFService,
        private _calculationService: CalculationService,
        private _wizardService: WizardServiceProxy,
        private _router: Router,
        private _projectService: ProductService,
        private _subscriptionService: SubscriptionServiceProxy,
        private sharedService: SharedService,
        private http: HttpClient,
        @Optional() @Inject(API_BASE_URL2) baseUrl?: string
    ) {
        super(injector);
        (window as any).cablePro = this;
        this.baseUrl = baseUrl ? baseUrl : "";
        this.toRemoveData = this.route.params.subscribe((params) => {
            this.newCalculationId = parseInt(params.cid);
            this.projectId = parseInt(params.pid);
            this.calculationName = this.route.snapshot.queryParamMap.get('cname');
            this.projectName = this.route.snapshot.queryParamMap.get('name');
            this.CalculationReportInput.projectname = this.projectName;
            this.CalculationReportInput.jobNo = this.calculationName;
            this._calculationService.getVoltageDrop().subscribe(result => {
                this.voltageSupplyLabel = result;
                this.prevVoltageSupplyLabel = result;

                if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null) {
                    this.voltageSupplyLabel = 'drop';
                    this.prevVoltageSupplyLabel = 'drop';
                }
                if (this.voltageSupplyLabel == 'rise') {

                    if (this.currentUnit == "1") {
                        this.lblcurrent = 'Output current';
                    }
                    else {
                        this.lblcurrent = 'Output power';
                    }
                }
            });
        });
    }
    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
    private actionSubscription!: Subscription;
    ngOnInit(): void {
        this.actionSubscription = this.sharedService.action$.subscribe((action: string) => {
            if (action === 'report') {
                this.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
                this.companyLogo_FileName = undefined;
                this.CalculationReportInput.eamilList = undefined;
                this.deleteVisible = false;
                $("#reportDlg").modal('show');
            }
        });
        this.inputSubject
            .pipe(debounceTime(1000)) // Adjust the debounce time (in milliseconds) as needed
            .subscribe((value: any) => {
                this.GetTable271Dr(value.e, value.type, value.checkdecimalplaces);
            });
        this.CableSize = {
            insulation: '0',
            conductor: '0',
            earthconductor: '0',
            core: '0',
            phase: '0',
            faultlevel: '3',
            supplyvoltage: '230',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            protection: {
                shortcircuittime: '0.1'
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '4',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: '0',
            isderatingwiz: '0',
            deratingtemp: '40',
            conduitsize: {
                mode: '0',
                size: '0'
            }
        };
        this.drInstallType = 1;
        this.table271model = new Table271Model();
        this.table271model.UseStandard = true;
        this.CalculationReportInput.printName = true;

        this.ShowNameChanged();
        this._settingService.getSettings().subscribe((result) => {
            if (result.standards_CableSizing !== undefined) {
                if (this.isFromWebfacing && this.nzStandard) {
                    this.CableSize['calculationstandard'] = "1";
                } else
                    this.CableSize['calculationstandard'] = result.standards_CableSizing.toString();
                this.CableSize['voltagefactor'] = result.short_Circuit_VoltageFactor_C.toString();
                this.CableSize['dcvoltage'] = result.deafaultVoltages_DcVoltage.toString();
                this.CableSize['onephasevoltage'] = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.CableSize['threephasevoltage'] = result.deafaultVoltages_ThreePhaseAcvoltage.toString();
                this.dc_Voltage = result.deafaultVoltages_DcVoltage.toString();
                this.onePhase_Voltage = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                this.threePhase_Voltage = result.deafaultVoltages_ThreePhaseAcvoltage.toString();

                this.standardCountryReport = this.CableSize['calculationstandard'];
                //report fields
                this.companyLogo_ShowLogo = result.companyLogo_ShowLogo;
                this.customeReportFields_ShowCustomField = result.customeReportFields_ShowCustomField;
                this.companyLogo_FileName = result.companyLogo_LogoUrl;
                this.customFields = result.customFields;
            } else {
                if (this.isFromWebfacing && this.nzStandard) {
                    this.CableSize['calculationstandard'] = "1";
                } else
                    this.CableSize['calculationstandard'] = "0";
                this.CableSize['voltagefactor'] = '1.1';
                this.CableSize['dcvoltage'] = '1500';
                this.CableSize['onephasevoltage'] = '230';
                this.CableSize['threephasevoltage'] = '400';
                this.dc_Voltage = '1500';
                this.onePhase_Voltage = '230';
                this.threePhase_Voltage = '400';
                this.standardCountryReport = this.CableSize['calculationstandard'];
                this.companyLogo_ShowLogo = false;
                this.customeReportFields_ShowCustomField = false;
                this.companyLogo_FileName = '';
                this.customFields = null;
            }
            this.GetData('Load', '', 0, 0, 0, 0, 0, 0, 0, '', '', this.newCalculationId, 0, 0, 0);

            var standard = parseInt(this.CableSize['calculationstandard']);

            if (this.drInstallType == 1) {
                if (standard == 0) {
                    this.table271model.AbientTemp = 40;
                }
                else {
                    this.table271model.AbientTemp = 30;
                }
            }
            else {
                if (standard == 0) {
                    this.table271model.AbientTemp = 25;
                }
                else {
                    this.table271model.AbientTemp = 15;
                }
            }
            this.table271model.ConductorTemp = 75;
            this.table271model.drf = 1;
        });
        setTimeout(() => {
            this.specifyConductorChange();
            this.onDropDownChange();
            this.onCheckBoxChange();
            this.onNoEarthChange();
            document.getElementById('defaultOpen').click();

            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });
        });

        this.onretrySave();
        this.premiumUser = false;
        this.isPlanAddedManually = false;
        this.displayCurrentError = false;
        this.displayShortCircuitPerformance = false;
        this.getCurrentLoginInformations();

        this.drBunchingType = 1;
        this.drBunchingCore = 1;
        this.wizard = undefined;
        this.UserWizard = false;
        this.table22model = new Table22Model();
        this.table22model.Arrangement = 1;
        this.table22model.AddType = 1;
        this.table22model.NoOfCircuits = 1
        this.table22model.drf = 1;
        this.drTable22output = null;
        this.drTable22ArrangementValues = [
            { "name": "Bunched in air", "value": 1 },
            { "name": "Bunched on a surface or enclosed", "value": 2 },
            { "name": "Single layer on wall or floor", "value": 3 },
            { "name": "Single layer under ceiling", "value": 4 },
        ];
        this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
        { "name": 2, "value": 2 },
        { "name": 3, "value": 3 },
        { "name": 4, "value": 4 },
        { "name": 5, "value": 5 },
        { "name": 6, "value": 6 }];

        /* Soil installation - step 1 - Group of buried circuits */
        this.table25model = new Table25Model();
        this.table25model.NoOfCircuits = 1;
        this.table25model.CableType = 1;
        this.table25model.TouchingType = 1;
        this.table25model.InstallType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;
        this.table25NoOfCircuitValues = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
            { "name": "7 groups", "value": 7 },
            { "name": "8 groups", "value": 8 },
            { "name": "9 groups", "value": 9 },
            { "name": "10 groups", "value": 10 },
            { "name": "11 groups", "value": 11 },
            { "name": "12 groups", "value": 12 },
        ];
        this.table25ArrangementValues = [
            { "name": "Touching trefoil", "value": 1 },
            { "name": "Touching flat", "value": 2 },
            { "name": "Spaced 0.15 m", "value": 3 },
            { "name": "Spaced 0.30 m", "value": 4 },
            { "name": "Spaced 0.45 m", "value": 5 },
            { "name": "Spaced 0.60 m", "value": 6 },
        ];
        /* Soil installation - step 1 - Group of buried circuits */

        /* Soil installation - step 2 - Depth of Burial (m) */
        this.table28model = new Table28Model();
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.5;
        this.table28model.Buried = 1;
        this.table28model.ConductorSize = 3;
        this.table28model.Core = 1;
        this.table28model.drf = 1;
        this.table28ConductorSizeValues = [
            { "name": "Up to 50 mm²", "value": 1 },
            { "name": "Above 50 mm² up to 300 mm²", "value": 2 },
            { "name": "Above 300 mm²", "value": 3 },
        ];
        /* Soil installation - step 2 - Depth of Burial (m) */

        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */
        this.table29model = new Table29Model();
        this.table29model.UseStandard = true;
        this.table29model.Resitivity = 1.20;
        this.table29model.Installation = 2;
        this.table29model.drf = 1;
        this.table29InstallationValues = [
            { "name": "Multi-core cable buried direct", "value": 1 },
            { "name": "Two or three single core cables buried direct", "value": 2 },
            { "name": "Multi-core cable in a wiring enclosure", "value": 3 },
            { "name": "Two single core cable in a wiring enclosure", "value": 4 },
            { "name": "Tree single core cable in a wiring enclosure", "value": 5 },
        ];
        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */

        /* Soil installation - step 4 - Soil ambient temperature */
        $('#inputUseStandardSoil').attr('disabled', 'true');
        $('#inputUseStandardAir1').attr('disabled', 'true');
        $('#inputUseStandardAir2').attr('disabled', 'true');
        if (this.drInstallType == 2) {
            this.conductorTempValues = [
                // { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        else {
            this.conductorTempValues = [
                { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        /* Soil installation - step 4 - Soil ambient temperature */

        this.table23model = new Table23Model();
        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
        if (this.drBunchingCore == 1) {
            this.GetTable23Values();
            this.table23CableValues = [
                { "name": "1 cct", "value": 1 },
                { "name": "2 ccts", "value": 2 },
                { "name": "3 ccts", "value": 3 },
            ];
        }
        else {
            this.GetTable24Values();
            this.table23CableValues = [
                { "name": "1", "value": 1 },
                { "name": "2", "value": 2 },
                { "name": "3", "value": 3 },
                { "name": "4", "value": 4 },
                //{ "name": "5", "value": 5 },
                { "name": "6", "value": 6 },
                { "name": "9", "value": 9 },
            ];
        }
        this.table23RowValues = [
            { "name": "1 row", "value": 1 },
            { "name": "2 rows", "value": 2 },
            { "name": "3 rows", "value": 3 },
        ];
        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');
        $('#divburiedGroupArrangement').css('display', 'none');
        //this.onSelectInstall(1);


        if (localStorage.getItem("ReportSettingEmails")) {
            this.CalculationReportInput.eamilList = localStorage.getItem("ReportSettingEmails");
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        // if (changes && changes.projectName && changes.projectName.currentValue) {
        //     this.CalculationReportInput.projectname = changes.projectName.currentValue;
        // }
        // if (changes && changes.calculationName && changes.calculationName.currentValue) {
        //     this.CalculationReportInput.jobNo = changes.calculationName.currentValue;
        // }

    }

    ngDoCheck() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover'
        });
    }

    getCurrentLoginInformations(): void {
        this.user = this._appSessionService.user;
        this.premiumUser = this.user.premiumUser;
        this.planLevel = this.user.userStripePlanLevel;
        this.isPlanAddedManually = this.user.isPlanAddedManually;
        this.isWebFacingGuestUser = this.user.userName.toLocaleLowerCase() == "webfacing.guest";
    }
    GetData(mode: string, value: string, insulationId: number, conductorId: number, coreId: number, phaseId: number, methodIndex: number, installTableId: number, windSpeedId: number, load: string, protection: string, calculationId: number, ConductorIndex: number, CoreIndex: number, earthConductorId: number) {
        this.showLoader();
        var armourId = 0;
        this._CableproService.getCableproes(mode, value, insulationId, conductorId, armourId, coreId, phaseId, methodIndex, installTableId, windSpeedId, load, protection, calculationId, ConductorIndex, CoreIndex).subscribe((result) => {
            if (mode === 'Load') {
                if (result.cableproXmlInputOpen === null || result.cableproXmlInputOpen === undefined) {
                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.installTableData = result.installTableDto;
                    //
                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.windSpeedData = result.windSpeedDto;
                    // 
                    if (this.windSpeedData.length > 0) {
                        this.hasWind = true;
                        this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                        this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue; // ak change
                    } else {
                        this.hasWind = false;
                    }
                    if ($('#neutral-toggle').val() !== 'on') {
                        $('#neutral-toggle').click();
                    }
                    this.NeutralDisabled = true;
                    $('#Neutral_c').attr('disabled', 'true');
                    $('#neutral-toggle').attr('disabled', 'true');

                    $('#Active_c').attr('disabled', 'true');
                    $('#Earth_c').attr('disabled', 'true');
                    this.voltageSupplyLabel = 'drop';
                    this.prevVoltageSupplyLabel = 'drop';
                    this.lblcurrent = 'Current';

                    this.CableproInput.InsulationId = this.insulationData[0].id;
                    this.CableproInput.ConductorId = this.conductorData[0].id;
                    this.CableproInput.EarthConductorId = 0;
                    this.CableproInput.CoreId = this.coreData[0].id;
                    this.CableproInput.PhaseId = this.phaseData[0].id;
                    this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                    this.PrevActiveValue = this.specifyConductorData[0].value.replace(' mm²', '');
                    this.CableproInput.EarthId = this.earthList[0].id;
                    this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                    this.CableproInput.InstallTableId = this.installTableData[0].id;
                    this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                    this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                    this.drBunchingCore = 1;
                }
                else {


                    this._projectService.refreshSidebarProjects();

                    this.flag = true;
                    this.calculation_type = result.cableproXmlInputOpen.calculationType;
                    if (result.cableproXmlInputOpen.phaseXmlVal == '0') {
                        if ($('#neutral-toggle').val() !== 'on') {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal == '1') {
                        this.NeutralDisabled = false;
                        $('#Neutral_c').removeAttr('disabled');
                    }

                    else if (result.cableproXmlInputOpen.phaseXmlVal == '2') {
                        if ($('#neutral-toggle').prop('checked')) {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal == '3' || result.cableproXmlInputOpen.phaseXmlVal == '4') {
                        this.NeutralDisabled = false;
                        $('#Neutral_c').removeAttr('disabled');
                    }
                    // 

                    $('#Active_c').attr('disabled', 'true');
                    $('#Earth_c').attr('disabled', 'true');
                    this.device = result.cableproXmlInputOpen.protection_device;
                    $('input[name="current"]').val(result.cableproXmlInputOpen.load_current);
                    $('input[name="Derating factor"]').val(result.cableproXmlInputOpen.load_deratingFactor);
                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.efficiency = result.cableproXmlInputOpen.efficiency;

                    //  $('input[name="Efficiency"]').val(result.cableproXmlInputOpen.efficiency);
                    this.tempDeracting = result.cableproXmlInputOpen.load_deratingFactor;
                    $('input[name="power-factor"]').val(result.cableproXmlInputOpen.load_powerFactor);
                    $('input[name="Voltage Drop"]').val(result.cableproXmlInputOpen.supply_voltageDrop);
                    this.voltage_txt = result.cableproXmlInputOpen.supply_voltage;
                    // $('input[name="Voltage-Supply"]').val(result.cableproXmlInputOpen.supply_voltage);
                    $('input[name="fault-level"]').val(result.cableproXmlInputOpen.supply_faultLevel);
                    $('input[name="length-of-run"]').val(result.cableproXmlInputOpen.supply_lengthOfrun);
                    // alert(result.cableproXmlInputOpen.powerunit);
                    if (result.cableproXmlInputOpen.powerunit != null) {
                        this.powerunit = result.cableproXmlInputOpen.powerunit;
                        this.onddlPowerUnitChange(this.powerunit, "true");
                        // $("#ddlunit").val(this.powerunit).trigger('change', "true");
                        // $("#ddlunit").val(this.powerunit);

                    }
                    else {
                        this.powerunit = "1";
                        this.onddlPowerUnitChange(this.powerunit, "true");
                        // $("#ddlunit").val(this.powerunit).trigger('change', "true");
                        // $("#ddlunit").val(this.powerunit);
                    }
                    if (result.cableproXmlInputOpen.supply_useVd == '1') {
                        $('#use-vd').prop('checked', true);
                    } else {
                        $('#use-vd').prop('checked', false);
                    }
                    if (result.cableproXmlInputOpen.earthconductorXmlVal != null && result.cableproXmlInputOpen.earthconductorXmlVal != undefined)
                        this.PrevEarthConductorId = parseInt(result.cableproXmlInputOpen.earthconductorXmlVal);
                    else
                        this.PrevEarthConductorId = 0;
                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.installTableData = result.installTableDto;
                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.windSpeedData = result.windSpeedDto;
                    if (this.windSpeedData.length > 0) {
                        this.hasWind = true;
                        if (result.cableproXmlInputOpen != undefined && result.cableproXmlInputOpen != null) {
                            this.CableproInput.WindSpeedId = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].xmlValue;// ak change


                        }
                        else {
                            this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue;// ak change

                            //this.onWindChange();

                        }
                    } else {
                        this.hasWind = false;
                    }
                    this.CableproInput.InsulationId = result.cableproXmlInputOpen.insulationId;
                    this.CableproInput.ConductorId = result.cableproXmlInputOpen.conductorId;
                    this.CableproInput.EarthConductorId = this.PrevEarthConductorId;
                    if (result.cableproXmlInputOpen.isVoltageDrop == false) {
                        this.voltageSupplyLabel = 'rise';
                        this.prevVoltageSupplyLabel = 'rise';
                    }
                    else {
                        this.voltageSupplyLabel = 'drop';
                        this.prevVoltageSupplyLabel = 'drop';
                    }
                    if (this.voltageSupplyLabel == 'rise') {
                        if (this.currentUnit == "1") {
                            this.lblcurrent = 'Output current';
                        }
                        else {
                            this.lblcurrent = 'Output power';
                        }
                    }
                    this.CableproInput.CoreId = result.cableproXmlInputOpen.coreId;
                    this.CableproInput.PhaseId = result.cableproXmlInputOpen.phaseId;
                    this.CableproInput.MethodIndex = result.cableproXmlInputOpen.methodIndex;
                    this.CableproInput.InstallTableId = result.cableproXmlInputOpen.installTableId;
                    this.PrevConductorId = parseInt(result.cableproXmlInputOpen.conductorXmlVal);
                    this.PrevCoreId = parseInt(result.cableproXmlInputOpen.coreXmlVal);
                    if (this.PrevCoreId == 0)
                        this.drBunchingCore = 1;
                    else
                        this.drBunchingCore = 2;
                    this.OnChangeCore(null);
                    this.PrevImageIndex = this.CableproInput.MethodIndex;
                    this.onResetControlValues();

                    if (result.cableproXmlInputOpen.protection_device == '0') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        setTimeout(() => {
                            $('#protection input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                        }, 1000);
                    }
                    else if (result.cableproXmlInputOpen.protection_device == '1') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;

                        this.CableSize['protection']['MCBrating'] = result.cableproXmlInputOpen.protection_MCBrating;
                        this.CableSize['protection']['curve'] = result.cableproXmlInputOpen.protection_curve;
                        this.protection_curve = parseInt(result.cableproXmlInputOpen.protection_curve);
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        setTimeout(() => {
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('#select_MCB').val(result.cableproXmlInputOpen.protection_MCBrating).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd == '1') {
                                this.rcd_chk = true;
                                // $('#rcd').prop('checked', true);
                            } else {
                                this.rcd_chk = false;
                                // $('#rcd').prop('checked', false);
                            }
                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '2') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['MCCBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;

                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);

                            let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);

                            $('#select_MCCB').val(result.cableproXmlInputOpen.protection_MCCBrating).trigger('change');

                            if (result.cableproXmlInputOpen.protection_rcd == '1') {
                                this.rcd_chk = true;
                                // $('#rcd').prop('checked', true);
                            } else {
                                this.rcd_chk = false;
                                // $('#rcd').prop('checked', false);
                            }
                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['MCCBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            /* code added on 29-11-2019 */

                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '3') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['ACBrating'] = result.cableproXmlInputOpen.protection_ACBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);
                            $('#select_ACB').val(result.cableproXmlInputOpen.protection_ACBrating).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd == '1') {
                                this.rcd_chk = true;
                                // $('#rcd').prop('checked', true);
                            } else {
                                this.rcd_chk = false;
                                // $('#rcd').prop('checked', false);
                            }
                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['ACBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            /* code added on 29-11-2019 */
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '4') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['HRCFuserating'] = result.cableproXmlInputOpen.protection_HRCrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('#select_HRCFuse').val(result.cableproXmlInputOpen.protection_HRCrating).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd == '1') {
                                this.rcd_chk = true;
                                // $('#rcd').prop('checked', true);
                            } else {
                                this.rcd_chk = false;
                                // $('#rcd').prop('checked', false);
                            }
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            this.select2Dropdown();
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device == '5') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['customrating'] = result.cableproXmlInputOpen.protection_Customrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.rcd_chk = result.cableproXmlInputOpen.protection_rcd == "1";
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.CableSize['protection']['description'] = result.cableproXmlInputOpen.protection_description;
                        this.earthfaulttime = parseInt(result.cableproXmlInputOpen.protection_earthFault);
                        setTimeout(() => {
                            $('input[caption="Custom Rating"]').val(result.cableproXmlInputOpen.protection_Customrating);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('input[caption="Description"]').val(result.cableproXmlInputOpen.protection_description);
                            if (result.cableproXmlInputOpen.protection_rcd == '1') {
                                this.rcd_chk = true;
                                // $('#rcd').prop('checked', true);
                            } else {
                                this.rcd_chk = false;
                                // $('#rcd').prop('checked', false);
                            }

                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['Custom Rating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            /* code added on 29-11-2019 */
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                        }, 1000);
                    }
                    this.CableSize['insulation'] = result.cableproXmlInputOpen.insulationXmlVal;
                    this.CableSize['conductor'] = result.cableproXmlInputOpen.conductorXmlVal;
                    this.CableSize['earthconductor'] = result.cableproXmlInputOpen.earthconductorXmlVal;
                    this.CableSize['core'] = result.cableproXmlInputOpen.coreXmlVal;
                    this.CableSize['phase'] = result.cableproXmlInputOpen.phaseXmlVal;
                    this.CableSize['faultlevel'] = result.cableproXmlInputOpen.supply_faultLevel;
                    this.CableSize['voltagedrop'] = result.cableproXmlInputOpen.supply_voltageDrop;
                    this.CableSize['powerunit'] = result.cableproXmlInputOpen.powerunit;
                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.CableSize['efficiency'] = result.cableproXmlInputOpen.efficiency;
                    else
                        this.CableSize['efficiency'] = this.efficiency;

                    this.CableSize['supplyvoltage'] = result.cableproXmlInputOpen.supply_voltage;
                    this.CableSize['lengthofrun'] = result.cableproXmlInputOpen.supply_lengthOfrun;
                    this.CableSize['usevd'] = result.cableproXmlInputOpen.supply_useVd;
                    this.CableSize['isderatingwiz'] = result.cableproXmlInputOpen.isderatingwiz;
                    this.CableSize['deratingtemp'] = result.cableproXmlInputOpen.deratingtemp;
                    // if(result.cableproXmlInputOpen.supply_useVd =='0')
                    // {
                    //     $(".lblcurrent").text('Output current (A)');
                    // }
                    // else{
                    //     $(".lblcurrent").text('Current (A)');

                    // } 

                    this.CableSize['current'] = result.cableproXmlInputOpen.load_current;
                    this.CableSize['currentamp'] = result.cableproXmlInputOpen.load_currentamp;
                    this.CableSize['powerfactor'] = result.cableproXmlInputOpen.load_powerFactor;
                    this.CableSize['deratingfactor'] = parseFloat(result.cableproXmlInputOpen.load_deratingFactor).toFixed(2);
                    this.CableSize['device'] = result.cableproXmlInputOpen.protection_device;
                    // alert(result.cableproXmlInputOpen.specify_Phaseconductor_circuit + "getdata");
                    if (result.cableproXmlInputOpen.specify_Phaseconductor_mode == '1') {
                        $('#active-toggle').prop('checked', true);
                        this.showActiveLab = false;
                        this.ShowActive = true;
                        $('#Active_c').removeAttr('disabled');
                        $('#Active_c').val(result.cableproXmlInputOpen.specify_Phaseconductor_circuit);
                        this.CableSize['specifyconductor']['phase']['mode'] = '1';
                        this.CableSize['specifyconductor']['phase']['circuits'] = result.cableproXmlInputOpen.specify_Phaseconductor_circuit;
                        this.CableSize['specifyconductor']['phase']['size'] = result.cableproXmlInputOpen.specify_Phaseconductor_size;
                        //alert(result.cableproXmlInputOpen.specify_Phaseconductor_size);
                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Phaseconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.ActiveId = selectedItem.id;
                            this.PrevActiveValue = selectedItem.value.replace(' mm²', '');
                        }
                    }
                    else {
                        this.ShowActive = false;
                        this.showActiveLab = true;
                    }
                    if (result.cableproXmlInputOpen.conduit_Mode == '1') {
                        $('#conduit-size-toggle').prop('checked', true);
                        this.ShowConduitSizeLab = false;
                        this.ShowConduitSize = true;
                        this.CableproInput.ConduitSizeId = parseInt(result.cableproXmlInputOpen.conduit_Size);
                        this.CableSize['conduitsize']['mode'] = '1';
                        this.CableSize['conduitsize']['size'] = parseFloat(result.cableproXmlInputOpen.conduit_Size);

                        this.PrevConduitSize = result.cableproXmlInputOpen.conduit_Size;
                    }
                    else {
                        this.ShowConduitSize = false;
                        this.CableSize['conduitsize']['mode'] = '0';
                        $('#conduit-size-toggle').prop('checked', false);
                        this.ShowConduitSizeLab = true;
                    }

                    if (this.PrevConduitSize !== undefined) {
                        let val = this.conduitSizes.filter(x => x.id === this.PrevConduitSize.trim());
                        if (val.length > 0) {
                            this.CableproInput.ConduitSizeId = val[0].id;
                            this.PrevConduitSize = val[0].id;
                        } else {
                            this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                        }
                    } else {
                        this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                        this.PrevConduitSize = this.conduitSizes[0].id;
                    }

                    if (result.cableproXmlInputOpen.specify_Neutralconductor_mode == '1') {
                        $('#neutral-toggle').prop('checked', true);
                        $('#Neutral_c').val(result.cableproXmlInputOpen.specify_Neutralconductor_circuit);
                        this.CableSize['specifyconductor']['neutral']['circuits'] = result.cableproXmlInputOpen.specify_Neutralconductor_circuit;
                        this.CableSize['specifyconductor']['neutral']['size'] = result.cableproXmlInputOpen.specify_Neutralconductor_size;
                        this.CableSize['specifyconductor']['neutral']['mode'] = 1
                        this.ShowNeutral = true;
                        this.ShowNeutralLab = false
                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size || x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.NeutralId = selectedItem.id;
                            this.PrevNeutralValue = selectedItem.value.replace(' mm²', '');
                        }
                    }
                    else {
                        this.ShowNeutral = false;
                        this.ShowNeutralLab = true;
                        $('#neutral-toggle').prop('checked', false);
                        $('#Neutral_c').attr('disabled', 'true');
                    }
                    if (result.cableproXmlInputOpen.specify_Earthconductor_mode == '0') {
                        $('#earth-chk').prop('checked', true);
                        this.CableSize['specifyconductor']['earth']['circuits'] = "0";
                        this.CableSize['specifyconductor']['earth']['size'] = '0'
                        this.CableSize['specifyconductor']['earth']['mode'] = '0';
                        $('#earth-toggle').attr('disabled', 'true');
                        this.ShowEarth = false;
                        $('#Earth_c').attr('disabled', 'true');
                        $('#Earth_c').val(1);
                        this.ShowEarthLab = true;
                        this.showEarthButton = false;

                    } else if (result.cableproXmlInputOpen.specify_Earthconductor_mode == '2') {
                        $('#earth-toggle').prop('checked', true);
                        $('#Earth_c').removeAttr('disabled');
                        $('#Earth_c').val(result.cableproXmlInputOpen.specify_Earthconductor_circuit);
                        this.CableSize['specifyconductor']['earth']['circuits'] = result.cableproXmlInputOpen.specify_Earthconductor_circuit;
                        this.CableSize['specifyconductor']['earth']['mode'] = '2';
                        this.CableSize['specifyconductor']['earth']['size'] = parseFloat(result.cableproXmlInputOpen.specify_Earthconductor_size);
                        var obj = this.earthList.find(x => x.id == result.cableproXmlInputOpen.specify_Earthconductor_size);
                        if (obj) {
                            this.CableproInput.EarthId = obj.id;
                        }
                        this.ShowEarth = true;
                        this.ShowEarthLab = false;
                    }
                    //
                    this.CableSize['tableno'] = result.cableproXmlInputOpen.installTable;
                    this.CableSize['tablectrlvalue'] = result.cableproXmlInputOpen.methodIndex;
                    this.CableSize['description'] = result.cableproXmlInputOpen.installTable_description;
                    this.onResetControlValues();
                    if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                        if (this.CableSize['tableno'] == '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 1000);
                        } else if (this.CableSize['tableno'] == '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    }
                    if (this.CableSize['tableno'] == '16') {
                        setTimeout(() => {
                            $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                            $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                            $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                            $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                        }, 1000);
                    }
                    this.onCancel();
                    if (result.cableproXmlInputOpen.deratingFactorData) {
                        this.finalDrf = parseFloat(result.cableproXmlInputOpen.deratingFactorData.finalDrf.toFixed(2));
                        this.CalculationReportInput.wizardValues = result.cableproXmlInputOpen.deratingFactorData;
                        // $('#checkboxUseWizard').attr('checked', 'true');
                        $('#deratingFactor').val(this.finalDrf);
                        this.UserWizard = true;
                        this.CableSize['isderatingwiz'] = "1";
                        this.OriDrfWizard = result.cableproXmlInputOpen.deratingFactorData;
                    }
                    this.setWizardFactorValues();
                }
                this.loading = false;
                this.flag = false;
            }
            else {
                if (result.insulationDto !== null && result.insulationDto !== undefined) {
                    this.insulationData = result.insulationDto;
                    this.CableproInput.InsulationId = this.insulationData[0].id;
                    this.CableSize['insulation'] = this.insulationData[0].xmlValue.toString();
                }
                if (result.conductorDto !== null && result.conductorDto !== undefined) {
                    this.conductorData = result.conductorDto;
                    this.CableproInput.ConductorId = this.conductorData[this.PrevConductorId].id;
                    this.CableSize['conductor'] = this.conductorData[this.PrevConductorId].xmlValue.toString();
                }

                if (this.earthConductorData && this.earthConductorData.length > 0) {
                    if (this.PrevEarthConductorId == -1) {
                        this.PrevEarthConductorId = 1;
                    }
                    this.CableSize['earthconductor'] = this.PrevEarthConductorId;
                    this.CableproInput.EarthConductorId = this.PrevEarthConductorId;
                }
                if (result.coreDto !== null && result.coreDto !== undefined) {
                    this.coreData = result.coreDto;
                    this.CableproInput.CoreId = this.coreData[this.PrevCoreId].id;
                    this.CableSize['core'] = this.coreData[this.PrevCoreId].xmlValue.toString();
                }

                if (result.phaseDto !== null && result.phaseDto !== undefined) {
                    this.phaseData = result.phaseDto;
                    this.CableproInput.PhaseId = this.phaseData[0].id;
                    this.CableSize['phase'] = this.phaseData[0].xmlValue.toString();
                    if (this.CableproInput.PhaseId.toString() == '100') {
                        this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '101') {
                        this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '102') {
                        this.CableSize['supplyvoltage'] = this.dc_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '103') {
                        this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    }
                    else if (this.CableproInput.PhaseId.toString() == '104') {
                        this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                    }
                }

                if (result.installTableDto !== null && result.installTableDto !== undefined) {
                    this.installTableData = result.installTableDto;
                    this.CableproInput.InstallTableId = this.installTableData[0].id;
                    this.CableSize['tableno'] = this.installTableData[0].name;
                    var installTableImageName = '', installTableImageDesc = '';


                    if (this.installTableData[0].images.length - 1 < this.PrevImageIndex) {
                        this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                        this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                        this.CableSize['description'] = this.installTableData[0].images[0].name + ' ' + this.installTableData[0].images[0].description;
                        installTableImageName = this.installTableData[0].images[0].name;
                        installTableImageDesc = this.installTableData[0].images[0].description;
                    } else {
                        this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                        this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                        this.CableSize['description'] = this.installTableData[0].images[this.PrevImageIndex].name + ' ' + this.installTableData[0].images[this.PrevImageIndex].description;
                        installTableImageName = this.installTableData[0].images[this.PrevImageIndex].name;
                        installTableImageDesc = this.installTableData[0].images[this.PrevImageIndex].description;
                    }
                    this.showWarningOnInstallationChange(this.CableproInput.MethodIndex, this.CableproInput.InstallTableId, installTableImageName, installTableImageDesc, 'getdata');
                    if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                        if (this.CableSize['tableno'] == '17') {
                            setTimeout(() => {
                                // $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                // $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 1000);
                        } else if (this.CableSize['tableno'] == '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 1000);
                        }
                    }
                    if (this.CableSize['tableno'] == '16') {
                        setTimeout(() => {
                            $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                            $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                            $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                            $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                        }, 1000);
                    }
                }
                if (result.specifyConductorDto !== null && result.specifyConductorDto !== undefined) {
                    this.specifyConductorData = result.specifyConductorDto;

                    if (this.PrevActiveValue !== undefined) {
                        let val = this.specifyConductorData.filter(x => x.value === this.PrevActiveValue.trim() || x.value === this.PrevActiveValue.trim() + ' mm²');
                        if (val.length > 0) {
                            this.CableproInput.ActiveId = val[0].id;
                            this.PrevActiveValue = val[0].value.replace(' mm²', '');
                        } else {
                            this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                        }
                    } else {
                        this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                        this.PrevActiveValue = this.specifyConductorData[0].value.replace(' mm²', '');
                    }
                    if (this.PrevNeutralValue !== undefined) {
                        let val = this.specifyConductorData.filter(x => x.value === this.PrevNeutralValue.trim() || x.value === this.PrevNeutralValue.trim() + ' mm²');
                        if (val.length > 0) {
                            this.CableproInput.NeutralId = val[0].id;
                        } else {
                            this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                        }
                    } else {
                        this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                    }
                }
                if (result.windSpeedDto !== null && result.windSpeedDto !== undefined) {
                    this.windSpeedData = result.windSpeedDto;
                    if (this.windSpeedData.length > 0) {
                        this.hasWind = true;
                        // 
                        if (result.cableproXmlInputOpen != undefined && result.cableproXmlInputOpen != null) {
                            this.CableproInput.WindSpeedId = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].xmlValue; // ak change
                        }
                        else {
                            this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue;
                            //    this.onWindChange();


                        }

                    } else {
                        this.hasWind = false;
                    }
                }
                if (result.uiControlsLookupDto !== null && result.uiControlsLookupDto !== undefined) {
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.CableSize['protection'] = {};
                    for (let i = 0; i < result.uiControlsLookupDto.length; i++) {
                        if (result.uiControlsLookupDto[i].type === 'Protection' && protection !== '') {
                            if (result.uiControlsLookupDto[i].uiControlsValues === protection) {
                                for (let j = 0; j < result.uiControlsLookupDto[i].controles.length; j++) {
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Short-circuit time') {
                                        this.CableSize['protection']['shortcircuittime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Earth fault time') {
                                        this.CableSize['protection']['earthfaulttime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        this.earthfaulttime = parseInt(result.uiControlsLookupDto[i].controles[j].fDefaultValue);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Rating') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                if (protection === 'HRC Fuse') {
                                                    this.CableSize['protection']['HRCFuserating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                }
                                                else {
                                                    this.CableSize['protection']['' + protection + 'rating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                }
                                            }
                                        }
                                        setTimeout(() => {
                                            if (protection === 'MCB') {
                                                if (this.PrevMCBVal === '') {
                                                    this.PrevMCBVal = $('#select_MCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevMCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCBVal.toString()].value;

                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCBVal]);
                                                }
                                            } else if (protection === 'MCCB') {
                                                if (this.PrevMCCBVal === '') {
                                                    this.PrevMCCBVal = $('#select_MCCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCCBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevMCCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCCBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCCBVal]);
                                                    let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'ACB') {
                                                if (this.PrevACBVal === '') {
                                                    this.PrevACBVal = $('#select_ACB').prop("selectedIndex");
                                                }
                                                if (this.PrevACBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevACBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevACBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevACBVal]);
                                                    let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];

                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'HRC Fuse') {
                                                if (this.PrevHRCVal === '') {
                                                    this.PrevHRCVal = $('#select_HRCFuse').prop("selectedIndex");
                                                }
                                                if (this.PrevHRCVal !== '') {
                                                    $('#select_HRCFuse')[0][this.PrevHRCVal].selected = true;
                                                    this.CableSize['protection']['HRCFuserating'] = $('#select_HRCFuse')[0][this.PrevHRCVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_HRCFuse')[0][this.PrevHRCVal]);
                                                }
                                            }
                                            this.forceCalc = true;
                                            this.select2Dropdown();
                                            this.getAllControlsValue();
                                        }, 100);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Curve') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                this.CableSize['protection']['curve'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                this.protection_curve = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                            }
                                        }
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'RCD') {
                                        this.CableSize['protection']['rcd'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        this.rcd_chk = result.uiControlsLookupDto[i].controles[j].fDefaultValue == "1";
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip current') {
                                        this.CableSize['protection']['tripcurrent'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip Multiplier') {
                                        this.CableSize['protection']['tripmultiplier'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Thermal setting') {
                                        this.CableSize['protection']['thermalsetting'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Custom Rating') {
                                        this.CableSize['protection']['customrating'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        setTimeout(() => {
                                            if (this.PrevCustomeRatingVal === '') {
                                                this.PrevCustomeRatingVal = $('input').filter("[caption='Custom Rating']").val();
                                            }
                                            if (this.PrevCustomeRatingVal !== '') {
                                                $('input').filter("[caption='Custom Rating']").val(this.PrevCustomeRatingVal);
                                                this.CableSize['protection']['customrating'] = $('input').filter("[caption='Custom Rating']").val();
                                                this.ProtectionCalcForCustomRating();
                                            }
                                        }, 100);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Description') {
                                        this.CableSize['protection']['description'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.getAllControlsValue();
            $(".tooltip").hide();
            //this.hideLoader();
        },
            error => {
                this.hideLoader();
            });

    }
    highlighttext(txt: any) {
        $(".clscurrent").removeClass('highlightcontrol');
        $("#currentdiv").removeAttr("data-toggle");
        $("#currentdiv").removeAttr("data-original-title");

        $(".clsfaultlevel").removeClass('highlightcontrol');
        $("#faultleveldiv").removeAttr("data-toggle");
        $("#faultleveldiv").removeAttr("data-original-title");
        $("#faultleveldiv").removeAttr("title");

        $(".clsvoltagedrop").removeClass('highlightcontrol');
        $("#voltagedropdiv").removeAttr("data-toggle");
        $("#voltagedropdiv").removeAttr("data-original-title");
        $("#voltagedropdiv").removeAttr("title");

        $(".clsthermal[caption='Thermal setting']").removeClass('highlightcontrol');
        $("#clsthermaldiv").removeAttr("data-original-title");
        $("#clsthermaldiv").removeAttr("data-toggle");

        $(".ddlmcb").removeAttr("data-original-title");
        $(".ddlmcb").removeAttr("data-toggle");

        $(".ddlhrcfuse").removeAttr("data-original-title");
        $(".ddlhrcfuse").removeAttr("data-toggle");

        var droporrise = this.voltageSupplyLabel;

        $(".ddlmcb").removeClass('borderdropdown');
        $(".ddlhrcfuse").removeClass('borderdropdown');

        //$("#clsthermaldiv[caption='Thermal setting']").removeAttr("data-original-title");


        if (txt == 'Current') {
            //this.clscurrent = true;
            $("#currentdiv").attr("data-toggle", "tooltip");
            $("#currentdiv").attr("data-original-title", "Current or device rating is dictating the active size");
            $(".clscurrent").addClass('highlightcontrol');

            $(".clsthermal[caption='Thermal setting']").addClass('highlightcontrol');
            $("#clsthermaldiv").attr("data-toggle", "tooltip");
            $("#clsthermaldiv").attr("data-original-title", "Current or device rating is dictating the active size");

            //$("#clsthermaldiv[caption='Thermal setting']").attr("data-toggle","tooltip");
            $(".ddlmcb").addClass('borderdropdown');
            $(".ddlmcb").attr("data-toggle", "tooltip");
            $(".ddlmcb").attr("data-original-title", "Current or device rating is dictating the active size");

            $(".ddlhrcfuse").addClass('borderdropdown');
            $(".ddlhrcfuse").attr("data-toggle", "tooltip");
            $(".ddlhrcfuse").attr("data-original-title", "Current or device rating is dictating the active size");

            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });


        }
        else if (txt == 'Voltage drop') {

            $(".clsvoltagedrop").addClass('highlightcontrol');
            $("#voltagedropdiv").attr("data-toggle", "tooltip");
            $("#voltagedropdiv").attr("data-original-title", "Voltage " + droporrise + " is dictating the active size");
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Short-circuit') {
            $("#faultleveldiv").attr("data-toggle", "tooltip");
            $("#faultleveldiv").attr("data-original-title", "Short-circuit is dictating the active size");
            $(".clsfaultlevel").addClass('highlightcontrol');
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Voltage drop and Short-circuit') {

            $("#faultleveldiv").attr("data-toggle", "tooltip");
            $("#faultleveldiv").attr("data-original-title", "Voltage " + droporrise + " and/or short-circuit are dictating the active size");
            $("#voltagedropdiv").attr("data-toggle", "tooltip");
            $("#voltagedropdiv").attr("data-original-title", "Voltage " + droporrise + " and/or short-circuit are dictating the active size");

            $(".clsfaultlevel").addClass('highlightcontrol');
            $(".clsvoltagedrop").addClass('highlightcontrol');
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });

        }
        else if (txt == 'Manual') {

        }

    }
    getAllControlsValue() {
        this.flag = false;
        this.showLoader();
        this.isCalculating = true;
        if (this.CableSizeValuesUpdate()) {
            this.CableSize['browsername'] = this.detectBrowserName();
            this.CableSize['calculationId'] = this.newCalculationId;
            this.CableSize['UserName'] = this.user.userName;
            if (!this.isOldCalculation) {
                //  alert(this.CableSize['specifyconductor']['phase']['circuits'] + " input");
                // if (this.isWebFacingGuestUser && !this._settingService.showNav) {
                //     if (this.calcCount >= 5) {
                //         window.parent.postMessage({ action: 'login_required' }, environment.WebFacingUrl);
                //         this.hideLoader();
                //         return;
                //     }
                //     //this.calcCount += 1;
                // }
                this._CableproCalc.Calculate(this.CableSize).subscribe((result) => {
                    if (result.errorstring) {
                        this.warningErrorStrData = JSON.stringify(result.errorstring);
                        this.generateErrorsModel(result.errorstring);
                    }
                    this.isCalculating = false;
                    this.forceCalc = false;
                    let res = new CalculationResult();
                    res.Id = this.newCalculationId;
                    res.resultValue = JSON.stringify(result);

                    this.resultXml = res.resultValue;
                    this.Xml = result;
                    this.Dictatingfactorres = this.Xml.Dictatingfactorres;

                    this.highlighttext(this.Dictatingfactorres);
                    if (this.newCalculationId > 0) {
                        this._CableproCalc.setCalculationOutput(res).subscribe((result) => {
                        });
                    }
                    //debugger
                    //alert(this.Xml.active.Val);
                    if (!isNaN(this.Xml.active.Val) && this.Xml.active.Val.toString().indexOf('.') != -1) {
                        // alert(this.Xml.active.Val);
                        this.active_res = parseFloat(this.Xml.active.Val);
                        let val = this.specifyConductorData.filter(x => x.value === this.active_res + ' mm²');
                        if (val.length > 0) {
                            this.CableproInput.ActiveId = val[0].id;
                            this.PrevActiveValue = val[0].value.replace(' mm²', '');
                            // if ($('#active-toggle').is(':checked')) {
                            //     (<any>$('#Active')).val(this.CableproInput.ActiveId).trigger('change');
                            // }
                        }
                    } else {
                        this.active_res = parseInt(this.Xml.active.Val);
                    }
                    if (!isNaN(this.Xml.neutral.Val) && this.Xml.neutral.Val.toString().indexOf('.') != -1) {
                        this.neutral_res = parseFloat(this.Xml.neutral.Val);
                    } else {
                        this.neutral_res = parseInt(this.Xml.neutral.Val);
                    }
                    this.active_NoOf_circuit = this.Xml.active1.Val;

                    //  alert(this.active_NoOf_circuit  + " out");
                    $('#Active_c').val(this.active_NoOf_circuit);
                    this.neutral_NoOf_circuit = this.Xml.neutral1.Val;
                    $('#Neutral_c').val(this.neutral_NoOf_circuit);

                    if ($('#earth-chk').is(':checked') === true) {
                        this.earth_NoOf_circuit = '-';
                        $('#earth_lab').children().hide();
                        this.earth_res = '-';
                    } else {
                        this.earth_NoOf_circuit = this.Xml.earth1.Val;
                        if (!isNaN(this.Xml.earth.Val) && this.Xml.earth.Val.toString().indexOf('.') != -1) {
                            if ($('#earth_lab').children().length) {
                                $('#earth_lab').children().show();
                            }
                            this.earth_res = (this.Xml.earth ? parseFloat(this.Xml.earth.Val) : 0);// + ' ' + 'mm';
                        } else {
                            if ($('#earth_lab').children().length) {
                                $('#earth_lab').children().show();
                            }
                            this.earth_res = (this.Xml.earth ? parseInt(this.Xml.earth.Val) : 0);// + ' ' + 'mm';
                        }
                    }
                    //this.onWindChange();

                    $('#Earth_c').val(this.earth_NoOf_circuit);

                    this.voltage_res = this.Xml.voltage ? this.RemoveTrailingZeros((Math.round(this.Xml.voltage.Val * 100) / 100).toFixed(2)) : 0;
                    this.voltage_res1 = this.Xml.voltageDrop ? this.RemoveTrailingZeros((Math.round(this.Xml.voltageDrop.Val * 100) / 100).toFixed(2)) : 0;
                    this.faultLoop_res = Math.round(this.Xml.earth3.Val * 10000) / 10000;
                    //this.conduit_res = this.Xml.conduit.Val;
                    this.Circuitrating_drated = Math.round(this.Xml.Circuitrating_drated.Val);
                    this.Currentrating_cable_tabulated = Math.round(this.Xml.Currentrating_cable_tabulated.Val);

                    if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "61") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "6-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "91") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "9-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "121") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "12-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "151") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "15-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "51") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "5-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "81") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "8-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "111") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "11-1";
                    }
                    else if (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val == "141") {
                        this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val = "14-1";
                    }


                    if (parseInt(this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val) == 341) {
                        this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val = "34-1";
                    }
                    else if (parseInt(this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val) == 351) {
                        this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val = "35-1";
                    }

                    this.Currentrating_cable_tabulated_Ref_Desc_TableNo = this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val != null && this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val != undefined ? (this.Xml.Currentrating_cable_tabulated_Ref_Desc_TableNo.Val).split('.')[0] : "";
                    this.Currentrating_cable_tabulated_Ref_Desc_ColumnNo = this.Xml.Currentrating_cable_tabulated_Ref_Desc_ColumnNo.Val;
                    this.CCC_Currentrating_cable_tabulated_Operating_temperature = Math.round(this.Xml.CCC_Currentrating_cable_tabulated_Operating_temperature.Val * 100) / 100;
                    this.VoltageDrop_RefAndDesc_Resistance_TableNo = this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val != null && this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val != undefined ? (this.Xml.VoltageDrop_RefAndDesc_Resistance_TableNo.Val).split('.')[0] : "";
                    this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp = this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp.Val;
                    this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp = this.Xml.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp.Val;
                    this.VoltageDrop_RefAndDesc_Reactance_TableNo = this.Xml.VoltageDrop_RefAndDesc_Reactance_TableNo.Val;
                    this.VoltageDrop_RefAndDesc_Reactance_ColumnNo = this.Xml.VoltageDrop_RefAndDesc_Reactance_ColumnNo.Val;
                    this.VoltageDrop_Data_PhaseConductor_Resistance = Math.round(this.Xml.VoltageDrop_Data_PhaseConductor_Resistance.Val * 10000) / 10000;
                    this.VoltageDrop_Data_PhaseConductor_Reactance = Math.round(this.Xml.VoltageDrop_Data_PhaseConductor_Reactance.Val * 10000) / 10000;
                    this.VoltageDrop_Result_MaxLengthOfRun = Math.round(this.Xml.VoltageDrop_Result_MaxLengthOfRun.Val * 100) / 100;
                    this.CableSize['currentamp'] = this.Xml.currentamp ? this.Xml.currentamp : '';
                    this.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size = Math.round(this.Xml.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size.Val * 100) / 100;
                    this.ShortCircuitPerformance_PhaseConductor_kfactor = Math.round(this.Xml.ShortCircuitPerformance_PhaseConductor_kfactor.Val * 100) / 100;
                    this.ShortCircuitPerformance_ok = this.Xml.ShortCircuitPerformance_ok.Val;
                    this.ShortCircuitPerformance_EarthConductor_ok = this.Xml.ShortCircuitPerformance_EarthConductor_ok.Val;
                    if (this.ShortCircuitPerformance_ok == '0')
                        this.displayShortCircuitPerformance = true;
                    else
                        this.displayShortCircuitPerformance = false;

                    this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct.Val * 100) / 100;
                    this.ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size = Math.round(this.Xml.ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size.Val * 100) / 100;
                    this.ShortCircuitPerformance_NuetralConductor_Kfactor = Math.round(this.Xml.ShortCircuitPerformance_NuetralConductor_Kfactor.Val * 100) / 100;
                    this.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size = Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size.Val * 100) / 100;
                    this.ShortCircuitPerformance_EarthConductor_Kfactor = Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_Kfactor.Val * 100) / 100;
                    this.ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct = Math.round(this.Xml.ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct.Val * 100) / 100;

                    this.CircuitCurrentrating_Adequate = this.Xml.CircuitCurrentrating_Adequate.Val;
                    this.VoltageDrop_Result_VoltageDrop_Acceptable = this.Xml.VoltageDrop_Result_VoltageDrop_Acceptable.Val;
                    this.EarthLoop_ok = this.Xml.EarthLoop_ok.Val;
                    this.MaxAllowedDevice = this.Xml.MaxAllowedDevice.Val;
                    this.ConduitDiameter = this.Xml.ConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConduitDiameter.Val * 100) / 100).toFixed(2)) : "16";
                    this.MinimumConduitDiameter = this.Xml.MinimumConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.MinimumConduitDiameter.Val * 100) / 100).toFixed(2)) : "16";
                    if (this.Xml.ConditSizeFound == "-") {
                        this.ConditSizeFound = this.Xml.ConditSizeFound;
                        this.ShowConduitSize = false;
                        this.CableSize['conduitsize']['mode'] = '0';
                        $('#conduit-size-toggle').prop('checked', false);
                        this.ShowConduitSizeLab = true;
                        //this.getAllControlsValue();
                    }
                    else {
                        this.ConditSizeFound = this.Xml.ConditSizeFound.Val;
                        this.ConditSizeBasedOn = this.Xml.conduitsizebasedon;
                        this.ConduitDiameter = this.Xml.ConduitDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConduitDiameter.Val * 100) / 100).toFixed(2)) : 0;
                        if (this.CableSize['conduitsize']['mode'] == '0') {
                            this.CableSize['conduitsize']['size'] = this.ConduitDiameter;
                            this.CableproInput.ConduitSizeId = this.ConduitDiameter;
                        }
                    }

                    this.NoofConduit = this.Xml.NoofConduit ? this.Xml.NoofConduit.Val : '1';
                    this.ConditCableDiameter = this.Xml.ConditCableDiameter ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameter.Val * 100) / 100).toFixed(2)) : 0;
                    this.ConditCableDiameterActive = this.Xml.ConditCableDiameterActive ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameterActive.Val * 100) / 100).toFixed(2)) : 0;
                    this.Activeneutralshownseparately = this.Xml.Activeneutralshownseparately;
                    this.ConditCableDiameterNeutral = this.Xml.ConditCableDiameterNeutral ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditCableDiameterNeutral.Val * 100) / 100).toFixed(2)) : 0;

                    this.conduitSizes = this.conduitSizesPristine.filter(m => parseFloat(m.id) >= parseFloat(this.MinimumConduitDiameter));
                    if (this.ShowConduitSize) {
                        let selectedOption = this.conduitSizes.find(x => x.id == this.CableproInput.ConduitSizeId);
                        if (!selectedOption) {
                            this.CableproInput.ConduitSizeId = this.conduitSizes[0].id;
                            this.CableSize['conduitsize']['size'] = this.CableproInput.ConduitSizeId;
                            this.getAllControlsValue();
                        }
                    }
                    if (this.Activeneutralshownseparately == "1") {
                        if (this.ShowConduitSize) {
                            $('#conduit-size-toggle').click();
                        }
                        $('#conduit-size-toggle').attr('disabled', 'true');
                    } else {
                        $('#conduit-size-toggle').removeAttr('disabled');
                    }

                    this.ConditSpaceFactorActual = this.Xml.ConditSpaceFactorActual ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditSpaceFactorActual.Val * 100) / 100).toFixed(2)) : 0;
                    this.ConditSpaceFactorStandard = this.Xml.ConditSpaceFactorStandard ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditSpaceFactorStandard.Val * 100) / 100).toFixed(2)) : 0;
                    this.ConditSpaceFactorActualNeutral = this.Xml.ConditSpaceFactorActualNeutral ? this.RemoveTrailingZeros((Math.round(this.Xml.ConditSpaceFactorActualNeutral.Val * 100) / 100).toFixed(2)) : 0;
                    this.ConditNoofCablesPerCondit = this.Xml.ConditNoofCablesPerCondit ? this.Xml.ConditNoofCablesPerCondit.Val : 0;
                    this.ConditNoofCablesPerConditActive = this.Xml.ConditNoofCablesPerConditActive ? this.Xml.ConditNoofCablesPerConditActive.Val : 0;
                    this.ConditNoofCablesPerConditNeutral = this.Xml.ConditNoofCablesPerConditNeutral ? this.Xml.ConditNoofCablesPerConditNeutral.Val : 0;
                    this.NoofConduit = this.Xml.NoofConduit ? this.RemoveTrailingZeros((Math.round(this.Xml.NoofConduit.Val * 100) / 100).toFixed(2)) : "0";
                    this.ConditRefrenceTable = this.Xml.ConditRefrenceTable ? this.Xml.ConditRefrenceTable.Val : '';
                    this.ConditNeutralRefrenceTable = this.Xml.ConditNeutralRefrenceTable ? this.Xml.ConditNeutralRefrenceTable.Val : '';

                    this.faultloop1 = parseFloat(this.Xml.faultloop1.Val).toFixed(4);
                    this.faultloop2 = parseFloat(this.Xml.faultloop2.Val).toFixed(4);
                    this.faultloop3 = parseFloat(this.Xml.faultloop3.Val).toFixed(4);
                    this.faultloop4 = parseFloat(this.Xml.faultloop4.Val).toFixed(4);
                    this.faultloop5 = parseFloat(this.Xml.faultloop5.Val).toFixed(4);
                    this.faultloop6 = parseFloat(this.Xml.faultloop6.Val).toFixed(4);
                    this.faultloop7 = parseFloat(this.Xml.faultloop7.Val).toFixed(2);

                    if (this.CircuitCurrentrating_Adequate == '0') {
                        $("#active_lab").css("cssText", "color: red !important;");
                    } else {
                        $("#active_lab").css("cssText", "color: rgba(0, 0, 0) !important;");
                    }
                    if (this.VoltageDrop_Result_VoltageDrop_Acceptable == '0') {
                        $('.vda').css("cssText", "color: red !important;");
                    } else {
                        $('.vda').css("cssText", "color: rgba(0, 0,0) !important;");
                    }
                    if (this.EarthLoop_ok == '0') {
                        $('.fli').css("cssText", "color: red !important;");
                    } else {
                        $('.fli').css("cssText", "color: rgba(0,0, 0) !important;");
                    }
                    // if (this.ConditSizeFound == "1") {
                    // this.ConditSizeFound = Math.round(this.Xml.conduit.Val) ;//+ ' ' + 'mm';
                    // }
                    //else {
                    //   this.ConditSizeFound = '-';

                    //}
                    /*if (this.ConditSizeFound === '0') {
                        this.ConditSizeFound = '-';
                    } else {
                        this.ConditSizeFound = Math.round(this.Xml.conduit.Val) + ' ' + 'mm';
                    }*/
                    this.Spare_current_capacity_of_circuit = Math.round(this.Xml.Spare_current_capacity_of_circuit.Val);
                    if (this.Spare_current_capacity_of_circuit < 0) {
                        $('.spr').css("cssText", "color: red !important;");
                    } else {
                        $('.spr').css("cssText", "color: rgba(0,0, 0) !important;");
                    }

                    if (this.newCalculationId) {
                        this.cableproXmlInput.xmlValue = this.Xml.InputXml;
                        this.cableproXmlInput.resultValue = this.resultXml;
                        this.cableproXmlInput.id = this.newCalculationId;
                        this.vdrop = this.Xml.voltageDrop.Val;
                        this.vdroppecent = this.Xml.voltage.Val;
                        this.vdropPermissibale = this.Xml.PermissableVoltageDrop.Val;
                        this.referencetempdegCReport = this.Xml.ReferencetempdegCReport.Val;

                        this.activeCondz1Report = Math.round(this.Xml.ActiveCondz1Report.Val * 1000000) / 1000000;
                        this.Earthcondz2Report = Math.round(this.Xml.Earthcondz2Report.Val * 1000000) / 1000000;
                        this.VoltageDrop_Data_NeutralConductor_Resistance = Math.round(this.Xml.VoltageDrop_Data_NeutralConductor_Resistance.Val * 10000) / 10000;
                        this.VoltageDrop_Data_NeutralConductor_Reactance = Math.round(this.Xml.VoltageDrop_Data_NeutralConductor_Reactance.Val * 10000) / 10000;

                        this.Three_phaseShort_circuitCurrent = Math.round(this.Xml.Three_phaseShort_circuitCurrent.Val * 10) / 10;
                        this.Active_to_EarthShort_circuitCurrent = Math.round(this.Xml.Active_to_EarthShort_circuitCurrent.Val * 10) / 10;
                        this.tempDeracting = this.CableSize['deratingfactor'];
                        if (navigator.onLine === true) {
                            this.UpdateXmlValues(this.cableproXmlInput);
                        } else {
                            this.hideLoader();
                            $('#popup-mask').show();
                        }
                        this.showHideProtectionWarning();
                    }
                }, error => {
                    this.hideLoader();

                });
            }
            else {
                this.hideLoader();
            }
        }

    }
    onretrySave() {
        $('#retry-save').on('click', () => {
            if (navigator.onLine === true) {
                this.UpdateXmlValues(this.cableproXmlInput);
                $('#popup-mask').hide();
            }
        });
        $('#dismissAlert').on('click', () => {
            $('#popup-mask').hide();
        });
    }
    onSelectInstallTable() {

        let methodIndex = this.SelectInstallTableMethodIndex;
        let tableId = this.SelectInstallTableTableId;
        let name = this.SelectInstallTableName;
        let description = this.SelectInstallTableDescription;
        this.CableSize['conduitsize']['mode'] = '0';

        if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        }
        if (this.CableSize['tableno'] == '16') {
            setTimeout(() => {
                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
            }, 100);
        }
        this.CableproInput.MethodIndex = methodIndex;
        this.PrevImageIndex = methodIndex;
        $('#divInstallTbl').find('img').each(function (e) {
            if ($(this).attr('data-index') === methodIndex.toString()) {
                $(this).attr('style', 'border:2px #2ea1f8 solid').attr('style', 'width:100%');
            } else {
                $(this).attr('style', 'border:2px #fff solid').attr('style', 'width:100%');
            }
        });
        this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex;
        this.CableSize['description'] = name + ' ' + description;
        this.GetData('installation', '', 0, this.CableproInput.ConductorId, 0, 0, this.CableproInput.MethodIndex, tableId, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.EarthConductorId);
    }
    specifyConductorChange() {
        $('#active-toggle').change((e) => {

            if ($(e.target).is(':checked') === true) {
                //console.log('checked');
                this.ShowActive = true;
                $('#Active_c').removeAttr('disabled');
                this.showActiveLab = false;
                // alert(this.PrevActiveValue);
                this.CableSize['specifyconductor']['phase']['mode'] = '1';
                this.CableSize['specifyconductor']['phase']['size'] = this.PrevActiveValue !== undefined ? parseFloat(this.PrevActiveValue.replace(' mm²', '')) : 1;
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            } else {
                //console.log('not checked');
                this.ShowActive = false;
                $('#Active_c').attr('disabled', 'true');
                $('#Active_c').val(1);
                this.showActiveLab = true;
                this.CableSize['specifyconductor']['phase']['mode'] = '0';
                this.CableSize['specifyconductor']['phase']['size'] = '25';
                //    alert(this.CableSize['specifyconductor']['phase']['circuits']);
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            }
        });
        $('#conduit-size-toggle').change((e) => {

            if ($(e.target).is(':checked') === true) {

                this.ShowConduitSize = true;
                this.ShowConduitSizeLab = false;
                this.CableSize['conduitsize']['mode'] = '1';

                this.getAllControlsValue();
            } else {
                //console.log('not checked');
                this.ShowConduitSize = false;

                this.ShowConduitSizeLab = true;
                this.CableSize['conduitsize']['mode'] = '0';
                this.getAllControlsValue();
            }
        });
        $('#neutral-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowNeutral = true;
                $('#Neutral_c').removeAttr('disabled');
                this.ShowNeutralLab = false;
                this.CableSize['specifyconductor']['neutral']['mode'] = '1';
                this.CableSize['specifyconductor']['neutral']['size'] = this.PrevNeutralValue !== undefined ? parseFloat(this.PrevNeutralValue.replace(' mm²', '')) : 1;

                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            } else {
                this.ShowNeutral = false;
                $('#Neutral_c').attr('disabled', 'true');
                $('#Neutral_c').val(1);
                this.ShowNeutralLab = true;
                this.CableSize['specifyconductor']['neutral']['mode'] = '0';
                this.CableSize['specifyconductor']['neutral']['size'] = '25';
                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            }
        });
        $('#earth-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowEarth = true;
                $('#Earth_c').removeAttr('disabled');
                this.ShowEarthLab = false;
                this.CableSize['specifyconductor']['earth']['mode'] = '2';
                let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                if (obj)
                    this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            } else {
                this.ShowEarth = false;
                $('#Earth_c').attr('disabled', 'true');
                $('#Earth_c').val(1);
                this.ShowEarthLab = true;
                this.CableSize['specifyconductor']['earth']['mode'] = '1';
                this.CableSize['specifyconductor']['earth']['size'] = '25';
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                //console.log("specifyConductorChange-getAllControlsValue");
                this.getAllControlsValue();
            }
        });
    }
    ngAfterViewInit() {
        //console.log('ngAfterViewInit');
        this.loading = true;
        setTimeout(() => {
            $(document).off('change', '#ddlunit');

            $('#ddlunit').on('change', (e: any, isTriggered) => {

                this.currentUnit = (e.target.options[e.target.selectedIndex].value);
                if (this.currentUnit == "1") {
                    if (this.voltageSupplyLabel == "rise") {
                        this.lblcurrent = 'Output current';
                    }
                    else
                        this.lblcurrent = 'Current';
                }
                else {
                    if (this.voltageSupplyLabel == "rise") {
                        this.lblcurrent = 'Output power';
                    }
                    else
                        this.lblcurrent = 'Power';
                }


                if (this.currentUnit == "4") {
                    this.showEfficency = true;
                }
                else {
                    this.showEfficency = false;

                }

                if (this.currentUnit != null && this.currentUnit != "") {

                    this.CableSize['powerunit'] = this.currentUnit;
                    //console.log('onCurrentChange-getAllControlsValue');
                    if (isTriggered != "true")
                        this.getAllControlsValue();
                }

            });

            if ($('.c-select').length) {
                let $singleSelect = $('.c-select'),
                    $singleSelectHasSearch = $('.c-select.has-search'),
                    $multipleSelect = $('.c-select.c-select--multiple'),
                    $disabledSelect = $('.c-select.is-disabled');

                $singleSelect.select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });

                // multiple select
                $multipleSelect.select2({
                    width: '100%',
                    multiple: true
                });

                // disabled select
                $disabledSelect.select2({
                    width: '100%',
                    disabled: true
                });
            }

            $('input[name=current]').change(this.showHideProtectionWarning);
            $('#gotopricing1, #gotopricing2, #spSubscribeNow').on('click', (e) => {
                this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
            });


            $("#reportDlg").on('shown.bs.modal', () => {
                this.CalculationReportInput.jobNo = this.route.snapshot.queryParamMap.get('cname');
                this.calculationName = this.route.snapshot.queryParamMap.get('cname');
            });

            $('#okBtn').on('click', (e) => {

                this.onSelectInstallTable();
                $('#confrimation-model').modal('hide');
                this.UserWizard = false;
                this.CableSize['isderatingwiz'] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.setInstallationType();
                this.onResetControlValues();
                this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);

            });
            $('#confrimation-model').modal({
                show: false,
                keyboard: false,
                backdrop: 'static'
            })
        });
    }

    zoomScale() {
        let zoomLevel = 1;

        const resetZoom = () => {
            document.body.style.transform = 'scale(1)';
            document.body.style.transformOrigin = '0 0';
            document.body.style.width = '100%';
            document.body.style.height = '100%';
        };

        const adjustZoom = () => {
            // Check for scrollbars
            const hasVerticalScrollbar = document.documentElement.scrollHeight > window.innerHeight;
            const hasHorizontalScrollbar = document.documentElement.scrollWidth > window.innerWidth;

            if ((hasVerticalScrollbar || hasHorizontalScrollbar) && window.innerHeight > 500 && window.innerWidth > 992) {
                // Reduce the zoom level
                zoomLevel -= 0.01; // Decrease by 1% per iteration
                document.body.style.transform = `scale(${zoomLevel})`;
                document.body.style.transformOrigin = '0 0';

                // Compensate for scaled size
                document.body.style.width = `${100 / zoomLevel}%`;
                document.body.style.height = `${100 / zoomLevel}%`;

                // Continue adjusting
                requestAnimationFrame(adjustZoom);
            } else {
                // Send a message back to the parent to confirm
                //event.source.postMessage({ status: 'Zoom adjustment complete', zoomLevel }, event.origin);

                // Re-enable scrollbars if necessary
                document.body.style.overflow = 'auto';
            }
        };

        // Start adjusting zoom
        resetZoom();
        adjustZoom();
    }

    ngAfterContentInit() {
        $('#deratingWizard').on('hidden.bs.modal', () => {
            if (this.CableSize['isderatingwiz'] == "0")
                this.UserWizard = false;
        });
    }

    onVoltageDrop(e: any) {
        //console.log('onVoltageDrop');
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.CableSize['voltagedrop'] = e.target.value;
        //console.log('onVoltageDrop-getAllControlsValue');
        this.getAllControlsValue();
    }
    onVoltageSupplyChange(e: any) {
        //console.log('onVoltageDrop');
        if (this.CableproInput.PhaseId.toString() == '102') /* DC */
            $('input[name="Voltage-Supply"]').attr('max', 1500);
        else
            $('input[name="Voltage-Supply"]').attr('max', 1000);

        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);

        this.CableSize['supplyvoltage'] = e.target.value;
        //console.log('supplyvoltage-getAllControlsValue');
        this.getAllControlsValue();
    }
    SetVoltageLabelValue(calledBy: string) {

        this.CableSize['usevd'] = '1';
        if (calledBy == 'rise') {
            // this.lblcurrent='Output current';

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Output current';
            }
            else {
                this.lblcurrent = 'Output power';
            }
        }
        else {
            // this.lblcurrent='Current';

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Current';
            }
            else {
                this.lblcurrent = 'Power';
            }

        }
        this.voltageSupplyLabel = calledBy;

        if (this.voltageSupplyLabel !== this.prevVoltageSupplyLabel) {
            this.prevVoltageSupplyLabel = this.voltageSupplyLabel;
            this.UpdateVoltageLabelValue();
        }
    }
    onFaultLevelChange(e: any) {
        //console.log('onFaultLevelChange');
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 4);

        this.CableSize['faultlevel'] = e.target.value;
        //console.log('onFaultLevelChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    onLengthOfRunChange(e: any) {
        //console.log('onLengthOfRunChange');
        // if (!this.premiumUser && e.target.value > 200) {
        //     $('#length-of-run-restriction-model').modal('show');
        //     e.target.value = this.CableSize['lengthofrun'];
        //     return false;
        // }
        // else {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);

        this.CableSize['lengthofrun'] = e.target.value;
        //console.log('onLengthOfRunChange-getAllControlsValue');
        this.getAllControlsValue();
        // }
    }
    onActiveCircuitChange(e: any) {
        this.CommonValidationFn(e);
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['phase']['circuits'] = val.toFixed(0);
        //console.log('onActiveCircuitChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    onNeutralCircuitChange(e: any) {
        this.CommonValidationFn(e);
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['neutral']['circuits'] = val.toFixed(0);
        //console.log('onNeutralCircuitChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    onEarthCircuitChange(e: any) {
        this.CommonValidationFn(e);
        //console.log('onEarthCircuitChange');
        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['earth']['circuits'] = val.toFixed(0);
        //console.log('onEarthCircuitChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    onCurrentChange(e: any) {
        //console.log('onCurrentChange');
        // if (!this.premiumUser && e.target.value > 200) {
        //     $('#current-restriction-model').modal('show');
        //     e.target.value = this.CableSize['current'];
        //     return false;
        // }
        // else {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 4);
        this.CableSize['current'] = e.target.value;
        //console.log('onCurrentChange-getAllControlsValue');
        this.getAllControlsValue();
        //  }
    }
    onEfficiencyChange(e: any) {
        //console.log('onCurrentChange');
        this.CommonValidationFn(e);
        this.Allowdecimal(e);

        this.CableSize['efficiency'] = e.target.value;
        //console.log('onCurrentChange-getAllControlsValue');
        this.getAllControlsValue();

    }
    Allowdecimal(event: any) {
        var num = event.target.value;

        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        this.efficiency = event.target.value;
    }
    allowDecimalSetModal(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "airambtemp") {
            this.table271model.AbientTemp = event.target.value;
        }
        if (type == "AbientTemp") {
            this.table271model.AbientTemp = event.target.value;
        }
        if (type == "Depth") {
            this.table28model.Depth = event.target.value;
        }


    }
    allowDecimalSetModalThree(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "Resitivity") {
            this.table29model.Resitivity = event.target.value;
        }

    }
    Allowdecimalwithplaces(event: any, places: number) {

        var num = event.target.value;
        var with2Decimals = 0;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        if (places == 2) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        }
        else if (places == 3) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        }
        else if (places == 4) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : event.target.value;

        }

        event.target.value = with2Decimals
        //this.efficiency=event.target.value;
    }

    onPowerFactorChange(e: any) {
        //console.log('onPowerFactorChange');
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);
        //alert(e.target.value);
        this.CableSize['powerfactor'] = e.target.value;
        //console.log('onPowerFactorChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    onDeratingFactorChange(e: any) {
        //console.log('onDeratingFactorChange');
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');

        this.CableSize['deratingfactor'] = e.target.value;
        this.getAllControlsValue();

        this.CalculationReportInput.wizardValues = new DRFWizrd();
        this.CalculationReportInput.wizardValues.finalDrf = Number(parseFloat(e.target.value).toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];

        this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);
    }
    CommonValidationFn(event: any) {
        //console.log('CommonValidationFn');

        if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            event.target.value = event.target.max;
        }
        if (parseFloat(event.target.value) < parseFloat(event.target.min) || event.target.value === '') {
            event.target.value = event.target.min;
        }
        //this.getAllControlsValue();
    }
    ProtectionCalcDropdown(e: any, elem: any) {
        //console.log('ProtectionCalcDropdown');

        this._calculationService.getPrevValue().subscribe((result) => {
            let prevRatingValue = result;
            //console.log('prevRatingValue ' + prevRatingValue);
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            let RatingValue: any = '';
            if (e !== '') {
                RatingValue = e.target.options[e.target.selectedIndex].innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }
            if (elem !== '') {
                RatingValue = elem.innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }
            if (prevRatingValue == null || prevRatingValue == undefined || prevRatingValue == '') {
                prevRatingValue = RatingValue;
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => { }, error => { });
            }

            if (prevRatingValue != RatingValue) {
                //console.log('RatingValue ' + RatingValue);
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => {
                    if ($('input').filter("[caption='Thermal setting']").length) {
                        $('input').filter("[caption='Thermal setting']").val(RatingValue);
                        //console.log('setting from here - ProtectionCalcDropdown ');
                        this.CableSize['protection']['thermalsetting'] = RatingValue;
                    }

                    let currentValue: any = '';
                    let Val1: any = '';
                    let Val2: any = '';
                    if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
                        Val1 = parseFloat(RatingValue);
                    } else {
                        Val1 = parseInt(RatingValue);
                    }
                    if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                        Val2 = parseFloat(MultiplierValue);
                    } else {
                        Val2 = parseInt(MultiplierValue);
                    }
                    currentValue = Val1 * Val2;
                    currentValue = currentValue.toFixed(2);
                    if ($('input').filter("[caption='Trip current']").length) {
                        $('input').filter("[caption='Trip current']").val(currentValue);
                        this.CableSize['protection']['tripcurrent'] = currentValue;
                    }

                    this.getAllControlsValue();
                });
            }
            //else {
            //    //console.log('ProtectionCalcDropdown-getAllControlsValue-Else');
            //    this.getAllControlsValue();
            //}
        });
    }
    ProtectionCalcForCustomRating() {
        //console.log('ProtectionCalcForCustomRating');
        const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
        const RatingValue = $('input').filter("[caption='Custom Rating']").val().toString();
        $('input').filter("[caption='Thermal setting']").val(RatingValue);
        $('input[caption="Thermal setting"]').attr('max', RatingValue);
        //console.log('setting from here - ProtectionCalcForCustomRating ');
        this.CableSize['protection']['thermalsetting'] = RatingValue;
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (RatingValue < (100).toString()) {
            this.showLable = true;
        } else {
            this.showLable = false;
        }
        if (!Number.isNaN(Number(RatingValue)) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
            Val2 = parseFloat(MultiplierValue);
        } else {
            Val2 = parseInt(MultiplierValue);
        }
        currentValue = Val1 * Val2;
        currentValue = currentValue.toFixed(2);
        $('input').filter("[caption='Trip current']").val(currentValue);
        this.CableSize['protection']['tripcurrent'] = currentValue;
        //console.log('ProtectionCalcForCustomRating-getAllControlsValue');
        this.getAllControlsValue();
    }
    OnInputChange(e: any, numberofplaces: number) {
        //console.log('OnInputChange');
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, numberofplaces);
        const caption: string = e.target.getAttribute('caption');
        const device: string = e.target.getAttribute('device');
        if (caption === 'Short-circuit time') {
            this.CableSize['protection']['shortcircuittime'] = e.target.value;
        } else if (caption === 'Trip current') {
            this.CableSize['protection']['tripcurrent'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Trip Multiplier') {
            this.CableSize['protection']['tripmultiplier'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Thermal setting') {
            //console.log('OnInputChange-setting from here');
            this.CableSize['protection']['thermalsetting'] = e.target.value;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Custom Rating') {
            this.CableSize['protection']['customrating'] = e.target.value;
            this.PrevCustomeRatingVal = e.target.value;
            this.ProtectionCalcForCustomRating();
        }
        //console.log('OnInputChange-getAllControlsValue');
        this.getAllControlsValue();
    }
    ProtectionCalcForCurrentandMultiplier(e: any, device: any, caption: any) {

        //console.log('ProtectionCalcForCurrentandMultiplier');
        let RatingValue: any = ''
        if (device === 'MCB') {
            RatingValue = $('#select_MCB')[0][this.PrevMCBVal].innerHTML.split(" ")[1];
        } else if (device === 'MCCB') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
            // RatingValue = $('#select_MCCB')[0][this.PrevMCCBVal].innerHTML.split(" ")[1];
        } else if (device === 'ACB') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
            // RatingValue = $('#select_ACB')[0][this.PrevACBVal].innerHTML.split(" ")[1];
        } else if (device === 'HRC Fuse') {
            RatingValue = $('#select_HRCFuse')[0][this.PrevHRCVal].innerHTML.split(" ")[1];
        } else if (device === 'Custom') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
        }
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
            Val2 = parseFloat(e.target.value);
        } else {
            Val2 = parseInt(e.target.value);
        }
        if (caption === 'Trip current') {
            currentValue = Math.round(Val2 / Val1 * 100) / 100;
            if ($('input').filter("[caption='Trip Multiplier']").length) {
                $('input').filter("[caption='Trip Multiplier']").val(currentValue);
                this.CableSize['protection']['tripmultiplier'] = currentValue;
            }
        } else if (caption === 'Trip Multiplier') {
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            if ($('input').filter("[caption='Trip current']").length) {
                $('input').filter("[caption='Trip current']").val(currentValue);
                this.CableSize['protection']['tripcurrent'] = currentValue;
            }
        } else if (caption === 'Thermal setting') {
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            if (e.target.value < 100) {
                this.showLable = true;
            } else {
                this.showLable = false;
            }
            if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                Val1 = parseFloat(MultiplierValue);
            } else {
                Val1 = parseInt(MultiplierValue);
            }
            if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
                Val2 = parseFloat(e.target.value);
            } else {
                Val2 = parseInt(e.target.value);
            }
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            $('input').filter("[caption='Trip current']").val(currentValue);
            this.CableSize['protection']['tripcurrent'] = currentValue;
        }
        //console.log('ProtectionCalcForCurrentandMultiplier-getAllControlsValue');
        this.getAllControlsValue();
    }
    onDescriptionChnage(e: any) {
        //console.log('onDescriptionChnage');
        this.CommonValidationFn(e);

        const caption: string = e.target.getAttribute('caption');
        if (caption === 'Description') {
            this.CableSize['protection']['description'] = e.target.value;
        }
        //console.log('onDescriptionChnage-getAllControlsValue');
        this.getAllControlsValue();
    }
    onDropDownChange() {
        $(document).off('change', '#select_HRCFuse');

        $(document).on('change', '#select_HRCFuse', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['HRCFuserating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevHRCVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
                //  this.getAllControlsValue();

            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
            //console.log('onDropDownChange-select_HRCFuse');
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_MCB');
        $(document).on('change', '#select_MCB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['MCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevMCBVal = e.target.selectedIndex;

                this.ProtectionCalcDropdown(e, '');
                //  this.getAllControlsValue();
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
            //console.log('onDropDownChange-select_MCB');
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_MCCB');

        $(document).on('change', '#select_MCCB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['MCCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);
                this.CableSize['protection']['thermalsetting'] = this.tempRating;
                this.PrevMCCBVal = e.target.selectedIndex;
                this.ProtectionCalcDropdown(e, '');
                // this.getAllControlsValue();

            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
            //console.log('onDropDownChange-select_MCCB');
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_ACB');

        $(document).on('change', '#select_ACB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);
                this.CableSize['protection']['ACBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevACBVal = e.target.selectedIndex;
                this.CableSize['protection']['thermalsetting'] = this.tempRating;
                this.ProtectionCalcDropdown(e, '');
                // this.getAllControlsValue();
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.getAllControlsValue();
            }
            //console.log('onDropDownChange-select_ACB');
            //this.getAllControlsValue();
        });
    }
    onCheckBoxChange() {
        //console.log('onCheckBoxChange');
        $(document).off('change', '.chkGroup input:checkbox');
        $(document).on('change', '.chkGroup input:checkbox', (e: any) => {
            if ($(e.target).prop('checked') == true) {
                this.CableSize['protection']['rcd'] = '1';
            } else {
                this.CableSize['protection']['rcd'] = '0';
            }
            //console.log('onCheckBoxChange-getAllControlsValue');
            this.getAllControlsValue();
        });
    }

    onNoEarthChange() {
        //console.log('onNoEarthChange');
        $('#earth-chk').on('change', (e: any) => {
            if ($(e.target).is(':checked') === true) {
                this.CableSize['specifyconductor']['earth']['mode'] = '0';
                this.CableSize['specifyconductor']['earth']['size'] = '0'
                this.CableSize['specifyconductor']['earth']['circuits'] = '0';
                $('#earth-toggle').attr('disabled', 'true');
                this.ShowEarth = false;
                $('#Earth_c').attr('disabled', 'true');
                $('#Earth_c').val(1);
                this.ShowEarthLab = true;
                this.showEarthButton = false;
            } else {
                $('#earth-toggle').removeAttr('disabled');
                this.showEarthButton = true;
                if ($('#earth-toggle').is(':checked') === true) {
                    this.ShowEarth = true;
                    $('#Earth_c').removeAttr('disabled');
                    this.ShowEarthLab = false;
                    this.CableSize['specifyconductor']['earth']['mode'] = '2';
                    let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                    if (obj)
                        this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                } else {
                    this.ShowEarth = false;
                    $('#Earth_c').attr('disabled', 'true');
                    $('#Earth_c').val(1);
                    this.ShowEarthLab = true;
                    this.CableSize['specifyconductor']['earth']['mode'] = '1';
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                }
            }
            //console.log('onNoEarthChange-getAllControlsValue');
            this.getAllControlsValue();
        });
    }
    tabChange(evt, tabId) {
        //console.log('tabChange');
        //if (tabId === 'tab2' && !this.premiumUser) return false;
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
    }
    StoreDHROutput(data: DRFWizrd) {
        //console.log('StoreDHROutput');
        if (data === undefined) {
            return
        }
        if (data.finalDrf == 1 && data.result.length === 0) {
            return;
        }

        this.UserWizard = $('#checkboxUseWizard:checked').length > 0;
        if (this.UserWizard) {
            //this.CableSize['isderatingwiz'] = "1";
            this.getAllControlsValue();
        }
        else {
            this.CableSize['isderatingwiz'] = "0";
            this.getAllControlsValue();
        }
        this.updateDeratingFactorValue(this.UserWizard, data);
    }
    updateDeratingFactorValue(useWizard: boolean, data: DRFWizrd) {
        this.showLoader();
        this._CableproService.storeDHROutput(this.newCalculationId, useWizard, data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            //console.log("There is no internet connection");
            this.hideLoader();
        });
    }
    ClearTrailOne(): void {
        $('#reportDlg').modal('hide');
    }
    SetUpgradeLink() {
        if (this.isPlanAddedManually === true)
            this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else
            this.ManageSubscription(this.user, this.loaderService, this._subscriptionService);
        $('#reportDlg').modal('hide')
    }
    downloadTextFile(tabDelimitedContent, filename) {
        const blob = new Blob([tabDelimitedContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    DownloadErrorsAndWarning() {
        const rows = this.warningErrorList
            .map(error => {
                const modifiedIsError = error.IsError == '1' ? "Error" : "Warning";  // Modify the age by adding 5 years
                return `${modifiedIsError}\t${error.ErrorCode}\t${error.Message}`;  // Combine name, modified age, and city
            })
            .join('\n');
        const tabDelimitedContent = ["Type\tCode\t\Message\n", ...rows].join('');
        this.downloadTextFile(tabDelimitedContent, 'Errors and Warnings.txt')
    }
    GenerateReport(): void {
        if (this.IsReportEmailValid()) {
            if (this.IsReportFormValid()) {
                this.showreportloader = true;
                localStorage.setItem('ReportSettingEmails', this.CalculationReportInput.eamilList);
                this.CalculationReportInput.reportAttachmentName = 'Cable_Pro_Report_';
                this.CalculationReportInput.useWizard = this.UserWizard;
                this.CalculationReportInput.imgSrc = '';

                if ($("#selectedimage").length) {

                    this.CalculationReportInput.imgSrc = $("#selectedimage").attr("src");


                }
                this.CalculationReportInput.voltagefactor = this.CableSize['voltagefactor'];
                this.CalculationReportInput.conductor = this.CableSize['conductor'];
                this.CalculationReportInput.core = this.CableSize['core'];
                this.CalculationReportInput.current = this.CableSize['current'];
                this.CalculationReportInput.powerunit = this.CableSize['powerunit'];
                this.CalculationReportInput.efficiency = this.CableSize['efficiency'];
                this.CalculationReportInput.dcvoltage = this.CableSize['dcvoltage'];
                this.CalculationReportInput.deratingfactor = this.CableSize['deratingfactor'];
                this.CalculationReportInput.description = this.CableSize['description'];
                this.CalculationReportInput.device = this.CableSize['device'];
                this.CalculationReportInput.endingrowindex = this.CableSize['endingrowindex'];
                this.CalculationReportInput.faultlevel = this.CableSize['faultlevel'];
                this.CalculationReportInput.insulation = this.CableSize['insulation'];
                this.CalculationReportInput.lengthofrun = this.CableSize['lengthofrun'];
                this.CalculationReportInput.onephasevoltage = this.CableSize['onephasevoltage'];
                this.CalculationReportInput.phase = this.CableSize['phase'];
                this.CalculationReportInput.powerfactor = this.CableSize['powerfactor'];
                this.CalculationReportInput.startingrowindex = this.CableSize['startingrowindex'];
                this.CalculationReportInput.supplyvoltage = this.CableSize['supplyvoltage'];
                this.CalculationReportInput.tablectrlvalue = this.CableSize['tablectrlvalue'];
                this.CalculationReportInput.tableno = this.CableSize['tableno'];
                this.CalculationReportInput.threephasevoltage = this.CableSize['threephasevoltage'];
                this.CalculationReportInput.unit = this.CableSize['unit'];
                this.CalculationReportInput.circuitrating_drated = this.Circuitrating_drated;
                this.CalculationReportInput.Dictatingfactor = this.Dictatingfactorres;
                this.CalculationReportInput.currentrating_cable_tabulated = this.Currentrating_cable_tabulated;
                this.CalculationReportInput.ccC_Currentrating_cable_tabulated_Operating_temperature = this.CCC_Currentrating_cable_tabulated_Operating_temperature;
                this.CalculationReportInput.currentrating_cable_tabulated_Ref_Desc_TableNo = this.Currentrating_cable_tabulated_Ref_Desc_TableNo;
                this.CalculationReportInput.currentrating_cable_tabulated_Ref_Desc_ColumnNo = this.Currentrating_cable_tabulated_Ref_Desc_ColumnNo;
                this.CalculationReportInput.voltageDrop_Result_MaxLengthOfRun = this.VoltageDrop_Result_MaxLengthOfRun;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp = this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp = this.VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Resistance_TableNo = this.VoltageDrop_RefAndDesc_Resistance_TableNo;
                this.CalculationReportInput.spare_current_capacity_of_circuit = this.Spare_current_capacity_of_circuit;
                this.CalculationReportInput.voltageDrop_Data_PhaseConductor_Resistance = this.VoltageDrop_Data_PhaseConductor_Resistance;
                this.CalculationReportInput.referencetempdegC = this.referencetempdegCReport;
                this.CalculationReportInput.errordata = this.warningErrorStrData;
                this.CalculationReportInput.standardCountry = this.standardCountryReport;
                this.CalculationReportInput.calculation_type = this.calculation_type.toString();
                this.CalculationReportInput.conduitmode = this.CableSize['conduitsize']['mode'];

                if ($('#select_ACB').length) {
                    this.CalculationReportInput.acBrating = $('#select_ACB option:selected')[0].innerText.trim();
                }
                if ($('#select_MCB').length) {
                    this.CalculationReportInput.mcBrating = $('#select_MCB option:selected')[0].innerText.trim();
                }
                if ($('#select_MCCB').length) {
                    this.CalculationReportInput.mccBrating = $('#select_MCCB option:selected')[0].innerText.trim();
                }

                if (this.protection_curve)
                    this.CalculationReportInput.curve = this.protection_curve.toString();// $('#select_Curve option:selected')[0].innerText.trim();
                //}
                if ($('#select_HRCFuse').length) {
                    this.CalculationReportInput.hrcFuserating = $('#select_HRCFuse option:selected')[0].innerText.trim();
                }
                this.CalculationReportInput.earthfaulttime = this.CableSize['protection']['earthfaulttime'];
                this.CalculationReportInput.rcd = this.CableSize['protection']['rcd'];
                this.CalculationReportInput.shortcircuittime = this.CableSize['protection']['shortcircuittime'];
                //console.log('on report');
                this.CalculationReportInput.thermalsetting = this.CableSize['protection']['thermalsetting'];
                this.CalculationReportInput.tripcurrent = this.CableSize['protection']['tripcurrent'];
                this.CalculationReportInput.tripmultiplier = this.CableSize['protection']['tripmultiplier'];
                this.CalculationReportInput.customrating = this.CableSize['protection']['customrating'];
                this.CalculationReportInput.protectiondescription = this.CableSize['protection']['description'];
                this.CalculationReportInput.faultLoop_res = this.faultLoop_res;
                this.CalculationReportInput.ShortCircuitPerformance_ok = this.ShortCircuitPerformance_ok;
                this.CalculationReportInput.ShortCircuitPerformance_EarthConductor_ok = this.ShortCircuitPerformance_EarthConductor_ok;
                this.CalculationReportInput.shortCircuitPerformance_PhaseConductor_kfactor = this.ShortCircuitPerformance_PhaseConductor_kfactor;
                this.CalculationReportInput.shortCircuitPerformance_PhaseConductor_MinActive_Cond_Size = this.ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size;
                this.CalculationReportInput.shortCircuitPerformance_EarthConductor_Kfactor = this.ShortCircuitPerformance_EarthConductor_Kfactor;
                this.CalculationReportInput.shortCircuitPerformance_EarthConductor_MinEarth_Cond_Size = this.ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size;
                this.CalculationReportInput.active_NoOf_circuit = this.active_NoOf_circuit;
                this.CalculationReportInput.neutral_NoOf_circuit = this.neutral_NoOf_circuit;
                this.CalculationReportInput.earth_NoOf_circuit = this.earth_NoOf_circuit;
                this.CalculationReportInput.active_res = this.active_res;
                this.CalculationReportInput.earth_res = this.earth_res;
                this.CalculationReportInput.neutral_res = this.neutral_res;
                this.CalculationReportInput.activeCondz1Report = this.activeCondz1Report;
                this.CalculationReportInput.earthcondz2Report = this.Earthcondz2Report;
                this.CalculationReportInput.companyLogo_ShowLogo = this.companyLogo_ShowLogo;
                this.CalculationReportInput.customeReportFields_ShowCustomField = this.customeReportFields_ShowCustomField;
                this.CalculationReportInput.companyLogo_FileName = this.companyLogo_FileName;
                this.CalculationReportInput.customFields = this.customFields;
                this.CalculationReportInput.calculationName = this.calculationName;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Reactance_TableNo = this.VoltageDrop_RefAndDesc_Reactance_TableNo;
                this.CalculationReportInput.voltageDrop_RefAndDesc_Reactance_ColumnNo = this.VoltageDrop_RefAndDesc_Reactance_ColumnNo;
                this.CalculationReportInput.voltageDrop_Data_NeutralConductor_Resistance = this.VoltageDrop_Data_NeutralConductor_Resistance;
                this.CalculationReportInput.voltageDrop_Data_PhaseConductor_Reactance = this.VoltageDrop_Data_PhaseConductor_Reactance;
                this.CalculationReportInput.voltageDrop_Data_NeutralConductor_Reactance = this.VoltageDrop_Data_NeutralConductor_Reactance;
                this.CalculationReportInput.supplyvoltage = this.CableSize['supplyvoltage'];
                this.CalculationReportInput.voltagedropTable8 = (Math.round(this.vdrop * 100) / 100).toString();
                this.CalculationReportInput.voltageDropTable8Percentage = (Math.round(this.vdroppecent * 100) / 100).toString();
                this.CalculationReportInput.voltageDropPermissisableTable8 = (Math.round(this.vdropPermissibale * 100) / 100).toString();
                this.CalculationReportInput.permissibaleVolatagedrop = this.CableSize['voltagedrop'];
                this.CalculationReportInput.windspeed = this.CableSize['installtablewind']['wind'];
                this.CalculationReportInput.protective_Device_ImpedanceZmax = this.EarthLoop_ok;
                this.CalculationReportInput.faultloop1 = this.faultloop1;
                this.CalculationReportInput.faultloop2 = this.faultloop2;
                this.CalculationReportInput.faultloop3 = this.faultloop3;
                this.CalculationReportInput.faultloop4 = this.faultloop4;
                this.CalculationReportInput.faultloop5 = this.faultloop5;


                this.CalculationReportInput.ConditSizeFound = this.ConditSizeFound;
                this.CalculationReportInput.ConditCableDiameter = this.ConditCableDiameter;
                this.CalculationReportInput.ConditNoofCablesPerCondit = this.ConditNoofCablesPerCondit;
                this.CalculationReportInput.ConditSpaceFactorActual = this.ConditSpaceFactorActual;
                this.CalculationReportInput.ConditSpaceFactorStandard = this.ConditSpaceFactorStandard;
                this.CalculationReportInput.ConditSpaceFactorActualNeutral = this.ConditSpaceFactorActualNeutral;

                this.CalculationReportInput.ConditNoofCablesPerConditActive = this.ConditNoofCablesPerConditActive;
                this.CalculationReportInput.ConditNoofCablesPerConditNeutral = this.ConditNoofCablesPerConditNeutral;
                this.CalculationReportInput.ConditRefrenceTable = this.ConditRefrenceTable;
                this.CalculationReportInput.ConditNeutralRefrenceTable = this.ConditNeutralRefrenceTable;

                this.CalculationReportInput.ConditCableDiameterActive = this.ConditCableDiameterActive;
                this.CalculationReportInput.Activeneutralshownseparately = this.Activeneutralshownseparately;

                this.CalculationReportInput.ConditCableDiameterNeutral = this.ConditCableDiameterNeutral;
                this.CalculationReportInput.ConduitDiameter = this.ConduitDiameter;
                this.CalculationReportInput.ConditSizeBasedOn = this.ConditSizeBasedOn;

                this.CalculationReportInput.NoofConduit = this.NoofConduit;
                this.CalculationReportInput.MinimumConduitDiameter = this.MinimumConduitDiameter;



                if (this.MaxAllowedDevice === 'Inf') {
                    this.CalculationReportInput.faultloop6 = '-';
                    this.CalculationReportInput.maxAllowedDevice = 'Device Unknown';
                } else {
                    this.CalculationReportInput.maxAllowedDevice = (Math.round(this.MaxAllowedDevice * 1000) / 1000).toString();
                    this.CalculationReportInput.faultloop6 = this.faultloop6;
                }
                this.CalculationReportInput.faultloop7 = this.faultloop7;
                this.CalculationReportInput.short_circuitCurrentText = this.getShortCircuitCurrent();
                this.CalculationReportInput.three_phaseShort_circuitCurrent = this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct;
                this.CalculationReportInput.active_to_EarthShort_circuitCurrent = this.Active_to_EarthShort_circuitCurrent;
                this.CalculationReportInput.earthConductor = this.CableSize['earthconductor'];
                this.CalculationReportInput.earthMode = this.CableSize['specifyconductor']['earth']['mode'];
                this.CalculationReportInput.neutral_mode = this.CableSize['specifyconductor']['neutral']['mode'];
                this.CalculationReportInput.active_mode = this.CableSize['specifyconductor']['phase']['mode'];

                //this.CalculationReportInput.projectname = (this.CalculationReportInput.projectname || '').trim().replace(' ', '-');
                // this.CalculationReportInput.jobNo = (this.CalculationReportInput.jobNo || '').trim().replace(' ', '-');
                if (this.CalculationReportInput.jobNo != null && this.CalculationReportInput.jobNo.trim() == '')
                    this.CalculationReportInput.jobNo = (this.CalculationReportInput.jobNo || '').trim().replace(' ', '-');

                if (this.CalculationReportInput.projectname != null && this.CalculationReportInput.projectname.trim() == '')
                    this.CalculationReportInput.projectname = (this.CalculationReportInput.projectname || '').trim().replace(' ', '-');



                this.CalculationReportInput.reportAttachmentName += this.projectName + '_';
                this.CalculationReportInput.reportAttachmentName += this.calculationName + '.pdf';
                this.CalculationReportInput.calculationId = this.newCalculationId;
                this.CalculationReportInput.reportHeaderDate = moment().format('DD-MMM-YYYY hh:mm A').toString();
                this.CalculationReportInput.voltageSupplyLabel = this.voltageSupplyLabel;

                this._pdfReportSerivceProxy.CustomGenerateReport(this.CalculationReportInput).subscribe((result) => {
                    if (!this.CalculationReportInput.sendEmail) {
                        let blob = new Blob([result.body], { type: 'application/pdf' });
                        saveAs(blob, this.CalculationReportInput.reportAttachmentName);
                    }
                    else {
                        $('#EmailSent').modal('show');
                    }
                    this.ClearReport();
                    this.showreportloader = false;
                });
            }
        }
    }
    showLoader(): void {
        //console.log('showLoader');
        this.loaderService.showHeader();
    }
    hideLoader(): void {
        //console.log('hideLoader');
        this.loaderService.hideHeader();
    }
    ClearReport(): void {
        //console.log('ClearReport');
        // this.CalculationReportInput.projectname = '';
        // this.CalculationReportInput.jobNo = '';
        this.CalculationReportInput.sendEmail = false;
        this.CalculationReportInput.printName = true;
        $('#chkPrintName').prop('checked', true);

        //this.CalculationReportInput.eamilList = '';

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        $('#reportDlg').modal('hide');
    }
    showHideProtectionWarning() {
        if (this.CableSize != undefined && (<any>this.CableSize).currentamp != undefined) {
            switch (this.device) {
                case '0':
                    this.displayCurrentError = false;
                    break;
                case '1':

                    var tempvalue = parseInt(this.CableSize['protection']['MCBrating']);

                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device=MCB][value=' + tempvalue + ']').text())) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '2':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '3':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '4':
                    var tempvalue = parseInt(this.CableSize['protection']['HRCFuserating']);
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device="HRC Fuse"][value=' + tempvalue + ']').text())) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '5':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    } else {
                        this.displayCurrentError = false;
                    }
                    break;
            }
        }
    }
    UseWizardChange(e) {
        setTimeout(() => {
            this.UserWizard = $('#checkboxUseWizard:checked').length > 0;

            if (this.UserWizard) {
                //this.CableSize['isderatingwiz'] = "1";
                $('#showWizard').click();
            }
            else {
                this.CableSize['isderatingwiz'] = "0";
            }
            this.StoreDHROutput(this.CalculationReportInput.wizardValues);
        });
    }
    bindWizard(startStep: number = 1) {
        this.SetFirstWizardScreen();
    }
    /* In air - Bunched Circuits */
    GetTable22Dr() {
        //console.log('GetTable22Dr');
        setTimeout(() => {
            this.drfService.getTable22(this.table22model.Arrangement, this.table22model.AddType, this.table22model.NoOfCircuits).subscribe(result => {
                this.table22model.drf = parseFloat(result["Derating Factor:"]);
                this.drTable22output = result;

                if (this.table22model.Arrangement != 1) {
                    this.drTable22CircuitValues = [
                        { "name": 1, "value": 1 },
                        { "name": 2, "value": 2 },
                        { "name": 3, "value": 3 },
                        { "name": 4, "value": 4 },
                        { "name": 5, "value": 5 },
                        { "name": 6, "value": 6 },
                        { "name": 7, "value": 7 },
                        { "name": 8, "value": 8 },
                        { "name": 9, "value": 9 },
                        { "name": 10, "value": 10 },
                        { "name": 12, "value": 12 },
                        { "name": 14, "value": 14 },
                        { "name": 16, "value": 16 },
                        { "name": 18, "value": 18 },
                        { "name": "20 or more", "value": 20 }];
                }
            });
        }, 500);
    }
    /* In air - Cable Trays */
    GetTable23Dr(e: any) {
        //console.log('GetTable23Dr');
        this.drfService.getTable23(this.table23model.CableType, this.table23model.Installation, this.table23model.Row, this.table23model.Cables).subscribe(result => {
            this.table23model.drf = parseFloat(result["Derating Factor:"]);
            this.table23output = result;
            //this.updateWizardFactorValues(1, 'cable trays');
        });
    }
    /* In air/soil  - ambient temperature */
    GetTable271Dr(e: any, type: any = '', checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces && type == 'AbientTemp')
            this.allowDecimalSetModal(e, type);
        if (checkdecimalplaces && type == 'airambtemp')
            this.allowDecimalSetModal(e, type);
        setTimeout(() => {
            if (e) {
                if ($(e.target).is(':checked') === true) {
                    if (this.drInstallType == 2)
                        $('#inputUseStandardSoil').attr('disabled', 'true');
                    else {
                        if (this.drBunchingType == 1)
                            $('#inputUseStandardAir1').attr('disabled', 'true');
                        else
                            $('#inputUseStandardAir2').attr('disabled', 'true');
                    }
                }
                else {
                    if (this.drInstallType == 2)
                        $('#inputUseStandardSoil').removeAttr('disabled');
                    else {
                        if (this.drBunchingType == 1)
                            $('#inputUseStandardAir1').removeAttr('disabled');
                        else
                            $('#inputUseStandardAir2').removeAttr('disabled');
                    }
                }
            }

            var standard = parseInt(this.CableSize['calculationstandard']);

            if (this.table271model.UseStandard == true) {
                if (this.drInstallType == 1) {
                    this.table271model.AbientTemp = standard == 0 ? 40 : 30;
                } else {
                    this.table271model.AbientTemp = standard == 0 ? 25 : 15;
                }
                this.table271model.ConductorTemp = 75;
                this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(result["Derating Factor:"]);
                    this.drTable271output = result;
                    this.drTable271output["Conductor temperature (deg.C.):"] = null;
                    // this.finalDrf = this.table271model.drf;
                });
                this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();
                return false;

            }
            if (this.drInstallType == 1) {

                if (
                    (this.table271model.ConductorTemp > 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 10)
                    ||
                    (this.table271model.ConductorTemp < 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 5)
                ) {
                    if (this.table271model.ConductorTemp > 100) {
                        this.table271model.AbientTemp = this.table271model.ConductorTemp - 10;
                    }
                    else {
                        this.table271model.AbientTemp = this.table271model.ConductorTemp - 5;
                    }
                }
                if (this.table271model.AbientTemp < 15) {
                    this.table271model.AbientTemp = 15;
                }
            }
            else if (this.drInstallType == 2) {
                if (this.table271model.AbientTemp > 40 && standard == 0) {
                    this.table271model.AbientTemp = 40;
                }
                else if (this.table271model.AbientTemp < 10) {
                    this.table271model.AbientTemp = 10;
                }
            }
            if (!this.table271model.UseStandard) {
                this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(result["Derating Factor:"]);
                    this.drTable271output = result;
                    // this.finalDrf = this.table271model.drf;
                });
            }
            this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();

        }, 100);
    }
    /* Depth of Burial (m) */
    GetTable25Dr() {
        if (this.table25model.NoOfCircuits == 1) {
            this.table25model.drf = 1;
            this.drTable25output = undefined;
            $('#divburiedGroupArrangement').css('display', 'none');
            return false;
        }
        $('#divburiedGroupArrangement').css('display', 'block');
        if (this.table25model.CableType == 2 && this.table25model.InstallType == 2) {
            this.table25model.InstallType = 1;
        }
        if (this.table25model.InstallType == 2) {
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        else if (this.table25model.InstallType == 3) {
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.30 m", "value": 4 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];

        }
        else {
            if (this.table25model.CableType == 1) {
                this.table25ArrangementValues = [
                    { "name": "Touching trefoil", "value": 1 },
                    { "name": "Touching flat", "value": 2 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
            }
            else if (this.table25model.CableType == 2) {
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
            }
        }
    }
    /* Depth of Burial (m) */
    setTable25Dr() {
        //console.log("setTable25Dr");
        if (this.table25model.NoOfCircuits == 1)
            $('#divburiedGroupArrangement').css('display', 'none');
        else
            $('#divburiedGroupArrangement').css('display', 'block');
        this.drfService.getTable25(this.table25model.CableType, this.table25model.NoOfCircuits, this.table25model.TouchingType, this.table25model.Distance, this.table25model.InstallType).subscribe(result => {
            this.table25model.drf = parseFloat(result["Derating Factor:"]);
            this.drTable25output = result;
            if (this.table25model.NoOfCircuits == 1) {
                this.drTable25output["Spacing (m):"] = null

            }
            //this.updateWizardFactorValues(1, 'buried circuits');
        });
    }

    /* Depth of Burial (m) - arrangement values  */
    setTable25Values(arrangementValue: any) {
        //console.log("setTable25Values");
        switch (parseInt(arrangementValue)) {
            case 1:
                this.table25model.Distance = 0;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 1;
                        break;
                    case 2:
                        this.table25model.TouchingType = 4;
                        break;
                    case 3:
                        this.table25model.TouchingType = 4;
                        break;
                }
                break;
            case 2:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 2;
                break;
            case 3:
                this.table25model.Distance = 0.15;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }

                break;
            case 4:
                this.table25model.Distance = 0.30;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
            case 5:
                this.table25model.Distance = 0.45;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
            case 6:
                this.table25model.Distance = 0.60;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
        }
        this.setTable25Dr();
    }
    GetTable28Dr(e, type: any = '', checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces && type == 'Depth')
            this.allowDecimalSetModal(e, 'Depth');

        //console.log('GetTable28Dr');
        setTimeout(() => {
            if (this.table28model.UseStandard) {
                // this.table28model.Buried = 1;


                let imageName: string = this.CableSize['description'].toLowerCase().trim();

                if (imageName === 'buried direct') {
                    this.table28model.Buried = 1;
                }
                else {
                    this.table28model.Buried = 2;

                }

                this.table28model.Depth = 0.5;
                this.table28model.ConductorSize = 3;
                this.table28model.Core = 1;
                this.table28model.drf = 1;
                //    this.table28output = undefined;

                this.setTable28Dr();
                return false;
            }

            if (this.table28model.Depth < 0.5) {
                this.table28model.Depth = 0.5;
            }

            if (this.table28model.Depth > 3) {
                this.table28model.Depth = 3;
            }
            this.setTable28Dr();
        }, 800);
    }
    /* Depth of Burial (m) - set values   Conductor size:*/
    setTable28Dr() {
        //console.log("setTable28Dr");
        this.drfService.getTable28(this.table28model.Depth, this.table28model.ConductorSize, this.table28model.Core, 0, this.table28model.Buried).subscribe(result => {
            this.table28model.drf = parseFloat(result["Derating Factor:"]);
            this.table28output = result;
            //this.updateWizardFactorValues(2, 'soil');
            if (this.table28model.UseStandard) {
                this.table28output["Conductor size:"] = null;

            }
        });
    }
    OnInstallationTypeChange(e: any) {
        setTimeout(() => {
            this.table271model.UseStandard == true;
            if (this.drInstallType == 1) {
                this.table271model.AbientTemp = 40;
            } else {
                this.table271model.AbientTemp = 25;
            }
            this.table271model.ConductorTemp = 75;
            //alert(this.drInstallType);
            if (this.drInstallType == 2) {
                this.conductorTempValues = [
                    // { "name": "150 &#176;C", "value": 150 },
                    { "name": "110 &#176;C", "value": 110 },
                    { "name": "90 &#176;C", "value": 90 },
                    { "name": "80 &#176;C", "value": 80 },
                    { "name": "75 &#176;C", "value": 75 },
                ];
            }
            else {
                this.conductorTempValues = [
                    { "name": "150 &#176;C", "value": 150 },
                    { "name": "110 &#176;C", "value": 110 },
                    { "name": "90 &#176;C", "value": 90 },
                    { "name": "80 &#176;C", "value": 80 },
                    { "name": "75 &#176;C", "value": 75 },
                ];
            }

            $('#drTable271CondutortTemp').val(this.table271model.ConductorTemp).trigger('change');
            this.GetTable271Dr(null);
        });
    }
    /* Soil thermal resitivity  (e: any , type : any ='', checkdecimalplaces : boolean =false)*/
    GetTable29Dr(e: any, type: any = '', checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces && type == 'Resitivity')
            this.allowDecimalSetModalThree(e, 'Resitivity')
        //console.log('GetTable28Dr');
        setTimeout(() => {
            if (this.table29model.UseStandard) {
                this.table29model.Resitivity = 1.2;
                this.table29model.Installation = 2;
                this.table29model.drf = 1;
                // this.table29output = undefined;

                this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Installation).subscribe(result => {
                    this.table29model.drf = parseFloat(result["Derating Factor:"]);
                    this.table29output = result;
                    this.table29output["Type of installation:"] = null;

                    //this.updateWizardFactorValues(3, 'soil');
                });
                return false;
            }

            if (this.table29model.Resitivity > 3) {
                this.table29model.Resitivity = 3;
            }
            if (this.table29model.Resitivity < 0.8) {
                this.table29model.Resitivity = 0.8;
            }
            this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Installation).subscribe(result => {
                this.table29model.drf = parseFloat(result["Derating Factor:"]);
                this.table29output = result;
                //this.updateWizardFactorValues(3, 'soil');
            });
        }, 800);
    }
    public Table23InstallationValues: Table23InstallationModel[];
    public Table24InstallationValues: Table23InstallationModel[];
    GetTable23Values() {
        //console.log("setTable23Dr");
        this.drfGetService.getTable23Installations().subscribe(result => {
            this.Table23InstallationValues = result;
        });
    }
    GetTable24Values() {
        //console.log("setTable24Dr");
        this.drfGetService.getTable24Installations().subscribe(result => {
            this.Table24InstallationValues = result;
        });
    }
    onSelectInstall(value: number) {
        this.table23model.Installation = value;

        //  alert(this.drBunchingCore);
        if (this.drBunchingCore == 1) {
            if (value == 4 || value == 8) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                ];
                if (value == 4) {
                    if (this.table23model.Cables > 2) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                    ];
                }
                else {
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                        { "name": "3 ccts", "value": 3 },
                    ];
                }
            }
            else {
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                    { "name": "3 rows", "value": 3 },
                ];
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
            }
        }
        else {
            if (value == 7 || value == 8) {
                if (this.table23model.Row > 2)
                    this.table23model.Row = 1;

                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                ];
            }
            else {
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                    { "name": "3 rows", "value": 3 },
                ];
            }
            if (value == 2 || value == 4 || value == 6 || value == 8) {
                if (this.table23model.Cables > 6)
                    this.table23model.Cables = 1;
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    //{ "name": "5", "value": 5 },
                    { "name": "6", "value": 6 },
                ];
            }
            else {
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    //{ "name": "5", "value": 5 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
            }
        }
        this.GetTable23Dr(null);
    }
    updateWizardFactorValues() {
        this.wizardFactorInputList = [];
        var stepId = 1;
        var controlId = 1;
        var standardId = this.CableSize['calculationstandard'];
        this.CalculationReportInput.wizardValues = new DRFWizrd();
        this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];
        if (this.drInstallType == 1) {
            if (this.drBunchingType == 2) {
                /* control 1 */
                let arrangementName = this.drTable22ArrangementValues.find(x => x.value == this.table22model.Arrangement).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "Arrangement of Cables";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let circuitName = this.drTable22CircuitValues.find(x => x.value == this.table22model.NoOfCircuits).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "No of circuits";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.NoOfCircuits.toString();
                this.wizardFactorInput.controlText = circuitName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                if (this.table22model.Arrangement < 3)
                    this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                if (this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4) {
                    let addTypeName = this.table22model.AddType == 1 ? "Touching" : "Spaced";
                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = 1;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Bunched Circuits";
                    this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                    this.wizardFactorInput.controlId = 3;
                    this.wizardFactorInput.controlLabel = "Bunched arrangement";
                    this.wizardFactorInput.controlType = "radiobutton";
                    this.wizardFactorInput.controlValue = this.table22model.AddType.toString();
                    this.wizardFactorInput.controlText = addTypeName;
                    this.wizardFactorInput.defaultValue = "";
                    this.wizardFactorInput.useDefaultValue = false;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
                stepId += 1;
            }
            else if (this.drBunchingType == 3) {
                /* control 1 */
                let rowName = this.table23RowValues.find(x => x.value == this.table23model.Row).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "No. of tiers of rows of support";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Row.toString();
                this.wizardFactorInput.controlText = rowName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let cableName = this.table23CableValues.find(x => x.value == this.table23model.Cables).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "No. of circuits per tier or row" : "No. of cables";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Cables.toString();
                this.wizardFactorInput.controlText = cableName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 3 */
                let imgNameandDescription: any;
                if (this.drBunchingCore == 1)
                    imgNameandDescription = this.Table23InstallationValues.find(x => x.value == this.table23model.Installation);
                else
                    imgNameandDescription = this.Table24InstallationValues.find(x => x.value == this.table23model.Installation);

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 3;
                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "Single core - In trays, racks, cleats or other supports in air" : "Multi-core - In trays, racks, cleats or other supports in air";
                this.wizardFactorInput.controlType = "img";
                this.wizardFactorInput.controlValue = this.table23model.Installation.toString();
                this.wizardFactorInput.controlText = imgNameandDescription.name + ' ' + imgNameandDescription.desc;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.imageName = imgNameandDescription.name;
                this.wizardFactorInput.imageDesc = imgNameandDescription.desc;
                this.wizardFactorInput.imageTitle = imgNameandDescription.desc2;
                this.wizardFactorInput.imageSrc = imgNameandDescription.img;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                stepId += 1;
            }

            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 1;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Air temprature";
            this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 40 &#176;C" : "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AbientTemp.toString();
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 40 &#176;C" : "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                let conductorTempName = this.conductorTempValues.find(x => x.value == this.table271model.ConductorTemp).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Air temprature";
                this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Conductor Temprature";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table271model.ConductorTemp.toString();
                this.wizardFactorInput.controlText = conductorTempName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            //// Storing results
            var controlId = 1;
            if (this.drBunchingType != 1) {
                if (this.drTable22output && this.drBunchingType == 2) {
                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Derating Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Derating Factor:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Derating Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Arrangement of cables:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Bunched arrangement:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table22";
                    item.summary = $('#table22').text();;
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
                else if (this.table23output && this.drBunchingType == 3) {
                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Derating Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["Derating Factor:"];
                    this.wizardFactorInput.controlText = this.table23output["Derating Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Installation:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["Installation:"];
                    this.wizardFactorInput.controlText = this.table23output["Installation:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "No. of tiers or rows of supports:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.controlText = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new WizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Conductor size:";
                    this.wizardFactorInput.controlType = "label";
                    if (this.table23output['No.of circuits per tier or row:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No.of circuits per tier or row:'];
                        this.wizardFactorInput.controlText = this.table23output['No.of circuits per tier or row:'];
                    }
                    if (this.table23output['No. of multi-core cables:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No. of multi-core cables:'];
                        this.wizardFactorInput.controlText = this.table23output['No. of multi-core cables:'];
                    }
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table23";
                    item.summary = $('#table23').text();
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
            }
            if (this.drTable271output) {
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Air ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                var item = new TableItem();
                item.name = "Table27";
                if (this.drBunchingType == 1)
                    item.summary = $('#table27Air1').text();
                else
                    item.summary = $('#table27Air2').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }

            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = "Overall Derating Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

        }
        else if (this.drInstallType == 2) {
            let imageName: string = this.CableSize['description'].toLowerCase().trim();

            if (imageName === 'buried direct') {
                this.table25model.InstallType = 1;
                this.table28model.Buried = 1;
            }
            else {
                this.table28model.Buried = 2;
                if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure')
                    this.table25model.InstallType = 3;
                else if (imageName === 'buried enclosure separate')
                    this.table25model.InstallType = 2;
            }
            stepId = 1;
            /* control 1 */
            let noOfCircuitsName = this.table25NoOfCircuitValues.find(x => x.value == this.table25model.NoOfCircuits).name;
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table25model.InstallType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Buried groups";
            this.wizardFactorInput.stepTitle = "Groups of buried circuits";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "No. of circuits";
            this.wizardFactorInput.controlType = "dropdownlist";
            this.wizardFactorInput.controlValue = this.table25model.NoOfCircuits.toString();
            this.wizardFactorInput.controlText = noOfCircuitsName;
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = false;
            this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (this.table25model.NoOfCircuits > 1) {
                let arrangementName = this.table25ArrangementValues.find(x => x.value == this.table25model.Arrangement).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.table25model.InstallType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Buried groups";
                this.wizardFactorInput.stepTitle = "Groups of buried circuits";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Arrangement";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table25model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table28model.Buried;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Depth of burial";
            this.wizardFactorInput.stepTitle = "Depth of Burial (m)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Depth";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table28model.UseStandard) {
                this.wizardFactorInput.controlValue = "0.50";
                this.wizardFactorInput.controlText = "Use standard 0.5 m";
                this.wizardFactorInput.defaultValue = "Use standard 0.5 m";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table28model.Depth.toString();
                this.wizardFactorInput.controlText = this.table28model.Depth.toString();
                this.wizardFactorInput.defaultValue = "Use standard 0.5 m";
                this.wizardFactorInput.useDefaultValue = false;
                if (this.table28model.Buried == 1)
                    this.wizardFactorInput.isLastControl = false;
                else
                    this.wizardFactorInput.isLastControl = true;
            }
            this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            if (!this.table28model.UseStandard && this.table28model.Buried == 1) {
                /* control 2 */
                let conductorSize = this.table28ConductorSizeValues.find(x => x.value == this.table28model.ConductorSize).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.table28model.Buried;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Depth of burial";
                this.wizardFactorInput.stepTitle = "Depth of Burial (m)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Conductor Size";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table28model.ConductorSize.toString();
                this.wizardFactorInput.controlText = conductorSize;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = null;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil resistivity";
            this.wizardFactorInput.stepTitle = "Soil thermal resistivity";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Resistivity";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table29model.UseStandard) {
                this.wizardFactorInput.controlValue = "1.2";
                this.wizardFactorInput.controlText = "Use standard 1.2 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "1.2";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table29model.Resitivity.toString();
                this.wizardFactorInput.controlText = "Use standard 1.2 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "1.2";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table29model.UseStandard) {
                let installation = this.table29InstallationValues.find(x => x.value == this.table29model.Installation).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = null;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Soil resistivity";
                this.wizardFactorInput.stepTitle = "Soil thermal resistivity";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Type of installation";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table29model.Installation.toString();
                this.wizardFactorInput.controlText = installation;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 2;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = null;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil temprature";
            this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";;
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 25 &#176;C" : "Use standard 15 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AbientTemp.toString();
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 25 &#176;C" : "Use standard 15 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                let conductorTempName = this.conductorTempValues.find(x => x.value == this.table271model.ConductorTemp).name;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 2;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = null;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Soil temprature";
                this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Conductor Temprature";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table271model.ConductorTemp.toString();
                this.wizardFactorInput.controlText = conductorTempName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }

            /* Storing Results */
            controlId = 1;
            if (this.drTable25output) {
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.drTable25output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Spacing (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.controlText = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "No. of circuits:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.controlText = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table25";
                item.summary = $('#table25').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table28output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.table28output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Depth of burial (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.controlText = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference depth (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.controlText = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor size:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Conductor size:"];
                this.wizardFactorInput.controlText = this.table28output["Conductor size:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                /////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table28";
                item.summary = $('#table28').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table29output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.table29output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Thermal resistivity of soil (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference resistivity (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Type of installation:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Type of installation:"];
                this.wizardFactorInput.controlText = this.table29output["Type of installation:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table29";
                item.summary = $('#table29').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable271output) {
                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Soil ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new WizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27Soil').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            controlId += 1;
            this.wizardFactorInput = new WizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = 5;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = "Overall Derating Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);
        }
        this.OriDrfWizard = this.CalculationReportInput.wizardValues;
        this._wizardService.storeWizardFactor(this.wizardFactorInputList).subscribe((result) => {
            this.updateDeratingFactorValue(true, this.CalculationReportInput.wizardValues);
        }, error => { });
    }
    OnChangeCore(e: any) {
        // setTimeout(() => {
        if (this.drBunchingCore == 1) {
            this.table23model.CableType = 1;
            this.table25model.CableType = 1;
            this.table28model.Core = 1;
            this.GetTable23Values();
            this.table23CableValues = [
                { "name": "1 cct", "value": 1 },
                { "name": "2 ccts", "value": 2 },
                { "name": "3 ccts", "value": 3 },
            ];
            this.table25ArrangementValues = [
                { "name": "Touching trefoil", "value": 1 },
                { "name": "Touching flat", "value": 2 },
                { "name": "Spaced 0.15 m", "value": 3 },
                { "name": "Spaced 0.30 m", "value": 4 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        else {
            this.table23model.CableType = 2;
            this.table25model.CableType = 2;
            this.table28model.Core = 2;
            this.GetTable24Values();
            this.table23CableValues = [
                { "name": "1", "value": 1 },
                { "name": "2", "value": 2 },
                { "name": "3", "value": 3 },
                { "name": "4", "value": 4 },
                //{ "name": "5", "value": 5 },
                { "name": "6", "value": 6 },
                { "name": "9", "value": 9 },
            ];
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.15 m", "value": 3 },
                { "name": "Spaced 0.30 m", "value": 4 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }

        this.onSelectInstall(this.table23model.Installation);

        // }, 200);
    }
    OnNext() {
        this.setWizardFactorDefaultValues();
        if (this.drInstallType == 1 && this.wizard.currentStep == 1) {

            if (this.drBunchingType == 2) {
                $('#divBunchingType2').css('display', 'block');
                $('#divBunchingType3').css('display', 'none');
            }
            else if (this.drBunchingType == 3) {
                $('#divBunchingType3').css('display', 'block');
                $('#divBunchingType2').css('display', 'none');
            }
        }
        if (this.drInstallType == 1 && this.drBunchingType != 1 && this.wizard.currentStep == 3) {
            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        setTimeout(() => {
            this.finalDrf = 1;
            if (this.drInstallType == 1) {
                if (this.drBunchingType != 1) {
                    if (this.drTable22output && this.drBunchingType == 2)
                        this.finalDrf = this.finalDrf * parseFloat(this.drTable22output["Derating Factor:"]);
                    else if (this.table23output && this.drBunchingType == 3)
                        this.finalDrf = this.finalDrf * parseFloat(this.table23output["Derating Factor:"]);
                }
                if (this.drTable271output) {
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable271output["Derating Factor:"]);
                    //$("#table27Air1").show();
                }
            }
            else if (this.drInstallType == 2) {
                if (this.drTable25output)
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable25output["Derating Factor:"]);
                if (this.table28output)
                    this.finalDrf = this.finalDrf * parseFloat(this.table28output["Derating Factor:"]);
                if (this.table29output)
                    this.finalDrf = this.finalDrf * parseFloat(this.table29output["Derating Factor:"]);
                if (this.drTable271output)
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable271output["Derating Factor:"]);
            }
            //  this.setWizardFactorDefaultValues();

        }, 250);
    }
    onResetControlValues() {

        var standard = parseInt(this.CableSize['calculationstandard']);
        var imageName = this.CableSize['description'].toLowerCase().trim();

        this.drInstallType = 1;
        if (imageName.search('buried') === -1) {
            this.drBunchingType = 1;
            $('#divBunchingType2').css('display', 'none');
            $('#divBunchingType3').css('display', 'none');

            this.table22model.Arrangement = 1;
            this.table22model.AddType = 1;
            this.table22model.NoOfCircuits = 1;
            // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
            this.table22model.drf = 1;

            this.table23model.CableType = this.drBunchingCore;
            this.table23model.Row = 1;
            this.table23model.Cables = 1;
            this.table23model.Installation = 1;
            this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;

            this.table271model.UseStandard = true;
            this.table271model.ConductorTemp = 75;
            this.table271model.drf = 1;
            this.table271model.AbientTemp = standard == 0 ? 40 : 30;

            this.conductorTempValues = [
                { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];

        }
        else {
            this.drInstallType = 2;
            this.conductorTempValues = [
                // { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];

            this.table25model.NoOfCircuits = 1;
            this.table25model.CableType = this.drBunchingCore;
            if (imageName === 'buried direct') {
                this.table25model.InstallType = 1;
                if (this.table25model.CableType == 1) {
                    this.table25ArrangementValues = [
                        { "name": "Touching trefoil", "value": 1 },
                        { "name": "Touching flat", "value": 2 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                else {
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
            }
            else {
                if (imageName === 'buried enclosure separate') {
                    this.table25model.InstallType = 2;
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                else if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure') {
                    this.table25model.InstallType = 3;
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
            }

            this.table25model.TouchingType = 1;
            this.table25model.Distance = 0;
            this.table25model.drf = 1;
            this.table25model.Arrangement = 1;
            this.setTable25Values(this.table25model.Arrangement);

            this.table28model.Depth = 0.5;
            this.table28model.UseStandard = true;
            if (imageName.toLowerCase().trim() === 'buried direct')
                this.table28model.Buried = 1;
            else
                this.table28model.Buried = 2;
            this.table28model.ConductorSize = 3;
            this.table28model.Core = this.drBunchingCore;
            this.table28model.drf = 1;

            this.table29model.UseStandard = true;
            this.table29model.Resitivity = 1.20;
            this.table29model.Installation = 2;
            this.table29model.drf = 1;

            this.table271model.UseStandard = true;
            this.table271model.ConductorTemp = 75;
            //(<any>$('#drTable271ConductorTempSoil')).select2('val', '75');
            this.table271model.drf = 1;
            this.table271model.AbientTemp = standard == 0 ? 25 : 15;
        }

        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;
        this.drTable271output = undefined;
        this.wizardFactorInputList = [];
        this.wizardFactorOutput = [];
    }
    onCancel() {
        //console.log('onCancel-De Rating Wizard');

        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;

        let imageName = this.CableSize['description'].toLowerCase().trim();

        var standard = parseInt(this.CableSize['calculationstandard']);

        this.drBunchingType = 1;
        this.drInstallType = 1;
        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');

        if (imageName.search('buried') === -1) {
            $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');
            $("#m_wizard_form_step_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
        }
        else {
            this.drInstallType = 2;
            $('#m_wizard').css('display', 'none');
            $('#m_wizard_soil').css('display', 'block');
            $("#m_wizard_form_step_soil_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_4").removeClass('m-wizard__form-step--current');
        }

        this.table22model.Arrangement = 1;
        this.table22model.AddType = 1;
        this.table22model.NoOfCircuits = 1;
        // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
        this.table22model.drf = 1;

        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;

        this.table271model.UseStandard = true;
        this.table271model.ConductorTemp = 75;
        if (this.drInstallType == 1)
            (<any>$('#drTable271ConductorTempAir1')).select2('val', '75');
        else
            (<any>$('#drTable271ConductorTempSoil')).select2('val', '75');
        this.table271model.drf = 1;
        this.table271model.AbientTemp = this.drInstallType == 1 ? (standard == 0 ? 40 : 30) : (standard == 0 ? 25 : 15);

        /* In ground controls reset */
        this.table25model.NoOfCircuits = 1;
        this.table25model.CableType = this.drBunchingCore;
        if (imageName.toLowerCase().trim() === 'buried direct') {
            this.table25model.InstallType = 1;
        }
        else {
            if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure')
                this.table25model.InstallType = 3;
            else if (imageName === 'buried enclosure separate')
                this.table25model.InstallType = 2;
        }
        this.table25model.TouchingType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;

        if (imageName.toLowerCase().trim() === 'buried direct') {
            this.table28model.Buried = 1;
        }
        else {
            this.table28model.Buried = 2;
        }
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.5;
        this.table28model.ConductorSize = 3;
        this.table28model.Core = this.drBunchingCore;
        this.table28model.drf = 1;

        this.table29model.UseStandard = true;
        this.table29model.Resitivity = 1.20;
        this.table29model.Installation = 2;
        this.table29model.drf = 1;



        if (this.drInstallType == 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
        }
        else {
            this.wizard = (<any>$('#m_wizard_soil')).mWizard({
                startStep: 1,
            });
        }

        this.wizard.goTo(1);
    }
    OnSubmit() {
        this.UserWizard = true;
        this.CableSize['isderatingwiz'] = "1";

        // $('#checkboxUseWizard').attr('checked', 'true');
        //console.log('OnSubmit-De Rating Wizard');
        $('#deratingFactor').val(this.finalDrf.toFixed(2)).change();
        $('#deratingWizard').modal('toggle');
        $('#startStep1').click();
        this.CableSize['deratingfactor'] = this.finalDrf.toFixed(2);
        this.getAllControlsValue();
        this.updateWizardFactorValues();
        this.SetFirstWizardScreen();
    }
    onClose() {
        //if (this.wizardFactorOutput.length == 0 && this.wizardFactorInputList.length == 0) {
        //    this.UserWizard = false;
        //    $('#checkboxUseWizard').removeAttr('checked');
        //}
        this.SetFirstWizardScreen();
    }
    setInstallationType() {
        let newImageName = this.CableSize['description'].toLowerCase().trim();

        if (newImageName.search('buried') === -1)
            this.drInstallType = 1;
        else
            this.drInstallType = 2;
    }
    SetFirstWizardScreen() {
        this.setInstallationType();
        if (this.drInstallType == 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
            $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');
            $("#m_wizard_form_step_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        else if (this.drInstallType === 2) {
            this.wizard = (<any>$('#m_wizard_soil')).mWizard({
                startStep: 1,
            });
            $('#m_wizard').css('display', 'none');
            $('#m_wizard_soil').css('display', 'block');
            $("#m_wizard_form_step_soil_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_4").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard_soil')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        this.wizard.goTo(1);
    }
    OnChangeBunchingType(bunchingType: number) {

        if (bunchingType == 1)
            this.wizard.totalSteps = 3;
        else
            this.wizard.totalSteps = 4;

        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');

        if (bunchingType == 2)
            $('#divBunchingType2').css('display', 'block');
        else if (bunchingType == 3)
            $('#divBunchingType3').css('display', 'block');
    }
    EmailReportChanged() {
        setTimeout(() => {
            if (this.CalculationReportInput.sendEmail) {
                $('#chkEmailReport').attr('checked', 'true');
            } else {
                $('#chkEmailReport').removeAttr('checked');
            }
        }, 100);
    }

    ShowNameChanged() {
        setTimeout(() => {
            if (this.CalculationReportInput.printName) {
                $('#chkPrintName').attr('checked', 'true');
            } else {
                $('#chkPrintName').removeAttr('checked');
            }
        }, 100);
    }
    rebind() {
        //setTimeout(() => {
        //    this.calculationName = this.route.snapshot.queryParamMap.get('cname');
        //    this.projectName = this.route.snapshot.queryParamMap.get('name');
        //    this.route.params.subscribe((params) => {
        //        
        //        this.newCalculationId = parseInt(params.cid);
        //        this.projectId = parseInt(params.pid);
        //        this.GetData('Load', '', 0, 0, 0, 0, 0, 0, 0, '', '', this.newCalculationId, 0, 0);
        //    });            
        //}, 250);
    }
    UpdateXmlValues(data: CableproXmlInput) {
        //console.log('UpdateXmlValues');
        this.showLoader();
        this._CableproService.storeXml(data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            //console.log("There is no internet connection");
            this.hideLoader();
        });
    }
    UpdateVoltageLabelValue() {
        //  
        //console.log('UpdateVoltageStatus');
        if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null || this.voltageSupplyLabel.trim() == '')
            return false;

        if (this.newCalculationId === undefined || this.newCalculationId === null || this.newCalculationId == 0)
            return false;

        let isVoltageDrop: boolean = (this.voltageSupplyLabel == 'drop');

        this.highlighttext(this.Dictatingfactorres);

        this._calculationService.updateVoltageStatus(this.newCalculationId, isVoltageDrop).subscribe((result) => {

        }, error => {
            //console.log("There is no internet connection");
        });
    }
    CableSizeValuesUpdate(): boolean {
        if (this.PrevCableSize === null) {
            this.PrevCableSize = {
                insulation: '0',
                conductor: '0',
                earthconductor: '0',
                core: '0',
                phase: '0',
                faultlevel: '3',
                supplyvoltage: '230',
                voltagedrop: '2.5',
                powerunit: '1',
                efficiency: '100',
                lengthofrun: '50',
                current: '100',
                powerfactor: '0.9',
                unit: '0',
                deratingfactor: '1',
                device: '0',
                usevd: '1',
                protection: {
                    shortcircuittime: '0.1'
                },
                installtablewind: { wind: '' },
                specifyconductor: {
                    phase: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    neutral: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    earth: {
                        mode: '1',
                        size: '0',
                        circuits: '1'
                    }
                },
                tableno: '4',
                columnno: '0',
                startingrowindex: '0',
                endingrowindex: '0',
                description: 'Unenclosed Spaced',
                tablectrlvalue: '0',
                voltagefactor: '1.1',
                calculationstandard: '0',
                conduitsize: {
                    mode: '0',
                    size: '0'
                }
            };

            this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
            this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
            this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
            this.PrevCableSize['insulation'] = this.CableSize['insulation'];
            this.PrevCableSize['conductor'] = this.CableSize['conductor'];
            this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
            this.PrevCableSize['core'] = this.CableSize['core'];
            this.PrevCableSize['phase'] = this.CableSize['phase'];
            this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
            this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
            this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
            this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
            this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];

            this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
            this.PrevCableSize['current'] = this.CableSize['current'];
            this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
            this.PrevCableSize['unit'] = this.CableSize['unit'];
            this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
            this.PrevCableSize['device'] = this.CableSize['device'];
            this.PrevCableSize['usevd'] = this.CableSize['usevd'];
            //
            this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
            this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
            this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
            this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
            this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
            this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
            this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
            this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
            this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
            this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
            this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
            this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
            this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];
            this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];

            this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
            this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
            this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];

            this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits'];
            this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
            this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];

            this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
            this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
            this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];

            this.PrevCableSize['tableno'] = this.CableSize['tableno'];
            this.PrevCableSize['columnno'] = this.CableSize['columnno'];
            this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
            this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex'];
            this.PrevCableSize['description'] = this.CableSize['description'];
            this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue'];
            this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor'];
            this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
            this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
            this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];
            this.PrevCableSize['conduitsize']['mode'] = this.CableSize['conduitsize']['mode'];
            this.PrevCableSize['conduitsize']['size'] = this.CableSize['conduitsize']['size'];
            //console.log(this.PrevCableSize['protection']['thermalsetting'] + this.CableSize['protection']['thermalsetting'] + 'isnull');
            return true;
        }
        else {
            if (this.forceCalc == true) {
                return true;
            }
            //console.log(this.PrevCableSize['protection']['thermalsetting'] + this.CableSize['protection']['thermalsetting'] + 'before');
            if (this.PrevCableSize['dcvoltage'] != this.CableSize['dcvoltage']) {
                this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
                return true;
            }

            if (this.PrevCableSize['onephasevoltage'] != this.CableSize['onephasevoltage']) {
                this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['threephasevoltage'] != this.CableSize['threephasevoltage']) {
                this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['insulation'] != this.CableSize['insulation']) {
                this.PrevCableSize['insulation'] = this.CableSize['insulation'];
                return true;
            }

            if (this.PrevCableSize['conductor'] != this.CableSize['conductor']) {
                this.PrevCableSize['conductor'] = this.CableSize['conductor'];
                return true;
            }
            if (this.PrevCableSize['earthconductor'] != this.CableSize['earthconductor']) {
                this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
                return true;
            }

            if (this.PrevCableSize['core'] != this.CableSize['core']) {
                this.PrevCableSize['core'] = this.CableSize['core'];
                return true;
            }

            if (this.PrevCableSize['phase'] != this.CableSize['phase']) {
                this.PrevCableSize['phase'] = this.CableSize['phase'];
                return true;
            }

            if (this.PrevCableSize['faultlevel'] != this.CableSize['faultlevel']) {
                this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
                return true;
            }

            if (this.PrevCableSize['supplyvoltage'] != this.CableSize['supplyvoltage']) {
                this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
                return true;
            }

            if (this.PrevCableSize['voltagedrop'] != this.CableSize['voltagedrop']) {
                this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
                return true;
            }
            if (this.PrevCableSize['powerunit'] != this.CableSize['powerunit']) {
                this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
                return true;
            }
            if (this.PrevCableSize['efficiency'] != this.CableSize['efficiency']) {
                this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];
                return true;
            }

            if (this.PrevCableSize['lengthofrun'] != this.CableSize['lengthofrun']) {
                this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
                return true;
            }

            if (this.PrevCableSize['current'] != this.CableSize['current']) {
                this.PrevCableSize['current'] = this.CableSize['current'];
                return true;
            }

            if (this.PrevCableSize['powerfactor'] != this.CableSize['powerfactor']) {
                this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
                return true;
            }

            if (this.PrevCableSize['unit'] != this.CableSize['unit']) {
                this.PrevCableSize['unit'] = this.CableSize['unit'];
                return true;
            }

            if (this.PrevCableSize['deratingfactor'] != this.CableSize['deratingfactor']) {
                this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
                return true;
            }

            if (this.PrevCableSize['device'] != this.CableSize['device']) {
                this.PrevCableSize['device'] = this.CableSize['device'];
                return true;
            }

            if (this.PrevCableSize['usevd'] != this.CableSize['usevd']) {
                this.PrevCableSize['usevd'] = this.CableSize['usevd'];
                return true;
            }

            let protectionValuesUpdateCount: number = 0;

            if (this.PrevCableSize['protection']['shortcircuittime'] != this.CableSize['protection']['shortcircuittime']) {
                this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCBrating'] != this.CableSize['protection']['MCBrating']) {
                this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['ACBrating'] != this.CableSize['protection']['ACBrating']) {
                this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['HRCFuserating'] != this.CableSize['protection']['HRCFuserating']) {
                this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCCBrating'] != this.CableSize['protection']['MCCBrating']) {
                this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['curve'] != this.CableSize['protection']['curve']) {
                this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['earthfaulttime'] != this.CableSize['protection']['earthfaulttime']) {
                this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['rcd'] != this.CableSize['protection']['rcd']) {
                this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripcurrent'] != this.CableSize['protection']['tripcurrent']) {
                this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripmultiplier'] != this.CableSize['protection']['tripmultiplier']) {
                this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['thermalsetting'] != this.CableSize['protection']['thermalsetting']) {
                this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['customrating'] != this.CableSize['protection']['customrating']) {
                this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['description'] != this.CableSize['protection']['description']) {
                this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];
                protectionValuesUpdateCount += 1;
            }

            let specifyConductorValuesUpdateCount: number = 0;

            if (this.PrevCableSize['specifyconductor']['phase']['mode'] != this.CableSize['specifyconductor']['phase']['mode']) {
                this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['circuits'] != this.CableSize['specifyconductor']['phase']['circuits']) {
                this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['size'] != this.CableSize['specifyconductor']['phase']['size']) {
                this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['circuits'] != this.CableSize['specifyconductor']['neutral']['circuits']) {
                this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits']
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['size'] != this.CableSize['specifyconductor']['neutral']['size']) {
                this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['mode'] != this.CableSize['specifyconductor']['neutral']['mode']) {
                this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['mode'] != this.CableSize['specifyconductor']['earth']['mode']) {
                this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['size'] != this.CableSize['specifyconductor']['earth']['size']) {
                this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['circuits'] != this.CableSize['specifyconductor']['earth']['circuits']) {
                this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['installtablewind']['wind'] != this.CableSize['installtablewind']['wind']) {
                this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];
                return true;
            }

            if (this.PrevCableSize['tableno'] != this.CableSize['tableno']) {
                this.PrevCableSize['tableno'] = this.CableSize['tableno'];
                return true;
            }

            if (this.PrevCableSize['columnno'] != this.CableSize['columnno']) {
                this.PrevCableSize['columnno'] = this.CableSize['columnno'];
                return true;
            }

            if (this.PrevCableSize['startingrowindex'] != this.CableSize['startingrowindex']) {
                this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
                return true;
            }

            if (this.PrevCableSize['endingrowindex'] != this.CableSize['endingrowindex']) {
                this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex']
                return true;
            }

            if (this.PrevCableSize['description'] != this.CableSize['description']) {
                this.PrevCableSize['description'] = this.CableSize['description']
                return true;
            }

            if (this.PrevCableSize['tablectrlvalue'] != this.CableSize['tablectrlvalue']) {
                this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue']
                return true;
            }

            if (this.PrevCableSize['voltagefactor'] != this.CableSize['voltagefactor']) {
                this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor']
                return true;
            }

            if (this.PrevCableSize['calculationstandard'] != this.CableSize['calculationstandard']) {
                this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
                return true;
            }
            if (this.PrevCableSize['isderatingwiz'] != this.CableSize['isderatingwiz']) {
                this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
                return true;
            }
            if (this.PrevCableSize['deratingtemp'] != this.CableSize['deratingtemp']) {
                this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];
                return true;
            }

            if (this.PrevCableSize['conduitsize']['mode'] != this.CableSize['conduitsize']['mode']) {
                this.PrevCableSize['conduitsize']['mode'] = this.CableSize['conduitsize']['mode'];
                return true;
            }

            if (this.PrevCableSize['conduitsize']['size'] != this.CableSize['conduitsize']['size']) {
                this.PrevCableSize['conduitsize']['size'] = this.CableSize['conduitsize']['size'];
                return true;
            }

            //this.hideLoader();
            //console.log(this.PrevCableSize['protection']['thermalsetting'] + this.CableSize['protection']['thermalsetting'] + 'after');
            if (specifyConductorValuesUpdateCount > 0 || protectionValuesUpdateCount > 0)
                return true;
            else
                return false;
        }
    }
    onddlEarthConductorChange(value, isTriggered: any = "false") {
        this.CableproInput.EarthConductorId = value;

        if (isTriggered === "true") {
            this.CableSize['earthconductor'] = this.PrevEarthConductorId.toString();
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
        else {
            this.PrevEarthConductorId = value;
            this.CableSize['earthconductor'] = value;
            this.getAllControlsValue();

        }
    }
    IsReportEmailValid(): boolean {

        let isValidFlag: boolean = true;

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        if ($('#chkEmailReport').is(':checked')) {

            let reportEmailAddress: string = $('#sendEmail').val().toString().trim();
            let reportEmailAddressArray: string[] = reportEmailAddress.split(',');

            let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,13}\b$/i;

            if (reportEmailAddress.length === 0) {
                isValidFlag = false;
                $('#sendEmailError1').css('display', '');
            }
            else if (reportEmailAddressArray.length > 0) {
                for (let i = 0; i < reportEmailAddressArray.length; i++) {
                    if (reportEmailAddressArray[i].trim().length > 0 && !emailPattern.test(reportEmailAddressArray[i].trim())) {
                        isValidFlag = false;
                        $('#sendEmailError2').css('display', '');
                        break;
                    }
                }
            }
            else if (!emailPattern.test(reportEmailAddress)) {
                isValidFlag = false;
                $('#sendEmailError2').css('display', '');
            }

            $('#sendEmail').focus();
        }
        return isValidFlag;
    }

    IsReportFormValid(): boolean {

        let isValidFlag: boolean = true;

        $('#RptProjectName').css('display', 'none');
        $('#RptCalculationName').css('display', 'none');

        if ($('#chkPrintName').is(':checked')) {

            let rptProjectName: string = $('#project-name').val().toString().trim();

            if (rptProjectName.length === 0) {
                isValidFlag = false;
                $('#RptProjectName').css('display', '');
                $('#project-name').focus();
                return isValidFlag;
            }

            let rptCalculationName: string = $('#job-number').val().toString().trim();

            if (rptCalculationName.length === 0) {
                isValidFlag = false;
                $('#RptCalculationName').css('display', '');
                $('#job-number').focus();
            }

        }
        return isValidFlag;
    }

    ngOnDestroy() {
        this.toRemoveData.unsubscribe();
        this.newCalculationId = null;
        this._calculationService.setPrevValue('').subscribe((result) => { }, error => { });
        if (this.actionSubscription) {
            this.actionSubscription.unsubscribe();
        }
    }
    /* to copy object and avoid global value passing*/
    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    RemoveTrailingZeros(numberWithZero: string): string {
        try {
            if (numberWithZero.indexOf('.') != -1) {
                var splitnumberList = numberWithZero.split('.');

                let afterDecimalValue = parseInt(splitnumberList[1]);

                if (afterDecimalValue < 10)
                    return parseFloat(numberWithZero).toString();
                else {
                    let modulusValue = afterDecimalValue % 10;

                    if (modulusValue == 0)
                        return parseFloat(numberWithZero).toFixed(1);
                    else
                        return parseFloat(numberWithZero).toFixed(2);
                }
            }
            else
                return parseInt(numberWithZero).toString();
        } catch (e) {
            return numberWithZero;

        }
    }
    setWizardFactorDefaultValues() {

        let stepId = this.wizard.currentStep;
        let callby = ($('#m_wizard_soil .m-wizard__step--current:first')).find('.m-wizard__step-label').text().trim().toLowerCase();
        if (this.drInstallType == 1)
            callby = ($('#m_wizard .m-wizard__step--current')).find('.m-wizard__step-label').text().trim().toLowerCase();
        switch (stepId) {
            case 1:
                //  this.drBunchingType = 1;
                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                    // $('#drTable22NoOfCircuits').trigger('change');
                    if ((this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                        if (this.table22model.AddType == 1)
                            $('#drTable22AddType1').click();
                        else
                            $('#drTable22AddType2').click();
                    }
                    this.GetTable22Dr();
                    //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                }
                else if (callby === 'in trays') { /* In air - Cable Trays */
                    this.GetTable23Dr(null);
                }
                else if (callby === 'buried groups' || callby === 'arrangement  buried groups') { /* In ground - Buried Circuits */
                }
                else if (this.drInstallType == 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                    /* control 1 */

                    if (this.drBunchingType == 1) {
                        $('#inputUseStandardAir1').trigger('change');
                        $('#drTable271UseStandardAir1').trigger('change');
                    }
                    else {
                        $('#inputUseStandardAir2').trigger('change');
                        $('#drTable271UseStandardAir2').trigger('change');
                    }
                    //}
                    if (!this.table271model.UseStandard) {/* control 2 */
                        //  this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        if (this.drBunchingType == 1)
                            $('#drTable271ConductorTempAir1').trigger('change');
                    }
                    //if (wizardFactor.isLastControl) {
                    this.GetTable271Dr(null);
                    //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    //}
                }
                break;
            case 2:
            case 4:
                if (stepId === 2) {
                    if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                        // $('#drTable22NoOfCircuits').trigger('change');
                        if ((this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                            if (this.table22model.AddType == 1)
                                $('#drTable22AddType1').click();
                            else
                                $('#drTable22AddType2').click();
                        }
                        this.GetTable22Dr();
                        //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    }

                    if (callby === 'in trays') { /* In air - Cable Trays */
                        this.GetTable23Dr(null);
                    }
                    if (this.drInstallType == 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                        //  if (wizardFactor.controlId == 1) {/* control 1 */

                        if (this.drBunchingType == 1) {
                            $('#inputUseStandardAir1').trigger('change');
                            $('#drTable271UseStandardAir1').trigger('change');
                        }
                        else {
                            $('#inputUseStandardAir2').trigger('change');
                            $('#drTable271UseStandardAir2').trigger('change');
                        }
                        //  }
                        if (!this.table271model.UseStandard) {/* control 2 */
                            // this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                            if (this.drBunchingType == 1)
                                $('#drTable271ConductorTempAir1').trigger('change');
                        }

                        this.GetTable271Dr(null);
                    }
                    else if (this.drInstallType === 2 && (callby === 'depth of burial' || callby === 'arrangement  depth of burial')) {/* In ground */
                        $('#inputDepth').trigger('change');
                        this.table28model.Core = this.drBunchingCore;
                        this.GetTable28Dr(null);
                    }
                }
                else if (stepId === 4 && this.drInstallType === 2 && (callby === 'soil temprature' || callby === 'soil temperature' || callby === 'arrangement  soil temperature')) {/* In ground */
                    //  if (wizardFactor.controlId == 1) {/* control 1 */
                    //this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                    //this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandardSoil').trigger('change');
                    $('#drTable271UseStandardSoil').trigger('change');
                    //   }
                    if (!this.table271model.UseStandard) {/* control 2 */
                        //this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        $('#drTable271ConductorTempSoil').trigger('change');
                    }
                    this.GetTable271Dr(null);
                }
                break;
            case 3:
                if (this.drInstallType == 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                    //  if (wizardFactor.controlId == 1) {/* control 1 */

                    if (this.drBunchingType == 1) {
                        $('#inputUseStandardAir1').trigger('change');
                        $('#drTable271UseStandardAir1').trigger('change');
                    }
                    else {
                        $('#inputUseStandardAir2').trigger('change');
                        $('#drTable271UseStandardAir2').trigger('change');
                    }
                    //  }
                    if (!this.table271model.UseStandard) {/* control 2 */
                        // this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        if (this.drBunchingType == 1)
                            $('#drTable271ConductorTempAir1').trigger('change');
                    }

                    this.GetTable271Dr(null);
                }
                if (this.drInstallType === 2 && (callby === 'soil resistivity' || callby === 'arrangement  soil resistivity')) {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                    //this.table29model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandardSoil').trigger('change');
                    $('#drTable29UseStandard').trigger('change');
                    // }                    
                    this.GetTable29Dr(null);
                    //this.table29model.drf = parseFloat(wizardFactor.overallWizardFactor);
                }
                break;
        }
    }


    setWizardFactorValues() {
        if (this.newCalculationId != null && this.newCalculationId != undefined) {
            this._wizardService.getWizardFactors(this.newCalculationId, this.drInstallType).subscribe(result => {
                if (result.items.length > 0) {
                    this.wizardFactorOutput = result.items;
                    for (let wizardFactor of this.wizardFactorOutput) {
                        let stepId = wizardFactor.stepId;
                        let callby = wizardFactor.stepName.toLowerCase();

                        switch (stepId) {
                            case 1:
                                this.drBunchingType = 1;
                                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                                    this.drBunchingType = 2;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                                    }
                                    if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                        // $('#drTable22NoOfCircuits').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.controlId == 3 && (this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                                        this.table22model.AddType = parseInt(wizardFactor.controlValue);
                                        if (this.table22model.AddType == 1)
                                            $('#drTable22AddType1').click();
                                        else
                                            $('#drTable22AddType2').click();
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable22Dr();
                                        //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (callby === 'in trays') { /* In air - Cable Trays */
                                    this.drBunchingType = 3;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table23model.Row = parseInt(wizardFactor.controlValue);
                                    }
                                    else if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table23model.Cables = parseInt(wizardFactor.controlValue);
                                    }
                                    else if (wizardFactor.controlId == 3) {/* control 3 */
                                        this.table23model.Installation = parseInt(wizardFactor.controlValue);
                                        this.onSelectInstall(this.table23model.Installation);
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.table23model.CableType = this.drBunchingCore;
                                        this.GetTable23Dr(null);
                                        //this.table23model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (callby === 'buried groups') { /* In ground - Buried Circuits */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table25model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                    }
                                    if (this.table25model.NoOfCircuits > 1 && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table25model.Arrangement = parseInt(wizardFactor.controlValue);
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.drInstallType = 2;
                                        this.table25model.CableType = this.drBunchingCore;
                                        //this.table25model.InstallType = wizardFactor.buriedType;
                                        this.GetTable25Dr();
                                        this.setTable25Values(this.table25model.Arrangement);
                                        //this.table25model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (wizardFactor.installationType == 1 && callby === 'air temprature') {/* In air */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                        if (this.drBunchingType == 1) {
                                            $('#inputUseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else {
                                            $('#inputUseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                        }
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                        if (this.drBunchingType == 1)
                                            $('#drTable271ConductorTempAir1').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable271Dr(null);
                                        //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                            case 2:
                            case 4:
                                if (stepId === 2) {
                                    if (wizardFactor.installationType == 1 && callby === 'air temprature') {/* In air */
                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                            this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                            if (this.drBunchingType == 1) {
                                                $('#inputUseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                                $('#drTable271UseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                            }
                                            else {
                                                $('#inputUseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                                $('#drTable271UseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                            }
                                        }
                                        else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                            this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                            if (this.drBunchingType == 1)
                                                $('#drTable271ConductorTempAir1').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        if (wizardFactor.isLastControl) {
                                            this.GetTable271Dr(null);
                                            //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                        }
                                    }
                                    else if (wizardFactor.installationType === 2 && callby === 'depth of burial') {/* In ground */
                                        //this.table28model.Buried = wizardFactor.buriedType;
                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                                            this.table28model.UseStandard = wizardFactor.useDefaultValue;
                                            $('#inputDepth').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else if (!this.table28model.UseStandard && this.table28model.Buried == 1 && wizardFactor.controlId == 2) {/* control 2 */
                                            this.table28model.ConductorSize = parseInt(wizardFactor.controlValue);
                                        }
                                        if (wizardFactor.isLastControl) {
                                            this.table28model.Core = this.drBunchingCore;
                                            this.GetTable28Dr(null);
                                            //this.table28model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                        }
                                    }
                                }
                                else if (stepId === 4 && wizardFactor.installationType === 2 && callby === 'soil temprature') {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                        $('#inputUseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                        $('#drTable271UseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                        $('#drTable271ConductorTempSoil').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable271Dr(null);
                                        //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                            case 3:
                                if (wizardFactor.installationType === 2 && callby === 'soil resistivity') {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                                        this.table29model.UseStandard = wizardFactor.useDefaultValue;
                                        $('#inputUseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                        $('#drTable29UseStandard').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (!this.table29model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table29model.Installation = parseInt(wizardFactor.controlValue);
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable29Dr(null);
                                        //this.table29model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                        }
                    }
                }

            });
        }
    }
    showWarningOnInstallationChange(methodIndex: any, tableId: any, name: string, description: string, callby: string) {
        if (callby == 'mainpage') {
            this.SelectInstallTableMethodIndex = methodIndex;
            this.SelectInstallTableTableId = tableId;
            this.SelectInstallTableName = name;
            this.SelectInstallTableDescription = description;
        }

        let newImageName = (name + ' ' + description).toLowerCase().trim();
        var mainInstallationType = 0;

        if (newImageName.search('buried') == -1)
            mainInstallationType = 1;
        else
            mainInstallationType = 2;
        if (callby == 'mainpage') {
            var standard = parseInt(this.CableSize['calculationstandard']);
            if (mainInstallationType == 1) {
                this.table271model.AbientTemp = standard == 0 ? 40 : 30;
            } else {
                this.table271model.AbientTemp = standard == 0 ? 25 : 15;
            }
            this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();
        }

        if (this.wizardFactorOutput.length > 0) {
            let wizardInstallationtype = this.wizardFactorOutput[0].installationType;

            if (wizardInstallationtype != mainInstallationType) {
                if (callby == 'mainpage')
                    this.onSelectInstallTable();

                this.UserWizard = false;
                this.CableSize['isderatingwiz'] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.onResetControlValues();
                this.resetWizardFactorValue();
            }
            else {
                if (callby == 'mainpage')
                    this.onSelectInstallTable();
                if (mainInstallationType == 2)
                    this.resetWizardControlValuesOnInstallationChange(newImageName);
                if (callby == 'getdata')
                    this.resetWizardFactorValue();
            }
        }
        else {
            if (callby == 'mainpage')
                this.onSelectInstallTable();
            if (callby == 'getdata')
                this.resetWizardFactorValue();
            this.onResetControlValues();
            this.loadPreviousValues();
        }
    }
    loadPreviousValues() {
        this.setWizardFactorValues();
    }
    resetWizardFactorValue() {
        if (this.finalDrf) {
            this.CalculationReportInput.wizardValues = new DRFWizrd();
            this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
            this.CalculationReportInput.wizardValues.result = [];
            this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);
        }
    }
    resetWizardControlValuesOnInstallationChange(newImageName: string) {
        var isInstallTypeChanged = false;

        if (newImageName === 'buried direct' && this.table25model.InstallType != 1) {
            this.table25model.InstallType = 1;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 1;
            this.table28model.Core = 1;
            this.table25model.CableType = this.drBunchingCore;
            isInstallTypeChanged = true;
            if (this.table25model.CableType == 1) {
                this.table25ArrangementValues = [
                    { "name": "Touching trefoil", "value": 1 },
                    { "name": "Touching flat", "value": 2 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
            }
            else {
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
            }
        }
        else if (newImageName === 'buried enclosure separate' && this.table25model.InstallType != 2) {
            this.table25model.InstallType = 2;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 2;
            this.table28model.Core = this.drBunchingCore;
            isInstallTypeChanged = true;
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        else if ((newImageName === 'buried enclosure combined' || newImageName === 'buried enclosure') && this.table25model.InstallType != 3) {
            this.table25model.InstallType = 3;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 2;
            this.table28model.Core = this.drBunchingCore;
            isInstallTypeChanged = true;
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.30 m", "value": 4 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        if (isInstallTypeChanged) {
            this.setTable25Values(this.table25model.Arrangement);
            this.GetTable28Dr(null);
        }
    }

    getShortCircuitCurrent(): string {
        const currentPhaseValue = this.CableproInput.PhaseId;
        let returnStr: string;
        if (this.Xml && currentPhaseValue) {
            switch (currentPhaseValue) {
                case 100:
                    returnStr = 'Ph.to-neutral short-cct';
                    if (this.Xml.Phase_To_Neutral_SC_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Phase_To_Neutral_SC_circuitCurrent.Val * 10) / 10;
                    break;
                case 101:
                    returnStr = 'Three Ph. Short-cct';
                    if (this.Xml.Three_phaseShort_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Three_phaseShort_circuitCurrent.Val * 10) / 10;
                    break;
                case 102:
                    returnStr = 'Active-to-neutral short-cct';
                    if (this.Xml.Phase_To_Neutral_SC_circuitCurrent)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.Phase_To_Neutral_SC_circuitCurrent.Val * 10) / 10;
                    break;
                case 103:
                    returnStr = 'Phase short-cct';
                    if (this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct.Val * 10) / 10;
                    break;
                case 104:
                    returnStr = 'Phase short-cct';
                    if (this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct)
                        this.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct = Math.round(this.Xml.ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct.Val * 10) / 10;
                    break;
                default:
                    returnStr = 'Phase short-cct';
                    break;
            }
        }
        return returnStr;
    }

    generateErrorsModel(errorStr) {
        this.warningErrorList = errorStr;
        if (this.warningErrorList && errorStr != "[]" && this.warningErrorList.length > 0) {
            this.warningErrorList = this.warningErrorList.filter(m => m.ErrorCode);
            if (this.warningErrorList && this.warningErrorList.length > 0) {
                this.warningErrorList = this.warningErrorList.sort((a, b) => a.ErrorCode < b.ErrorCode ? -1 : 1).sort((a, b) => a.Type < b.Type ? -1 : 1).sort((a, b) => parseInt(a.IsError) > parseInt(b.IsError) ? -1 : 1);
                this.warningErrorStrData = JSON.stringify(this.warningErrorList);
                this.displayErrorBtn = true;
                this.paginationConfig.totalItems = this.warningErrorList.length;
                let errors = this.warningErrorList.filter(m => m.IsError == "1");
                let warnings = this.warningErrorList.filter(m => m.IsError == "0");
                if (errors && errors.length > 0) {
                    this.warningErrorBtnText = "Errors (" + errors.length + ")";
                }
                else {
                    this.warningErrorBtnText = "Warnings (" + warnings.length + ")";
                }

                if ((errors && errors.length > 0) && (warnings && warnings.length > 0)) {
                    this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings (" + warnings.length + ")";
                }
                else if ((errors && errors.length > 0) && (!warnings || warnings.length == 0))
                    this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings";
                else if ((warnings && warnings.length > 0) && (!errors || errors.length == 0))
                    this.warningErrorHeader = "Errors and Warnings (" + warnings.length + ")";
                else {
                    this.warningErrorHeader = "Errors and Warnings";
                }
            }
        }
        else {
            this.displayErrorBtn = false;
        }

    }

    showWarningErrorPopup() {
        $('#warnings-errors-model-cablesize').modal('show');
    }

    pageChanged(event) {
        this.paginationConfig.currentPage = event;
    }

    getErrorMsg(error: string): string {
        let ret: string = '';
        if (error) {
            return error.replace(/\\"/g, '"').replace(/lessthansymbol/g, "<");
        }
        return ret;
    }

    onddlDeviceChange(value) {
        this.device = value;
        $(".tooltip").hide();
        let val: string = '';
        if (this.device == '0') {
            val = 'Unknown';
        } else if (this.device == '1') {
            val = 'MCB';
        } else if (this.device == '2') {
            val = 'MCCB';
        } else if (this.device == '3') {
            val = 'ACB';
        } else if (this.device == '4') {
            val = 'HRC Fuse';
        } else if (this.device == '5') {
            val = 'Custom';
        }
        this.CableSize['device'] = this.device;

        if (this.flag !== true) {
            this._calculationService.setPrevValue('-1').subscribe((result) => {
                this.GetData('protection', '', 0, 0, 0, 0, 0, 0, 0, '', val, this.newCalculationId, 0, 0, 0);

            }, error => { });
        }
        setTimeout(function () {
            $('#protection').find('select').each(function () {
                $(this).select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            });
        }, 1000);/**/
        this.showHideProtectionWarning();
    }

    onddlActiveChange(e) {
        this.CableproInput.ActiveId = e.id;
        this.PrevActiveValue = e.value;
        this.CableSize['specifyconductor']['phase']['mode'] = '1';
        this.CableSize['specifyconductor']['phase']['size'] = parseFloat(this.PrevActiveValue.replace(' mm²', ''));
        this.getAllControlsValue();
    }

    onddlConduitSizeChange(e) {
        this.CableproInput.ConduitSizeId = e.id;
        this.PrevConduitSize = e.id;
        this.CableSize['conduitsize']['mode'] = '1';
        this.CableSize['conduitsize']['size'] = e.id;
        this.getAllControlsValue();
    }

    onddlNeutralChange(e) {
        this.CableproInput.NeutralId = e.id;
        this.PrevNeutralValue = e.value;
        this.CableSize['specifyconductor']['neutral']['mode'] = '1';
        this.CableSize['specifyconductor']['neutral']['size'] = parseFloat(this.PrevNeutralValue);
        this.getAllControlsValue();
    }

    onddlEarthChange(e) {
        this.CableproInput.EarthId = e.id;
        let val = e.text;
        this.CableSize['specifyconductor']['earth']['mode'] = '2';
        this.CableSize['specifyconductor']['earth']['size'] = parseFloat(val);
        this.getAllControlsValue();
    }

    onddlPowerUnitChange(value, isTriggered = "false") {
        this.powerunit = value;
        this.currentUnit = value;
        if (this.currentUnit == "1") {
            if (this.voltageSupplyLabel == "rise") {
                this.lblcurrent = 'Output current';
            }
            else
                this.lblcurrent = 'Current';
        }
        else {
            if (this.voltageSupplyLabel == "rise") {
                this.lblcurrent = 'Output power';
            }
            else
                this.lblcurrent = 'Power';
        }


        if (this.currentUnit == "4") {
            this.showEfficency = true;
        }
        else {
            this.showEfficency = false;

        }

        if (this.currentUnit != null && this.currentUnit != "") {

            this.CableSize['powerunit'] = this.currentUnit;
            //console.log('onCurrentChange-getAllControlsValue');
            if (isTriggered != "true")
                this.getAllControlsValue();
        }
    }

    onddlPhaseChange(e) {
        this.CableproInput.PhaseId = e.id;
        if (this.CableproInput.PhaseId.toString() == '100') {
            this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
        } else if (this.CableproInput.PhaseId.toString() == '101') {
            this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        } else if (this.CableproInput.PhaseId.toString() == '102') {
            this.CableSize['supplyvoltage'] = this.dc_Voltage;
        } else if (this.CableproInput.PhaseId.toString() == '103') {
            this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        } else if (this.CableproInput.PhaseId.toString() == '104') {
            this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            if (this.CableSize['tableno'] == '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] == '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        }

        if (this.CableproInput.PhaseId.toString() == '100' || this.CableproInput.PhaseId.toString() == '102') {
            if ($('#neutral-toggle').prop('checked')) {
                $('#neutral-toggle').click();
            }
            this.NeutralDisabled = true;
            $('#neutral-toggle').attr('disabled', 'true');
            $('#Neutral_c').attr('disabled', 'true');

        } else {
            this.NeutralDisabled = false;
            $('#neutral-toggle').removeAttr('disabled');
        }
        this.voltage_txt = this.CableSize['supplyvoltage'];
        this.CableSize['phase'] = e.xmlValue.toString();// (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
        this.GetData('phase', this.CableproInput.PhaseId.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, 0, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.EarthConductorId);
    }

    onddlInsulationChange(e) {
        this.CableproInput.InsulationId = parseInt(e.id);
        if ((this.CableproInput.InsulationId == 102 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 2)) {
            this.PrevConductorId = 0;
        }
        if ((this.CableproInput.InsulationId == 100 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 103 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 104 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 107 && this.PrevConductorId == 3)) {
            this.PrevConductorId = 0;
        }

        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');
        this.CableSize['insulation'] = e.xmlValue.toString();//(e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
        this.GetData('insulation', e.id, 0, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, this.PrevConductorId, this.PrevCoreId, this.PrevEarthConductorId);
    }

    onddlConductorChange(value) {
        this.CableproInput.ConductorId = value;
        let obj = this.conductorData.find(m => m.id == this.CableproInput.ConductorId);
        if (obj) {
            this.PrevConductorId = obj.xmlValue;
            this.CableSize['conductor'] = obj.xmlValue.toString();// (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
            this.GetData('conductor', value, this.CableproInput.InsulationId, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, this.PrevCoreId, this.CableproInput.EarthConductorId);
        }
    }

    onddlCoreChange(e) {
        this.CableproInput.CoreId = parseInt(e.id);
        this.PrevCoreId = e.xmlValue;
        let bunchingCore: string = e.xmlValue.toString();
        this.CableSize['core'] = bunchingCore;
        if (bunchingCore == "0") {
            this.drBunchingCore = 1;
        }
        else
            this.drBunchingCore = 2;

        if (this.drInstallType == 1) {
            this.table23model.Row = 1;
            this.table23model.Cables = 1;
            this.table23model.Installation = 1;
            this.table23model.CableType = this.drBunchingCore;
            this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
            this.OnChangeCore(null);
            this.GetTable23Dr(null);
        }
        else {
            if (this.table25model.NoOfCircuits > 1) {
                this.table25model.CableType = this.drBunchingCore;
                this.setTable25Dr();
            }
            if (!this.table28model.UseStandard && this.table28model.Buried == 2)
                this.GetTable28Dr(null);
        }
        this.UserWizard = false;
        this.CableSize['isderatingwiz'] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');
        this.GetData('core', e.id.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.EarthConductorId);
    }

    onddlWindChange(e) {
        this.CableproInput.WindSpeedId = parseInt(e.id);
        this.CableSize['installtablewind']['wind'] = e.xmlValue.toString();
        this.getAllControlsValue();
    }

    onddldrTable22ArrangementChange(e) {
        this.table22model.Arrangement = parseInt(e.value);
        this.GetTable22Dr();
        if (this.table22model.Arrangement == 1) {
            this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
            { "name": 2, "value": 2 },
            { "name": 3, "value": 3 },
            { "name": 4, "value": 4 },
            { "name": 5, "value": 5 },
            { "name": 6, "value": 6 }];
        }
        else {
            this.drTable22CircuitValues = [
                { "name": 1, "value": 1 },
                { "name": 2, "value": 2 },
                { "name": 3, "value": 3 },
                { "name": 4, "value": 4 },
                { "name": 5, "value": 5 },
                { "name": 6, "value": 6 },
                { "name": 7, "value": 7 },
                { "name": 8, "value": 8 },
                { "name": 9, "value": 9 },
                { "name": 10, "value": 10 },
                { "name": 12, "value": 12 },
                { "name": 14, "value": 14 },
                { "name": 16, "value": 16 },
                { "name": 18, "value": 18 },
                { "name": "20 or more", "value": 20 }];
        }
        if (this.table22model.NoOfCircuits > 6 && this.table22model.Arrangement == 1) {
            this.table22model.NoOfCircuits = 6;
        }
    }

    onddldrTable22NoOfCircuitsChange(e) {
        this.table22model.NoOfCircuits = parseInt(e.value);
        if (this.table22model.NoOfCircuits == 1) {
            this.table22model.drf = 1;
            this.drTable22output = undefined;
            return false;
        }
        this.GetTable22Dr();
    }

    onddldrTable23RowChange(e) {
        this.table23model.Row = e.value;
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable23CableChange(e) {
        this.table23model.Cables = parseInt(e.value);
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable271ConductorChange(e) {
        this.table271model.ConductorTemp = parseInt(e.value);

        if (this.drInstallType == 2)
            $('#inputUseStandardSoil').removeAttr('disabled');
        else {
            if (this.drBunchingType == 1)
                $('#inputUseStandardAir1').removeAttr('disabled');
            else
                $('#inputUseStandardAir2').removeAttr('disabled');
        }

        var standard = parseInt(this.CableSize['calculationstandard']);

        if ((this.table271model.ConductorTemp > 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 10) ||
            (this.table271model.ConductorTemp < 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 5)) {

            if (this.table271model.ConductorTemp > 100) {
                this.table271model.AbientTemp = this.table271model.ConductorTemp - 10;
            }
            else {
                this.table271model.AbientTemp = this.table271model.ConductorTemp - 5;
            }
        }
        if (this.table271model.AbientTemp < 15) {
            this.table271model.AbientTemp = 15;
        }
        this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
            this.table271model.drf = parseFloat(result["Derating Factor:"]);
            if (this.table271model.UseStandard != true) {
                this.drTable271output = result;
            }
        });
    }

    onddldrTable25NoOfCicruitsChange(e) {
        this.table25model.NoOfCircuits = parseInt(e.value);
        this.GetTable25Dr();
        this.setTable25Values(this.table25model.Arrangement);
    }

    onddldrTable25ArrangementChange(e) {
        this.table25model.Arrangement = parseInt(e.value);
        this.setTable25Values(e.value);
    }

    onddldrTable28CondutorSizeChange(e) {
        this.table28model.ConductorSize = parseInt(e.value);
        this.setTable28Dr();
    }

    onddldrTable29InstallationChange(e) {
        this.table29model.Installation = parseInt(e.value);
        this.GetTable29Dr(null);
    }

    onddlEarthFaultTimeChange(e) {
        this.earthfaulttime = parseInt(e.value);
        this.CableSize['protection']['earthfaulttime'] = e.value.toString();
        this.getAllControlsValue();
    }

    onddlProtectionCurveChange(e) {
        this.protection_curve = parseInt(e.value);

        this.CableSize['protection']['curve'] = e.value.toString();
        this.PrevCurveVal = e.value.toString();

        if (this.CableSize['protection']['curve'] == '0') {
            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('4');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseInt(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        } else if (this.CableSize['protection']['curve'] == '1') {

            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('7.5');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseFloat(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        } else {

            // if (isTriggered != 'false')
            $('input[caption="Trip Multiplier"]').val('15');
            let trip = $('input[caption="Trip Multiplier"]').val().toString();
            let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
            if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                rat = parseFloat(rat);
            } else {
                rat = parseInt(rat);
            }
            let count = parseInt(trip) * rat;
            $('input').filter("[caption='Trip current']").val(count);
            this.CableSize['protection']['tripcurrent'] = count;
            this.CableSize['protection']['tripmultiplier'] = trip;
        }
        this.getAllControlsValue();
    }

    select2Dropdown() {
        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }
    }

    private inputSubject = new Subject<any>();
    GetTable271DrInputChange(e: any, type: any = '', checkdecimalplaces: boolean = false) {
        this.inputSubject.next({ e: e, type: type, checkdecimalplaces: checkdecimalplaces });
    }

    public deleteVisible: Boolean = false;
    public defaultLogoImagePath = '/assets/common/images/Nologo.png';
    removeLogo(): void {
        // this.CalculationReportInput.companyLogo_LogoUrl = '';
        this.CalculationReportInput.companyLogo_FileName = '';
        this.companyLogo_FileName = undefined;
        this.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
        this.deleteVisible = false;
    }

    openfileControl(): void {
        $('#fileupload').click();
    }
    private baseUrl: string;
    public btnuploadDisabled: boolean = false;
    public company_Logo_LogoPresignedUrl: string = '/assets/common/images/Nologo.png';
    SetLogoImage(event: any) {
        let url_ = this.baseUrl + '/api/services/app/Setting/CreateFile';
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            if (!this.validateFile(file.name)) {
                return false;
            }
            else {
                let resizedFile: File;
                var utils = new ImageUtils();
                utils.resizeImage(file, 600, 600).then(blob => {
                    //You can upload the resized image: doUpload(blob)
                    resizedFile = utils.blobToFile(blob, file.name);
                    let formData: FormData = new FormData();
                    formData.append('file', resizedFile, file.name);
                    // formData.append('file', file, file.name);
                    let headers = new Headers();
                    headers.append('Content-Type', 'multipart/form-data');
                    headers.append('Accept', 'application/json');
                    this.btnuploadDisabled = true;
                    this.getUploadResponse(url_, formData).subscribe(result => {
                        this.settingOutput = result;
                        if (this.settingOutput !== undefined) {
                            // this.CalculationReportInput.companyLogo_LogoUrl = this.settingOutput['result'].companyLogo_LogoUrl;
                            this.company_Logo_LogoPresignedUrl = this.settingOutput['result'].company_Logo_LogoPresignedUrl;
                            this.companyLogo_FileName = this.settingOutput['result'].companyLogo_LogoUrl;
                            this.deleteVisible = true;
                            this.btnuploadDisabled = false;
                        }
                    }, (error) => {
                        this.btnuploadDisabled = false;
                        this.isInvalidFileType = true;
                    });
                }, err => {
                    console.error("Photo error", err);
                });
            }
        }
    }

    public isInvalidFileType: boolean = false;
    validateFile(name: string): boolean {
        var ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'gif' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png'
            || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'ico' || ext.toLowerCase() == 'bmp') {
            this.isInvalidFileType = false;
            return true;
        }
        else {
            this.isInvalidFileType = true;
            return false;
        }
    }

    getUploadResponse(url_: string, formData: FormData): Observable<SettingOutput> {
        return this.http.post<SettingOutput>(url_, formData);
    }
}
